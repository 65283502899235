import React from 'react';
import './DesktopNavigation.scss';
import Illustration from 'components/Illustrations/Illustration';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonSpinner
} from '@ionic/react';
import { useHistory, useLocation } from 'react-router';
import { RootState } from 'core/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  DIGITAL_PAGES,
  FULL_SERVICE_PAGES,
  HKPlatform,
  ZENDESK_HELP_URL
} from 'core/constants';
import { fetchZendeskToken } from 'core/services/UserActions';
import { openHelp } from 'core/util';

type DesktopNavigationProps = {
  headerSlot?: any;
};

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
  headerSlot,
  children,
  ...rest
}) => {
  const { isExpired, builderLogo, globalAccountSettings, warrantyURL } =
    useSelector((state: RootState) => state.home);
  const { accessToken } = useSelector((state: RootState) => state.login);
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { zendesk_token_url } = useSelector((state: RootState) => state.user);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const navigateTo = (route: string) => {
    if (route.startsWith('http://') || route.startsWith('https://')) {
      window.open(route, '_blank');
    } else {
      history.push(route);
    }
  };

  const isTutorialPage = () => {
    return location.pathname.match('/tutorial');
  };

  const isOnboardingPage = () => {
    return (
      location.pathname.match('/onboarding/what-type-of-home-do-you-own') ||
      location.pathname.match('/onboarding/what-is-your-home-address') ||
      location.pathname.match(
        '/onboarding/what-are-you-looking-to-get-out-of-this-app'
      ) ||
      location.pathname.match('/onboarding/what-type-of-homeowner-are-you') ||
      location.pathname.match('/onboarding/how-long-do-you-expect-to-stay')
    );
  };

  const goToZendesk = async () => {
    await dispatch(fetchZendeskToken(ZENDESK_HELP_URL));
    if (!!zendesk_token_url) {
      openHelp(zendesk_token_url, platformType);
    }
  };

  const NavBar = () => {
    let navigationLinks =
      isDigital && !isOnboardingPage() ? DIGITAL_PAGES : FULL_SERVICE_PAGES;

    if (warrantyURL) {
      navigationLinks = navigationLinks.map((page) => {
        if (page.title === 'Warranty') {
          return { ...page, path: warrantyURL };
        } else {
          return page;
        }
      });
    } else {
      navigationLinks = navigationLinks.filter(
        (page) => page.title !== 'Warranty'
      );
    }

    return (
      <>
        {isDigital &&
          !isOnboardingPage() &&
          navigationLinks.map((page, i) => {
            return (
              <div key={`menu-item-${i}`}>
                {isExpired && page.path === '/account' && (
                  <IonItem
                    className={
                      location.pathname.includes(page.path) ? 'selected' : ''
                    }
                    onClick={() => navigateTo(page.path)}
                  >
                    <IonIcon icon={page.icon} slot="start" />
                    {page.title}
                  </IonItem>
                )}
                {!isExpired && (
                  <IonItem
                    className={
                      location.pathname.includes(page.path) ? 'selected' : ''
                    }
                    onClick={() => navigateTo(page.path)}
                  >
                    <IonIcon icon={page.icon} slot="start" />
                    {page.title}
                  </IonItem>
                )}
              </div>
            );
          })}
        {!isDigital &&
          navigationLinks.map((page, i) => {
            return (
              <IonItem
                key={`menu-item-${i}`}
                className={
                  location.pathname.includes(page.path) ? 'selected' : ''
                }
                onClick={() => navigateTo(page.path)}
              >
                <IonIcon icon={page.icon} slot="start" />
                {page.title}
              </IonItem>
            );
          })}
      </>
    );
  };

  return platformType === HKPlatform.DESKTOP &&
    isDesktopWidth &&
    !isTutorialPage() &&
    accessToken ? (
    <IonPage className="hk-desktop-nav">
      <div className="hk-desktop-nav-header-container" {...rest}>
        <div
          className={`hk-desktop-nav-header ${
            isDigital && 'hk-desktop-digital-nav-header'
          }`}
        >
          <div className="hk-desktop-nav-header-top">
            <div onClick={() => navigateTo('/dashboard')}>
              {builderLogo ? (
                <Illustration
                  image={builderLogo}
                  className="hk-desktop-nav-header-top-logo"
                />
              ) : (
                <IonSpinner name="crescent" />
              )}
            </div>
            <IonRow className="hk-desktop-nav-header-top-nav">
              <NavBar />
              <IonButton
                className="hk-desktop-nav-header-top-help"
                color="secondary"
                onClick={goToZendesk}
              >
                Help
              </IonButton>
            </IonRow>
          </div>
        </div>
      </div>
      <div className="hk-desktop-nav-body">{children}</div>
    </IonPage>
  ) : (
    <>{children}</>
  );
};

export default DesktopNavigation;
