import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInput,
  IonPage,
  IonSpinner,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { gaBtnClickEvent, gaModalView, gaToggle } from 'core/util';
import './DeleteAccount.scss';
import Close from 'assets/icons/close.svg';
import { deleteHome } from 'core/services/HomeActions';
import { resetAppToInitialState } from 'features/shared/login/LoginActions';
import { logout } from 'features/shared/login/LoginSlice';
import { useHistory } from 'react-router';
import { hideDeleteModal } from './AccountSlice';

const DeleteAccount: React.FC = () => {
  const { loading } = useSelector((state: RootState) => state.loading);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { globalAccountSettings } = useSelector(
    (state: RootState) => state.home
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const dispatch = useDispatch();
  let history = useHistory();
  const [enableDelete, setEnableDelete] = useState(false);

  const confirmInputDelete = (value: string) => {
    value === 'DELETE' ? setEnableDelete(true) : setEnableDelete(false);
  };

  const onDelete = () => {
    dispatch(hideDeleteModal());
    gaBtnClickEvent('delete_my_account');
    if (!!homeId) {
      dispatch(deleteHome(homeId)).then(() => {
        gaToggle(globalAccountSettings?.impersonating === null);
        dispatch(logout());
        dispatch(resetAppToInitialState());
        history.push(`/login`);
      });
    }
  };

  const cancelDelete = () => {
    gaBtnClickEvent('cancel_delete_my_account');
    dispatch(hideDeleteModal());
  };

  useEffect(() => {
    gaModalView(isDigital, '/account/delete');
  }, []);

  function MobileDeleteAccountView() {
    return (
      <>
        <IonContent fullscreen className="hk-delete-account-content">
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={cancelDelete}
            >
              <IonIcon color="white" icon={Close} />
            </IonFabButton>
          </IonFab>
          <div className="hk-delete-account-body">
            <div>
              <h3 className="hk-delete-account-body-title">
                Delete Your Account
              </h3>
              <h6 className="hk-delete-account-body-subtitle">Warning!</h6>
              <p className="hk-delete-account-body-description">
                Deleting your account will remove <u>all</u> your data in this
                app and will <u>not</u> be able to be recovered.
              </p>
            </div>
            <div className="hk-delete-account-body-confirm">
              <IonInput
                className="hk-delete-account-body-confirm-input"
                onIonChange={(e) => confirmInputDelete(e.detail.value!)}
              ></IonInput>
              <p className="hk-delete-account-body-confirm-description">
                Type <span>DELETE</span> to confirm.
              </p>
            </div>
          </div>
        </IonContent>
        <IonToolbar className="hk-mobile-delete-account-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={loading}
            onClick={cancelDelete}
          >
            Cancel
          </IonButton>
          <IonButton
            color="danger"
            className="hk-delete-account-button"
            expand="block"
            strong={true}
            disabled={!enableDelete}
            onClick={onDelete}
          >
            {loading ? <IonSpinner name="dots" /> : 'Confirm Delete'}
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? MobileDeleteAccountView()
        : MobileDeleteAccountView()}
    </IonPage>
  );
};

export default DeleteAccount;
