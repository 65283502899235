import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  DEFAULT_HK_BUILDER_ID,
  firebaseConfig,
  HKEnvironment
} from 'core/constants';
import { getEnvName, getCurrentUser, getIdToken } from 'core/util';
import { Capacitor } from '@capacitor/core';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth
} from 'firebase/auth';
import { getApp } from 'firebase/app';
import { initializeApp } from 'firebase/app';
import { getFirestore, getDoc, doc } from 'firebase/firestore';
import { BuilderLogo } from 'core/types';
// import { RemoteConfig, getRemoteConfig } from 'firebase/remote-config';

export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);
// export let remoteConfig: RemoteConfig = getRemoteConfig();
// Default fetch is once every 12 hours
// Rapidly fetches remoteConfig changes for development (only uncomment in devl)
// remoteConfig.settings.minimumFetchIntervalMillis = 10000;

const refreshFirebaseToken = async () => {
  let auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence
    });
  } else {
    auth = getAuth();
  }
  getCurrentUser().then((result) => {
    if (!!result.user) {
      getIdToken();
    }
  });
};

export function useFirebaseUpload() {
  firebase.initializeApp(firebaseConfig);
  const uploadFile = async (homeId: number, name: string, file: any) => {
    await refreshFirebaseToken();
    const env = getEnvName() === HKEnvironment.PROD ? 'prod' : 'dev';
    const storage = getStorage();
    const storageRef = ref(storage, `/${env}/homes/home_${homeId}/${name}`);
    const blobFile = new Blob([file]);
    return uploadBytes(storageRef, blobFile);
  };
  return {
    uploadFile
  };
}

export const getFirebaseDownloadUrl = async (filePath: string) => {
  const storage = getStorage();
  const result = await getDownloadURL(ref(storage, `/${filePath}`));
  return result;
};

export const getFirebaseBuilderLogo = async (
  builderId: number
): Promise<BuilderLogo | undefined> => {
  try {
    const docRef = doc(db, 'builders', `builder_${builderId}`);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data() as BuilderLogo;
    }
    throw new Error('Document does not exist');
  } catch (error) {
    console.log(error);
  }
};
