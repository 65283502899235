import {
  createApiRequest,
  formatISODate,
  gaBtnClickEvent
} from '../../../../core/util';
import {
  APPOINTMENT_PROPOSALS_URL,
  APPOINTMENT_RESCHEDULE_URL,
  DATE_FORMAT_DATE_TIMESTAMP,
  HKScheduleType
} from '../../../../core/constants';
import {
  confirmAppointmentSuccess,
  confirmAppointmentError,
  fetchProposalsSuccess,
  fetchProposalsError
} from './AppointmentWizardSlice';
import store, { AppDispatch, AppThunk } from '../../../../core/store';
import {
  resetAppointmentList,
  resetVisitsList
} from '../../dashboard/DashboardSlice';
import { fetchAppointmentDetailsSuccess } from '../AppointmentDetailsSlice';

export const requestAppointmentProposals = (
  homeId: number,
  visitHash: string,
  date?: string
) =>
  createApiRequest({
    route: addOptionalDate(APPOINTMENT_PROPOSALS_URL, date),
    replace: [
      ['home_id', homeId],
      ['hash', visitHash]
    ],
    onSuccess: fetchProposalsSuccess,
    onError: fetchProposalsError,
    defaultErrorMessage: 'There was a problem loading available appointments.',
    useGlobalLoader: true
  })();

export const confirmAppointmentProposal = (
  homeId: number,
  visitHash: string,
  body: any
) =>
  createApiRequest({
    route: APPOINTMENT_PROPOSALS_URL,
    method: 'post',
    replace: [
      ['home_id', homeId],
      ['hash', visitHash]
    ],
    body: body,
    onSuccess: confirmAppointmentSuccess,
    onError: confirmAppointmentError,
    onComplete: refreshDashboardLists,
    defaultErrorMessage: 'There was a problem confirming your appointment.',
    useGlobalLoader: true
  })();

export const requestRescheduleProposals = (
  homeId: number,
  appointmentId: number,
  date?: string
) =>
  createApiRequest({
    route: addOptionalDate(APPOINTMENT_RESCHEDULE_URL, date),
    replace: [
      ['home_id', homeId],
      ['appointment_id', appointmentId]
    ],
    onSuccess: fetchProposalsSuccess,
    onError: fetchProposalsError,
    defaultErrorMessage: 'There was a problem loading available appointments.',
    useGlobalLoader: true
  })();

export const confirmRescheduleProposal = (
  homeId: number,
  appointmentId: number,
  body: any
) =>
  createApiRequest({
    route: APPOINTMENT_RESCHEDULE_URL,
    method: 'post',
    replace: [
      ['home_id', homeId],
      ['appointment_id', appointmentId]
    ],
    body: body,
    onSuccess: confirmAppointmentSuccess,
    onError: confirmAppointmentError,
    onComplete: refreshDashboardLists,
    defaultErrorMessage: 'There was a problem confirming your appointment.',
    useGlobalLoader: true
  })();

export const refreshDashboardLists =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const { appointmentWizard } = store.getState();
    if (appointmentWizard.scheduleType === HKScheduleType.RESCHEDULE) {
      dispatch(fetchAppointmentDetailsSuccess(appointmentWizard.appointment));
    }
    dispatch(resetAppointmentList());
    dispatch(resetVisitsList());
    gaBtnClickEvent('appointment_scheduled');
  };

export const addOptionalDate = (url: string, date?: string) => {
  if (!!date) {
    const formatted = formatISODate(date, DATE_FORMAT_DATE_TIMESTAMP);
    return `${url}?date=${encodeURIComponent(formatted)}`;
  }
  return url;
};
