import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToolbar,
  useIonPicker
} from '@ionic/react';
import {
  CONTACT_TYPE_LIST,
  EMAIL_REGEX,
  HKPlatform,
  PHONE_REGEX
} from 'core/constants';
import { displaySuccess } from 'core/services/AlertActions';
import { RootState } from 'core/store';
import { createContact } from 'features/shared/contacts/ContactsActions';
import { hideNewContactsModal } from 'features/shared/contacts/ContactsSlice';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import ContactsImage from 'assets/images/contacts_card.jpg';
import DownArrow from 'assets/icons/arrow-down.svg';
import './NewContact.scss';
import {
  formatMobilePhoneNumber,
  gaBtnClickEvent,
  gaModalView,
  validateURL
} from 'core/util';
import ErrorIcon from 'assets/icons/overdue.svg';

export interface NewContactForm {
  [index: string]: string;
}

export interface ContactInputField {
  label: string;
  id: string;
}

export const contactFields: ContactInputField[] = [
  { label: 'Contact Name*', id: 'name' },
  { label: 'Phone Number', id: 'mobile_phone' },
  { label: 'Type', id: 'type' },
  { label: 'Email', id: 'email' },
  { label: 'Website', id: 'url' }
];

const NewContact: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { loading } = useSelector((state: RootState) => state.contacts);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const [fields, setFields] = useState<any[]>([]);
  const [selectedContactTypeLabel, setSelectedContactTypeLabel] =
    useState<any>();

  const [present] = useIonPicker();

  const dispatch = useDispatch();

  const contactTypeAlertOptions = {
    header: 'Contact Type',
    cssClass: 'hk-contact-type-alert'
  };

  const formRule = (id: string) => {
    if (id === 'mobile_phone') {
      return {
        pattern: {
          value: PHONE_REGEX,
          message: 'Invalid phone number'
        }
      };
    }
    if (id === 'email') {
      return {
        pattern: {
          value: EMAIL_REGEX,
          message: 'Invalid email address'
        }
      };
    }
    if (id === 'type' || id === 'name') {
      return {
        required: true
      };
    }
    return { required: false };
  };

  const { control, handleSubmit, errors, formState, setValue } =
    useForm<NewContactForm>({
      defaultValues: {
        name: '',
        mobile_phone: '',
        email: '',
        type: 'contractor',
        url: ''
      },
      mode: 'onChange'
    });

  const cancelAddNewContact = () => {
    gaBtnClickEvent('cancel_new_contact');
    dispatch(hideNewContactsModal());
  };

  const saveNewContact = (formData: any) => {
    // Remove empty keys in formData object before submitting
    Object.keys(formData).forEach((k) => !formData[k] && delete formData[k]);
    gaBtnClickEvent('save_new_contact');
    if (!!homeId && !!formData) {
      if (!!formData['url']) {
        formData['url'] = validateURL(formData['url']);
      }
      dispatch(createContact(homeId, formData))
        .then(() => {
          dispatch(displaySuccess('Contact Added', 'Success'));
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const openPicker = async () => {
    present({
      cssClass: 'hk-picker',
      mode: 'ios',
      columns: [
        {
          name: 'contactTypes',
          options: CONTACT_TYPE_LIST.map((contact: any, i: number) => {
            return {
              text: `${contact.name}`,
              value: contact.type
            };
          })
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: (value) => {
            setSelectedContactTypeLabel(value.contactTypes.text);
            setValue('type', value.contactTypes.value);
          }
        }
      ]
    });
  };

  useEffect(() => {
    gaModalView(isDigital, '/contact/details/new');
    if (!!homeId) {
      setFields(contactFields);
      setSelectedContactTypeLabel('Contractor');
    }
  }, [dispatch, homeId]);

  function DesktopDigitalNewContactView() {
    return (
      <>
        <IonContent className="hk-desktop-digital-new-contact">
          {loading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-desktop-digital-new-contact-header"
                style={{
                  backgroundImage: `linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)
              ), url(${ContactsImage})`
                }}
              >
                <div className="hk-desktop-digital-new-contact-header-title">
                  Add New Contact
                </div>
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideNewContactsModal())}
                  >
                    <IonIcon color="white" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <div className="hk-desktop-digital-new-contact-body">
                <form
                  className="hk-desktop-digital-new-contact-form"
                  onSubmit={handleSubmit(saveNewContact)}
                >
                  {fields.map((field, i) => {
                    const { id, label } = field;
                    return (
                      <Controller
                        key={`controller-${i}`}
                        control={control}
                        name={id}
                        rules={formRule(id)}
                        render={({ onChange, onBlur, value }) => (
                          <IonItem
                            lines="none"
                            className="hk-desktop-digital-new-contact-form-item ion-no-padding"
                          >
                            <IonLabel position="floating" color="medium">
                              {label}
                            </IonLabel>
                            {id !== 'type' &&
                              id !== 'mobile_phone' &&
                              id !== 'email' && (
                                <IonInput
                                  className="hk-desktop-digital-new-contact-form-item-field ion-margin-bottom"
                                  onIonChange={(value) => {
                                    onChange(value);
                                  }}
                                  value={value}
                                  color={errors[field.id] ? 'danger' : 'dark'}
                                  type="text"
                                ></IonInput>
                              )}
                            {id === 'email' && (
                              <>
                                <IonInput
                                  className="hk-digital-new-contact-form-item-field ion-margin-bottom"
                                  onIonChange={(value) => {
                                    onChange(value);
                                  }}
                                  value={value}
                                  color={errors[field.id] ? 'danger' : 'dark'}
                                  type="text"
                                ></IonInput>
                                {!!errors[field.id] && (
                                  <span className="invalid-email-address-error">
                                    Invalid email
                                    <IonIcon src={ErrorIcon} />
                                  </span>
                                )}
                              </>
                            )}
                            {id === 'mobile_phone' && (
                              <>
                                <IonInput
                                  className="hk-desktop-digital-new-contact-form-item-field ion-margin-bottom"
                                  onIonChange={(e) => {
                                    onChange(
                                      formatMobilePhoneNumber(e.detail.value!)
                                    );
                                  }}
                                  maxlength={14}
                                  value={formatMobilePhoneNumber(value)}
                                  color={errors[field.id] ? 'danger' : 'dark'}
                                  type="tel"
                                  inputMode="tel"
                                  pattern="tel"
                                ></IonInput>
                                {!!errors[field.id] && (
                                  <span className="invalid-phone-number-error">
                                    Invalid phone number
                                    <IonIcon src={ErrorIcon} />
                                  </span>
                                )}
                              </>
                            )}
                            {id === 'type' && (
                              <IonSelect
                                className="hk-desktop-digital-new-contact-form-item-field-select"
                                interfaceOptions={contactTypeAlertOptions}
                                value={value}
                                color={errors[field.id] ? 'danger' : 'light'}
                                onIonChange={(e) => onChange(e.detail.value)}
                              >
                                {CONTACT_TYPE_LIST.map((contact) => {
                                  return (
                                    <IonSelectOption
                                      key={contact.type}
                                      value={contact.type}
                                    >
                                      {contact.name}
                                    </IonSelectOption>
                                  );
                                })}
                              </IonSelect>
                            )}
                          </IonItem>
                        )}
                      />
                    );
                  })}
                </form>
              </div>
            </>
          )}
        </IonContent>
        <div className="hk-desktop-digital-new-contact-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={loading}
            onClick={cancelAddNewContact}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={!formState.isValid || loading}
            onClick={handleSubmit(saveNewContact)}
          >
            {loading ? <IonSpinner name="dots" /> : 'Save'}
          </IonButton>
        </div>
      </>
    );
  }

  function MobileDigitalNewContactView() {
    return (
      <>
        <IonContent className="hk-digital-new-contact">
          {loading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-digital-new-contact-header"
                style={{
                  backgroundImage: `linear-gradient(
                    rgba(0, 0, 0, 0.4),
                    rgba(0, 0, 0, 0.4)
                  ), url(${ContactsImage})`
                }}
              >
                <div className="hk-mobile-digital-new-contact-header-title">
                  Add New Contact
                </div>
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideNewContactsModal())}
                  >
                    <IonIcon color="white" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <div className="hk-digital-new-contact-body">
                <form
                  className="hk-digital-new-contact-form"
                  onSubmit={handleSubmit(saveNewContact)}
                >
                  {fields.map((field, i) => {
                    const { id, label } = field;
                    return (
                      <Controller
                        key={`controller-${i}`}
                        control={control}
                        name={id}
                        rules={formRule(id)}
                        render={({ onChange, onBlur, value }) => (
                          <IonItem
                            lines="none"
                            className="hk-digital-new-contact-form-item ion-no-padding"
                          >
                            <IonLabel position="floating" color="medium">
                              {label}
                            </IonLabel>
                            {id !== 'type' &&
                              id !== 'mobile_phone' &&
                              id !== 'email' && (
                                <>
                                  <IonInput
                                    className="hk-digital-new-contact-form-item-field ion-margin-bottom"
                                    onIonChange={(value) => {
                                      onChange(value);
                                    }}
                                    value={value}
                                    color={errors[field.id] ? 'danger' : 'dark'}
                                    type="text"
                                  ></IonInput>
                                </>
                              )}
                            {id === 'email' && (
                              <>
                                <IonInput
                                  className="hk-digital-new-contact-form-item-field ion-margin-bottom"
                                  onIonChange={(value) => {
                                    onChange(value);
                                  }}
                                  value={value}
                                  color={errors[field.id] ? 'danger' : 'dark'}
                                  type="text"
                                ></IonInput>
                                {!!errors[field.id] && (
                                  <span className="invalid-email-address-error">
                                    Invalid email
                                    <IonIcon src={ErrorIcon} />
                                  </span>
                                )}
                              </>
                            )}
                            {id === 'mobile_phone' && (
                              <>
                                <IonInput
                                  className="hk-desktop-digital-new-contact-form-item-field ion-margin-bottom"
                                  onIonChange={(e) => {
                                    onChange(
                                      formatMobilePhoneNumber(e.detail.value!)
                                    );
                                  }}
                                  maxlength={14}
                                  value={formatMobilePhoneNumber(value)}
                                  color={errors[field.id] ? 'danger' : 'dark'}
                                  type="tel"
                                  inputMode="tel"
                                  pattern="tel"
                                ></IonInput>
                                {!!errors[field.id] && (
                                  <span className="invalid-phone-number-error">
                                    Invalid phone number
                                    <IonIcon src={ErrorIcon} />
                                  </span>
                                )}
                              </>
                            )}
                            {id === 'type' && (
                              <>
                                <div className="hk-digital-new-contact-form-picker">
                                  <IonInput
                                    readonly
                                    className="hk-desktop-digital-new-contact-form-item-field ion-margin-bottom"
                                    onClick={openPicker}
                                    onIonChange={(value) => {
                                      onChange(value);
                                    }}
                                    value={selectedContactTypeLabel}
                                    color={errors[field.id] ? 'danger' : 'dark'}
                                    type="text"
                                  ></IonInput>
                                  <IonIcon
                                    className="hk-digital-new-contact-form-picker-input-icon"
                                    icon={DownArrow}
                                  />
                                </div>
                              </>
                            )}
                          </IonItem>
                        )}
                      />
                    );
                  })}
                </form>
              </div>
            </>
          )}
        </IonContent>
        <IonToolbar className="hk-digital-new-contact-toolbar ion-padding">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={loading}
            onClick={cancelAddNewContact}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={!formState.isValid || loading}
            onClick={handleSubmit(saveNewContact)}
          >
            {loading ? <IonSpinner name="dots" /> : 'Save'}
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDigitalNewContactView()
        : MobileDigitalNewContactView()}
    </>
  );
};

export default NewContact;
