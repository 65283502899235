import React from 'react';
import './ExternalLink.scss';
import { IonIcon, IonTitle } from '@ionic/react';
import LinkImage from 'assets/icons/link.svg';

type ExternalLinkProps = {
  name: string;
  url?: string | null;
};

const ExternalLink: React.FC<ExternalLinkProps> = ({
  name = '',
  url = '',
  ...rest
}) => {
  const openLink = (link: string | null) => {
    if (link) {
      window.open(link, '_blank');
    }
  };

  const checkIfUrlValid = (url: string | null) => {
    return url
      ? url.startsWith('http://') || url.startsWith('https://')
      : false;
  };

  return (
    <div
      className={`hk-external-link ${checkIfUrlValid(url) ? '' : 'no-url'}`}
      onClick={() => openLink(url)}
      {...rest}
    >
      <IonTitle className="hk-external-link__name">{name}</IonTitle>
      {checkIfUrlValid(url) && (
        <IonIcon className="hk-external-link__icon" icon={LinkImage} />
      )}
    </div>
  );
};

export default ExternalLink;
