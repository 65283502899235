import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  useIonPicker
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DigitalCatalogDetails.scss';
import Close from 'assets/icons/close.svg';
import DownArrow from 'assets/icons/arrow-down.svg';
import ReactPlayer from 'react-player';
import {
  clearCurrentCatalogProduct,
  hideCatalogModal,
  resetDigitalCatalogTaskDetails,
  setDigitalCatalogTaskLoading
} from 'features/digital/store/DigitalCatalogSlice';
import { cameraOutline, alertOutline } from 'ionicons/icons';
import { usePhotoGallery, UserPhoto } from 'core/hooks/usePhotoGallery';
import {
  createCatalogProduct,
  fetchCatalogProductDetails,
  fetchCatalogProducts,
  fetchCatalogProductTemplate,
  fetchCatalogTask,
  fetchCatalogTaskStatusTags,
  patchCatalogTaskDetails
} from 'features/digital/store/DigitalCatalogActions';
import { useForm } from 'react-hook-form';
import {
  getFirebaseDownloadUrl,
  useFirebaseUpload
} from 'core/hooks/useFirebase';
import { displayError, displaySuccess } from 'core/services/AlertActions';
import { gaBtnClickEvent, gaModalView } from 'core/util';
import { ProductBodyDetails, TaskBodyDetails } from 'core/types';

export enum ProductAttributeType {
  STRING = 'string',
  TAGGED = 'tagged'
}

export enum PhotoType {
  MODEL = 'model',
  BRAND = 'brand'
}

const DigitalCatalogDetails: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { loading } = useSelector((state: RootState) => state.loading);
  const {
    taskLoading,
    catalogTasksList,
    catalogTaskId,
    catalogTaskProductTypeId,
    catalogTaskDetails,
    catalogTaskStatusTags,
    currentCatalogProduct,
    productTemplate,
    productsList
  } = useSelector((state: RootState) => state.digitalCatalog);

  const { takePhoto } = usePhotoGallery();
  const { uploadFile } = useFirebaseUpload();
  const [present] = useIonPicker();
  const [isTitleVisible, setIsTitleVisible] = useState(true);
  const [modelPhoto, setModelPhoto] = useState<UserPhoto | null>();
  const [brandPhoto, setBrandPhoto] = useState<UserPhoto | null>();
  const [selectedProductTags, setSelectedProductTags] = useState<any[]>([]);
  const [selectedStatusTags, setSelectedStatusTags] = useState<number[]>([]);
  const [availableProducts, setAvailableProducts] = useState<any[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<any>();
  const [selectedProductLabel, setSelectedProductLabel] = useState<any>();
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const userId = useSelector((state: RootState) => state.user.id);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty }
  } = useForm();

  const handleScroll = (event: any) => {
    if (event.detail.scrollTop > 0) {
      setIsTitleVisible(false);
    } else {
      setIsTitleVisible(true);
    }
  };

  const generatePickerOptions = () => {
    const pickerOptions = availableProducts.map((product: any, i: number) => {
      return {
        text: `${product.type.replace(/_/g, ' ')} ${i + 1}`,
        value: product.id
      };
    });
    pickerOptions.unshift({ text: 'None', value: null });
    return pickerOptions;
  };

  const openPicker = async () => {
    present({
      cssClass: 'hk-picker',
      mode: 'ios',
      columns: [
        {
          name: 'products',
          options: generatePickerOptions()
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: (value) => {
            gaBtnClickEvent('select_product');
            if (value.products.text === 'None') {
              setSelectedProductId(null);
              setSelectedProductLabel(null);
            } else {
              setSelectedProductId(value.products.value);
              setSelectedProductLabel(value.products.text);
            }
          }
        }
      ]
    });
  };

  const selectProductTag = (field: string, tag: any) => {
    const keyExists = selectedProductTags.some((item: any) => {
      return field in item;
    });
    if (keyExists) {
      if (selectedProductTags.every((item: any) => item[field] === tag)) {
        setSelectedProductTags([]);
      } else {
        const existingTagIndex = selectedProductTags.findIndex((item: any) => {
          return field in item;
        });
        setSelectedProductTags(selectedProductTags.splice(existingTagIndex, 1));
        setSelectedProductTags([...selectedProductTags, { [field]: tag }]);
      }
    } else {
      setSelectedProductTags([...selectedProductTags, { [field]: tag }]);
    }
  };

  const selectStatusTag = (tagId: number) => {
    if (selectedStatusTags.includes(tagId)) {
      setSelectedStatusTags(selectedStatusTags.filter((id) => id !== tagId));
    } else {
      setSelectedStatusTags([...selectedStatusTags, tagId]);
    }
  };

  const checkIfProductTagSelected = (field: string, tag: any) => {
    return selectedProductTags.some((item: any) => {
      return item[field] === tag;
    });
  };

  const checkIfTaskEdited = () => {
    let isEdited = false;
    if (catalogTaskDetails?.finished !== null) {
      if (
        isDirty ||
        !!selectedProductTags.length ||
        !!selectedStatusTags.length ||
        !!modelPhoto ||
        !!brandPhoto
      ) {
        isEdited = true;
      }
    }
    return isEdited;
  };

  const checkIfLastCatalogTask = () => {
    const unfinishedTasks = catalogTasksList.filter(
      (task) => task.finished === null
    );
    return unfinishedTasks.length === 1;
  };

  const hasTakePhotoStep = (name: string) => {
    return (
      name.toLowerCase().includes('take') ||
      name.toLowerCase().includes('catalog')
    );
  };

  const addPhoto = (type: string) => {
    gaBtnClickEvent('catalog_add_photo');
    takePhoto()
      .then((photo) => {
        if (!!homeId && !!photo.filePath && !!photo.webviewPath) {
          photo.filePath = `product_${new Date().getTime()}_catalog_${
            type === PhotoType.MODEL ? '1' : '2'
          }.jpg`;
          if (type === PhotoType.MODEL) {
            setModelPhoto(photo);
          } else if (type === PhotoType.BRAND) {
            setBrandPhoto(photo);
          }
        }
      })
      .catch((error) => {
        console.log(`Upload Photo Error: ${error}`);
      });
  };

  const uploadPhotos = async () => {
    dispatch(setDigitalCatalogTaskLoading(true));
    const uploads: any[] = [];
    if (!!homeId && !!modelPhoto) {
      uploads.push(
        uploadFile(homeId, modelPhoto.filePath, modelPhoto.imageData).catch(
          (e) => {
            dispatch(setDigitalCatalogTaskLoading(false));
            displayError(e);
            throw e;
          }
        )
      );
    }
    if (!!homeId && !!brandPhoto) {
      uploads.push(
        uploadFile(homeId, brandPhoto.filePath, brandPhoto.imageData).catch(
          (e) => {
            dispatch(setDigitalCatalogTaskLoading(false));
            displayError(e);
            throw e;
          }
        )
      );
    }
    return await Promise.all(uploads);
  };

  const addProduct = (formData: any) => {
    gaBtnClickEvent('catalog_add_another');
    let promises: any[] = [];
    const productBody: ProductBodyDetails = {
      home_id: homeId,
      type: productTemplate.field,
      attributes: {}
    };

    if (!!selectedProductId) {
      resetProductForm(formData);
      dispatch(clearCurrentCatalogProduct());
    } else {
      // Create PHOTO for POST body (Upload to firebase storage)
      uploadPhotos().then(
        (uploadedImages) => {
          for (const image of uploadedImages) {
            promises.push(
              getFirebaseDownloadUrl(image.metadata.fullPath).then((url) => {
                if (image.metadata.name.toLowerCase().includes('catalog_1')) {
                  productBody['model_photo'] = url;
                }
                if (image.metadata.name.toLowerCase().includes('catalog_2')) {
                  productBody['brand_photo'] = url;
                }
              })
            );
          }

          // Create TAGGED attributes from availableProductTags for POST body
          if (selectedProductTags.length > 0) {
            selectedProductTags.forEach((tag) => {
              for (const [key, value] of Object.entries(tag)) {
                productBody.attributes[key] = [value];
              }
            });
          }

          // Create STRING attributes from formData for POST body
          if (!!productTemplate?.product_attribute_schema.length) {
            productTemplate.product_attribute_schema.forEach(
              (question: any) => {
                Object.entries(formData).forEach((datum) => {
                  const [key, value] = datum;
                  if (question.field === key) {
                    productBody.attributes[question.field] = value;
                  }
                });
              }
            );
          }

          // Create Product Here AND RESET FORM HERE
          if (!!homeId && !!productBody) {
            Promise.all(promises)
              .then(async () => {
                await dispatch(createCatalogProduct(homeId, productBody));
                resetProductForm(formData);
                dispatch(displaySuccess('Product Added', 'Success'));
                dispatch(fetchCatalogProducts(homeId));
              })
              .catch((error: any) => {
                console.log(error);
              });
          }
        },
        (error) => {
          dispatch(displayError(error));
        }
      );
    }
  };

  const completeTask = (formData: any) => {
    gaBtnClickEvent('catalog_complete_task');
    let promises: any[] = [];
    let firebasePromises: any[] = [];
    const taskBody: TaskBodyDetails = {};
    const productBody: ProductBodyDetails = {
      home_id: homeId,
      type: productTemplate.field,
      attributes: {}
    };

    // Create PHOTO for POST body (Upload to firebase storage)
    uploadPhotos().then((uploadedImages) => {
      for (const image of uploadedImages) {
        firebasePromises.push(
          getFirebaseDownloadUrl(image.metadata.fullPath).then((url) => {
            if (image.metadata.name.toLowerCase().includes('catalog_1')) {
              productBody['model_photo'] = url;
            }
            if (image.metadata.name.toLowerCase().includes('catalog_2')) {
              productBody['brand_photo'] = url;
            }
          })
        );
      }

      Promise.all(firebasePromises)
        .then(() => {
          // Create TAGGED attributes from availableProductTags for POST body
          if (selectedProductTags.length > 0) {
            selectedProductTags.forEach((tag) => {
              for (const [key, value] of Object.entries(tag)) {
                productBody.attributes[key] = [value];
              }
            });
          }

          // Create STRING attributes from formData for POST body
          if (!!productTemplate?.product_attribute_schema.length) {
            productTemplate.product_attribute_schema.forEach(
              (question: any) => {
                Object.entries(formData).forEach((datum) => {
                  const [key, value] = datum;
                  if (question.field === key) {
                    productBody.attributes[question.field] = value;
                  }
                });
              }
            );
          }
          // Create Product Here
          if (!!homeId && !!productBody) {
            promises.push(dispatch(createCatalogProduct(homeId, productBody)));
          }
          // Catalog Task Submission (DONE)
          if (!!homeId && !!catalogTaskId) {
            taskBody.finished = new Date();
            taskBody.finished_by_id = userId;
            taskBody.tag_ids = selectedStatusTags;
            taskBody.notes = formData.notes;
            promises.push(
              dispatch(patchCatalogTaskDetails(homeId, catalogTaskId, taskBody))
            );
          }
          Promise.all(promises)
            .then(() => {
              const completedMsg =
                catalogTaskDetails?.finished !== null
                  ? 'Task Updated'
                  : 'Task Completed';
              if (!checkIfLastCatalogTask()) {
                dispatch(displaySuccess(completedMsg, 'Success'));
              }
              dispatch(hideCatalogModal());
            })
            .catch((error: any) => {
              console.log(error);
            });
        })
        .catch((error: any) => {
          console.log(error);
        });
    });
  };

  const noItem = (notes: any) => {
    gaBtnClickEvent('catalog_no_item');

    const taskBody: TaskBodyDetails = {};
    if (!!homeId && !!catalogTaskId) {
      taskBody.finished = new Date();
      taskBody.finished_by_id = userId;
      taskBody.tag_ids = selectedStatusTags;
      taskBody.notes = notes;
      dispatch(patchCatalogTaskDetails(homeId, catalogTaskId, taskBody))
        .then(() => {
          dispatch(hideCatalogModal());
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const resetProductForm = (formData: any) => {
    setModelPhoto(null);
    setBrandPhoto(null);
    setSelectedProductId(null);
    setSelectedProductLabel(null);
    Object.entries(formData).forEach((datum) => {
      const [key] = datum;
      if (key !== 'notes') {
        setValue(key, '');
      }
    });
  };

  useEffect(() => {
    dispatch(resetDigitalCatalogTaskDetails());
    if (!!homeId && !!catalogTaskId && !!catalogTaskProductTypeId) {
      dispatch(fetchCatalogTask(homeId, catalogTaskId));
      dispatch(fetchCatalogTaskStatusTags());
      dispatch(fetchCatalogProductTemplate(catalogTaskProductTypeId));
      dispatch(fetchCatalogProducts(homeId));
    }
  }, [dispatch, homeId, catalogTaskId, catalogTaskProductTypeId]);

  useEffect(() => {
    if (!!catalogTaskDetails) {
      setValue('notes', catalogTaskDetails.notes);
      setSelectedStatusTags(
        catalogTaskDetails.status_tags.map((tag: any) => tag.id)
      );
    }
  }, [catalogTaskDetails]);

  useEffect(() => {
    if (!!productsList) {
      const matchingProductsList = productsList.filter(
        (product: any) => product.type_id === catalogTaskProductTypeId
      );
      setAvailableProducts(matchingProductsList);
    }
  }, [productsList]);

  useEffect(() => {
    if (!!homeId && catalogTaskProductTypeId && !!selectedProductId) {
      dispatch(fetchCatalogProductDetails(homeId, Number(selectedProductId)));
    }
  }, [selectedProductId]);

  useEffect(() => {
    gaModalView(isDigital, '/catalog/task');
  }, []);

  function CurrentCatalogQuestions() {
    return (
      <>
        {!!catalogTaskDetails?.name &&
          !!currentCatalogProduct &&
          hasTakePhotoStep(catalogTaskDetails.name) && (
            // Photo Questions
            <div className="hk-digital-catalog-details-body-steps">
              <div className="hk-digital-catalog-details-body-steps-card">
                <div className="hk-digital-catalog-details-body-steps-card-title">
                  1: Take a picture of, Model Number and Serial Number Details
                </div>
                <div className="hk-digital-catalog-details-body-steps-card-subtitle">
                  required task
                </div>
                <div className="hk-digital-catalog-details-body-steps-card-description">
                  <span>
                    This can be found on the inside or outside of the{' '}
                    {!!productTemplate?.label
                      ? productTemplate?.label
                      : 'product'}
                    .
                  </span>
                  <div className="hk-digital-catalog-details-body-steps-card-description-image">
                    {!!currentCatalogProduct.assets[0] && !modelPhoto && (
                      <>
                        <IonImg
                          src={currentCatalogProduct.assets[0].file_url}
                        />
                      </>
                    )}
                    {!!modelPhoto && (
                      <>
                        <IonImg src={modelPhoto.webviewPath} />
                        <input
                          name="model_photo"
                          ref={register}
                          type="hidden"
                          value={modelPhoto.webviewPath}
                        />
                      </>
                    )}
                  </div>
                  <IonButton
                    className="hk-digital-catalog-details-body-steps-card-description-button"
                    onClick={() => addPhoto(PhotoType.MODEL)}
                  >
                    <IonIcon color="dark" icon={cameraOutline} />
                    {'Replace Photo'}
                  </IonButton>
                </div>
              </div>
              <div className="hk-digital-catalog-details-body-steps-card">
                <div className="hk-digital-catalog-details-body-steps-card-title">
                  2: Take a picture of Brand / Manufacturer.
                </div>
                <div className="hk-digital-catalog-details-body-steps-card-description">
                  <span>
                    Typically found on the front or back of the{' '}
                    {!!productTemplate?.label
                      ? productTemplate?.label
                      : 'product'}
                    .
                  </span>
                  <div className="hk-digital-catalog-details-body-steps-card-description-image">
                    {!!currentCatalogProduct.assets[1] && !brandPhoto && (
                      <>
                        <IonImg
                          src={currentCatalogProduct.assets[1].file_url}
                        />
                      </>
                    )}
                    {!!brandPhoto && (
                      <>
                        <IonImg src={brandPhoto.webviewPath} />
                        <input
                          name="brand_photo"
                          ref={register}
                          type="hidden"
                          value={brandPhoto.webviewPath}
                        />
                      </>
                    )}
                  </div>
                  <IonButton
                    className="hk-digital-catalog-details-body-steps-card-description-button"
                    onClick={() => addPhoto(PhotoType.BRAND)}
                  >
                    <IonIcon color="dark" icon={cameraOutline} />
                    {'Replace Photo'}
                  </IonButton>
                </div>
              </div>
            </div>
          )}
        {!!productTemplate?.product_attribute_schema.length &&
          productTemplate.product_attribute_schema.map(
            (attribute: any, i: number) => {
              return (
                // Tagged Questions
                <div key={`attribute-${i}`}>
                  {attribute.type === ProductAttributeType.TAGGED ? (
                    <div
                      className="hk-digital-catalog-details-body-steps-card"
                      key={`tagged-question-${i}`}
                    >
                      <div className="hk-digital-catalog-details-body-steps-card-title">
                        {`${
                          !!catalogTaskDetails?.name &&
                          hasTakePhotoStep(catalogTaskDetails.name)
                            ? i + 2
                            : i + 1
                        }:`}{' '}
                        {attribute.label}
                      </div>
                      <div className="hk-digital-catalog-details-body-steps-card-description">
                        <div className="product-tag-container">
                          {!!attribute.allow &&
                            attribute.allow.map((tag: any, i: number) => (
                              <div
                                className={`product-tag ${
                                  checkIfProductTagSelected(
                                    attribute.field,
                                    tag
                                  )
                                    ? 'product-tag-selected'
                                    : ''
                                }`}
                                key={`product-tag-${i}`}
                                onClick={() =>
                                  selectProductTag(attribute.field, tag)
                                }
                              >
                                {tag}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    // Text Input Questions
                    <div
                      className="hk-digital-catalog-details-body-steps-card"
                      key={`input-question-${i}`}
                    >
                      <div className="hk-digital-catalog-details-body-steps-card-title">
                        {`${
                          !!catalogTaskDetails?.name &&
                          hasTakePhotoStep(catalogTaskDetails.name)
                            ? i + 3
                            : i + 1
                        }:`}{' '}
                        {attribute.label}
                      </div>
                      <div className="hk-digital-catalog-details-body-steps-card-description">
                        <IonInput
                          className="hk-digital-catalog-details-input"
                          placeholder="-"
                          readonly
                        ></IonInput>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
      </>
    );
  }

  function CatalogTaskQuestions() {
    return (
      <>
        {!!catalogTaskDetails?.name &&
          hasTakePhotoStep(catalogTaskDetails.name) && (
            // Photo Questions
            <div className="hk-digital-catalog-details-body-steps">
              <div className="hk-digital-catalog-details-body-steps-card">
                <div className="hk-digital-catalog-details-body-steps-card-title">
                  1: Take a picture of, Model Number and Serial Number Details
                </div>
                <div className="hk-digital-catalog-details-body-steps-card-subtitle">
                  <IonIcon icon={alertOutline} />
                  <span>required task</span>
                </div>
                <div className="hk-digital-catalog-details-body-steps-card-description">
                  <span>
                    This can be found on the inside or outside of the{' '}
                    {!!productTemplate?.label
                      ? productTemplate?.label
                      : 'product'}
                    .
                  </span>
                  <div className="hk-digital-catalog-details-body-steps-card-description-image">
                    {!!modelPhoto && (
                      <>
                        <IonImg src={modelPhoto.webviewPath} />
                        <input
                          name="model_photo"
                          ref={register}
                          type="hidden"
                          value={modelPhoto.webviewPath}
                        />
                      </>
                    )}
                  </div>
                  <IonButton
                    className="hk-digital-catalog-details-body-steps-card-description-button"
                    onClick={() => addPhoto(PhotoType.MODEL)}
                  >
                    <IonIcon color="dark" icon={cameraOutline} />
                    {!modelPhoto ? 'Add Photo' : 'Replace Photo'}
                  </IonButton>
                </div>
              </div>
              <div className="hk-digital-catalog-details-body-steps-card">
                <div className="hk-digital-catalog-details-body-steps-card-title">
                  2: Take a picture of Brand / Manufacturer.
                </div>
                <div className="hk-digital-catalog-details-body-steps-card-description">
                  <span>
                    Typically found on the front or back of the{' '}
                    {!!productTemplate?.label
                      ? productTemplate?.label
                      : 'product'}
                    .
                  </span>
                  <div className="hk-digital-catalog-details-body-steps-card-description-image">
                    {!!brandPhoto && (
                      <>
                        <IonImg src={brandPhoto.webviewPath} />
                        <input
                          name="brand_photo"
                          ref={register}
                          type="hidden"
                          value={brandPhoto.webviewPath}
                        />
                      </>
                    )}
                  </div>
                  <IonButton
                    className="hk-digital-catalog-details-body-steps-card-description-button"
                    onClick={() => addPhoto(PhotoType.BRAND)}
                  >
                    <IonIcon color="dark" icon={cameraOutline} />
                    {!brandPhoto ? 'Add Photo' : 'Replace Photo'}
                  </IonButton>
                </div>
              </div>
            </div>
          )}
        {!!productTemplate?.product_attribute_schema.length &&
          productTemplate.product_attribute_schema.map(
            (attribute: any, i: number) => {
              return (
                // Tagged Questions
                <div key={`attribute-${i}`}>
                  {attribute.type === ProductAttributeType.TAGGED ? (
                    <div
                      className="hk-digital-catalog-details-body-steps-card"
                      key={`tagged-question-${i}`}
                    >
                      <div className="hk-digital-catalog-details-body-steps-card-title">
                        {`${
                          !!catalogTaskDetails?.name &&
                          hasTakePhotoStep(catalogTaskDetails.name)
                            ? i + 2
                            : i + 1
                        }:`}{' '}
                        {attribute.label}
                      </div>
                      <div className="hk-digital-catalog-details-body-steps-card-description">
                        <div className="product-tag-container">
                          {!!attribute.allow &&
                            attribute.allow.map((tag: any, i: number) => (
                              <div
                                className={`product-tag ${
                                  checkIfProductTagSelected(
                                    attribute.field,
                                    tag
                                  )
                                    ? 'product-tag-selected'
                                    : ''
                                }`}
                                key={`product-tag-${i}`}
                                onClick={() =>
                                  selectProductTag(attribute.field, tag)
                                }
                              >
                                {tag}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    // Text Input Questions
                    <div
                      className="hk-digital-catalog-details-body-steps-card"
                      key={`input-question-${i}`}
                    >
                      <div className="hk-digital-catalog-details-body-steps-card-title">
                        {`${
                          !!catalogTaskDetails?.name &&
                          hasTakePhotoStep(catalogTaskDetails.name)
                            ? i + 3
                            : i + 1
                        }:`}{' '}
                        {attribute.label}
                      </div>
                      <div className="hk-digital-catalog-details-body-steps-card-description">
                        <IonInput
                          name={attribute.field}
                          ref={register}
                          className="hk-digital-catalog-details-input"
                          placeholder={`Enter ${attribute.label}...`}
                        ></IonInput>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
      </>
    );
  }

  function DesktopDigitalCatalogDetailsView() {
    return (
      <>
        <IonContent
          scrollEvents={true}
          onIonScroll={handleScroll}
          className="hk-desktop-digital-catalog-details"
        >
          {taskLoading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-desktop-digital-catalog-details-header"
                style={!isTitleVisible ? { height: '90px' } : {}}
              >
                <div className="hk-desktop-digital-catalog-details-header-title">
                  {!!productTemplate?.type_group_name
                    ? productTemplate.type_group_name
                    : 'Product Type Name'}
                </div>
                {isTitleVisible && (
                  <div
                    id="hide"
                    className="hk-desktop-digital-catalog-details-header-description"
                  >
                    {!!catalogTaskDetails?.name
                      ? catalogTaskDetails.name
                      : 'Catalog Refrigerator (or Fridge/Freezer, Wine Refrigerator, Beverage Center)'}
                  </div>
                )}
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideCatalogModal())}
                  >
                    <IonIcon color="dark" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <form onSubmit={handleSubmit(completeTask)}>
                <div
                  className={`hk-desktop-digital-catalog-details-body ${
                    catalogTaskDetails?.finished === null
                      ? 'hk-desktop-digital-task-details-body-margin-bottom'
                      : ''
                  }`}
                >
                  {!!availableProducts.length && (
                    <div>
                      <IonSelect
                        className="hk-desktop-digital-catalog-details-body-select-product"
                        placeholder="Select Product"
                        value={selectedProductId}
                        onIonChange={(e) =>
                          setSelectedProductId(e.detail.value)
                        }
                      >
                        {availableProducts.map((product: any, i: number) => {
                          return (
                            <IonSelectOption
                              key={'product' + product.id}
                              value={product.id}
                            >
                              {`${product.type.replace(/_/g, ' ')} ${i + 1}`}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    </div>
                  )}
                  <>
                    {!!selectedProductId
                      ? CurrentCatalogQuestions()
                      : CatalogTaskQuestions()}
                  </>
                  {!!catalogTaskDetails?.tutorial_url && (
                    <div className="hk-desktop-digital-catalog-details-body-video">
                      <div className="hk-desktop-digital-task-details-body-video-title">
                        Video Guide:
                      </div>
                      <div className="hk-desktop-digital-task-details-body-video-content">
                        <div className="video">
                          <>
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              controls={true}
                              url={catalogTaskDetails.tutorial_url}
                            />
                          </>
                        </div>
                        {/* TODO: Add back when video's have descriptions
                    <div className="video-description">
                      <div className="video-description-title">
                        HVAC Air and Fan
                      </div>
                      <div className="video-description-subtitle">
                        We walk you through everything on how to successfully
                        catalog your refrigeration device.
                      </div>
                    </div> */}
                      </div>
                    </div>
                  )}
                  <IonInput
                    name="notes"
                    ref={register}
                    className="hk-desktop-digital-catalog-details-input notes"
                    placeholder="Leave a note about, location, color, etc..."
                    value={getValues('notes')}
                  ></IonInput>
                  {catalogTaskStatusTags?.length !== 0 && (
                    <div className="hk-desktop-digital-catalog-details-body-tags">
                      <div className="hk-desktop-digital-catalog-details-body-tags-title">
                        Add a Tag:
                      </div>
                      <div className="hk-desktop-digital-catalog-details-body-tags-content">
                        {!!catalogTaskStatusTags &&
                          catalogTaskStatusTags.map((tag, i) => {
                            return (
                              <>
                                {tag.digital && (
                                  <div
                                    className={`tag ${
                                      selectedStatusTags.includes(tag.id) &&
                                      'tag-selected'
                                    }`}
                                    key={`tag-${i}`}
                                    onClick={() => selectStatusTag(tag.id)}
                                  >
                                    {tag.name}
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="hk-desktop-digital-catalog-details-footer">
                  <div className="hk-desktop-digital-catalog-details-footer-button-container">
                    <div
                      className="hk-desktop-digital-catalog-details-footer-no-item"
                      onClick={() => noItem(getValues('notes'))}
                    >
                      No Item
                    </div>
                    <IonButton
                      color="white"
                      className="hk-desktop-digital-catalog-details-footer-button"
                      type="button"
                      disabled={!modelPhoto}
                      onClick={() => addProduct(getValues())}
                    >
                      Add Another
                    </IonButton>
                    {catalogTaskDetails?.finished === null && (
                      <IonButton
                        className="hk-desktop-digital-catalog-details-footer-button"
                        color="primary"
                        type="submit"
                        disabled={!modelPhoto || loading}
                      >
                        {loading ? <IonSpinner name="dots" /> : 'Complete Task'}
                      </IonButton>
                    )}
                    {catalogTaskDetails?.finished !== null && (
                      <IonButton
                        className="hk-desktop-digital-catalog-details-footer-button"
                        color="primary"
                        type="submit"
                        disabled={!checkIfTaskEdited() || loading}
                      >
                        {loading ? <IonSpinner name="dots" /> : 'Update Task'}
                      </IonButton>
                    )}
                  </div>
                </div>
              </form>
            </>
          )}
        </IonContent>
      </>
    );
  }

  function MobileDigitalCatalogDetailsView() {
    return (
      <>
        <IonContent
          scrollEvents={true}
          onIonScroll={handleScroll}
          className="hk-digital-catalog-details"
        >
          {taskLoading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-digital-catalog-details-header"
                style={!isTitleVisible ? { height: '90px' } : {}}
              >
                <div className="hk-digital-catalog-details-header-title">
                  {!!productTemplate?.type_group_name
                    ? productTemplate.type_group_name
                    : 'Product Type Name'}
                </div>
                {isTitleVisible && (
                  <div
                    id="hide"
                    className="hk-digital-catalog-details-header-description"
                  >
                    {!!catalogTaskDetails?.name
                      ? catalogTaskDetails.name
                      : 'Catalog Refrigerator (or Fridge/Freezer, Wine Refrigerator, Beverage Center)'}
                  </div>
                )}
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideCatalogModal())}
                  >
                    <IonIcon color="dark" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <form onSubmit={handleSubmit(completeTask)}>
                <div
                  className={`hk-digital-catalog-details-body ${
                    catalogTaskDetails?.finished === null
                      ? 'hk-digital-catalog-details-body-margin-bottom'
                      : ''
                  }`}
                >
                  {!!availableProducts.length && (
                    <div className="hk-digital-catalog-details-body-picker">
                      <div className="hk-digital-catalog-details-body-picker-title">
                        Select Product
                      </div>
                      <input
                        className="hk-digital-catalog-details-body-picker-input"
                        name={'name'}
                        placeholder={'Select Product...'}
                        defaultValue={selectedProductLabel}
                        type="text"
                        onClick={openPicker}
                      />
                      <IonIcon
                        className="hk-digital-catalog-details-body-picker-input-icon"
                        icon={DownArrow}
                      />
                    </div>
                  )}
                  <>
                    {!!selectedProductId
                      ? CurrentCatalogQuestions()
                      : CatalogTaskQuestions()}
                  </>
                  {!!catalogTaskDetails?.tutorial_url && (
                    <div className="hk-digital-catalog-details-body-video">
                      <div className="hk-digital-task-details-body-video-title">
                        Video Guide:
                      </div>
                      <div className="hk-digital-task-details-body-video-content">
                        <div className="video">
                          <>
                            <ReactPlayer
                              width="100%"
                              height="100%"
                              controls={true}
                              url={catalogTaskDetails.tutorial_url}
                            />
                          </>
                        </div>
                        {/* TODO: Add back when video's have descriptions
                        <div className="video-description">
                          <div className="video-description-title">
                            HVAC Air and Fan
                          </div>
                          <div className="video-description-subtitle">
                            We walk you through everything on how to successfully
                            catalog your refrigeration device.
                          </div>
                        </div> */}
                      </div>
                    </div>
                  )}
                  <IonInput
                    name="notes"
                    ref={register}
                    className="hk-digital-catalog-details-input notes"
                    placeholder="Leave a note about, location, color, etc..."
                    type="text"
                    value={getValues('notes')}
                  ></IonInput>
                  {catalogTaskStatusTags?.length !== 0 && (
                    <div className="hk-digital-catalog-details-body-tags">
                      <div className="hk-digital-catalog-details-body-tags-title">
                        Add a Tag:
                      </div>
                      <div className="hk-digital-catalog-details-body-tags-content">
                        {!!catalogTaskStatusTags &&
                          catalogTaskStatusTags.map((tag, i) => {
                            return (
                              <>
                                {tag.digital && (
                                  <div
                                    className={`tag ${
                                      selectedStatusTags.includes(tag.id) &&
                                      'tag-selected'
                                    }`}
                                    key={`tag-${i}`}
                                    onClick={() => selectStatusTag(tag.id)}
                                  >
                                    {tag.name}
                                  </div>
                                )}
                              </>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="hk-digital-catalog-details-footer">
                  <div className="hk-digital-catalog-details-footer-button-container">
                    <IonButton
                      color="white"
                      className="hk-digital-catalog-details-footer-button"
                      type="button"
                      disabled={!modelPhoto}
                      onClick={() => addProduct(getValues())}
                    >
                      Add Another
                    </IonButton>
                    {catalogTaskDetails?.finished === null && (
                      <IonButton
                        className="hk-digital-catalog-details-footer-button"
                        color="primary"
                        type="submit"
                        disabled={!modelPhoto || loading}
                      >
                        {loading ? <IonSpinner name="dots" /> : 'Complete Task'}
                      </IonButton>
                    )}
                    {catalogTaskDetails?.finished !== null && (
                      <IonButton
                        className="hk-digital-catalog-details-footer-button"
                        color="primary"
                        type="submit"
                        disabled={!checkIfTaskEdited() || loading}
                      >
                        {loading ? <IonSpinner name="dots" /> : 'Update Task'}
                      </IonButton>
                    )}
                  </div>
                  <div
                    className="hk-digital-catalog-details-footer-no-item"
                    onClick={() => noItem(getValues('notes'))}
                  >
                    No Item
                  </div>
                </div>
              </form>
            </>
          )}
        </IonContent>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDigitalCatalogDetailsView()
        : MobileDigitalCatalogDetailsView()}
    </>
  );
};

export default DigitalCatalogDetails;
