import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HKBillingPeriod } from 'core/constants';

interface BillingState {
  loading: boolean;
  error: any;
  availablePlans: any;
  availableAddOns: any;
  currentPlans: any;
  currentBillingInfo: any;
  promoCode: string;
  newAddOns: any;
  newPlan: any;
  newCreditCard: any;
  newBillingPeriod: HKBillingPeriod;
  autoRenew: boolean;
  addOnModalVisible: boolean;
  subscriptionSummaryModalVisible: boolean;
}

export const initialState: BillingState = {
  loading: false,
  error: null,
  availablePlans: [],
  availableAddOns: [],
  currentPlans: [],
  currentBillingInfo: null,
  promoCode: '',
  newAddOns: [],
  newPlan: null,
  newCreditCard: null,
  newBillingPeriod: HKBillingPeriod.MONTHLY,
  autoRenew: true,
  addOnModalVisible: false,
  subscriptionSummaryModalVisible: false
};

export const BillingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    fetchSubscriptionPlansStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    fetchSubscriptionPlansSuccess: (state, action: PayloadAction<any>) => {
      state.availablePlans = action.payload.available_plans;
      state.currentPlans = action.payload.current_plans;
      state.loading = false;
    },
    fetchSubscriptionPlansFailure: (state, action: PayloadAction<any>) => {
      state.availablePlans = [];
      state.loading = false;
    },
    fetchCreditCardInfoStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    fetchCreditCardInfoSuccess: (state, action: PayloadAction<any>) => {
      if (Object.keys(action.payload.billing_info).length !== 0) {
        state.currentBillingInfo = action.payload.billing_info;
      }
      state.loading = false;
    },
    fetchCreditCardInfoFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    renewSubscriptionStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    renewSubscriptionSuccess: (state, action: PayloadAction<any>) => {
      if (
        !!action.payload.billing_info &&
        Object.keys(action.payload.billing_info).length !== 0
      ) {
        state.currentBillingInfo = action.payload.billing_info;
      }
      state.loading = false;
    },
    renewSubscriptionFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateSubscriptionStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    updateSubscriptionSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    updateSubscriptionFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    deleteSubscriptionStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    deleteSubscriptionSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    deleteSubscriptionFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    removeSavedCard: (state) => {
      state.currentBillingInfo = null;
    },
    setPromoCode: (state, action: PayloadAction<any>) => {
      state.promoCode = action.payload;
      state.loading = false;
    },
    setNewBillingPeriod: (state, action: PayloadAction<any>) => {
      state.newBillingPeriod = action.payload;
      state.loading = false;
    },
    setNewCreditCard: (state, action: PayloadAction<any>) => {
      state.newCreditCard = action.payload;
      state.loading = false;
    },
    setNewAddOns: (state, action: PayloadAction<any>) => {
      state.newAddOns = [];
      state.newAddOns = action.payload;
      state.loading = false;
    },
    setNewPlan: (state, action: PayloadAction<any>) => {
      state.newPlan = action.payload;
      state.loading = false;
    },
    setAutoRenew: (state, action: PayloadAction<any>) => {
      state.autoRenew = action.payload;
    },
    showAddOnsModal: (state, action: PayloadAction<any>) => {
      state.availableAddOns = action.payload;
      state.addOnModalVisible = true;
    },
    hideAddOnsModal: (state) => {
      state.availableAddOns = [];
      state.addOnModalVisible = false;
    },
    showSubscriptionSummaryModal: (state) => {
      state.subscriptionSummaryModalVisible = true;
    },
    hideSubscriptionSummaryModal: (state) => {
      state.subscriptionSummaryModalVisible = false;
    },
    resetBilling: () => initialState
  }
});

export const {
  fetchSubscriptionPlansStart,
  fetchSubscriptionPlansSuccess,
  fetchSubscriptionPlansFailure,
  fetchCreditCardInfoStart,
  fetchCreditCardInfoSuccess,
  fetchCreditCardInfoFailure,
  renewSubscriptionStart,
  renewSubscriptionSuccess,
  renewSubscriptionFailure,
  updateSubscriptionStart,
  updateSubscriptionSuccess,
  updateSubscriptionFailure,
  deleteSubscriptionStart,
  deleteSubscriptionSuccess,
  deleteSubscriptionFailure,
  removeSavedCard,
  setPromoCode,
  setNewBillingPeriod,
  setNewCreditCard,
  setNewAddOns,
  setNewPlan,
  setAutoRenew,
  showAddOnsModal,
  hideAddOnsModal,
  showSubscriptionSummaryModal,
  hideSubscriptionSummaryModal,
  resetBilling
} = BillingSlice.actions;

export default BillingSlice.reducer;
