import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonItem,
  IonToolbar,
  IonTextarea
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OnboardingQuestion5.scss';
import { useHistory } from 'react-router';
import LoadingIonList from 'core/layout/LoadingIonList';
import CompleteIcon from 'assets/icons/complete.svg';
import { useEffect, useState } from 'react';
import {
  saveOnboarding5,
  saveOnboardingOther5,
  setSignupLoading
} from 'features/shared/signup/SignupSlice';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import { updateHome } from 'core/services/HomeActions';
import { gaBtnClickEvent } from 'core/util';

interface LengthOfStayType {
  title: string;
}

const lengthOfStayTypes: LengthOfStayType[] = [
  {
    title: 'Less than a year'
  },
  {
    title: '1-3 years'
  },
  {
    title: '3-5 years'
  },
  {
    title: 'More than 5 years'
  },
  {
    title: 'Other'
  }
];

const OnboardingQuestion5: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { currentHome } = useSelector((state: RootState) => state.home);

  const {
    home_type,
    home_id,
    onboarding_3,
    onboarding_4,
    onboarding_5,
    onboarding_other_3,
    onboarding_other_4,
    onboarding_other_5
  } = useSelector((state: RootState) => state.signup);
  const [selectedLength, setSelectedLength] = useState<string>('');

  const onSubmit = () => {
    gaBtnClickEvent('submit_onboarding_questions');
    dispatch(setSignupLoading(true));
    const body: any = {
      change_type: home_type,
      question_1: onboarding_3,
      question_2: onboarding_4,
      question_3: selectedLength
    };
    if (onboarding_3.includes('Other')) {
      body['question_1_other'] = onboarding_other_3;
    }
    if (onboarding_4 === 'Other') {
      body['question_2_other'] = onboarding_other_4;
    }
    if (selectedLength === 'Other') {
      dispatch(saveOnboarding5(selectedLength));
      body['question_3_other'] = onboarding_other_5;
    } else {
      dispatch(saveOnboarding5(selectedLength));
    }
    let id = !!home_id ? home_id : currentHome?.id;
    dispatch(updateHome(body, id!));
    history.push(`/dashboard`);
    dispatch(setSignupLoading(false));
  };

  useEffect(() => {
    if (!!onboarding_5) {
      setSelectedLength(onboarding_5);
    }
  }, [dispatch, onboarding_5]);

  function DesktopOnboardingView() {
    return (
      <IonPage>
        <div className="desktop-onboarding-5-header-container">
          <div className="desktop-onboarding-5-header">
            How long do you expect to stay in your current home?
          </div>
          <div className="desktop-onboarding-5-subheader">
            Select the one that best describes your situation
          </div>
        </div>
        <IonContent
          className="desktop-onboarding-5-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <LoadingIonList
            className="desktop-onboarding-5-view-list"
            label="Length Of Stay Type"
            items={lengthOfStayTypes}
            loading={false}
          >
            {lengthOfStayTypes.map((length: LengthOfStayType, i: number) => {
              const { title } = length;
              return (
                <IonItem
                  key={`length-${i}`}
                  lines="none"
                  onClick={() => setSelectedLength(title)}
                >
                  {title === 'Other' ? (
                    <div
                      className={`desktop-onboarding-5-view-card ${
                        selectedLength === title &&
                        'desktop-onboarding-5-view-card-selected'
                      }`}
                    >
                      <div className="desktop-onboarding-5-view-card-body">
                        <div className="desktop-onboarding-5-view-card-body-title">
                          {title}
                        </div>
                        <div className="desktop-onboarding-5-view-card-body-input">
                          <IonTextarea
                            name="other"
                            placeholder="Enter Text"
                            autoCorrect="off"
                            autoCapitalize="none"
                            value={
                              !!onboarding_other_5 ? onboarding_other_5 : ''
                            }
                            onIonChange={(e) =>
                              dispatch(saveOnboardingOther5(e.detail.value!))
                            }
                            rows={1}
                            maxlength={255}
                            autoGrow={true}
                          ></IonTextarea>
                        </div>
                      </div>
                      <div className="desktop-onboarding-5-view-card-checkmark">
                        {selectedLength === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="desktop-onboarding-5-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={`home-type-${i}`}
                      className={`desktop-onboarding-5-view-card ${
                        selectedLength === title &&
                        'desktop-onboarding-5-view-card-selected'
                      }`}
                    >
                      <div className="desktop-onboarding-5-view-card-body">
                        <div className="desktop-onboarding-5-view-card-body-title">
                          {title}
                        </div>
                      </div>
                      <div className="desktop-onboarding-5-view-card-checkmark">
                        {selectedLength === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="desktop-onboarding-5-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  )}
                </IonItem>
              );
            })}
          </LoadingIonList>
          <div className="desktop-onboarding-5-button-container">
            <IonButton
              className="desktop-onboarding-5-button"
              color="primary"
              type="button"
              disabled={!selectedLength}
              onClick={() => onSubmit()}
            >
              Get Started
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileOnboardingView() {
    return (
      <IonPage>
        <BackButtonHeader title={''} />
        <div className="onboarding-5-header-container">
          <h3 className="onboarding-5-header">
            <b> How long do you expect to stay in your current home?</b>
          </h3>
          <h6 className="onboarding-5-subheader">
            Select the one that best describes your situation
          </h6>
        </div>
        <IonContent className="onboarding-5-view" fullscreen scrollY={true}>
          <LoadingIonList
            className="onboarding-5-view-list"
            label="Length Of Stay Type"
            items={lengthOfStayTypes}
            loading={false}
          >
            {lengthOfStayTypes.map((length: LengthOfStayType, i: number) => {
              const { title } = length;
              return (
                <IonItem
                  key={`length-${i}`}
                  lines="none"
                  onClick={() => setSelectedLength(title)}
                >
                  {title === 'Other' ? (
                    <div
                      className={`onboarding-5-view-card ${
                        selectedLength === title &&
                        'onboarding-5-view-card-selected'
                      }`}
                    >
                      <div className="onboarding-5-view-card-body">
                        <div className="onboarding-5-view-card-body-title">
                          {title}
                        </div>
                        <div className="onboarding-5-view-card-body-input">
                          <IonTextarea
                            name="other"
                            placeholder="Enter Text"
                            autoCorrect="off"
                            autoCapitalize="none"
                            value={
                              !!onboarding_other_5 ? onboarding_other_5 : ''
                            }
                            onIonChange={(e) =>
                              dispatch(saveOnboardingOther5(e.detail.value!))
                            }
                            rows={1}
                            maxlength={255}
                            autoGrow={true}
                          ></IonTextarea>
                        </div>
                      </div>
                      <div className="onboarding-5-view-card-checkmark">
                        {selectedLength === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="onboarding-5-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`onboarding-5-view-card ${
                        selectedLength === title &&
                        'onboarding-5-view-card-selected'
                      }`}
                    >
                      <div className="onboarding-5-view-card-body">
                        <div className="onboarding-5-view-card-body-title">
                          {title}
                        </div>
                      </div>
                      <div className="onboarding-5-view-card-checkmark">
                        {selectedLength === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="onboarding-5-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  )}
                </IonItem>
              );
            })}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="onboarding-5-toolbar">
          <IonButton
            className="onboarding-5-button"
            color="primary"
            type="button"
            disabled={!selectedLength}
            onClick={() => onSubmit()}
          >
            Get Started
          </IonButton>
        </IonToolbar>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopOnboardingView()
        : MobileOnboardingView()}
    </>
  );
};

export default OnboardingQuestion5;
