import { configureStore } from '@reduxjs/toolkit';
import { Action, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import loadingReducer from 'core/services/LoadingSlice';
import homeReducer from 'core/services/HomeSlice';
import loginReducer from 'features/shared/login/LoginSlice';
import menuReducer from 'core/services/MenuSlice';
import userReducer from 'core/services/UserSlice';
import appointmentWizardReducer from '../features/full-service/appointments/wizard/AppointmentWizardSlice';
import appointmentReducer from '../features/full-service/appointments/AppointmentDetailsSlice';
import dashboardReducer from 'features/full-service/dashboard/DashboardSlice';
import platformReducer from 'core/services/PlatformSlice';
import alertReducer from 'core/services/AlertSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import signupReducer from 'features/shared/signup/SignupSlice';
import digitalTasksReducer from 'features/digital/store/DigitalTasksSlice';
import digitalCatalogReducer from 'features/digital/store/DigitalCatalogSlice';
import contactsReducer from 'features/shared/contacts/ContactsSlice';
import inventoryReducer from 'features/shared/inventory/InventorySlice';
import documentsReducer from 'features/shared/documents/DocumentsSlice';
import accountReducer from 'features/shared/account/AccountSlice';
import billingReducer from 'features/shared/billing/BillingSlice';

export const rootReducer = combineReducers({
  loading: loadingReducer,
  alert: alertReducer,
  login: loginReducer,
  menu: menuReducer,
  home: homeReducer,
  user: userReducer,
  account: accountReducer,
  platform: platformReducer,
  dashboard: dashboardReducer,
  appointment: appointmentReducer,
  appointmentWizard: appointmentWizardReducer,
  signup: signupReducer,
  digitalTasks: digitalTasksReducer,
  digitalCatalog: digitalCatalogReducer,
  contacts: contactsReducer,
  inventory: inventoryReducer,
  documents: documentsReducer,
  billing: billingReducer
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: persistedReducer,
  middleware: [thunk]
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export default store;
