import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonText
} from '@ionic/react';
import Image from 'assets/icons/image.svg';
import Documents from 'assets/icons/documents.svg';
import './InventoryDetailsDesktop.scss';
import { Asset } from 'core/types';
import { HKPlatform } from 'core/constants';
import { useHistory, useParams } from 'react-router';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { viewDocument } from 'core/util';
import {
  fetchProductDetails,
  requestProductGroupCount
} from 'features/shared/inventory/InventoryActions';
import {
  ProductGroup,
  hideAddItemModal,
  showAddItemModal
} from 'features/shared/inventory/InventorySlice';
import InventoryAddProduct from './InventoryAddProduct';
import { HKDocumentType } from '../documents/DocumentsSlice';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';

interface ItemAttribute {
  label: string;
  value: string;
}

export interface ItemParams {
  groupId?: string | undefined;
}

const InventoryDetailsDesktop: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { product, product_id, productGroups, loading, addItemModalVisible } =
    useSelector((state: RootState) => state.inventory);
  const [attributes, setAttributes] = useState<ItemAttribute[]>([]);
  const [group, setGroup] = useState<ProductGroup>({
    id: 0,
    count: 0,
    group: ''
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const { groupId } = useParams<ItemParams>();

  const assets = !!product
    ? [...product.product_template.assets, ...product.assets]
    : [];

  const imageSlideOpts = {
    initialSlide: 0,
    height: 400,
    speed: 400
  };

  const displayAssetThumbnail = (asset: Asset) => {
    return asset.thumb_cloud_store ? asset.thumb_cloud_store : asset.file_url;
  };

  const openAddItemModal = () => {
    dispatch(showAddItemModal());
  };

  const closeTask = () => {
    if (!!homeId) {
      dispatch(requestProductGroupCount(homeId));
    }
    dispatch(hideAddItemModal());
  };

  useEffect(() => {
    if (!!product) {
      setAttributes([
        { label: 'Product', value: product.product_template.type_data.label },
        { label: 'Brand', value: product.product_template.manufacturer.name },
        { label: 'Model', value: product.product_template.model },
        { label: 'Serial', value: product.serial }
      ]);
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (!product && !!homeId && loading) {
      dispatch(fetchProductDetails(homeId, Number(product_id)));
    }
  }, [dispatch, product, loading, homeId, product_id]);

  useEffect(() => {
    const goBackToInventoryList = () => {
      history.goBack();
    };
    if (platformType === HKPlatform.DESKTOP && !isDesktopWidth) {
      goBackToInventoryList();
    }
  });

  useEffect(() => {
    if (!!productGroups && !group.count) {
      const g = productGroups?.find((group) => group.id === Number(groupId));
      if (!!g) {
        setGroup(g);
      }
    }
  }, [productGroups, groupId]);

  return (
    <IonPage className="hk-desktop-digital-inventory-details-page">
      <IonRow className="hk-desktop-digital-inventory-details-header">
        <div className="hk-desktop-digital-inventory-details-header-container">
          <IonBreadcrumbs className="hk-desktop-digital-inventory-details-header-breadcrumbs">
            <IonBreadcrumb href="/inventory">Inventory</IonBreadcrumb>
            <IonBreadcrumb onClick={() => history.goBack()}>
              {group.group}
            </IonBreadcrumb>
            <IonBreadcrumb>
              {product?.product_template.manufacturer.name}{' '}
              {product?.product_template.type_data.label}{' '}
              {product?.product_template.model}
            </IonBreadcrumb>
          </IonBreadcrumbs>
          <div className="hk-desktop-digital-inventory-details-header-button">
            <IonButton
              expand="block"
              color="primary"
              strong={true}
              onClick={() => openAddItemModal()}
            >
              Add Item
            </IonButton>
          </div>
        </div>
      </IonRow>
      <IonContent>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="hk-desktop-digital-inventory-details-container">
            <div className="hk-desktop-digital-inventory-details">
              <div className="hk-desktop-digital-inventory-details-overview">
                {assets.length ? (
                  <Swiper
                    className="hk-desktop-digital-inventory-details-overview-image-slider"
                    pager={true}
                    options={imageSlideOpts}
                  >
                    {assets.map((asset, i) => {
                      return (
                        <>
                          {asset.type === 'photos' && (
                            <SwiperSlide key={`slide-${i}`}>
                              <img
                                src={displayAssetThumbnail(asset)}
                                alt="Product"
                              />
                            </SwiperSlide>
                          )}
                        </>
                      );
                    })}
                  </Swiper>
                ) : (
                  <div className="hk-image-placeholder">
                    <img src={Image} alt="No product" />
                  </div>
                )}
                <div className="hk-desktop-digital-inventory-details-overview-container">
                  <div className="hk-desktop-digital-inventory-details-overview-product-details-section-header ion-no-padding">
                    <IonText className="hk-desktop-digital-inventory-details-label">
                      Product Details
                    </IonText>
                  </div>
                  {attributes.map((att, i) => {
                    const { label, value } = att;
                    return (
                      <IonItem
                        key={`feature-${i}`}
                        lines="none"
                        className="hk-desktop-digital-inventory-details-overview-attribute ion-no-padding"
                      >
                        <IonLabel color="medium">{label}</IonLabel>
                        <IonLabel className="hk-bold ion-text-end ion-no-margin">
                          {value}
                        </IonLabel>
                      </IonItem>
                    );
                  })}
                  {!!product?.product_template.assets.filter(
                    (asst) => asst.type === HKDocumentType.Manuals
                  ).length && (
                    <>
                      <div className="hk-desktop-digital-inventory-details-overview-section-header section-header-documents ion-no-padding">
                        <IonText className="hk-desktop-inventory-details-label">
                          Documents
                        </IonText>
                      </div>
                      <IonItem lines="none" className="ion-no-padding">
                        <div className="hk-desktop-digital-inventory-details-overview-document-slider">
                          {product?.product_template.assets
                            .filter(
                              (asst) => asst.type === HKDocumentType.Manuals
                            )
                            .map((attr, i) => {
                              const { name, file_url } = attr;
                              return (
                                <div
                                  className="hk-desktop-digital-inventory-details-overview-document-slider-slide"
                                  key={`slide-${i}`}
                                  onClick={() =>
                                    viewDocument(file_url, platformType)
                                  }
                                >
                                  <IonImg
                                    src={Documents}
                                    className="hk-desktop-digital-inventory-details-overview-document-slider-slide-img"
                                  />
                                  <IonText
                                    color="medium"
                                    className="hk-desktop-digital-inventory-details-overview-document-slider-slide-name ion-text-center"
                                  >
                                    {name}
                                  </IonText>
                                </div>
                              );
                            })}
                        </div>
                      </IonItem>
                    </>
                  )}
                </div>
              </div>
              {/* <div className="hk-desktop-digital-inventory-details-overview-section-header section-header-maintenance">
                <IonText className="hk-desktop-digital-inventory-details-label">
                  Maintenance
                </IonText>
              </div> */}
            </div>
          </div>
        )}
      </IonContent>
      <IonModal
        className="hk-digital-inventory-add-product-modal"
        isOpen={addItemModalVisible}
        onDidDismiss={() => {
          closeTask();
        }}
      >
        <InventoryAddProduct />
      </IonModal>
    </IonPage>
  );
};

export default InventoryDetailsDesktop;
