import { IonIcon } from '@ionic/react';
import {
  DATE_FORMAT_DATE_TIMESTAMP,
  DATE_FORMAT_FULL_MONTH_YEAR,
  DATE_FORMAT_SHORT_MONTH
} from 'core/constants';
import { RootState } from 'core/store';
import { copyJSON, formatISODate } from 'core/util';
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
  subMonths
} from 'date-fns';
import {
  DateConfig,
  filterDaysForSelectedMonth,
  setDateConfig
} from 'features/full-service/appointments/wizard/AppointmentWizardSlice';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Calendar.scss';

type CalendarProps = {
  className?: string;
  initialDateSelected?: any;
  availableDates?: any;
  onDateSelected?: (e: any) => void;
};

const Calendar: React.FC<CalendarProps> = ({
  className,
  initialDateSelected,
  availableDates,
  onDateSelected,
  ...rest
}) => {
  const [currentDate, setCurrentDate] = useState(parseISO(initialDateSelected));
  const [selectedDate, setSelectedDate] = useState(
    parseISO(initialDateSelected)
  );
  const dispatch = useDispatch();

  const { dateConfig } = useSelector(
    (state: RootState) => state.appointmentWizard
  );

  const selectedMonthLabel = () => {
    if (
      !!dateConfig &&
      dateConfig.monthIndex !== undefined &&
      !!dateConfig.monthList
    ) {
      return formatISODate(
        dateConfig.monthList[dateConfig.monthIndex],
        DATE_FORMAT_FULL_MONTH_YEAR
      );
    }
  };

  const prevMonthLabel = () => {
    if (
      !!dateConfig &&
      !!dateConfig.monthList &&
      dateConfig.monthIndex !== undefined &&
      dateConfig.monthIndex > 0
    ) {
      const date = dateConfig.monthList[dateConfig.monthIndex - 1];
      return formatISODate(date, DATE_FORMAT_SHORT_MONTH);
    }
    return '';
  };

  const nextMonthLabel = () => {
    if (
      !!dateConfig &&
      !!dateConfig.monthList &&
      dateConfig.monthIndex !== undefined &&
      dateConfig.monthIndex + 1 < dateConfig.monthList.length
    ) {
      const date = dateConfig.monthList[dateConfig.monthIndex + 1];
      return formatISODate(date, DATE_FORMAT_SHORT_MONTH);
    }
    return '';
  };

  const prevMonth = () => {
    if (dateConfig?.monthIndex !== undefined && dateConfig?.monthIndex > 0) {
      setCurrentDate(subMonths(currentDate, 1));
      setMonthIndex(dateConfig.monthIndex - 1);
    }
  };

  const nextMonth = () => {
    if (
      dateConfig?.monthIndex !== undefined &&
      dateConfig?.monthList &&
      dateConfig?.monthIndex + 1 < dateConfig?.monthList.length
    ) {
      setCurrentDate(addMonths(currentDate, 1));
      setMonthIndex(dateConfig.monthIndex + 1);
    }
  };

  const setMonthIndex = (index: number) => {
    if (!!dateConfig) {
      const configCopy = copyJSON(dateConfig) as DateConfig;
      configCopy.monthIndex = index;
      filterDaysForSelectedMonth(configCopy);
      dispatch(setDateConfig(configCopy));
      document.getElementById('date-list')?.scrollTo(0, 0);
    }
  };

  const onDateClick = (day: any) => {
    setSelectedDate(day);
    if (onDateSelected) {
      onDateSelected(format(day, DATE_FORMAT_DATE_TIMESTAMP));
    }
  };

  const header = () => {
    return (
      <div className="hk-calendar-header">
        <div className="hk-calendar-header-prev" onClick={prevMonth}>
          {prevMonthLabel() !== '' && <IonIcon icon={chevronBackOutline} />}
          {prevMonthLabel()}
        </div>
        <div className="hk-calendar-header-current">{selectedMonthLabel()}</div>
        <div className="hk-calendar-header-next" onClick={nextMonth}>
          {nextMonthLabel()}
          {nextMonthLabel() !== '' && <IonIcon icon={chevronForwardOutline} />}
        </div>
      </div>
    );
  };

  const weekDays = () => {
    const dateFormat = 'EEE';
    const days = [];
    let startDate = startOfWeek(currentDate);
    for (let i = 0; i < 7; i++) {
      days.push(
        <label className="hk-calendar-week-date-label" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </label>
      );
    }
    return <div className="hk-calendar-week">{days}</div>;
  };

  const isDateAvailable = (date: any) => {
    let listOfAvailableDates = !!availableDates ? availableDates : null;
    let formattedDate = format(date, DATE_FORMAT_DATE_TIMESTAMP);
    return listOfAvailableDates.includes(formattedDate);
  };

  const days = () => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`hk-calendar-days-column cell ${
              !isSameMonth(day, monthStart) || !isDateAvailable(day)
                ? 'disabled'
                : isSameDay(day, selectedDate)
                ? 'selected'
                : ''
            }`}
            key={`${day}`}
            onClick={() => onDateClick(cloneDay)}
          >
            <span className="number">{formattedDate}</span>
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="hk-calendar-days-row" key={`${day}`}>
          {' '}
          {days}{' '}
        </div>
      );
      days = [];
    }

    return <div className="hk-calendar-days">{rows}</div>;
  };

  return (
    <div className="hk-calendar">
      <div className="hk-calendar-header-container">{header()}</div>
      <div className="hk-calendar-week-container">{weekDays()}</div>
      <div className="hk-calendar-days-container">{days()}</div>
    </div>
  );
};
export default Calendar;
