import {
  CATALOG_TASKS_URL,
  DIGITAL_TASK_URL,
  PRODUCT_DETAILS_URL,
  PRODUCT_TYPE_DETAIL_URL,
  PRODUCT_TYPE_LIST_URL,
  PRODUCT_URL,
  STATUS_TAG_LIST_URL
} from 'core/constants';
import { createApiRequest } from 'core/util';
import {
  createDigitalCatalogProductError,
  createDigitalCatalogProductStart,
  createDigitalCatalogProductSuccess,
  fetchDigitalCatalogListStart,
  fetchDigitalCatalogListSuccess,
  fetchDigitalCatalogListError,
  fetchDigitalCatalogProductDetailsSuccess,
  fetchDigitalCatalogTaskStart,
  fetchDigitalCatalogTaskError,
  fetchDigitalCatalogTaskStatusTags,
  fetchDigitalCatalogTaskStatusTagsError,
  fetchDigitalCatalogTaskSuccess,
  fetchDigitalProducts,
  fetchDigitalProductTemplateError,
  fetchDigitalProductTemplatesList,
  fetchDigitalProductTemplatesListError,
  fetchDigitalProductTemplateStart,
  fetchDigitalProductTemplateSuccess,
  patchDigitalCatalogTaskDetailsError,
  patchDigitalCatalogTaskDetailsStart,
  patchDigitalCatalogTaskDetailsSuccess
} from './DigitalCatalogSlice';

export const fetchCatalogTasksList = (homeId: number) =>
  createApiRequest({
    route: CATALOG_TASKS_URL,
    method: 'get',
    replace: [['home_id', homeId]],
    onStart: fetchDigitalCatalogListStart,
    onSuccess: fetchDigitalCatalogListSuccess,
    onError: fetchDigitalCatalogListError,
    defaultErrorMessage: 'There was a problem fetching your catalog tasks.'
  })();

export const fetchCatalogTask = (homeId: number, taskId: number) =>
  createApiRequest({
    route: DIGITAL_TASK_URL,
    method: 'get',
    replace: [
      ['home_id', homeId],
      ['task_id', taskId]
    ],
    onStart: fetchDigitalCatalogTaskStart,
    onSuccess: fetchDigitalCatalogTaskSuccess,
    onError: fetchDigitalCatalogTaskError,
    defaultErrorMessage: 'There was a problem fetching your catalog task.'
  })();

export const fetchCatalogTaskStatusTags = () =>
  createApiRequest({
    route: STATUS_TAG_LIST_URL,
    method: 'get',
    onSuccess: fetchDigitalCatalogTaskStatusTags,
    onError: fetchDigitalCatalogTaskStatusTagsError
  })();

export const fetchCatalogProducts = (homeId: number) =>
  createApiRequest({
    route: PRODUCT_URL,
    method: 'get',
    replace: ['home_id', homeId],
    onSuccess: fetchDigitalProducts
  })();

export const fetchCatalogProductDetails = (homeId: number, itemId: number) =>
  createApiRequest({
    route: PRODUCT_DETAILS_URL,
    replace: [
      ['home_id', homeId],
      ['item_id', itemId]
    ],
    onSuccess: fetchDigitalCatalogProductDetailsSuccess,
    defaultErrorMessage: 'There was a problem loading item details.',
    useGlobalLoader: true
  })();

export const fetchCatalogProductTemplatesList = () =>
  createApiRequest({
    route: PRODUCT_TYPE_LIST_URL,
    method: 'get',
    onSuccess: fetchDigitalProductTemplatesList,
    onError: fetchDigitalProductTemplatesListError
  })();

export const fetchCatalogProductTemplate = (productTypeId: number) =>
  createApiRequest({
    route: PRODUCT_TYPE_DETAIL_URL,
    method: 'get',
    replace: [['product_type_id', productTypeId]],
    onStart: fetchDigitalProductTemplateStart,
    onSuccess: fetchDigitalProductTemplateSuccess,
    onError: fetchDigitalProductTemplateError,
    defaultErrorMessage: 'There was a problem fetching your catalog tasks.'
  })();

export const patchCatalogTaskDetails = (
  homeId: number,
  taskId: number,
  body: object
) =>
  createApiRequest({
    route: DIGITAL_TASK_URL,
    replace: [
      ['home_id', homeId],
      ['task_id', taskId]
    ],
    method: 'patch',
    body: body,
    onStart: patchDigitalCatalogTaskDetailsStart,
    onSuccess: patchDigitalCatalogTaskDetailsSuccess,
    onError: patchDigitalCatalogTaskDetailsError,
    defaultErrorMessage: 'There was an error updating the product templates.'
  })();

export const createCatalogProduct = (homeId: number, body: object) =>
  createApiRequest({
    route: PRODUCT_URL,
    method: 'post',
    body: body,
    replace: [['home_id', homeId]],
    onStart: createDigitalCatalogProductStart,
    onSuccess: createDigitalCatalogProductSuccess,
    onError: createDigitalCatalogProductError,
    defaultErrorMessage: 'There was an error updating the product templates.'
  })();
