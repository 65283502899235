import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, Switch, useLocation, useHistory } from 'react-router';
import MenuLayout from 'core/layout/MenuLayout';
import PrivateRoute from './PrivateRoute';
import Login from 'features/shared/login/Login';
import Dashboard from 'features/full-service/dashboard/Dashboard';
import Tutorial from 'features/full-service/tutorial/Tutorial';
import InitialApppointment from '../../features/full-service/appointments/initial/InitialAppointment';
import Account from '../../features/shared/account/Account';
import AppointmentDetails from 'features/full-service/appointments/AppointmentDetails';
import Checkpoint from '../../features/shared/login/Checkpoint';
import Update from '../../features/shared/update/Update';
import ForgotPasswordReset from '../../features/shared/login/ForgotPasswordReset';
import IntegrationReset from 'features/shared/login/IntegrationReset';
import PasswordReset from 'features/shared/login/PasswordReset';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { DIGITAL_PAGES, FULL_SERVICE_PAGES } from 'core/constants';
import Recurly from 'features/shared/signup/views/digital-signup/Recurly';
import Plans from 'features/shared/signup/views/digital-signup/Plans';
import CreateAccount from 'features/shared/signup/views/digital-signup/CreateAccount';
import DigitalDashboard from 'features/digital/views/dashboard/DigitalDashboard';
import Contacts from 'features/shared/contacts/Contacts';
import DigitalCatalog from 'features/digital/views/catalog/DigitalCatalog';
import ContactDetails from 'features/shared/contacts/ContactDetails';
import InventoryGroups from 'features/shared/inventory/InventoryGroups';
import InventoryProducts from 'features/shared/inventory/InventoryProducts';
import DocumentsGroups from 'features/shared/documents/DocumentsGroups';
import DocumentsList from 'features/shared/documents/DocumentsList';
import InventoryDetailsDesktop from 'features/shared/inventory/InventoryDetailsDesktop';
import Renew from 'features/shared/billing/Renew';
import Confirmation from 'features/shared/billing/Confirmation';
import CheckoutDesktop from 'features/shared/billing/CheckoutDesktop';
import OnboardingQuestion1 from 'features/shared/signup/views/digital-signup/onboarding/OnboardingQuestion1';
import OnboardingQuestion3 from 'features/shared/signup/views/digital-signup/onboarding/OnboardingQuestion3';
import OnboardingQuestion4 from 'features/shared/signup/views/digital-signup/onboarding/OnboardingQuestion4';
import OnboardingQuestion5 from 'features/shared/signup/views/digital-signup/onboarding/OnboardingQuestion5';
import NotFound from 'core/layout/NotFound';
import InitialSignIn from 'features/shared/login/InitialSignIn';
import DocumentEdit from 'features/shared/documents/DocumentEdit';

const Routes: React.FC = () => {
  let location = useLocation();
  let history = useHistory();

  const [lastPathIndex, setLastPathIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState('left');

  const { isDesktopWidth } = useSelector((state: RootState) => state.platform);
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { isExpired } = useSelector((state: RootState) => state.home);
  const isAuthenticated = useSelector(
    (state: RootState) => state.login.loggedIn
  );

  const nodeRef = useRef(null);

  const getCurrentPathIndex = () => {
    let currentPathIndex = 0;
    if (!isDigital) {
      FULL_SERVICE_PAGES.forEach((page, i) => {
        if (location.pathname.match(page.path)) {
          currentPathIndex = i;
        }
      });
    } else {
      DIGITAL_PAGES.forEach((page, i) => {
        if (location.pathname.match(page.path)) {
          currentPathIndex = i;
        }
      });
    }

    return currentPathIndex;
  };

  const getLastPathIndex = () => {
    if (!isDigital) {
      FULL_SERVICE_PAGES.forEach((page, i) => {
        if (location.pathname.match(page.path)) {
          setLastPathIndex(i);
        }
      });
    } else {
      DIGITAL_PAGES.forEach((page, i) => {
        if (location.pathname.match(page.path)) {
          setLastPathIndex(i);
        }
      });
    }
  };

  const checkIfDigitalExpired = () => {
    if (
      isDigital &&
      isExpired &&
      location.pathname !== '/renew' &&
      location.pathname !== '/renew/checkout' &&
      location.pathname !== '/account' &&
      location.pathname !== '/renew/confirmation'
    ) {
      history.push(`/renew`);
    }
  };

  useEffect(() => {
    getLastPathIndex();
    setSlideDirection(getCurrentPathIndex() > lastPathIndex ? 'left' : 'right');
    checkIfDigitalExpired();
  }, [location]);

  function DigitalSignupRoutes() {
    return (
      <Switch>
        <Route exact path="/signup/plans" component={Plans} />
        <Route exact path="/signup/create" component={CreateAccount} />
        <Route exact path="/signup/recurly" component={Recurly} />
      </Switch>
    );
  }

  function FullServiceAppRoutes() {
    return (
      <Switch location={location}>
        <PrivateRoute path="/checkpoint">
          <MenuLayout>
            <Checkpoint />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute path="/dashboard">
          <MenuLayout>
            <Dashboard />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/initial">
          <MenuLayout>
            <InitialApppointment />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/account">
          <MenuLayout>
            <Account />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/appointment/:id">
          <MenuLayout>
            <AppointmentDetails />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/inventory">
          <MenuLayout showHelp={false}>
            <InventoryGroups />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/inventory/group/:id">
          <MenuLayout showHelp={false}>
            <InventoryProducts />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/inventory/group/:groupId/product/:productId">
          <MenuLayout>
            <InventoryDetailsDesktop />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contacts">
          <MenuLayout showHelp={false}>
            <Contacts />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contacts/:id">
          <MenuLayout showHelp={false}>
            <ContactDetails />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/documents">
          <MenuLayout showHelp={false}>
            <DocumentsGroups />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/documents/group/:type">
          <MenuLayout showHelp={false}>
            <DocumentsList />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/documents/group/:type/:id">
          <MenuLayout showHelp={false}>
            <DocumentEdit />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute path="/renew/confirmation">
          <MenuLayout showHelp={false}>
            <Confirmation />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute path="/renew/checkout">
          <MenuLayout showHelp={false}>
            <CheckoutDesktop />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/renew">
          <MenuLayout showHelp={false}>
            <Renew />
          </MenuLayout>
        </PrivateRoute>
        <Route path="*">
          <Route component={NotFound} />
        </Route>
      </Switch>
    );
  }

  function DigitalRoutes() {
    return (
      <Switch>
        <PrivateRoute path="/checkpoint">
          <MenuLayout>
            <Checkpoint />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute path="/dashboard">
          <MenuLayout>
            <DigitalDashboard />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/onboarding/what-type-of-home-do-you-own">
          <MenuLayout>
            <OnboardingQuestion1 />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/onboarding/what-are-you-looking-to-get-out-of-this-app"
        >
          <MenuLayout>
            <OnboardingQuestion3 />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/onboarding/what-type-of-homeowner-are-you">
          <MenuLayout>
            <OnboardingQuestion4 />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/onboarding/how-long-do-you-expect-to-stay">
          <MenuLayout>
            <OnboardingQuestion5 />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/account">
          <MenuLayout showHelp={true}>
            <Account />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/catalog">
          <MenuLayout>
            <DigitalCatalog />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/inventory">
          <MenuLayout showHelp={false}>
            <InventoryGroups />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/inventory/group/:id">
          <MenuLayout>
            <InventoryProducts />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/inventory/group/:groupId/product/:productId">
          <MenuLayout>
            <InventoryDetailsDesktop />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/documents">
          <MenuLayout showHelp={false}>
            <DocumentsGroups />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/documents/group/:type">
          <MenuLayout>
            <DocumentsList />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/documents/group/:type/:id">
          <MenuLayout>
            <DocumentEdit />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contacts">
          <MenuLayout showHelp={false}>
            <Contacts />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/contacts/:id">
          <MenuLayout>
            <ContactDetails />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute path="/renew/confirmation">
          <MenuLayout>
            <Confirmation />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute path="/renew/checkout">
          <MenuLayout>
            <CheckoutDesktop />
          </MenuLayout>
        </PrivateRoute>
        <PrivateRoute exact path="/renew">
          <MenuLayout>
            <Renew />
          </MenuLayout>
        </PrivateRoute>
        <Route path="*">
          <Route component={NotFound} />
        </Route>
      </Switch>
    );
  }

  function RouteList() {
    return (
      <Switch location={location}>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/update" component={Update} />
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPasswordReset} />
        <Route path="/password/reset" component={PasswordReset} />
        <Route path="/integration/reset" component={IntegrationReset} />
        <Route path="/initial-setup" component={InitialSignIn} />
        <Route path="/auth/:code" component={Login} />
        <Route path="/tutorial">
          <MenuLayout showHelp={false}>
            <Tutorial />
          </MenuLayout>
        </Route>

        <Route path="/signup" component={DigitalSignupRoutes} />
        <PrivateRoute path="/checkpoint">
          <Checkpoint />
        </PrivateRoute>
        <Route
          path="*"
          render={() => {
            if (!isAuthenticated) {
              return (
                <>
                  {FullServiceAppRoutes()}
                  {DigitalRoutes()}
                </>
              );
            } else {
              return isAuthenticated && !isDigital
                ? FullServiceAppRoutes()
                : DigitalRoutes();
            }
          }}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }

  return isDesktopWidth ? (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        timeout={300}
        nodeRef={nodeRef}
      >
        <div ref={nodeRef} className={slideDirection}>
          <RouteList />
        </div>
      </CSSTransition>
    </SwitchTransition>
  ) : (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        classNames="slide"
        timeout={300}
        nodeRef={nodeRef}
      >
        <div ref={nodeRef}>
          <RouteList />
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Routes;
