import {
  IonPage,
  IonContent,
  IonButton,
  IonRow,
  IonSpinner,
  IonToolbar,
  IonIcon,
  IonText,
  IonCheckbox,
  IonLabel
} from '@ionic/react';
import { RootState } from 'core/store';
import {
  resetSignup,
  saveEmail,
  saveUid,
  setPassword
} from 'features/shared/signup/SignupSlice';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './CreateAccount.scss';
import { checkEmail } from 'features/shared/signup/SignupActions';
import { displayError } from 'core/services/AlertActions';
import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { HKPlatform, firebaseConfig, EMAIL_REGEX } from 'core/constants';

import {
  getAuth,
  OAuthProvider,
  GoogleAuthProvider,
  signInWithCredential,
  indexedDBLocalPersistence,
  initializeAuth
} from 'firebase/auth';
import { getApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import AppleLogo from 'assets/illustrations/apple-logo.svg';
import GoogleLogo from 'assets/illustrations/google-logo.svg';
import { FirebaseAuthentication } from '@tambroseavid/capacitor-firebase-authentication';
import { gaBtnClickEvent } from 'core/util';
import Footer from '../Footer';
import Header from '../Header';
import CompleteIcon from 'assets/icons/complete.svg';

const CreateAccount: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  const { emailValid, loading, error } = useSelector(
    (state: RootState) => state.signup
  );
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false);
  const [consentChecked, setConsentChecked] = useState(true);
  const { register, handleSubmit, formState, getValues, trigger } =
    useForm<FormData>({
      defaultValues: { email: '', password: '', passwordConfirm: '' },
      reValidateMode: 'onChange',
      criteriaMode: 'all',
      mode: 'onChange'
    });
  const { isValid, errors } = formState;
  const dispatch = useDispatch();
  const history = useHistory();

  type FormData = {
    email: string;
    password: string;
    passwordConfirm: string;
  };

  // Configure Firebase.
  firebase.initializeApp(firebaseConfig);

  const onCreateAccountFormSubmit = handleSubmit(({ email, password }) => {
    gaBtnClickEvent('sign_up_with_email');
    if (errors.email === undefined && errors.password === undefined) {
      dispatch(saveEmail(email));
      dispatch(setPassword(password));
      dispatch(checkEmail(email));
    }
  });

  const handleError = (err: any) => {
    console.log(err);
  };

  const signUpWithApple = async () => {
    gaBtnClickEvent('sign_up_with_google');
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple().catch(
      handleError
    );
    if (!!result) {
      // 2. Sign in on the web layer using the id token and nonce
      const provider = new OAuthProvider('apple.com');
      const credential = provider.credential({
        idToken: result.credential?.idToken,
        rawNonce: result.credential?.nonce
      });
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      await signInWithCredential(auth, credential).then((userCredential) => {
        createContactWithAuthCrendentials(
          userCredential.user?.email!,
          userCredential.user?.uid!
        );
      });
    }
  };

  const signUpWithGoogle = async () => {
    gaBtnClickEvent('sign_up_with_google');
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithGoogle().catch(
      handleError
    );
    // 2. Sign in on the web layer using the id token
    if (!!result) {
      const credential = GoogleAuthProvider.credential(
        result.credential?.idToken
      );
      let auth;
      if (Capacitor.isNativePlatform()) {
        auth = initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence
        });
      } else {
        auth = getAuth();
      }
      await signInWithCredential(auth, credential).then(
        (userCredential: any) => {
          createContactWithAuthCrendentials(
            userCredential.user?.email!,
            userCredential.user?.uid!
          );
        }
      );
    }
  };

  const createContactWithAuthCrendentials = (email: string, uid?: string) => {
    dispatch(saveEmail(email));
    if (!!uid) {
      dispatch(saveUid(uid));
      dispatch(checkEmail(email));
    } else {
      dispatch(checkEmail(email));
    }
  };

  const openSignin = () => {
    gaBtnClickEvent('have_an_account_sign_in');
    history.push(`/login`);
  };

  useEffect(() => {
    if (emailValid === true) {
      history.push(`/signup/recurly`);
    }
    if (!!error) {
      dispatch(displayError(error));
      dispatch(resetSignup());
    }
  }, [emailValid, error]);

  useEffect(() => {
    dispatch(resetSignup());
  }, []);

  function DesktopCreateAccountView() {
    return (
      <IonPage>
        <Header />
        <IonContent
          className="hk-desktop-create-account-view ion-padding"
          fullscreen
          color="background"
          scrollY={true}
        >
          <div className="hk-desktop-create-account-view-container">
            <div className="hk-desktop-create-account-view-content">
              <div>
                <h3 className="hk-desktop-create-account-header ion-text-center">
                  <b>Create Your Account</b>
                </h3>
                <h6 className="hk-desktop-create-account-subheader">
                  Get Started on your home maintenance journey
                </h6>
              </div>
              {!loading && (
                <>
                  {!showEmailPasswordForm && (
                    <div className="hk-desktop-create-account-options">
                      <IonButton
                        className="hk-desktop-create-account-options-google hk-signup-button"
                        color="white"
                        strong={true}
                        onClick={signUpWithGoogle}
                      >
                        <IonIcon
                          src={GoogleLogo}
                          slot="start"
                          className="ion-margin-horizontal"
                        />
                        Continue with Google
                      </IonButton>
                      <IonButton
                        className="hk-desktop-create-account-options-apple hk-signup-button"
                        color="white"
                        strong={true}
                        onClick={signUpWithApple}
                      >
                        <IonIcon
                          src={AppleLogo}
                          slot="start"
                          className="ion-margin-horizontal"
                        />
                        Continue with Apple
                      </IonButton>
                      <div className="hk-desktop-create-account-options-divider">
                        <span>or</span>
                        <hr />
                      </div>
                      <IonButton
                        className="hk-desktop-create-account-email-button"
                        color="primary"
                        size="default"
                        strong={true}
                        type="submit"
                        onClick={() => setShowEmailPasswordForm(true)}
                      >
                        Create Account
                      </IonButton>
                    </div>
                  )}
                  {showEmailPasswordForm && (
                    <section className="hk-desktop-create-account-view-form">
                      <form
                        className="hk-form"
                        onSubmit={onCreateAccountFormSubmit}
                      >
                        <div className="hk-form-container">
                          <div className="hk-form-row">
                            <input
                              type="text"
                              placeholder="Email"
                              name="email"
                              className="create-account-field"
                              autoCorrect="off"
                              autoCapitalize="none"
                              color="medium"
                              ref={register({
                                required: true,
                                minLength: 4,
                                pattern: EMAIL_REGEX
                              })}
                            />
                          </div>
                          <div className="hk-form-row">
                            <input
                              type="password"
                              name="password"
                              placeholder="Password"
                              onChange={() => {
                                trigger('passwordConfirm');
                              }}
                              ref={register({
                                validate: {
                                  hasSpecialCharacter: (value) => {
                                    const validation = /(?=.*[\d@$!%*?&])/.test(
                                      value
                                    );
                                    return validation;
                                  },
                                  hasMinimumLength: (value) => {
                                    const validation = value.length >= 8;
                                    return validation;
                                  },
                                  hasUpperCase: (value) => {
                                    const validation = /.*[A-Z]+.*/.test(value);
                                    return validation;
                                  }
                                }
                              })}
                            />
                          </div>
                          <div className="hk-form-row">
                            <input
                              type="password"
                              name="passwordConfirm"
                              placeholder="Confirm password"
                              ref={register({
                                required: 'Please confirm your password',
                                validate: (value) =>
                                  value === getValues('password') ||
                                  'The passwords do not match'
                              })}
                            />
                          </div>
                          <section className="hk-form-validation-container">
                            <div
                              className={`hk-form-validation ${
                                !formState.isDirty ||
                                getValues('password') === '' ||
                                (errors.password &&
                                  errors.password?.types?.hasOwnProperty(
                                    'hasMinimumLength'
                                  ))
                                  ? 'invalid'
                                  : ''
                              }`}
                            >
                              <IonIcon icon={CompleteIcon} />
                              At least 8 characters
                            </div>
                            <div
                              className={`hk-form-validation ${
                                !formState.isDirty ||
                                getValues('password') === '' ||
                                (errors.password &&
                                  errors.password?.types?.hasOwnProperty(
                                    'hasUpperCase'
                                  ))
                                  ? 'invalid'
                                  : ''
                              }`}
                            >
                              <IonIcon icon={CompleteIcon} />
                              At least 1 uppercase letter
                            </div>
                            <div
                              className={`hk-form-validation ${
                                !formState.isDirty ||
                                getValues('password') === '' ||
                                (errors.password &&
                                  errors.password?.types?.hasOwnProperty(
                                    'hasSpecialCharacter'
                                  ))
                                  ? 'invalid'
                                  : ''
                              }`}
                            >
                              <IonIcon icon={CompleteIcon} />
                              At least 1 number or special character
                            </div>
                          </section>
                          {errors.passwordConfirm && (
                            <p className="hk-error">
                              {errors.passwordConfirm.message}
                            </p>
                          )}
                          {errors.email && (
                            <p className="hk-error">
                              Your email appears to be invalid
                            </p>
                          )}
                          {errors.password && (
                            <p className="hk-error">
                              {errors.password.message}
                            </p>
                          )}{' '}
                          <div className="hk-form-terms">
                            <div className="hk-form-terms-consent">
                              We’ll be collecting some information about you to
                              be able to serve you, and so we can improve our
                              online platforms and our services.
                              <div className="hk-form-terms-consent-checkbox">
                                <IonCheckbox
                                  checked={consentChecked}
                                  onIonChange={(e) =>
                                    setConsentChecked(e.detail.checked)
                                  }
                                ></IonCheckbox>
                                <IonLabel>Is this ok with you?</IonLabel>
                              </div>
                            </div>
                            By clicking “Continue” you agree to the HomeKeep{' '}
                            <a
                              className="login-view-footer-link"
                              color="primary"
                              onClick={() =>
                                window.open(
                                  'https://homekeep.com/terms-of-use/',
                                  '_blank'
                                )
                              }
                            >
                              Terms of Use
                            </a>{' '}
                            and{' '}
                            <a
                              className="login-view-footer-link"
                              color="primary"
                              onClick={() =>
                                window.open(
                                  'https://homekeep.com/privacy-policy/',
                                  '_blank'
                                )
                              }
                            >
                              Privacy Policy
                            </a>
                            .{' '}
                            <a
                              className="login-view-footer-link"
                              color="primary"
                              onClick={() =>
                                window.open(
                                  'https://homekeep.zendesk.com/hc/en-us/articles/25239871624595-Understanding-Our-Privacy-Policy-How-We-Collect-Information',
                                  '_blank'
                                )
                              }
                            >
                              Learn more
                            </a>{' '}
                            about how we collect, use and share your
                            information.
                          </div>
                        </div>
                      </form>
                    </section>
                  )}
                </>
              )}
              {loading && (
                <div className="hk-body-spinner hk-create-account-spinner">
                  <IonRow className="vertical-align-center">
                    <IonSpinner name="dots" />
                  </IonRow>
                </div>
              )}
              {showEmailPasswordForm && (
                <IonButton
                  className="hk-desktop-create-account-button"
                  expand="block"
                  color="primary"
                  type="submit"
                  strong={true}
                  disabled={!(consentChecked && formState.isValid) || loading}
                  onClick={onCreateAccountFormSubmit}
                >
                  {loading ? <IonSpinner name="dots" /> : 'Continue'}
                </IonButton>
              )}
            </div>
          </div>
          <div className="hk-desktop-create-account-view-footer">
            <IonText>Have an Account? </IonText>
            <a
              className="hk-desktop-create-account-view-footer-link"
              color="primary"
              onClick={() => openSignin()}
            >
              {' '}
              Sign In
            </a>
          </div>
        </IonContent>
        <Footer />
      </IonPage>
    );
  }

  function MobileCreateAccountView() {
    return (
      <IonPage>
        <Header />
        <IonContent
          className="create-account-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <div>
            <h3 className="create-account-header ion-text-center">
              <b>Create Your Account</b>
            </h3>
            <h6 className="create-account-subheader">
              {!showEmailPasswordForm &&
                'Get started on your home maintenance journey'}
            </h6>
          </div>
          {!loading && (
            <>
              {!showEmailPasswordForm && (
                <div className="hk-create-account-options">
                  <IonButton
                    className="hk-signup-button"
                    color="white"
                    strong={true}
                    onClick={signUpWithGoogle}
                  >
                    <IonIcon src={GoogleLogo} slot="start" />
                    Continue with Google
                  </IonButton>
                  <IonButton
                    className="hk-signup-button"
                    color="white"
                    strong={true}
                    onClick={signUpWithApple}
                  >
                    <IonIcon src={AppleLogo} slot="start" />
                    Continue with Apple
                  </IonButton>
                  <div className="hk-create-account-options-divider">
                    <span>or</span>
                    <hr />
                  </div>
                  <IonButton
                    className="hk-create-account-email-button"
                    color="primary"
                    size="default"
                    strong={true}
                    type="submit"
                    onClick={() => setShowEmailPasswordForm(true)}
                  >
                    Create Account
                  </IonButton>
                </div>
              )}
              {showEmailPasswordForm && (
                <section className="create-account-view-form">
                  <form
                    className="hk-form"
                    onSubmit={onCreateAccountFormSubmit}
                  >
                    <div className="hk-form-container">
                      <div className="hk-form-row">
                        <input
                          type="text"
                          placeholder="Email"
                          name="email"
                          className="create-account-field"
                          autoCorrect="off"
                          autoCapitalize="none"
                          color="medium"
                          ref={register({
                            required: true,
                            minLength: 4,
                            pattern: EMAIL_REGEX
                          })}
                        />
                      </div>
                      <div className="hk-form-row">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          onChange={() => {
                            trigger('passwordConfirm');
                          }}
                          ref={register({
                            validate: {
                              hasSpecialCharacter: (value) => {
                                const validation = /(?=.*[\d@$!%*?&])/.test(
                                  value
                                );
                                return validation;
                              },
                              hasMinimumLength: (value) => {
                                const validation = value.length >= 8;
                                return validation;
                              },
                              hasUpperCase: (value) => {
                                const validation = /.*[A-Z]+.*/.test(value);
                                return validation;
                              }
                            }
                          })}
                        />
                      </div>
                      <div className="hk-form-row">
                        <input
                          type="password"
                          name="passwordConfirm"
                          placeholder="Confirm password"
                          ref={register({
                            required: 'Please confirm your password',
                            validate: (value) =>
                              value === getValues('password') ||
                              'The passwords do not match'
                          })}
                        />
                      </div>
                      <section className="hk-form-validation-container">
                        <div
                          className={`hk-form-validation ${
                            !formState.isDirty ||
                            getValues('password') === '' ||
                            (errors.password &&
                              errors.password?.types?.hasOwnProperty(
                                'hasMinimumLength'
                              ))
                              ? 'invalid'
                              : ''
                          }`}
                        >
                          <IonIcon icon={CompleteIcon} />
                          At least 8 characters
                        </div>
                        <div
                          className={`hk-form-validation ${
                            !formState.isDirty ||
                            getValues('password') === '' ||
                            (errors.password &&
                              errors.password?.types?.hasOwnProperty(
                                'hasUpperCase'
                              ))
                              ? 'invalid'
                              : ''
                          }`}
                        >
                          <IonIcon icon={CompleteIcon} />
                          At least 1 uppercase letter
                        </div>
                        <div
                          className={`hk-form-validation ${
                            getValues('password') === '' ||
                            (errors.password &&
                              errors.password?.types?.hasOwnProperty(
                                'hasSpecialCharacter'
                              ))
                              ? 'invalid'
                              : ''
                          }`}
                        >
                          <IonIcon icon={CompleteIcon} />
                          At least 1 number or special character
                        </div>
                      </section>
                      {errors.passwordConfirm && (
                        <p className="hk-error">
                          {errors.passwordConfirm.message}
                        </p>
                      )}
                      {errors.email && (
                        <p className="hk-error">
                          Your email appears to be invalid
                        </p>
                      )}
                      {errors.password && (
                        <p className="hk-error">{errors.password.message}</p>
                      )}
                      <div className="hk-form-terms">
                        <div className="hk-form-terms-consent">
                          We’ll be collecting some information about you to be
                          able to serve you, and so we can improve our online
                          platforms and our services.
                          <div className="hk-form-terms-consent-checkbox">
                            <IonCheckbox
                              checked={consentChecked}
                              onIonChange={(e) =>
                                setConsentChecked(e.detail.checked)
                              }
                            ></IonCheckbox>
                            <IonLabel>Is this ok with you?</IonLabel>
                          </div>
                        </div>
                        By clicking “Continue” you agree to the HomeKeep{' '}
                        <a
                          className="login-view-footer-link"
                          color="primary"
                          onClick={() =>
                            window.open(
                              'https://homekeep.com/terms-of-use/',
                              '_blank'
                            )
                          }
                        >
                          Terms of Use
                        </a>{' '}
                        and{' '}
                        <a
                          className="login-view-footer-link"
                          color="primary"
                          onClick={() =>
                            window.open(
                              'https://homekeep.com/privacy-policy/',
                              '_blank'
                            )
                          }
                        >
                          Privacy Policy
                        </a>
                        .{' '}
                        <a
                          className="login-view-footer-link"
                          color="primary"
                          onClick={() =>
                            window.open(
                              'https://homekeep.zendesk.com/hc/en-us/articles/25239871624595-Understanding-Our-Privacy-Policy-How-We-Collect-Information',
                              '_blank'
                            )
                          }
                        >
                          Learn more
                        </a>{' '}
                        about how we collect, use and share your information.
                      </div>
                    </div>
                  </form>
                </section>
              )}
            </>
          )}
          {loading && (
            <div className="hk-body-spinner hk-create-account-spinner">
              <IonRow className="vertical-align-center">
                <IonSpinner name="dots" />
              </IonRow>
            </div>
          )}
          <div className="hk-create-account-view-footer ion-margin-vertical">
            <IonText color="medium">Have an Account?</IonText>
            <a
              className="hk-create-account-view-footer-link"
              color="primary"
              onClick={() => openSignin()}
            >
              {' '}
              Sign In
            </a>
          </div>
        </IonContent>
        {showEmailPasswordForm && (
          <IonToolbar className="create-account-toolbar">
            <IonButton
              className="hk-create-account-button"
              expand="block"
              color="primary"
              type="submit"
              strong={true}
              disabled={!(consentChecked && formState.isValid) || loading}
              onClick={onCreateAccountFormSubmit}
            >
              {loading ? <IonSpinner name="dots" /> : 'Continue'}
            </IonButton>
          </IonToolbar>
        )}
        <Footer />
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopCreateAccountView()
        : MobileCreateAccountView()}
    </>
  );
};

export default CreateAccount;
