import { IonButton, IonIcon, IonModal } from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Logo from 'assets/icons/HK-logo.svg';
import AppleLogo from 'assets/illustrations/apple-logo.svg';
import AndroidLogo from 'assets/illustrations/google-logo.svg';
import './MobileWebRedirect.scss';

const MobileWebRedirect: React.FC = () => {
  const { platformType } = useSelector((state: RootState) => state.platform);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const dismissModal = () => {
    setIsModalOpen(false);
  };

  const openAppStoreLink = (platform: string) => {
    if (platform === HKPlatform.IOS_BROWSER) {
      window.open(
        'https://apps.apple.com/us/app/homekeep/id1562763274',
        '_self'
      );
    } else if (platform === HKPlatform.ANDROID_BROWSER) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.avidratings.homekeep',
        '_self'
      );
    }
  };

  function iOSMobileBrowserButton() {
    return (
      <>
        <div className="redirect-item-title">
          <IonIcon
            color="white"
            icon={AppleLogo}
            className="redirect-item-title-icon"
          />
          Mobile Browser
        </div>
        <IonButton
          size="small"
          color="medium"
          className="redirect-item-button continue"
          onClick={() => dismissModal()}
        >
          Continue
        </IonButton>
      </>
    );
  }

  function androidMobileBrowserButton() {
    return (
      <>
        <div className="redirect-item-title">
          <IonIcon icon={AndroidLogo} className="redirect-item-title-icon" />
          Mobile Browser
        </div>
        <IonButton
          size="small"
          color="medium"
          className="redirect-item-button continue"
          onClick={() => dismissModal()}
        >
          Continue
        </IonButton>
      </>
    );
  }

  return platformType === HKPlatform.IOS_BROWSER ||
    platformType === HKPlatform.ANDROID_BROWSER ? (
    <IonModal
      className="hk-mobile-web-redirect-modal"
      isOpen={isModalOpen}
      onDidDismiss={dismissModal}
    >
      <div className="hk-mobile-web-redirect-content">
        <div className="hk-mobile-web-redirect-content-header">
          See HomeKeep in...
        </div>
        <div className="hk-mobile-web-redirect-content-body">
          <div className="hk-mobile-web-redirect-content-body-redirect-item">
            <div className="redirect-item-title">
              <IonIcon icon={Logo} className="redirect-item-title-icon" />
              HomeKeep App
            </div>
            <IonButton
              size="small"
              color="primary"
              className="redirect-item-button open"
              onClick={() => openAppStoreLink(platformType)}
            >
              Open
            </IonButton>
          </div>
          <div className="hk-mobile-web-redirect-content-body-redirect-item">
            {platformType === HKPlatform.IOS_BROWSER &&
              iOSMobileBrowserButton()}
            {platformType === HKPlatform.ANDROID_BROWSER &&
              androidMobileBrowserButton()}
          </div>
        </div>
      </div>
    </IonModal>
  ) : null;
};

export default MobileWebRedirect;
