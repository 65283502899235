import React from 'react';
import { IonContent } from '@ionic/react';
import './DigitalDashboardSkeletonLoader.scss';
import headerImage from 'assets/illustrations/digital-dashboard-skeleton/digital-dashboard-skeleton-header.png';
import toggleImage from 'assets/illustrations/digital-dashboard-skeleton/digital-dashboard-skeleton-toggle.png';
import cogImage from 'assets/illustrations/digital-dashboard-skeleton/digital-dashboard-skeleton-cog.png';
import titleImage from 'assets/illustrations/digital-dashboard-skeleton/digital-dashboard-skeleton-title.png';
import cardImage from 'assets/illustrations/digital-dashboard-skeleton/digital-dashboard-skeleton-card.png';

const DigitalDashboardSkeletonLoader: React.FC = () => {
  function MobileSkeletonLoaderView() {
    return (
      <IonContent
        color="white"
        className="digital-dashboard-skeleton ion-padding"
        fullscreen
      >
        <img
          className="digital-dashboard-skeleton-header"
          src={headerImage}
          alt="loading"
        />
        <div className="digital-dashboard-skeleton-toggle-container">
          <span>
            <img
              className="digital-dashboard-skeleton-toggle"
              src={toggleImage}
              alt="loading"
            />
            <img
              className="digital-dashboard-skeleton-toggle"
              src={toggleImage}
              alt="loading"
            />
          </span>
          <img
            className="digital-dashboard-skeleton-cog"
            src={cogImage}
            alt="loading"
          />
        </div>
        <img
          className="digital-dashboard-skeleton-title"
          src={titleImage}
          alt="loading"
        />
        <img
          className="digital-dashboard-skeleton-card"
          src={cardImage}
          alt="loading"
        />
        <img
          className="digital-dashboard-skeleton-card"
          src={cardImage}
          alt="loading"
        />
        <img
          className="digital-dashboard-skeleton-card"
          src={cardImage}
          alt="loading"
        />
      </IonContent>
    );
  }

  return MobileSkeletonLoaderView();
};

export default DigitalDashboardSkeletonLoader;
