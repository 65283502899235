import { IonIcon } from '@ionic/react';
import React from 'react';
import Image from '../../assets/icons/image.svg';
import './Thumbnail.scss';

type ThumbnailProps = {
  src: string | undefined;
  alt?: string;
  className?: string;
};

const Thumbnail: React.FC<ThumbnailProps> = ({ src, alt, className = '' }) => {
  return (
    <>
      {!!src ? (
        <img
          className={className}
          src={!!src ? src : Image}
          alt={alt ? alt : 'thumbnail'}
        />
      ) : (
        <div className={`hk-thumbnail ${className ? className : ''}`}>
          <IonIcon icon={Image} className="hk-thumbnail-placeholder" />
        </div>
      )}
    </>
  );
};

export default Thumbnail;
