import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonPage,
  IonSpinner,
  IonTextarea,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { gaBtnClickEvent, gaModalView } from 'core/util';
import './CancelSubscription.scss';
import LeftArrow from 'assets/icons/arrow-left.svg';
import CompleteIcon from 'assets/icons/complete.svg';
import Close from 'assets/icons/close.svg';
import { useHistory } from 'react-router';
import { hideCancelSubscriptionModal, showChangeModal } from './AccountSlice';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { deleteSubscription } from '../billing/BillingActions';

interface SurveyQuestion {
  title: string;
  value: string;
  idx: number;
}

const surveyQuestions: SurveyQuestion[] = [
  {
    title: 'Missing features I needed',
    value: 'Missing features I needed',
    idx: 0
  },
  {
    title: `I didn't want a subscription`,
    value: `I didn't want a subscription`,
    idx: 1
  },
  {
    title: 'This plan is too expensive',
    value: 'This plan is too expensive',
    idx: 2
  },
  {
    title: 'I prefer to DIY my own home',
    value: 'I prefer to DIY my own home',
    idx: 3
  },
  {
    title: 'Other reasons',
    value: '',
    idx: 4
  }
];

const createRandomSurvey = (questions: SurveyQuestion[]) => {
  let i = questions.length - 1;
  for (; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = questions[i];
    questions[i] = questions[j];
    questions[j] = temp;
  }
  return questions;
};

const CancelSubscription: React.FC = () => {
  const [randomQuestions, setRandomSurvey] = useState(() =>
    createRandomSurvey(surveyQuestions)
  );

  const { loading } = useSelector((state: RootState) => state.loading);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { error } = useSelector((state: RootState) => state.billing);
  const dispatch = useDispatch();
  let history = useHistory();
  const [selectedSurveyAnswer, setSelectedSurveyAnswer] = useState({
    title: '',
    value: '',
    idx: 5
  });
  const [otherValue, setOtherValue] = useState('');
  let [toggleIndex, setToggleIndex] = useState(0);

  const previousSlide = () => {
    setToggleIndex(--toggleIndex);
    gaBtnClickEvent('previous_cancellation_slide');
    setRandomSurvey(randomQuestions);
  };

  const nextSlide = () => {
    setToggleIndex(++toggleIndex);
    gaBtnClickEvent('next_cancellation_slide');
  };

  const confirmCancellationRequest = () => {
    gaBtnClickEvent('confirm_cancellation_request');
    let body: any;
    if (selectedSurveyAnswer.idx !== 0 || 4) {
      body = {
        cancel_reason: selectedSurveyAnswer.value
      };
    } else {
      body = {
        cancel_reason: otherValue
      };
    }

    if (!!homeId) {
      dispatch(deleteSubscription(homeId, body));
      if (!error) {
        nextSlide();
      }
    }
  };

  const navigateToRenew = () => {
    dispatch(hideCancelSubscriptionModal());
    gaBtnClickEvent('resubscribe');
    setTimeout(() => {
      history.push(`/renew`);
    }, 0);
  };

  const cancelModal = () => {
    gaBtnClickEvent('close_confirm_cancellation_modal');
    dispatch(hideCancelSubscriptionModal());
    setRandomSurvey(surveyQuestions);
  };

  const openChangeModal = () => {
    gaBtnClickEvent('open_change_subscription_type_modal');
    dispatch(showChangeModal());
  };

  useEffect(() => {
    gaModalView(isDigital, '/account/cancel_subscription');
  }, []);

  function MobileCancelSubscriptionView() {
    return (
      <>
        <IonContent fullscreen className="hk-cancel-subscription-content">
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={cancelModal}
            >
              <IonIcon color="white" icon={Close} />
            </IonFabButton>
          </IonFab>
          <div
            className={
              selectedSurveyAnswer.idx === 0 || selectedSurveyAnswer.idx === 4
                ? 'hk-cancel-subscription-body-expanded'
                : 'hk-cancel-subscription-body'
            }
          >
            <div>
              <h3 className="hk-cancel-subscription-body-title">
                Cancel or switch plans at anytime.
              </h3>
              <p className="hk-cancel-subscription-body-description">
                Thank you for being a HomeKeep subscriber. Confirm your
                cancellation to continue, change your subscription type or go
                back.
              </p>
              <IonButton
                expand="block"
                color="danger"
                strong={true}
                onClick={nextSlide}
              >
                Confirm Cancellation
              </IonButton>
              <IonButton
                expand="block"
                color="white"
                strong={true}
                className="hk-cancel-subscription-body-back-button"
                disabled={loading}
                onClick={cancelModal}
              >
                Back to Settings
              </IonButton>
            </div>
            <div className="hk-cancel-subscription-change-plan">
              <IonButton
                expand="block"
                color="secondary"
                strong={true}
                className="hk-cancel-subscription-change-plan-button"
                disabled={loading}
                onClick={openChangeModal}
              >
                Change Subscription Type
              </IonButton>
              <p className="hk-cancel-subscription-change-plan-description">
                Is your current plan not for you or not the right price? We
                offer different plans that you can change to.
              </p>
            </div>
          </div>
        </IonContent>
      </>
    );
  }

  function MobileCancelSubscriptionQuestionsView() {
    return (
      <>
        <IonContent fullscreen className="hk-cancel-subscription-content">
          {toggleIndex === 1 && (
            <IonFab vertical="top" horizontal="start">
              <IonFabButton
                color="background"
                className={`hk-fab-button ${
                  platformType === HKPlatform.DESKTOP && isDesktopWidth
                    ? ''
                    : 'hk-fab-button-margin-top'
                }`}
                onClick={previousSlide}
              >
                <IonIcon color="white" icon={LeftArrow} />
              </IonFabButton>
            </IonFab>
          )}
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={cancelModal}
            >
              <IonIcon color="white" icon={Close} />
            </IonFabButton>
          </IonFab>
          <div
            className={
              selectedSurveyAnswer.idx === 0 || selectedSurveyAnswer.idx === 4
                ? 'hk-cancel-subscription-body-expanded'
                : 'hk-cancel-subscription-body'
            }
          >
            <div>
              <h3 className="hk-cancel-subscription-body-title">
                Cancel Subscription
              </h3>
              <p className="hk-cancel-subscription-body-description">
                Sorry HomeKeep isn't working for you, could you let us know why
                you are leaving?
              </p>
            </div>

            <div className="hk-cancel-subscription-body-questions">
              {randomQuestions.map((question: SurveyQuestion, i: number) => {
                const { title } = question;

                return (
                  <IonItem
                    className="question-item-container "
                    color="dark"
                    key={`homeowner-${i}`}
                    lines="none"
                    onClick={() => setSelectedSurveyAnswer(question)}
                  >
                    <div
                      className={`question-item ${
                        selectedSurveyAnswer.title === title &&
                        'question-item-selected'
                      }`}
                    >
                      <div className="question-item-checkmark">
                        {selectedSurveyAnswer.title === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="question-item-checkmark-circle"></div>
                        )}
                      </div>
                      <div className="question-item-body">
                        <div className="question-item-body-title">{title}</div>
                      </div>
                    </div>
                  </IonItem>
                );
              })}
            </div>
            {(selectedSurveyAnswer.idx === 0 ||
              selectedSurveyAnswer.idx === 4) && (
              <div className="hk-cancel-subscription-body-questions-footer">
                <h6>Please let us know how can we improve?</h6>
                <IonTextarea
                  className="hk-cancel-subscription-body-questions-footer-input"
                  name={
                    selectedSurveyAnswer.idx === 0 ||
                    selectedSurveyAnswer.idx === 4
                      ? 'other'
                      : 'missing'
                  }
                  placeholder="Enter your feedback here..."
                  autoCorrect="off"
                  autoCapitalize="none"
                  value={otherValue}
                  onIonChange={(e) => setOtherValue(e.detail.value!)}
                  rows={3}
                  maxlength={500}
                  autoGrow={true}
                ></IonTextarea>
              </div>
            )}
          </div>
        </IonContent>
        <IonToolbar className="hk-cancel-subscription-toolbar">
          <IonButton
            color="danger"
            className="hk-delete-account-button"
            expand="block"
            strong={true}
            disabled={!selectedSurveyAnswer}
            onClick={confirmCancellationRequest}
          >
            {loading ? <IonSpinner name="dots" /> : 'Confirm Cancellation'}
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  function MobileCancelSubscriptionConfirmationView() {
    return (
      <>
        <IonContent fullscreen className="hk-cancel-subscription-content">
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={cancelModal}
            >
              <IonIcon color="white" icon={Close} />
            </IonFabButton>
          </IonFab>
          <div className="hk-cancel-subscription-body">
            <div>
              <h3 className="hk-cancel-subscription-body-title">
                Your subscription is now canceled.
              </h3>
              <p className="hk-cancel-subscription-body-description-main">
                You can still view your data as read only, but can no longer
                schedule appointments or see future maintenance tasks from
                HomeKeep.
              </p>
              <p className="hk-cancel-subscription-body-description">
                If you would like to resubscribe you can do so in your account
                settings.
              </p>
            </div>
            <div className="hk-cancel-subscription-change-plan">
              <IonButton
                expand="block"
                color="secondary"
                strong={true}
                className="hk-cancel-subscription-change-plan-button"
                onClick={navigateToRenew}
              >
                Resubscribe
              </IonButton>
              <p className="hk-cancel-subscription-change-plan-description">
                Made a mistake? You can resubscribe here.
              </p>
            </div>
          </div>
        </IonContent>
        <IonToolbar className="hk-mobile-cancel-subscription-toolbar">
          <IonButton
            color="primary"
            expand="block"
            strong={true}
            onClick={cancelModal}
          >
            Back to Settings
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage>
      <SwitchTransition>
        <CSSTransition
          key={toggleIndex}
          addEndListener={(node, done) =>
            node.addEventListener('transitionend', done, false)
          }
          classNames="toggle"
        >
          <div
            className={`hk-cancel-subscription-animation ${
              toggleIndex === 0 && 'previous'
            } ${toggleIndex === 1 && 'next'}`}
          >
            {toggleIndex === 0 && MobileCancelSubscriptionView()}
            {toggleIndex === 1 && MobileCancelSubscriptionQuestionsView()}
            {toggleIndex === 2 && MobileCancelSubscriptionConfirmationView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </IonPage>
  );
};

export default CancelSubscription;
