import usePlacesAutocomplete, {
  getGeocode,
  getZipCode
} from 'use-places-autocomplete';
import Close from 'assets/icons/close.svg';
import { IonIcon, IonItem, IonSearchbar } from '@ionic/react';
import React, { useState } from 'react';
import ArrowRight from 'assets/icons/arrow-right.svg';
import './AddressSearchBar.scss';
import { setMalformedAddressError } from '../../features/shared/signup/SignupSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';

type AddressSearchBarProps = {
  className?: string;
  selectedAddress?: string;
  inputValue?: string;
  placeholder?: string;
  disabled?: boolean;
  debounce?: number;
  onSearchSelect?: (e: any) => void;
};

const AddressSearchBar: React.FC<AddressSearchBarProps> = ({
  className,
  selectedAddress,
  inputValue,
  placeholder,
  disabled,
  debounce,
  onSearchSelect,
  ...rest
}) => {
  let map: google.maps.Map;
  const center: google.maps.LatLngLiteral = { lat: 30, lng: -110 };
  const dispatch = useDispatch();

  function initMap(): void {
    map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
      center,
      zoom: 8
    });
  }
  const [address, setAddress] = useState(selectedAddress);

  const { malformedAddressError } = useSelector(
    (state: RootState) => state.signup
  );
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    callbackName: 'initAutocomplete',
    requestOptions: {
      /* TODO: Define search scope here */
    },
    debounce: 300
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }: any) =>
    () => {
      // When the user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue('', false);
      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const zipCode = getZipCode(results[0], false);
        setAddress(`${description} ${zipCode}`);
        if (!!onSearchSelect) {
          onSearchSelect(`${description} ${zipCode}`);
        }
      });
      clearSuggestions();
    };

  const handleClear = () => {
    setMalformedAddressError(false);
    setValue('', false);
    setAddress('');
    if (!!onSearchSelect) {
      onSearchSelect('');
    }
    clearSuggestions();
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      return (
        <IonItem
          lines="none"
          className="hk-address-search-bar-item"
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          <div className="hk-address-search-bar-item-text">
            <span>{main_text}</span>
            <strong>{secondary_text}</strong>
          </div>

          <IonIcon
            slot="end"
            icon={ArrowRight}
            className="hk-desktop-task-icon"
          ></IonIcon>
        </IonItem>
      );
    });

  return (
    <div
      className={`hk-address-search-bar-container ${
        !!className ? className : ''
      }`}
    >
      <IonSearchbar
        id="autocomplete"
        className="hk-address-search-bar"
        value={value}
        placeholder={!!placeholder ? placeholder : 'Search for an address'}
        disabled={disabled}
        debounce={debounce}
        onIonInput={(e) => handleInput(e)}
      ></IonSearchbar>
      {status === 'OK' && (
        <div id="hk-address-search-bar-suggestions">{renderSuggestions()}</div>
      )}
      {!!address && (
        <IonItem
          lines="none"
          className={
            malformedAddressError
              ? 'hk-error-address-search-bar-item hk-error-address-search-bar-item-selected'
              : 'hk-address-search-bar-item hk-address-search-bar-item-selected'
          }
          onClick={() => setAddress('')}
        >
          <div className="hk-address-search-bar-item-text">
            <span>{address.slice(0, address.indexOf(','))}</span>
            <strong>{address.slice(address.indexOf(',') + 1)}</strong>
          </div>
          <IonIcon
            slot="end"
            color="dark"
            icon={Close}
            className="hk-address-search-bar-item-selected-icon"
            onClick={handleClear}
          ></IonIcon>
        </IonItem>
      )}
      {malformedAddressError && !!address && (
        <div className="hk-desktop-signup-checkout-zipcode-error">
          <p>The selected address does not appear to be a street address.</p>
        </div>
      )}
    </div>
  );
};

export default AddressSearchBar;
