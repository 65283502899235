import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonItem,
  IonToolbar,
  IonTextarea
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OnboardingQuestion3.scss';
import { useHistory } from 'react-router';
import LoadingIonList from 'core/layout/LoadingIonList';
import CompleteIcon from 'assets/icons/complete-square.svg';
import { useEffect, useState } from 'react';
import {
  saveOnboarding3,
  saveOnboardingOther3
} from 'features/shared/signup/SignupSlice';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import { gaBtnClickEvent } from 'core/util';

interface MaintenanceType {
  title: string;
  description: string;
}

const maintenanceTypes: MaintenanceType[] = [
  {
    title: 'Maintenance Schedules',
    description: 'Keep track of maintenance tasks and schedules'
  },
  {
    title: 'Maintenance Reminders',
    description: 'Receive reminders for routine maintenance tasks'
  },
  {
    title: 'Maintenance Task Guidance',
    description: 'Learn how to complete maintenance tasks myself'
  },
  {
    title: 'Help with Maintenance Tasks',
    description: 'Find someone to do maintenance tasks for me'
  },
  {
    title: 'Find Contractors',
    description: 'Connect with local maintenance and service providers'
  },
  {
    title: 'Repairs Guidance',
    description: 'Teach me how to do home repairs'
  },
  {
    title: 'Other',
    description: 'Enter Text'
  }
];

const OnboardingQuestion3: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { onboarding_3, onboarding_other_3 } = useSelector(
    (state: RootState) => state.signup
  );
  const [selectedMaintenanceTypes, setSelectedMaintenanceTypes] = useState<
    any[]
  >([]);

  const handleClick = (title: string) => {
    if (selectedMaintenanceTypes.includes(title)) {
      setSelectedMaintenanceTypes(
        selectedMaintenanceTypes.filter(
          (selectedType) => selectedType !== title
        )
      );
    } else {
      setSelectedMaintenanceTypes([...selectedMaintenanceTypes, title]);
    }
  };

  const isSelected = (title: string) => {
    return selectedMaintenanceTypes.includes(title);
  };

  const onNext = () => {
    if (isSelected('Other')) {
      dispatch(saveOnboarding3(selectedMaintenanceTypes));
    } else {
      dispatch(saveOnboarding3(selectedMaintenanceTypes));
    }
    gaBtnClickEvent('next_onboarding_3');
    history.push(`/onboarding/what-type-of-homeowner-are-you`);
  };

  useEffect(() => {
    if (!!onboarding_3) {
      setSelectedMaintenanceTypes(onboarding_3);
    }
  }, [dispatch, onboarding_3]);

  function DesktopOnboardingView() {
    return (
      <IonPage>
        <div className="desktop-onboarding-3-header-container">
          <div className="desktop-onboarding-3-header">
            What are you looking to get out of this app?
          </div>
          <div className="desktop-onboarding-3-subheader">Help us help you</div>
        </div>
        <IonContent
          className="desktop-onboarding-3-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <LoadingIonList
            className="desktop-onboarding-3-view-list"
            label="Homeowner Type"
            items={maintenanceTypes}
            loading={false}
          >
            {maintenanceTypes.map((maintenance: MaintenanceType, i: number) => {
              const { title, description } = maintenance;
              return (
                <IonItem key={`desktop-homeowner-${i}`} lines="none">
                  {title === 'Other' ? (
                    <div
                      className={`desktop-onboarding-3-view-card ${
                        isSelected(title) &&
                        'desktop-onboarding-3-view-card-selected'
                      }`}
                    >
                      <div className="desktop-onboarding-3-view-card-body">
                        <div className="desktop-onboarding-3-view-card-body-title">
                          {title}
                        </div>
                        <div className="desktop-onboarding-3-view-card-body-input">
                          <IonTextarea
                            name="other"
                            placeholder="Enter Text"
                            autoCorrect="off"
                            autoCapitalize="none"
                            value={
                              !!onboarding_other_3 ? onboarding_other_3 : ''
                            }
                            onIonChange={(e) =>
                              dispatch(saveOnboardingOther3(e.detail.value!))
                            }
                            rows={1}
                            maxlength={255}
                            autoGrow={true}
                          ></IonTextarea>
                        </div>
                      </div>
                      <div
                        className="desktop-onboarding-3-view-card-checkmark"
                        onClick={() => handleClick(title)}
                      >
                        {isSelected(title) ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="desktop-onboarding-3-view-card-checkmark-square"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`desktop-onboarding-3-view-card ${
                        isSelected(title) &&
                        'desktop-onboarding-3-view-card-selected'
                      }`}
                      onClick={() => handleClick(title)}
                    >
                      <div className="desktop-onboarding-3-view-card-body">
                        <div className="desktop-onboarding-3-view-card-body-title">
                          {title}
                        </div>
                        <div className="desktop-onboarding-3-view-card-body-description">
                          {description}
                        </div>
                      </div>
                      <div className="desktop-onboarding-3-view-card-checkmark">
                        {isSelected(title) ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="desktop-onboarding-3-view-card-checkmark-square"></div>
                        )}
                      </div>
                    </div>
                  )}
                </IonItem>
              );
            })}
          </LoadingIonList>
          <div className="desktop-onboarding-3-button-container">
            <IonButton
              className="desktop-onboarding-3-button"
              color="primary"
              type="button"
              onClick={() => onNext()}
            >
              Next
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileOnboardingView() {
    return (
      <IonPage>
        <BackButtonHeader title={''} />
        <div className="onboarding-3-header-container">
          <h3 className="onboarding-3-header">
            <b> What are you looking to get out of this app?</b>
          </h3>
          <h6 className="onboarding-3-subheader">Help us help you</h6>
        </div>
        <IonContent className="onboarding-3-view" fullscreen scrollY={true}>
          <LoadingIonList
            className="onboarding-3-view-list"
            label="Homeowner Type"
            items={maintenanceTypes}
            loading={false}
          >
            {maintenanceTypes.map((maintenance: MaintenanceType, i: number) => {
              const { title, description } = maintenance;
              return (
                <IonItem key={`homeowner-${i}`} lines="none">
                  {title === 'Other' ? (
                    <div
                      className={`onboarding-3-view-card ${
                        isSelected(title) && 'onboarding-3-view-card-selected'
                      }`}
                    >
                      <div className="onboarding-3-view-card-body">
                        <div className="onboarding-3-view-card-body-title">
                          {title}
                        </div>
                        <div className="onboarding-3-view-card-body-input">
                          <IonTextarea
                            name="other"
                            placeholder="Enter Text"
                            autoCorrect="off"
                            autoCapitalize="none"
                            value={
                              !!onboarding_other_3 ? onboarding_other_3 : ''
                            }
                            onIonChange={(e) =>
                              dispatch(saveOnboardingOther3(e.detail.value!))
                            }
                            rows={1}
                            maxlength={255}
                            autoGrow={true}
                          ></IonTextarea>
                        </div>
                      </div>
                      <div
                        className="onboarding-3-view-card-checkmark"
                        onClick={() => handleClick(title)}
                      >
                        {isSelected(title) ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="onboarding-3-view-card-checkmark-square"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`onboarding-3-view-card ${
                        isSelected(title) && 'onboarding-3-view-card-selected'
                      }`}
                      onClick={() => handleClick(title)}
                    >
                      <div className="onboarding-3-view-card-body">
                        <div className="onboarding-3-view-card-body-title">
                          {title}
                        </div>
                        <div className="onboarding-3-view-card-body-description">
                          {description}
                        </div>
                      </div>
                      <div className="onboarding-3-view-card-checkmark">
                        {isSelected(title) ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="onboarding-3-view-card-checkmark-square"></div>
                        )}
                      </div>
                    </div>
                  )}
                </IonItem>
              );
            })}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="onboarding-3-toolbar">
          <IonButton
            className="onboarding-3-button"
            color="primary"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </IonButton>
        </IonToolbar>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopOnboardingView()
        : MobileOnboardingView()}
    </>
  );
};

export default OnboardingQuestion3;
