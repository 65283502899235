import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonSpinner,
  IonText,
  IonToolbar
} from '@ionic/react';
import { RootState } from 'core/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import './CancelAppointment.scss';
import {
  formatAppointmentDisplayTimeSlot,
  formatTimezoneDate,
  gaBtnClickEvent,
  gaModalView
} from 'core/util';
import { DATE_FORMAT_MONTH_DATE, HKPlatform } from 'core/constants';
import {
  hideCancelAppointmentModal,
  removeDetailedAppointment
} from '../AppointmentDetailsSlice';
import { useHistory } from 'react-router';
import Clock from 'assets/icons/clock.svg';
import { deleteScheduledAppointment } from '../AppointmentDetailActions';
import { displaySuccess } from 'core/services/AlertActions';

const CancelAppointment: React.FC = () => {
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { detailedAppointment, loading } = useSelector(
    (state: RootState) => state.appointment
  );
  const { currentHome } = useSelector((state: RootState) => state.home);

  const dispatch = useDispatch();
  const history = useHistory();

  const confirmCancellation = async () => {
    gaBtnClickEvent('confirm_cancel_appointment');
    dispatch(hideCancelAppointmentModal());
    if (!!detailedAppointment && !!currentHome) {
      await dispatch(
        deleteScheduledAppointment(currentHome.id, detailedAppointment.id)
      );
      dispatch(displaySuccess('Appointment Canceled', 'Success'));
      dispatch(removeDetailedAppointment());
      history.push(`/dashboard`);
    }
  };

  const cancelModal = () => {
    gaBtnClickEvent('close_cancel_appointment_modal');
    dispatch(hideCancelAppointmentModal());
  };

  useEffect(() => {
    gaModalView(isDigital, '/appointment/cancel');
  }, []);

  // component Unmount (cleanup)
  useEffect(() => {
    return () => {
      dispatch(hideCancelAppointmentModal());
    };
  }, []);

  function DesktopCancelView() {
    return (
      <>
        <IonHeader className="ion-no-border hk-desktop-cancel-appointment-header">
          <h4 className="hk-desktop-cancel-appointment-header__title">
            Cancel Appointment
          </h4>
          <IonIcon
            className="hk-desktop-cancel-appointment-header__icon"
            onClick={cancelModal}
            color="white"
            icon={Close}
          />
        </IonHeader>

        <IonContent color="dark">
          <div className="hk-desktop-cancel-appointment-body">
            <h6 className="hk-desktop-cancel-appointment-body__subtitle">
              Are you sure you want to cancel your appointment?
            </h6>
            <section className="hk-desktop-cancel-appointment-body__details">
              <h6>Your current appointment is set for:</h6>
              <div className="hk-desktop-cancel-appointment-body__details-time">
                <span>
                  <IonIcon color={'white'} icon={Clock} />
                  <IonText>
                    {!!detailedAppointment?.scheduled
                      ? formatTimezoneDate(
                          detailedAppointment?.scheduled || '',
                          DATE_FORMAT_MONTH_DATE,
                          currentHome?.market_timezone || ''
                        )
                      : ''}
                  </IonText>
                </span>
                <span>&nbsp;</span>
                <IonText className="hk-bold">
                  {!!detailedAppointment?.scheduled
                    ? `(${
                        !!detailedAppointment?.scheduled
                          ? formatAppointmentDisplayTimeSlot(
                              detailedAppointment,
                              currentHome?.market_timezone || ''
                            )
                          : ''
                      })`
                    : '-'}
                </IonText>
              </div>
            </section>{' '}
          </div>
        </IonContent>
        <div className="hk-desktop-cancel-appointment-footer">
          <IonButton
            onClick={cancelModal}
            expand="block"
            color="white"
            strong={true}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="danger"
            type="submit"
            disabled={loading}
            onClick={confirmCancellation}
          >
            {loading ? <IonSpinner name="dots" /> : 'Confirm Cancellation'}
          </IonButton>
        </div>
      </>
    );
  }

  function MobileCancelView() {
    return (
      <>
        <IonHeader className="ion-no-border hk-cancel-appointment-header">
          <IonIcon
            className="hk-cancel-appointment-header__icon"
            onClick={cancelModal}
            color="white"
            icon={Close}
          />
        </IonHeader>

        <IonContent color="dark">
          <div className="hk-cancel-appointment-body">
            <h4 className="hk-cancel-appointment-body__title">
              Cancel Appointment
            </h4>
            <h6 className="hk-cancel-appointment-body__subtitle">
              Are you sure you want to cancel your appointment?
            </h6>
            <section className="hk-cancel-appointment-body__details">
              <h6>Your current appointment is set for:</h6>
              <div className="hk-cancel-appointment-body__details-time">
                <span>
                  <IonIcon color={'white'} icon={Clock} />
                  <IonText>
                    {!!detailedAppointment?.scheduled
                      ? formatTimezoneDate(
                          detailedAppointment?.scheduled || '',
                          DATE_FORMAT_MONTH_DATE,
                          currentHome?.market_timezone || ''
                        )
                      : ''}
                  </IonText>
                </span>
                <span>&nbsp;</span>
                <IonText className="hk-bold">
                  {!!detailedAppointment?.scheduled
                    ? `(${
                        !!detailedAppointment?.scheduled
                          ? formatAppointmentDisplayTimeSlot(
                              detailedAppointment,
                              currentHome?.market_timezone || ''
                            )
                          : ''
                      })`
                    : '-'}
                </IonText>
              </div>
            </section>{' '}
          </div>
        </IonContent>
        <IonToolbar className="hk-mobile-cancel-appointment-footer">
          <IonButton
            onClick={cancelModal}
            expand="block"
            color="white"
            strong={true}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="danger"
            type="submit"
            disabled={loading}
            onClick={confirmCancellation}
          >
            {loading ? <IonSpinner name="dots" /> : 'Confirm Cancellation'}
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopCancelView()
        : MobileCancelView()}
    </>
  );
};

export default CancelAppointment;
