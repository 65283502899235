import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonText,
  IonToolbar
} from '@ionic/react';
import Close from 'assets/icons/close.svg';
import Image from 'assets/icons/image.svg';
import Documents from 'assets/icons/image.svg';
import './InventoryDetails.scss';
import { Asset } from 'core/types';
import { gaBtnClickEvent, gaModalView, viewDocument } from 'core/util';
import {
  hideItemDetailsModal,
  showAddItemModal
} from 'features/shared/inventory/InventorySlice';
import { fetchProductDetails } from 'features/shared/inventory/InventoryActions';
import { HKDocumentType } from '../documents/DocumentsSlice';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';

interface ItemAttribute {
  label: string;
  value: string;
}

const InventoryDetails: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType } = useSelector((state: RootState) => state.platform);
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { product, product_id, loading } = useSelector(
    (state: RootState) => state.inventory
  );
  const [attributes, setAttributes] = useState<ItemAttribute[]>([]);
  const dispatch = useDispatch();

  const assets = !!product
    ? [...product.product_template.assets, ...product.assets]
    : [];

  const imageSlideOpts = {
    initialSlide: 0,
    height: 400,
    speed: 400
  };

  const displayAssetThumbnail = (asset: Asset) => {
    return asset.thumb_cloud_store ? asset.thumb_cloud_store : asset.file_url;
  };

  const closeItemDetailsModal = () => {
    dispatch(hideItemDetailsModal());
  };

  const openAddItemModal = () => {
    gaBtnClickEvent('add_another_item');
    dispatch(showAddItemModal());
  };

  useEffect(() => {
    if (!!product) {
      setAttributes([
        { label: 'Product', value: product.product_template.type_data.label },
        { label: 'Brand', value: product.product_template.manufacturer.name },
        { label: 'Model', value: product.product_template.model },
        { label: 'Serial', value: product.serial }
      ]);
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (!product && !!product_id && !!homeId) {
      dispatch(fetchProductDetails(homeId, Number(product_id)));
    }
  }, [dispatch, product, homeId, product_id]);

  useEffect(() => {
    gaModalView(isDigital, '/inventory/group/product');
  }, []);

  return (
    <IonPage>
      {loading ? (
        <div className="hk-loading-spinner">
          <IonSpinner name="crescent" color="primary" />
        </div>
      ) : (
        <>
          <IonContent className="hk-digital-item-detail ion-margin-vertical ion-padding-vertical">
            <IonList className="ion-margin">
              {assets.length ? (
                <Swiper
                  className="hk-digital-item-detail-image-slider"
                  pager={true}
                  options={imageSlideOpts}
                >
                  {assets.map((asset, i) => {
                    return (
                      <>
                        {asset.type === 'photos' && (
                          <SwiperSlide key={`slide-${i}`}>
                            <img
                              src={displayAssetThumbnail(asset)}
                              alt="Product"
                            />
                          </SwiperSlide>
                        )}
                      </>
                    );
                  })}
                </Swiper>
              ) : (
                <IonItem lines="none">
                  <img
                    src={Image}
                    alt="No product"
                    className="hk-image-placeholder"
                  />
                </IonItem>
              )}
              <IonItem
                lines="none"
                className="hk-digital-item-detail-section-header ion-no-padding"
              >
                <IonText color="dark">Product Details</IonText>
              </IonItem>
              {attributes.map((att, i) => {
                const { label, value } = att;
                return (
                  <IonItem
                    key={`feature-${i}`}
                    lines="none"
                    className="hk-digital-item-detail-attribute ion-no-padding"
                  >
                    <IonLabel color="medium">{label}</IonLabel>
                    <IonLabel className="ion-text-end ion-no-margin">
                      {value}
                    </IonLabel>
                  </IonItem>
                );
              })}
              {!!product?.product_template.assets.filter(
                (asst) => asst.type === HKDocumentType.Manuals
              ).length && (
                <>
                  <IonItem
                    lines="none"
                    className="hk-digital-item-detail-section-header ion-no-padding"
                  >
                    <IonText color="dark">Documents</IonText>
                  </IonItem>
                  <IonItem lines="none" className="ion-no-padding">
                    <div className="hk-digital-item-detail-document-slider">
                      {product?.product_template.assets
                        .filter((asst) => asst.type === HKDocumentType.Manuals)
                        .map((attr, i) => {
                          const { name, file_url } = attr;
                          return (
                            <div
                              className="hk-digital-item-detail-document-slider-slide"
                              key={`slide-${i}`}
                            >
                              <IonImg
                                src={Documents}
                                className="hk-digital-item-detail-document-slider-slide-img"
                                onClick={() =>
                                  viewDocument(file_url, platformType)
                                }
                              />
                              <IonText
                                color="medium"
                                className="hk-digital-item-detail-document-slider-slide-name ion-text-center"
                              >
                                <p>{name}</p>
                              </IonText>
                            </div>
                          );
                        })}
                    </div>
                  </IonItem>
                </>
              )}
            </IonList>
            <IonFab vertical="top" horizontal="end">
              <IonFabButton
                color="white"
                className="hk-fab-button ion-margin-top"
                onClick={() => closeItemDetailsModal()}
              >
                <IonIcon color="medium" icon={Close} />
              </IonFabButton>
            </IonFab>
          </IonContent>
          <IonToolbar className="hk-digital-item-toolbar">
            <IonButton
              expand="block"
              color="primary"
              strong={true}
              onClick={() => openAddItemModal()}
            >
              Add Another Item
            </IonButton>
          </IonToolbar>
        </>
      )}
    </IonPage>
  );
};

export default InventoryDetails;
