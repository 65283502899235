import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonContent,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar
} from '@ionic/react';
import { useHistory } from 'react-router';
import Manual from 'assets/icons/manuals.svg';
import Image from 'assets/icons/image.svg';
import Contracts from 'assets/icons/contracts.svg';
import Warranty from 'assets/icons/warranty.svg';
import HoaCoa from 'assets/icons/hoa_coa.svg';
import Misc from 'assets/icons/misc_docs.svg';
import EmptyBox from 'assets/illustrations/empty-box.svg';
import { HKPlatform } from 'core/constants';
import './DocumentsGroups.scss';
import ArrowRight from 'assets/icons/arrow-right.svg';
import ListItem from 'components/items/list-item/ListItem';
import SearchBar from 'components/searchbar/SearchBar';
import LoadingIonList from 'core/layout/LoadingIonList';
import MenuHeader from 'core/layout/menu-header/MenuHeader';
import {
  hideDocumentsAddModal,
  resetDocuments,
  resetSearchedDocuments,
  setSelectedDocument,
  showDesktopDocumentEditModal,
  showDocumentsAddModal
} from 'features/shared/documents/DocumentsSlice';
import {
  fetchDocumentCount,
  fetchDocumentTypes,
  fetchSearchedDocuments
} from 'features/shared/documents/DocumentsActions';
import DocumentsAdd from './DocumentsAdd';
import { SearchedDocument } from 'core/types';
import ListCard from 'components/cards/list/ListCard';
import DocumentCard from 'components/cards/document-card/DocumentCard';
import { getDocumentFileTypeIcon } from 'core/util';
import FileIcon from 'assets/icons/file-icon.svg';
import DocumentEdit from './DocumentEdit';

export enum HKDocumentType {
  MANUALS = 'manuals',
  VIDEOS = 'videos',
  FLOOR_PLANS = 'floorplans',
  PROPERTY_RECORDS = 'property_records',
  LOAN_DOCUMENTS = 'loan_documents',
  WARRANTY = 'warranty',
  HOA_COA = 'hoa_coa',
  MISC = 'misc'
}

const DocumentsGroups: React.FC = () => {
  const {
    loading,
    counts,
    searchedDocuments,
    addModalVisible,
    editDesktopModalVisible
  } = useSelector((state: RootState) => state.documents);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const [documentVisible, setDocumentVisible] = useState(false);
  const [documentUrl, setDocumentUrl] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const generateListOfSearchedGroups = () => {
    let listOfGroups: any[] = [];
    let uniqueListOfGroups = new Set();
    searchedDocuments.forEach((document) => {
      const group = document.type_label;
      uniqueListOfGroups.add(group);
    });
    listOfGroups = Array.from(uniqueListOfGroups).filter((group) => {
      return group !== undefined;
    });
    return listOfGroups;
  };

  const filteredDocumentsGroups = generateListOfSearchedGroups();

  const imageForDocumentType = (type: string): string => {
    switch (type) {
      case HKDocumentType.MANUALS:
        return Manual;
      case HKDocumentType.VIDEOS:
        return Image;
      case HKDocumentType.FLOOR_PLANS:
        return Contracts;
      case HKDocumentType.PROPERTY_RECORDS:
        return Contracts;
      case HKDocumentType.LOAN_DOCUMENTS:
        return Manual;
      case HKDocumentType.WARRANTY:
        return Warranty;
      case HKDocumentType.HOA_COA:
        return HoaCoa;
      case HKDocumentType.MISC:
        return Misc;
    }
    return Contracts;
  };

  const openDesktopDocument = (document: SearchedDocument) => {
    dispatch(setSelectedDocument(document));
    dispatch(showDesktopDocumentEditModal());
  };

  const showGroup = (group: number) => {
    if (group !== undefined) {
      dispatch(resetDocuments());
      history.push(`/documents/group/${group}`);
    }
  };

  const closeAddDocumentsModal = () => {
    if (!!homeId) {
      dispatch(fetchDocumentCount(homeId));
    }
    dispatch(hideDocumentsAddModal());
  };

  const openDocument = (url: string) => {
    if (url.toLowerCase().includes('pdf') && /\s/.test(url.toLowerCase())) {
      let encodedUrl: string;
      if (platformType !== HKPlatform.DESKTOP) {
        encodedUrl =
          'https://drive.google.com/viewerng/viewer?url=' +
          encodeURI(url.toLowerCase().replace('http', 'https')) +
          '?pid=explorer&efh=false&a=v&chrome=false&embedded=true';
      } else {
        encodedUrl = encodeURI(url.toLowerCase().replace('http', 'https'));
      }
      setDocumentUrl(encodedUrl);
      downloadDocument(encodedUrl);
    } else {
      setDocumentUrl(url.toLowerCase().replace('http', 'https'));
      setDocumentVisible(true);
    }
  };

  const downloadDocument = (url: string) => {
    window.open(url, '_blank');
  };

  const resetDocumentUrl = () => {
    setDocumentUrl('');
    setDocumentVisible(false);
  };

  useEffect(() => {
    if (!!homeId) {
      dispatch(fetchDocumentCount(homeId));
      dispatch(fetchDocumentTypes());
    }
  }, [homeId]);

  useEffect(() => {
    if (!!homeId && !!search) {
      dispatch(fetchSearchedDocuments(homeId, search));
    } else {
      dispatch(resetSearchedDocuments());
    }
  }, [search, editDesktopModalVisible]);

  function SearchedDocumentsView() {
    return (
      <>
        {searchedDocuments.length ? (
          <>
            {filteredDocumentsGroups.map((group: string, i: number) => {
              return (
                <div
                  className={
                    platformType === HKPlatform.DESKTOP && isDesktopWidth
                      ? 'hk-desktop-searched-products'
                      : 'hk-searched-products'
                  }
                  key={`group-${i}`}
                >
                  <h4>{group}</h4>
                  {searchedDocuments!.map(
                    (searchedDocument: SearchedDocument, i: number) => {
                      const { name, file_url, type_label } = searchedDocument;
                      return (
                        <>
                          {type_label === group && (
                            <DocumentCard
                              key={`item-${i}`}
                              title={name}
                              image={
                                !!file_url
                                  ? getDocumentFileTypeIcon(file_url)
                                  : FileIcon
                              }
                              onClick={() => {
                                openDesktopDocument(searchedDocument);
                              }}
                            />
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <div className="hk-search-empty">
            <h1>No Matches Found</h1>
            <p>
              The thing you are searching for isn't here. Please try another
              search.
            </p>
            <p>
              You can also add a document
              {platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? ' above'
                : ' below'}
              .
            </p>
          </div>
        )}
      </>
    );
  }

  function DesktopDocumentsGroupView() {
    return (
      <>
        <IonRow className="hk-desktop-digital-documents-group-header">
          <div className="hk-desktop-digital-documents-group-header-container">
            <IonBreadcrumbs className="hk-desktop-digital-documents-group-header-breadcrumbs">
              <IonBreadcrumb>Documents</IonBreadcrumb>
            </IonBreadcrumbs>
            <div className="hk-desktop-digital-documents-group-header-right ">
              <div className="hk-desktop-digital-documents-group-search">
                <SearchBar
                  value={search}
                  disabled={counts?.length === 0}
                  debounce={1000}
                  onSearchChange={(e) => setSearch(e)}
                ></SearchBar>
              </div>
              <div className="hk-desktop-digital-documents-group-header-button">
                <IonButton
                  expand="block"
                  color="primary"
                  strong={true}
                  onClick={() => dispatch(showDocumentsAddModal())}
                >
                  Upload Document
                </IonButton>
              </div>
            </div>
          </div>
        </IonRow>
        <IonContent className="hk-desktop-digital-documents-group">
          <LoadingIonList
            className="hk-desktop-digital-documents-group-list"
            label="Documents"
            items={counts}
            loading={loading}
          >
            {!!search ? (
              <SearchedDocumentsView />
            ) : (
              <>
                {counts?.map((documentCount: any, i: any) => {
                  const { label, count, type } = documentCount;
                  return (
                    <ListItem
                      key={`item-${i}`}
                      customView={true}
                      onClick={() => showGroup(type)}
                    >
                      <IonIcon
                        slot="start"
                        icon={imageForDocumentType(type)}
                        className="hk-desktop-digital-documents-group-list-item-start-icon"
                      />
                      <div className="hk-desktop-digital-documents-group-list-item-body">
                        <IonText className="hk-desktop-digital-documents-group-list-item-body-title">
                          <h2>{label}</h2>
                        </IonText>
                        <IonText className="hk-desktop-digital-documents-group-list-item-body-description">
                          <p>{count + ' items'}</p>
                        </IonText>
                        <IonIcon
                          slot="end"
                          icon={ArrowRight}
                          className="hk-desktop-digital-documents-group-list-item-body-end-icon"
                        ></IonIcon>
                      </div>
                    </ListItem>
                  );
                })}
              </>
            )}
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  function MobileDocumentsGroupView() {
    return (
      <>
        <MenuHeader title={'Documents'} />
        <IonContent fullscreen>
          <SearchBar
            value={search}
            disabled={counts?.length === 0}
            debounce={1000}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList label="Documents" items={counts} loading={loading}>
            {!!search ? (
              <SearchedDocumentsView />
            ) : (
              <>
                {counts?.map((documentCount: any, i: any) => {
                  const { label, count, type } = documentCount;
                  return (
                    <ListItem
                      key={`item-${i}`}
                      startIcon={imageForDocumentType(type)}
                      endIcon={ArrowRight}
                      title={label}
                      description={count + ' items'}
                      onClick={() => showGroup(type)}
                    />
                  );
                })}
              </>
            )}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="hk-mobile-digital-documents-groups-toolbar">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={() => dispatch(showDocumentsAddModal())}
          >
            Add Document
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-digital-documents-group-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsGroupView()
        : MobileDocumentsGroupView()}
      <IonModal
        className="hk-digital-documents-add-modal"
        isOpen={addModalVisible}
        onDidDismiss={() => {
          closeAddDocumentsModal();
        }}
      >
        <DocumentsAdd />
      </IonModal>
      <IonModal
        className="hk-desktop-digital-documents-edit-modal"
        isOpen={editDesktopModalVisible}
        onDidDismiss={() => {
          resetDocumentUrl();
        }}
      >
        <DocumentEdit />
      </IonModal>
    </IonPage>
  );
};

export default DocumentsGroups;
