import React from 'react';
import { IonIcon, IonPage, IonRow, IonSpinner } from '@ionic/react';
import Logo from '../../assets/icons/HK-logo.svg';
import './LoadingSpinner.scss';

export enum LoadingMode {
  Dots,
  Logo
}

interface LoadingSpinnerProps {
  mode?: LoadingMode;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ mode = 0 }) => {
  return (
    <IonPage className="hk-loading-spinner-container">
      <div
        scroll-y="false"
        className={`hk-loading-spinner ion-padding ion-text-center ${
          mode === LoadingMode.Logo ? 'logo' : 'dots'
        }`}
      >
        {mode === LoadingMode.Dots ? (
          <IonRow className="vertical-align-center hk-loading-spinner-list">
            <IonSpinner name="dots" color="grey" />
          </IonRow>
        ) : null}
        {mode === LoadingMode.Logo ? (
          <div className="hk-loading-spinner-logo">
            <IonIcon icon={Logo} />
          </div>
        ) : null}
      </div>
    </IonPage>
  );
};

export default LoadingSpinner;
