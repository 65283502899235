import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Route, Redirect, useLocation } from 'react-router';
import { setLoginDest } from 'features/shared/login/LoginSlice';
import { useDispatch } from 'react-redux';

interface PrivateRouteProps {
  exact?: boolean;
  path: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  let location = useLocation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state: RootState) => state.login.loggedIn
  );

  useEffect(() => {
    if (!isAuthenticated && location.pathname !== '/login') {
      dispatch(setLoginDest(location.pathname));
    }
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
