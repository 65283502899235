import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Redirect } from 'react-router';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonSpinner
} from '@ionic/react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  FirebaseAuthentication,
  GetCurrentUserResult
} from '@tambroseavid/capacitor-firebase-authentication';
import { useForm } from 'react-hook-form';
import { firebaseConfig, HKPlatform } from 'core/constants';
import './IntegrationReset.scss';
import {
  requestTokenForFirebaseUserSuccess,
  setAuthEmail,
  setAuthIdToken,
  setError
} from './LoginSlice';
import {
  requestTokenForFirebaseUser,
  updateFirebasePassword
} from './LoginActions';
import { loadAuthTokenFromStorage } from 'core/util';
import Header from '../signup/views/Header';
import Footer from '../signup/views/Footer';
import CompleteIcon from 'assets/icons/complete.svg';

const IntegrationReset: React.FC = () => {
  const { loading, authEmail, authIdToken, accessToken } = useSelector(
    (state: RootState) => state.login
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [userPassword, setUserPassword] = useState('');
  const [token, setToken] = useState('');
  const [showConfirmationPage, setShowConfirmationPage] = useState(false);
  const { register, handleSubmit, formState, getValues, trigger } =
    useForm<FormData>({
      defaultValues: { password: '', passwordConfirm: '' },
      reValidateMode: 'onChange',
      criteriaMode: 'all',
      mode: 'onChange'
    });
  const { isValid, errors } = formState;
  const dispatch = useDispatch();

  type FormData = {
    password: string;
    passwordConfirm: string;
  };

  // Configure Firebase.
  firebase.initializeApp(firebaseConfig);

  const getCurrentUser = async (): Promise<GetCurrentUserResult> => {
    const result = await FirebaseAuthentication.getCurrentUser();
    return result;
  };

  const getIdToken = async () => {
    const result = await FirebaseAuthentication.getIdToken();
    return result.token;
  };

  const getIdTokenForEmail = (email: string) => {
    dispatch(setAuthEmail(email));
    getIdToken().then((token) => {
      dispatch(setAuthIdToken(token));
    });
  };

  const signInWithPassword = async (email: string, password: string) => {
    dispatch(setError(null));
    await FirebaseAuthentication.signInWithPassword({
      email: email,
      password: password
    }).catch((error) => {
      dispatch(setError('Sign in failed. Please try again.'));
    });
    getCurrentUser().then((result) => {
      if (!!result.user) {
        getIdTokenForEmail(result.user.email!);
      }
    });
  };

  const handlePasswordReset = async (password: string) => {
    setUserPassword(password);
    dispatch(updateFirebasePassword(password, token));
  };

  const onFormSubmit = handleSubmit(({ password, passwordConfirm }) => {
    if (errors.password === undefined && errors.passwordConfirm === undefined) {
      handlePasswordReset(password);
    }
  });

  // This signs in the user with the email and password
  useEffect(() => {
    if (!!authEmail) {
      setShowConfirmationPage(true);
      setTimeout(() => {
        signInWithPassword(authEmail, userPassword).then();
      }, 2000);
    }
  }, [authEmail]);

  // This retreives the accessToken for automatically signing in
  useEffect(() => {
    if (!!authEmail && !!authIdToken) {
      dispatch(requestTokenForFirebaseUser(authIdToken));
    }
  }, [authEmail, authIdToken]);

  useEffect(() => {
    if (!accessToken) {
      loadAuthTokenFromStorage().then((token) => {
        if (token) {
          dispatch(requestTokenForFirebaseUserSuccess({ access_token: token }));
        }
      });
    }
  }, [accessToken]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!!params.get('token')) {
      setToken(params.get('token')!);
    }
  }, []);

  function DesktopIntegrationResetView() {
    return (
      <IonPage>
        <Header />
        <IonContent className="hk-desktop-integration-reset-view">
          {showConfirmationPage ? (
            <>
              <section className="hk-desktop-integration-reset-view-header">
                <h3 className="hk-desktop-integration-reset-view-title ion-text-center">
                  <b>Password Set Successfully</b>
                </h3>
                <h6 className="hk-integration-reset-view-subheader ion-padding-horizontal ion-text-center">
                  Signing in
                  <div className="hk-body-spinner">
                    <IonRow className="vertical-align-center">
                      <IonSpinner name="dots" />
                    </IonRow>
                  </div>
                </h6>
              </section>
            </>
          ) : (
            <>
              <div className="hk-desktop-integration-reset-view-container">
                <section className="hk-desktop-integration-reset-view-header">
                  <h3 className="hk-desktop-integration-reset-view-title ion-text-center">
                    <b>Set Your Password</b>
                  </h3>
                  <h6 className="hk-desktop-integration-reset-view-subheader ion-padding-horizontal ion-text-center">
                    Setup a secure password and you are ready to start
                    maintaining your home investment
                  </h6>
                </section>
                <div className="hk-desktop-integration-reset-view-content">
                  <section className="hk-desktop-integration-reset-view-reset-form">
                    <form
                      className="hk-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="hk-desktop-integration-reset-view-content-container">
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={() => {
                              trigger('passwordConfirm');
                            }}
                            ref={register({
                              validate: {
                                hasSpecialCharacter: (value) => {
                                  const validation = /(?=.*[\d@$!%*?&])/.test(
                                    value
                                  );
                                  return validation;
                                },
                                hasMinimumLength: (value) => {
                                  const validation = value.length >= 8;
                                  return validation;
                                },
                                hasUpperCase: (value) => {
                                  const validation = /.*[A-Z]+.*/.test(value);
                                  return validation;
                                }
                              }
                            })}
                          />
                        </div>
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="passwordConfirm"
                            placeholder="Confirm password"
                            ref={register({
                              required: 'Please confirm your password',
                              validate: (value) =>
                                value === getValues('password') ||
                                'The passwords do not match'
                            })}
                          />
                        </div>

                        <section className="hk-form-validation-container">
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasMinimumLength'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 8 characters
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasUpperCase'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 uppercase letter
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasSpecialCharacter'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 number or special character
                          </div>
                        </section>
                        {errors.passwordConfirm && (
                          <p className="hk-error">
                            {errors.passwordConfirm.message}
                          </p>
                        )}
                      </div>
                      <div className="hk-form-actions">
                        <IonButton
                          className="hk-auth-button ion-margin-vertical"
                          color="primary"
                          size="default"
                          strong={true}
                          disabled={!isValid}
                          onClick={onFormSubmit}
                        >
                          Set Password
                        </IonButton>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
              {loading && (
                <div className="hk-body-spinner">
                  <IonRow className="vertical-align-center">
                    <IonSpinner name="dots" />
                  </IonRow>
                </div>
              )}
            </>
          )}
        </IonContent>
        <Footer darkMode />
      </IonPage>
    );
  }

  function MobileIntegrationResetView() {
    return (
      <IonPage>
        <Header />
        <IonContent className="hk-integration-reset-view">
          {showConfirmationPage ? (
            <>
              <section className="hk-integration-reset-view-header">
                <h3 className="hk-integration-reset-view-title ion-text-center">
                  <b>Password Set Successfully</b>
                </h3>
                <h6 className="hk-integration-reset-view-subheader ion-padding-horizontal ion-text-center">
                  Signing in
                  <div className="hk-body-spinner">
                    <IonRow className="vertical-align-center">
                      <IonSpinner name="dots" />
                    </IonRow>
                  </div>
                </h6>
              </section>
            </>
          ) : (
            <>
              <div className="hk-integration-reset-view-container">
                <section className="hk-integration-reset-view-header">
                  <h3 className="hk-integration-reset-view-title ion-text-center">
                    <b>Set Your Password</b>
                  </h3>
                  <h6 className="hk-integration-reset-view-subheader ion-padding-horizontal ion-text-center">
                    Setup a secure password and you are ready to start
                    maintaining your home investment
                  </h6>
                </section>
                <div className="hk-integration-reset-view-content">
                  <section className="hk-integration-reset-view-reset-form">
                    <form
                      className="hk-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="hk-integration-reset-view-content-container">
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={() => {
                              trigger('passwordConfirm');
                            }}
                            ref={register({
                              validate: {
                                hasSpecialCharacter: (value) => {
                                  const validation = /(?=.*[\d@$!%*?&])/.test(
                                    value
                                  );
                                  return validation;
                                },
                                hasMinimumLength: (value) => {
                                  const validation = value.length >= 8;
                                  return validation;
                                },
                                hasUpperCase: (value) => {
                                  const validation = /.*[A-Z]+.*/.test(value);
                                  return validation;
                                }
                              }
                            })}
                          />
                        </div>
                        <div className="hk-form-row">
                          <input
                            type="password"
                            name="passwordConfirm"
                            placeholder="Confirm password"
                            ref={register({
                              required: 'Please confirm your password',
                              validate: (value) =>
                                value === getValues('password') ||
                                'The passwords do not match'
                            })}
                          />
                        </div>

                        <section className="hk-form-validation-container">
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasMinimumLength'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 8 characters
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasUpperCase'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 uppercase letter
                          </div>
                          <div
                            className={`hk-form-validation ${
                              !formState.isDirty ||
                              (errors.password &&
                                errors.password?.types?.hasOwnProperty(
                                  'hasSpecialCharacter'
                                ))
                                ? 'invalid'
                                : ''
                            }`}
                          >
                            <IonIcon icon={CompleteIcon} />
                            At least 1 number or special character
                          </div>
                        </section>
                        {errors.passwordConfirm && (
                          <p className="hk-error">
                            {errors.passwordConfirm.message}
                          </p>
                        )}
                      </div>
                      <div className="hk-form-actions">
                        <IonButton
                          className="hk-auth-button ion-margin-vertical"
                          color="primary"
                          size="default"
                          strong={true}
                          disabled={!isValid}
                          onClick={onFormSubmit}
                        >
                          Set Password
                        </IonButton>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
              {loading && (
                <div className="hk-body-spinner">
                  <IonRow className="vertical-align-center">
                    <IonSpinner name="dots" />
                  </IonRow>
                </div>
              )}
            </>
          )}
        </IonContent>
        <Footer darkMode />
      </IonPage>
    );
  }

  return accessToken ? (
    <Redirect to="/checkpoint" />
  ) : (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopIntegrationResetView()
        : MobileIntegrationResetView()}
    </>
  );
};

export default IntegrationReset;
