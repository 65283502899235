import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DigitalProductTypeToggle,
  DigitalProductTypeTogglesList,
  DigitalTask,
  DigitalTaskDetails,
  StatusTag
} from 'core/types';

interface DigitalTasksState {
  loading: boolean;
  taskLoading: boolean;
  error: string | null;
  upcomingTasks: DigitalTask[];
  overdueTasks: DigitalTask[];
  completedTasks: DigitalTask[];
  productTypeToggles: DigitalProductTypeToggle[];
  modalTaskDetailsVisible: boolean;
  modalTasksCompleteVisible: boolean;
  modalProductTypeTogglesVisible: boolean;
  taskId: number | null;
  taskDetails: DigitalTaskDetails | null;
  taskStatusTags: StatusTag[] | null;
}

export const initialState: DigitalTasksState = {
  loading: false,
  taskLoading: false,
  error: null,
  upcomingTasks: [],
  overdueTasks: [],
  completedTasks: [],
  productTypeToggles: [],
  modalTaskDetailsVisible: false,
  modalTasksCompleteVisible: false,
  modalProductTypeTogglesVisible: false,
  taskId: null,
  taskDetails: null,
  taskStatusTags: null
};

export const digitalTasksSlice = createSlice({
  name: 'digitalTasks',
  initialState,
  reducers: {
    fetchDigitalUpcomingTasksStart: (state) => {
      state.loading = true;
    },
    fetchDigitalUpcomingTasksSuccess: (state, action: PayloadAction<any>) => {
      action.payload.forEach((task: DigitalTask) => {
        if (state.upcomingTasks.every((item) => item.id !== task.id)) {
          state.upcomingTasks.push(task);
        }
      });
      state.loading = false;
    },
    fetchDigitalOverdueTasks: (state, action: PayloadAction<any>) => {
      state.overdueTasks = action.payload;
      state.loading = false;
    },
    fetchDigitalFinishedTasks: (state, action: PayloadAction<any>) => {
      state.completedTasks = action.payload;
    },
    fetchProductTypeTogglesStart: (state) => {
      state.loading = true;
    },
    fetchProductTypeTogglesSuccess: (
      state,
      action: PayloadAction<DigitalProductTypeTogglesList>
    ) => {
      state.productTypeToggles = action.payload.toggles;
      state.loading = false;
    },
    fetchDigitalTasksError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchDigitalTaskDetailsStart: (state, action: PayloadAction<any>) => {
      state.taskLoading = true;
    },
    fetchDigitalTaskDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.taskDetails = action.payload;
      state.taskLoading = false;
    },
    fetchDigitalTaskStatusTags: (state, action: PayloadAction<any>) => {
      state.taskStatusTags = action.payload;
    },
    fetchDigitalTaskStatusTagsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.taskStatusTags = null;
    },
    patchProductTypeTogglesSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    patchProductTypeTogglesError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    patchDigitalTaskDetailsStart: (state) => {
      state.taskLoading = true;
    },
    patchDigitalTaskDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.taskDetails = action.payload;
      state.taskLoading = false;
    },
    patchDigitalTaskDetailsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.taskLoading = false;
    },
    showTaskDetailsModal: (state, action: PayloadAction<any>) => {
      state.taskId = Number(action.payload);
      state.modalTaskDetailsVisible = true;
    },
    hideTaskDetailsModal: (state) => {
      state.loading = true;
      state.taskLoading = true;
      state.taskDetails = null;
      state.modalTaskDetailsVisible = false;
    },
    showTasksCompleteModal: (state) => {
      state.modalTasksCompleteVisible = true;
    },
    hideTasksCompleteModal: (state) => {
      state.modalTasksCompleteVisible = false;
    },
    showProductTypeTogglesModal: (state) => {
      state.modalProductTypeTogglesVisible = true;
    },
    hideProductTypeTogglesModal: (state) => {
      state.modalProductTypeTogglesVisible = false;
    },
    resetDigitalTaskDetails: (state) => {
      state.taskDetails = null;
      state.taskId = null;
      state.taskStatusTags = null;
    },
    resetDigitalTasksList: () => initialState
  }
});

export const {
  fetchDigitalUpcomingTasksStart,
  fetchDigitalUpcomingTasksSuccess,
  fetchDigitalOverdueTasks,
  fetchDigitalFinishedTasks,
  fetchProductTypeTogglesStart,
  fetchProductTypeTogglesSuccess,
  fetchDigitalTasksError,
  fetchDigitalTaskDetailsStart,
  fetchDigitalTaskDetailsSuccess,
  fetchDigitalTaskStatusTags,
  fetchDigitalTaskStatusTagsError,
  patchProductTypeTogglesSuccess,
  patchProductTypeTogglesError,
  patchDigitalTaskDetailsStart,
  patchDigitalTaskDetailsSuccess,
  patchDigitalTaskDetailsError,
  showTaskDetailsModal,
  hideTaskDetailsModal,
  showTasksCompleteModal,
  hideTasksCompleteModal,
  showProductTypeTogglesModal,
  hideProductTypeTogglesModal,
  resetDigitalTaskDetails,
  resetDigitalTasksList
} = digitalTasksSlice.actions;

export default digitalTasksSlice.reducer;
