import {
  IonButton,
  IonContent,
  IonIcon,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import './ZipcodeCheck.scss';
import { gaBtnClickEvent, gaModalView } from 'core/util';
import {
  hideZipcodeModal,
  saveZipCode,
  setZipcodeError,
  setMalformedAddressError
} from '../../SignupSlice';
import { checkZipcode } from '../../SignupActions';
import { HKPlatform } from 'core/constants';

const ZipcodeCheck: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { signupPlans, postal_code, zipcodeError, malformedAddressError } =
    useSelector((state: RootState) => state.signup);
  const [fullZipcode, setFullZipcode] = useState('');
  const dispatch = useDispatch();

  const handleInputChange = (zipcode: any) => {
    if (zipcode.length !== 5) {
      setFullZipcode('');
    } else {
      setFullZipcode(zipcode);
    }
  };

  const checkAvailability = () => {
    if (fullZipcode.length === 5) {
      gaBtnClickEvent('check_zipcode_availability');
      dispatch(saveZipCode(fullZipcode));
      dispatch(checkZipcode(fullZipcode));
    }
  };

  const cancelModal = () => {
    gaBtnClickEvent('close_zipcode_modal');
    dispatch(hideZipcodeModal());
  };

  useEffect(() => {
    if (!!postal_code) {
      const error = signupPlans.length > 2 ? false : true;
      if (!error) {
        dispatch(hideZipcodeModal());
      } else {
        dispatch(setZipcodeError(error));
        dispatch(setMalformedAddressError(error));
      }
    }
  }, [signupPlans]);

  useEffect(() => {
    setZipcodeError(false);
    setMalformedAddressError(false);
    gaModalView(isDigital, '/signup/zipcode-check');
  }, []);

  function DesktopZipcodeView() {
    return (
      <>
        <div className="hk-desktop-zipcode-check-header">
          <IonTitle
            className="hk-desktop-zipcode-check-header__title"
            color="dark"
          >
            Full Service Availability Check
          </IonTitle>
          <IonIcon
            className="hk-desktop-zipcode-check-header__icon"
            onClick={cancelModal}
            color="dark"
            icon={Close}
          />
        </div>

        <IonContent>
          <div className="hk-desktop-zipcode-check-body">
            <h6 className="hk-desktop-zipcode-check-body__title">
              In order to make sure this service is available in your area,
              please enter your zip code below.
            </h6>
            <div className="hk-desktop-zipcode-check-body__input">
              <div
                className={`hk-desktop-zipcode-check-body__input-field ${
                  zipcodeError ? 'zipcode-error' : ''
                }`}
              >
                <input
                  className="zipcode-input"
                  type="tel"
                  id="zipcode"
                  name="zipcode"
                  pattern="[0-9]{5}"
                  maxLength={5}
                  title="Please enter a 5-digit ZIP code"
                  required
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                />
              </div>
            </div>
            <h4 className="hk-desktop-zipcode-check-body__subtitle">
              Zip Code
            </h4>
            {zipcodeError && (
              <p className="hk-desktop-zipcode-check-body__error">
                Full service is not currently available in your area.
              </p>
            )}
          </div>
        </IonContent>
        <div className="hk-desktop-zipcode-check-footer">
          <IonButton
            onClick={cancelModal}
            expand="block"
            color="white"
            strong={true}
          >
            Cancel
          </IonButton>
          <IonButton
            disabled={fullZipcode.length < 5}
            onClick={checkAvailability}
            expand="block"
            color="primary"
            type="submit"
            strong={true}
          >
            Check Availability
          </IonButton>
        </div>
      </>
    );
  }

  function MobileZipcodeView() {
    return (
      <>
        <div className="hk-zipcode-check-header">
          <IonTitle className="hk-zipcode-check-header__title" color="dark">
            Full Service Sign Up
          </IonTitle>
          <IonIcon
            className="hk-zipcode-check-header__icon"
            onClick={cancelModal}
            color="dark"
            icon={Close}
          />
        </div>

        <IonContent>
          <div className="hk-zipcode-check-body">
            <h6 className="hk-zipcode-check-body__title">
              In order to make sure this service is available in your area,
              please enter your zip code below.
            </h6>
            <div className="hk-zipcode-check-body__input">
              <div
                className={`hk-zipcode-check-body__input-field ${
                  zipcodeError ? 'zipcode-error' : ''
                }`}
              >
                <input
                  className="zipcode-input"
                  type="tel"
                  id="zipcode"
                  name="zipcode"
                  pattern="[0-9]{5}"
                  maxLength={5}
                  title="Please enter a 5-digit ZIP code"
                  required
                  onChange={(e) => {
                    handleInputChange(e.target.value);
                  }}
                />
              </div>
            </div>
            <h4 className="hk-zipcode-check-body__subtitle">Zip Code</h4>
            {zipcodeError && (
              <p className="hk-zipcode-check-body__error">
                Full service is not currently available in your area.
              </p>
            )}
          </div>
        </IonContent>
        <IonToolbar className="hk-zipcode-check-footer">
          <IonButton
            onClick={cancelModal}
            expand="block"
            color="white"
            strong={true}
          >
            Cancel
          </IonButton>
          <IonButton
            disabled={fullZipcode.length < 5}
            onClick={checkAvailability}
            expand="block"
            color="primary"
            type="submit"
            strong={true}
          >
            Check Availability
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopZipcodeView()
        : MobileZipcodeView()}
    </>
  );
};

export default ZipcodeCheck;
