import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  id: number | null;
  name: string;
  email: string;
  mobile_phone: string;
  zendesk_token: string | null;
  zendesk_token_url: string | null;
  error: string | null;
  isDigital: boolean | null;
  termsAndConditions: boolean | null;
  impersonating: boolean | false;
}

export const initialState: UserState = {
  id: null,
  name: '',
  email: '',
  mobile_phone: '',
  zendesk_token: null,
  zendesk_token_url: null,
  error: null,
  impersonating: false,
  isDigital: null,
  termsAndConditions: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    setUserId: (state, action: PayloadAction<any>) => {
      const { id, terms_and_conditions } = action.payload;
      state.id = id;
      state.termsAndConditions = terms_and_conditions;
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      const { name, email, mobile_phone } = action.payload;
      state.email = email;
      state.name = name;
      state.mobile_phone = mobile_phone;
    },
    setUserDigital: (state, action: PayloadAction<any>) => {
      state.isDigital = action.payload;
    },
    setImpersonating: (state, action: PayloadAction<any>) => {
      state.impersonating = action.payload > 0;
    },
    updateUserSuccess: (state, action: PayloadAction<any>) => {
      const { name, email, mobile_phone } = action.payload;
      state.name = name;
      state.email = email;
      state.mobile_phone = mobile_phone;
    },
    updateUserFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    updateUserMeSuccess: (state, action: PayloadAction<any>) => {
      const { terms_and_conditions } = action.payload;
      state.termsAndConditions = terms_and_conditions;
    },
    updateUserMeFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchZendeskTokenSuccess: (state, action: PayloadAction<any>) => {
      const { url, jwt_token } = action.payload;
      state.zendesk_token = jwt_token;
      state.zendesk_token_url = url;
    },
    fetchZendeskTokenFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    resetUserInfo: () => initialState
  }
});

export const {
  fetchUserFailure,
  setUserId,
  setUserInfo,
  setUserDigital,
  setImpersonating,
  resetUserInfo,
  updateUserMeSuccess,
  updateUserMeFailure,
  updateUserSuccess,
  updateUserFailure,
  fetchZendeskTokenSuccess,
  fetchZendeskTokenFailure
} = userSlice.actions;

export default userSlice.reducer;
