import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'core/store';
import { IonButton, IonContent, IonIcon, IonPage } from '@ionic/react';
import './Checkout.scss';
import {
  HKBillingPeriod,
  RECURLY_DEVL_KEY,
  RECURLY_PUBLIC_KEY
} from 'core/constants';
import CreditCard from 'assets/icons/credit-card.svg';
import Complete from 'assets/icons/complete.svg';
import VisaCard from 'assets/icons/visa-card.svg';
import Mastercard from 'assets/icons/mastercard.svg';
import DiscoverCard from 'assets/icons/discover-card.svg';
import AmericanExpressCard from 'assets/icons/american-express-card.svg';
import JcbCard from 'assets/icons/jcb-card.svg';
import EditIcon from 'assets/icons/edit.svg';

import {
  Elements,
  RecurlyProvider,
  useCheckoutPricing,
  UseCheckoutPricingInput
} from '@recurly/react-recurly';
import { hideSubscriptionSummaryModal, removeSavedCard } from './BillingSlice';
import CreditCardForm from './CreditCardForm';
import { renewSubscription, requestCreditCardInfo } from './BillingActions';
import { gaBtnClickEvent, gaModalView, isDev } from 'core/util';
import { displayError } from 'core/services/AlertActions';

type CheckoutProps = {
  onAddressEdit?: (e: any) => void;
};

// Mobile Only
const Checkout: React.FC<CheckoutProps> = ({ onAddressEdit, ...rest }) => {
  const { loggedIn } = useSelector((state: RootState) => state.login);
  const {
    address1,
    city,
    state,
    postal_code,
    zipcodeError,
    malformedAddressError
  } = useSelector((state: RootState) => state.signup);
  const { name, isDigital } = useSelector((state: RootState) => state.user);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);

  const {
    newAddOns,
    newPlan,
    newBillingPeriod,
    currentBillingInfo,
    promoCode,
    autoRenew,
    error
  } = useSelector((state: RootState) => state.billing);
  const dispatch = useDispatch();
  let history = useHistory();

  const addNewCard = () => {
    gaBtnClickEvent('add_new_card');
    dispatch(removeSavedCard());
  };

  const submitPayment = () => {
    gaBtnClickEvent('submit_payment');
    const body: any = {
      currency: 'USD',
      base_plan_id: newPlan.id,
      addon_ids: newAddOns,
      billing_period: newBillingPeriod === HKBillingPeriod.MONTHLY ? 1 : 12,
      billing_id: currentBillingInfo.billing_id,
      auto_renew: autoRenew
    };
    if (promoCode) {
      body['coupon_code'] = promoCode;
    }
    if (!!homeId) {
      dispatch(renewSubscription(homeId, body));
      if (!error) {
        dispatch(hideSubscriptionSummaryModal());
        setTimeout(() => {
          history.push('/renew/confirmation');
        }, 0);
      } else {
        console.log(error);
        dispatch(displayError(error));
      }
    }
  };

  const getCreditCardIcon = () => {
    switch (currentBillingInfo.card_type) {
      case 'MasterCard':
        return Mastercard;
      case 'Visa':
        return VisaCard;
      case 'Discover':
        return DiscoverCard;
      case 'AmericanExpress':
        return AmericanExpressCard;
      case 'JCB':
        return JcbCard;
    }
  };

  useEffect(() => {
    if (!!homeId) {
      dispatch(requestCreditCardInfo(homeId));
    }
    gaModalView(isDigital, '/renew/checkout/mobile');
  }, []);

  function PricingPreview() {
    const recurlyAddOns: { code: any; quantity: number }[] = [];

    // Full Service Plan (as an add-on)
    if (!!newPlan.pricing[newBillingPeriod].addon_code) {
      recurlyAddOns.push({
        code: newPlan.pricing[newBillingPeriod].addon_code,
        quantity: 1
      });
    }

    // Actual add-ons
    if (!!newAddOns.length) {
      newPlan.addons.forEach((item: any) => {
        if (newAddOns.includes(item.id) && !!item.pricing[newBillingPeriod]) {
          recurlyAddOns.push({
            code: item.pricing[newBillingPeriod].addon_code,
            quantity: 1
          });
        }
      });
    }

    const initialPricingInput: UseCheckoutPricingInput = {
      address: {
        first_name: name,
        last_name: name,
        postal_code: currentHome?.postal_code,
        country: 'US'
      },
      currency: 'USD',
      subscriptions: [
        {
          plan: newPlan.pricing[newBillingPeriod].base_code,
          quantity: 1,
          addons: recurlyAddOns
        }
      ],
      coupon: promoCode
    };

    const [{ price, loading }] = useCheckoutPricing(initialPricingInput);

    return (
      <div className="hk-billing-checkout-content">
        <h2>Checkout</h2>

        {!!currentBillingInfo ? (
          <p>Saved payment method.</p>
        ) : (
          <p>Add a payment method to your account to checkout.</p>
        )}

        <section className="hk-billing-checkout-due">
          <div className="hk-billing-checkout-due-item">
            <h6 className="hk-billing-checkout-due-item-name">
              TOTAL DUE TODAY
            </h6>
            <div className="hk-billing-checkout-due-item-price">
              {!loading ? <div>${price.next.total}</div> : <></>}
            </div>
          </div>
        </section>

        {!loggedIn && (
          <>
            <section
              className={`hk-billing-checkout-address ${
                zipcodeError && newPlan.name.includes('Full Service')
                  ? 'hk-billing-checkout-address-error'
                  : ''
              }
              `}
              onClick={onAddressEdit}
            >
              <div className="hk-billing-checkout-address-item">
                <div className="hk-billing-checkout-address-item-text">
                  <span>{address1}</span>
                  <strong>{`${city}, ${state} ${postal_code}`}</strong>
                </div>

                <IonIcon
                  slot="end"
                  color="dark"
                  icon={EditIcon}
                  className="hk-billing-checkout-address-item-icon"
                ></IonIcon>
              </div>
            </section>
            <section>
              {zipcodeError && newPlan.name.includes('Full Service') && (
                <div className="hk-billing-checkout-zipcode-error">
                  <p>
                    The address you entered is not available in our full service
                    area, please enter one that matches the zipcode you entered.
                  </p>
                </div>
              )}
            </section>
          </>
        )}
      </div>
    );
  }

  function SavedCard() {
    return (
      <>
        <div className="hk-billing-checkout-saved-card-container">
          <div className="hk-billing-checkout-card-icon">
            <IonIcon icon={CreditCard} />
            <span>Credit Card</span>
          </div>

          <div className="hk-saved-card">
            <div className="hk-saved-card-info-container">
              <div className="hk-saved-card-icon">
                <IonIcon icon={getCreditCardIcon()} />
              </div>
              <div className="hk-saved-card-info">
                <div className="hk-saved-card-info-card-number">
                  {currentBillingInfo.card_type} ending in{' '}
                  {currentBillingInfo.last_four}
                </div>
                <div className="hk-saved-card-info-expiration">
                  {currentBillingInfo.expire_month}/
                  {currentBillingInfo.expire_year}{' '}
                </div>
              </div>
            </div>
            <div className="hk-saved-card-check">
              <IonIcon color="primary" icon={Complete} />
            </div>
          </div>

          <div
            className="hk-billing-checkout-saved-card-new"
            onClick={addNewCard}
          >
            Add New Payment
          </div>
        </div>
      </>
    );
  }

  function MobileCheckoutView() {
    return (
      <>
        <IonContent fullscreen className="hk-checkout-content">
          <RecurlyProvider
            publicKey={isDev() ? RECURLY_DEVL_KEY : RECURLY_PUBLIC_KEY}
          >
            <Elements>
              <PricingPreview />
            </Elements>
            <div className="hk-billing-checkout-form">
              {!!currentBillingInfo ? (
                <SavedCard />
              ) : (
                <Elements>
                  <CreditCardForm />
                </Elements>
              )}
            </div>
          </RecurlyProvider>
          {!!currentBillingInfo && (
            <section className="hk-billing-checkout-button">
              <IonButton
                expand="block"
                color="primary"
                strong={true}
                onClick={submitPayment}
              >
                Submit
              </IonButton>
            </section>
          )}
        </IonContent>
      </>
    );
  }

  return (
    <IonPage className="hk-billing-checkout-page">
      {MobileCheckoutView()}
    </IonPage>
  );
};

export default Checkout;
