import React from 'react';
import { IonButton, IonIcon, IonText, IonThumbnail } from '@ionic/react';
import Clock from '../../../assets/icons/clock.svg';
import AvatarMissing from '../../../assets/icons/avatar-missing.svg';
import { Appointment } from '../../../core/types';
import './AppointmentCard.scss';
import {
  formatAppointmentDisplayTimeSlot,
  formatTimezoneDate
} from '../../../core/util';
import { DATE_FORMAT_MONTH_DATE, HKPlatform } from '../../../core/constants';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import Thumbnail from 'components/thumbnail/Thumbnail';

type AppointmentCardProps = {
  icon: string;
  appointment: Appointment;
  timezone: string;
  onClick?: (e: any) => void;
};

const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointment,
  timezone,
  icon,
  onClick,
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopAppointmentCardView() {
    return (
      <div
        className={`hk-desktop-appointment-card ${
          !!appointment.finished ? 'finished' : ''
        }`}
        {...rest}
      >
        <div className="hk-desktop-appointment-card-title">
          {icon && (
            <IonIcon
              className="hk-desktop-season-icon"
              color="white"
              icon={icon}
            />
          )}
          {!!appointment.finished ? (
            <div className="hk-desktop-appointment-card-title__name--completed">
              <IonText>{appointment?.name}</IonText>
              <div className="hk-complete-pill">complete</div>
            </div>
          ) : (
            <IonText className="hk-desktop-appointment-card-title__name">
              {appointment?.name}
            </IonText>
          )}
        </div>
        <div className="hk-desktop-appointment-card-body">
          <IonThumbnail slot="end" className="hk-desktop-list-card-thumbnail">
            <Thumbnail
              src={
                !!appointment?.worker?.image
                  ? appointment?.worker?.image
                  : AvatarMissing
              }
              alt="Service person"
            />
          </IonThumbnail>
          <div className="hk-desktop-appointment-card-body-details">
            <div>
              <IonText className="hk-bold">{appointment?.worker?.name}</IonText>
            </div>
            <div>
              <IonText>
                {appointment?.worker?.title
                  ? appointment?.worker?.title
                  : 'Maintenance Specialist'}
              </IonText>
            </div>
          </div>
        </div>
        <div className="hk-desktop-appointment-card-footer">
          <div className="hk-desktop-appointment-card-footer__details">
            <IonText className="hk-desktop-appointment-card-footer__details-title hk-bold">
              Appointment Time
            </IonText>
            <div>
              <span>
                <IonText>
                  {!!appointment.scheduled
                    ? formatTimezoneDate(
                        appointment.scheduled,
                        DATE_FORMAT_MONTH_DATE,
                        timezone
                      )
                    : '-'}
                </IonText>
              </span>
              <span>&nbsp;</span>
              {!!appointment.scheduled && (
                <IonText color="dark" className="hk-bold">
                  ({formatAppointmentDisplayTimeSlot(appointment, timezone)})
                </IonText>
              )}
            </div>
          </div>
        </div>
        <IonButton className={`hk-desktop-details-button`} onClick={onClick}>
          Details
        </IonButton>
      </div>
    );
  }

  function MobileAppointmentCardView() {
    return (
      <div
        className={`hk-appointment-card ion-margin-horizontal ${
          !!appointment.finished ? 'finished' : ''
        }`}
        {...rest}
      >
        <div className="hk-appointment-card-header-wrapper">
          <div className="hk-appointment-card-header">
            {icon && (
              <IonIcon className="hk-season-icon" color="white" icon={icon} />
            )}
            {!!appointment.finished ? (
              <div className="hk-appointment-card-header-name--completed">
                <IonText color="white">{appointment?.name}</IonText>
                <div className="hk-complete-pill">complete</div>
              </div>
            ) : (
              <IonText
                className="hk-appointment-card-header-name"
                color="white"
              >
                {appointment?.name}
              </IonText>
            )}
          </div>
        </div>
        <div className="hk-appointment-card-body-wrapper">
          <div className="hk-appointment-card-body">
            <IonThumbnail slot="end" className="hk-list-card-thumbnail">
              <Thumbnail
                src={
                  !!appointment?.worker?.image
                    ? appointment?.worker?.image
                    : AvatarMissing
                }
                alt="Service person"
              />
            </IonThumbnail>
            <div className="hk-appointment-card-body-details">
              <div className="hk-appointment-card-body-details-name">
                <IonText color="dark" className="hk-bold">
                  {appointment?.worker?.name}
                </IonText>
              </div>
              <div>
                <IonText color="dark">
                  {appointment?.worker?.title
                    ? appointment?.worker?.title
                    : 'Maintenance Specialist'}
                </IonText>
              </div>
            </div>
          </div>
          <div className="hk-appointment-card-footer">
            <span>
              <IonIcon
                color={!!appointment.finished ? 'grey' : 'dark'}
                icon={Clock}
              />
              <IonText color={!!appointment.finished ? 'grey' : 'dark'}>
                {!!appointment.scheduled
                  ? formatTimezoneDate(
                      appointment.scheduled,
                      DATE_FORMAT_MONTH_DATE,
                      timezone
                    )
                  : '-'}
              </IonText>
            </span>
            <span>&nbsp;</span>
            {!!appointment.scheduled && (
              <IonText
                color={!!appointment.finished ? 'grey' : 'dark'}
                className="hk-bold"
              >
                ({formatAppointmentDisplayTimeSlot(appointment, timezone)})
              </IonText>
            )}
          </div>
          <IonButton className={`hk-details-button}`} onClick={onClick}>
            Details
          </IonButton>
        </div>
      </div>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth ? (
        <DesktopAppointmentCardView />
      ) : (
        <MobileAppointmentCardView />
      )}
    </>
  );
};

export default AppointmentCard;
