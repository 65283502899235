import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonSpinner,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import './DocumentsAdd.scss';
import { hideDocumentsAddModal } from 'features/shared/documents/DocumentsSlice';
import {
  createDocument,
  fetchDocumentTypes
} from 'features/shared/documents/DocumentsActions';
import {
  getFirebaseDownloadUrl,
  useFirebaseUpload
} from 'core/hooks/useFirebase';
import { DocumentBody, DocumentType } from 'core/types';
import { gaBtnClickEvent, gaModalView } from 'core/util';

const DocumentsAdd: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { types, currentType } = useSelector(
    (state: RootState) => state.documents
  );
  const [selectedDocumentType, setSelectedDocumentType] = useState<
    string | null
  >();
  const [uploadLoading, setUploadLoading] = useState(false);
  const { isDigital } = useSelector((state: RootState) => state.user);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { uploadFile } = useFirebaseUpload();
  const dispatch = useDispatch();

  const hiddenFileInput = useRef<any>(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const selectDocuments = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    setUploadLoading(true);
    for (const file of files) {
      try {
        const uploadedDocument = await uploadDocument(file);
        const documentBody: DocumentBody = {
          name: file.name.substring(0, file.name.lastIndexOf('.')),
          type: selectedDocumentType!,
          file_url: ''
        };

        const url = await getFirebaseDownloadUrl(
          uploadedDocument.metadata.fullPath
        );
        documentBody.file_url = url;

        if (homeId && documentBody) {
          await dispatch(createDocument(homeId, documentBody));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSelectedDocumentType(null);
      }
    }
    setUploadLoading(false);
    dispatch(hideDocumentsAddModal());
  };

  const uploadDocument = async (document: File) => {
    gaBtnClickEvent('upload_document');

    try {
      const uploadedFile = await uploadFile(homeId!, document.name, document);
      return uploadedFile;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const cancelAddDocument = () => {
    gaBtnClickEvent('cancel_add_document');
    dispatch(hideDocumentsAddModal());
  };

  useEffect(() => {
    if (!types) {
      dispatch(fetchDocumentTypes());
    }
  }, [types]);

  useEffect(() => {
    if (!selectedDocumentType && !!currentType) {
      setSelectedDocumentType(currentType);
    }
  }, [selectedDocumentType, currentType]);

  useEffect(() => {
    gaModalView(isDigital, '/documents/add');
  }, []);

  function DesktopDocumentsAddView() {
    return (
      <>
        <IonContent className="hk-desktop-digital-add-document">
          {uploadLoading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-desktop-digital-add-document-header"
                style={{
                  background: `#353a4d`
                }}
              >
                <div className="hk-desktop-digital-add-document-header-title">
                  Add Document
                </div>
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideDocumentsAddModal())}
                  >
                    <IonIcon color="white" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <div className="hk-desktop-digital-add-document-body">
                <div className="hk-desktop-digital-add-document-body-title">
                  Choose what <strong>folder</strong> you are uploading and then{' '}
                  <strong>select files from library</strong>. You can upload one
                  or multiple media types at once.
                </div>
                <div className="hk-desktop-digital-add-document-body-document-types">
                  {types.map((documentType: DocumentType, i: number) => {
                    return (
                      <div
                        className={`document-type-card ${
                          selectedDocumentType === documentType.field
                            ? 'document-type-card-selected'
                            : ''
                        }`}
                        key={`document-type-${i}`}
                        onClick={() =>
                          setSelectedDocumentType(documentType.field)
                        }
                      >
                        {documentType.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </IonContent>
        <div className="hk-desktop-digital-add-document-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={uploadLoading}
            onClick={cancelAddDocument}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={!selectedDocumentType}
            onClick={handleClick}
          >
            {uploadLoading ? <IonSpinner name="dots" /> : 'Select From Library'}
            <input
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              type="file"
              multiple
              onChange={selectDocuments}
              id="file-input"
            />
          </IonButton>
        </div>
      </>
    );
  }

  function MobileDocumentsAddView() {
    return (
      <>
        <IonContent className="hk-digital-add-document">
          {uploadLoading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-digital-add-document-header"
                style={{
                  background: `#353a4d`
                }}
              >
                <div className="hk-digital-add-document-header-title">
                  Add Document
                </div>
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideDocumentsAddModal())}
                  >
                    <IonIcon color="white" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <div className="hk-digital-add-document-body">
                <div className="hk-digital-add-document-body-title">
                  Choose what <strong>folder</strong> you are uploading and then{' '}
                  <strong>select files from library</strong>. You can upload one
                  or multiple media types at once.
                </div>
                <div className="hk-digital-add-document-body-document-types">
                  {types.map((documentType: DocumentType, i: number) => {
                    return (
                      <div
                        className={`document-type-card ${
                          selectedDocumentType === documentType.field
                            ? 'document-type-card-selected'
                            : ''
                        }`}
                        key={`document-type-${i}`}
                        onClick={() =>
                          setSelectedDocumentType(documentType.field)
                        }
                      >
                        {documentType.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </IonContent>
        <IonToolbar className="hk-digital-add-document-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={uploadLoading}
            onClick={cancelAddDocument}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            disabled={!selectedDocumentType}
            onClick={handleClick}
          >
            {uploadLoading ? <IonSpinner name="dots" /> : 'Select From Library'}
            <input
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              type="file"
              multiple
              onChange={selectDocuments}
              id="file-input"
            />
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsAddView()
        : MobileDocumentsAddView()}
    </>
  );
};

export default DocumentsAdd;
