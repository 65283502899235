import React from 'react';
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonText,
  IonThumbnail
} from '@ionic/react';
import './DocumentCard.scss';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import Thumbnail from 'components/thumbnail/Thumbnail';
import ArrowRight from 'assets/icons/arrow-right.svg';

type DocumentItemProps = {
  title: string;
  descriptionOne?: string;
  descriptionTwo?: string;
  descriptionTitleOne?: string;
  descriptionTitleTwo?: string;
  image?: string;
  onClick?: (e: any) => void;
  className?: string;
};

const DocumentCard: React.FC<DocumentItemProps> = ({
  title,
  descriptionOne,
  descriptionTwo,
  descriptionTitleOne,
  descriptionTitleTwo,
  image,
  onClick,
  className = '',
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopDocumentsView() {
    return (
      <IonItem
        lines="none"
        className={`hk-desktop-document-card ${className}`}
        onClick={onClick}
        {...rest}
      >
        <IonThumbnail
          slot="start"
          color="primary"
          className="hk-desktop-document-card-thumbnail"
        >
          <Thumbnail src={image} alt="Item" />
        </IonThumbnail>
        <IonLabel className="hk-desktop-document-card-label">
          <IonText>
            <h2 className="hk-desktop-document-card-title">{title}</h2>
          </IonText>
          <IonText color="medium">
            <p className="hk-desktop-document-card-description">
              {descriptionOne}
            </p>
          </IonText>
          <IonText color="medium">
            <p className="hk-desktop-document-card-description">
              {descriptionTwo}
            </p>
          </IonText>
        </IonLabel>
        <span className="hk-desktop-document-card-marker"></span>
        <IonIcon
          slot="end"
          icon={ArrowRight}
          className="hk-desktop-document-card-arrow"
        ></IonIcon>
      </IonItem>
    );
  }

  function MobileDocumentsView() {
    return (
      <IonItem
        lines="none"
        className={`hk-document-card ${className}`}
        onClick={onClick}
        {...rest}
      >
        <IonThumbnail
          slot="start"
          color="primary"
          className="hk-document-card-thumbnail"
        >
          <Thumbnail src={image} alt="Item" />
        </IonThumbnail>
        <IonLabel className="hk-document-card-label">
          <IonText>
            <h2 className="hk-document-card-title">{title}</h2>
          </IonText>
          <IonText color="medium">
            <p className="hk-document-card-description">{descriptionOne}</p>
          </IonText>
          <IonText color="medium">
            <p className="hk-document-card-description">{descriptionTwo}</p>
          </IonText>
        </IonLabel>
        <span className="hk-document-card-marker"></span>
      </IonItem>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsView()
        : MobileDocumentsView()}
    </>
  );
};

export default DocumentCard;
