import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import { cameraOutline, alertOutline } from 'ionicons/icons';
import { usePhotoGallery, UserPhoto } from 'core/hooks/usePhotoGallery';
import { useForm } from 'react-hook-form';
import {
  getFirebaseDownloadUrl,
  useFirebaseUpload
} from 'core/hooks/useFirebase';
import { displayError, displaySuccess } from 'core/services/AlertActions';
import {
  hideAddItemModal,
  resetProductTemplate,
  setInventoryLoading
} from 'features/shared/inventory/InventorySlice';
import './InventoryAddProduct.scss';
import {
  createInventoryProduct,
  fetchProductTemplate,
  fetchProductTypeGroups
} from 'features/shared/inventory/InventoryActions';
import {
  AttributeSchema,
  ProductBodyDetails,
  ProductGroupType,
  ProductTemplateType
} from 'core/types';
import ItemImage from 'assets/images/item_card.jpg';
import { gaBtnClickEvent, gaModalView } from 'core/util';

export enum ProductAttributeType {
  STRING = 'string',
  TAGGED = 'tagged'
}

export enum PhotoType {
  MODEL = 'model',
  BRAND = 'brand'
}

const InventoryAddProduct: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { loading, productTypeGroupsList, productTemplate } = useSelector(
    (state: RootState) => state.inventory
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { takePhoto } = usePhotoGallery();
  const { uploadFile } = useFirebaseUpload();
  const [modelPhoto, setModelPhoto] = useState<UserPhoto | null>();
  const [brandPhoto, setBrandPhoto] = useState<UserPhoto | null>();
  const [selectedProductTags, setSelectedProductTags] = useState<any[]>([]);
  const [selectedProductGroup, setSelectedProductGroup] = useState<
    number | null
  >();
  const [selectedProductType, setSelectedProductType] = useState<
    number | null
  >();

  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);

  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();

  const selectProductTag = (field: string, tag: any) => {
    const keyExists = selectedProductTags.some((item: any) => {
      return field in item;
    });
    if (keyExists) {
      if (selectedProductTags.every((item: any) => item[field] === tag)) {
        setSelectedProductTags([]);
      } else {
        const existingTagIndex = selectedProductTags.findIndex((item: any) => {
          return field in item;
        });
        setSelectedProductTags(selectedProductTags.splice(existingTagIndex, 1));
        setSelectedProductTags([...selectedProductTags, { [field]: tag }]);
      }
    } else {
      setSelectedProductTags([...selectedProductTags, { [field]: tag }]);
    }
  };

  const checkIfProductTagSelected = (field: string, tag: any) => {
    return selectedProductTags.some((item: any) => {
      return item[field] === tag;
    });
  };

  const addPhoto = (type: string) => {
    takePhoto()
      .then((photo) => {
        if (!!homeId && !!photo.filePath && !!photo.webviewPath) {
          photo.filePath = `product_${new Date().getTime()}_catalog_${
            type === PhotoType.MODEL ? '1' : '2'
          }.jpg`;
          if (type === PhotoType.MODEL) {
            setModelPhoto(photo);
          } else if (type === PhotoType.BRAND) {
            setBrandPhoto(photo);
          }
        }
      })
      .catch((error) => {
        console.log(`Upload Photo Error: ${error}`);
      });
  };

  const uploadPhotos = async () => {
    dispatch(setInventoryLoading(true));
    const uploads: any[] = [];
    if (!!homeId && !!modelPhoto) {
      uploads.push(
        uploadFile(homeId, modelPhoto.filePath, modelPhoto.imageData).catch(
          (e) => {
            console.log(e);
            displayError(e);
            throw e;
          }
        )
      );
    }
    if (!!homeId && !!brandPhoto) {
      uploads.push(
        uploadFile(homeId, brandPhoto.filePath, brandPhoto.imageData).catch(
          (e) => {
            console.log(e);
            displayError(e);
            throw e;
          }
        )
      );
    }
    return await Promise.all(uploads);
  };

  const addProduct = (formData: any) => {
    gaBtnClickEvent('add_inventory_product');
    let promises: any[] = [];
    const productBody: ProductBodyDetails = {
      home_id: homeId,
      type: productTemplate?.field,
      attributes: {}
    };

    // Create PHOTO for POST body (Upload to firebase storage)
    uploadPhotos().then(
      (uploadedImages) => {
        for (const image of uploadedImages) {
          promises.push(
            getFirebaseDownloadUrl(image.metadata.fullPath).then((url) => {
              if (image.metadata.name.toLowerCase().includes('catalog_1')) {
                productBody['model_photo'] = url;
              }
              if (image.metadata.name.toLowerCase().includes('catalog_2')) {
                productBody['brand_photo'] = url;
              }
            })
          );
        }

        // Create TAGGED attributes from availableProductTags for POST body
        if (selectedProductTags.length > 0) {
          selectedProductTags.forEach((tag) => {
            for (const [key, value] of Object.entries(tag)) {
              productBody.attributes[key] = [value];
            }
          });
        }

        // Create STRING attributes from formData for POST body
        if (!!productTemplate?.product_attribute_schema.length) {
          productTemplate.product_attribute_schema
            .concat(productTemplate.attribute_schema)
            .forEach((question: AttributeSchema) => {
              Object.entries(formData).forEach((datum) => {
                const [key, value] = datum;
                if (question.field === key) {
                  productBody.attributes[question.field] = value;
                }
              });
            });
        }

        // Create Product and Reset Form here
        if (!!homeId && !!productBody) {
          Promise.all(promises)
            .then(() => {
              dispatch(createInventoryProduct(homeId, productBody));
              resetProductForm();
              dispatch(displaySuccess('Product Added', 'Success'));
              dispatch(hideAddItemModal());
            })
            .catch((error: any) => {
              console.log(error);
            });
        }
      },
      (error) => {
        dispatch(displayError(error));
      }
    );
  };

  const cancelAddProduct = () => {
    gaBtnClickEvent('cancel_add_inventory_product');
    dispatch(hideAddItemModal());
    resetProductForm();
  };

  const resetProductForm = () => {
    setModelPhoto(null);
    setBrandPhoto(null);
    setSelectedProductType(null);
    setSelectedProductGroup(null);
  };

  useEffect(() => {
    if (!!selectedProductGroup) {
      setSelectedProductType(null);
    }
  }, [selectedProductGroup]);

  useEffect(() => {
    dispatch(resetProductTemplate());
    if (!!selectedProductType) {
      dispatch(fetchProductTemplate(selectedProductType));
    }
  }, [selectedProductType]);

  useEffect(() => {
    if (!!productTypeGroupsList && productTypeGroupsList.length === 0) {
      dispatch(fetchProductTypeGroups());
    }
  }, [productTypeGroupsList]);

  useEffect(() => {
    gaModalView(isDigital, '/inventory/group/product/add');
  }, []);

  function AddProductQuestions() {
    return (
      <>
        {!!productTemplate && (
          // Photo Questions
          <div className="hk-digital-add-product-body-steps">
            <div className="hk-digital-add-product-body-steps-card">
              <div className="hk-digital-add-product-body-steps-card-title">
                1: Take a picture of, Model Number and Serial Number Details
              </div>
              <div className="hk-digital-add-product-body-steps-card-subtitle">
                <IonIcon icon={alertOutline} />
                <span>required task</span>
              </div>
              <div className="hk-digital-add-product-body-steps-card-description">
                <span>
                  This can be found on the inside or outside of the{' '}
                  {productTemplate.label}.
                </span>
                <div className="hk-digital-add-product-body-steps-card-description-image">
                  {!!modelPhoto && (
                    <>
                      <IonImg src={modelPhoto.webviewPath} />
                      <input
                        name="model_photo"
                        ref={register}
                        type="hidden"
                        value={modelPhoto.webviewPath}
                      />
                    </>
                  )}
                </div>
                <IonButton
                  className="hk-digital-add-product-body-steps-card-description-button"
                  onClick={() => addPhoto(PhotoType.MODEL)}
                >
                  <IonIcon color="dark" icon={cameraOutline} />
                  {!modelPhoto ? 'Add Photo' : 'Replace Photo'}
                </IonButton>
              </div>
            </div>
            <div className="hk-digital-add-product-body-steps-card">
              <div className="hk-digital-add-product-body-steps-card-title">
                2: Take a picture of Brand / Manufacturer.
              </div>
              <div className="hk-digital-add-product-body-steps-card-description">
                <span>
                  Typically found on the front or back of the{' '}
                  {productTemplate.label}.
                </span>
                <div className="hk-digital-add-product-body-steps-card-description-image">
                  {!!brandPhoto && (
                    <>
                      <IonImg src={brandPhoto.webviewPath} />
                      <input
                        name="brand_photo"
                        ref={register}
                        type="hidden"
                        value={brandPhoto.webviewPath}
                      />
                    </>
                  )}
                </div>
                <IonButton
                  className="hk-digital-add-product-body-steps-card-description-button"
                  onClick={() => addPhoto(PhotoType.BRAND)}
                >
                  <IonIcon color="dark" icon={cameraOutline} />
                  {!brandPhoto ? 'Add Photo' : 'Replace Photo'}
                </IonButton>
              </div>
            </div>
          </div>
        )}
        {!!productTemplate?.product_attribute_schema.length &&
          productTemplate.product_attribute_schema.map(
            (attribute: AttributeSchema, i: number) => {
              return (
                // Tagged Questions
                <div key={`attribute-${i}`}>
                  {attribute.type === ProductAttributeType.TAGGED ? (
                    <div
                      className="hk-digital-add-product-body-steps-card"
                      key={`tagged-question-${i}`}
                    >
                      <div className="hk-digital-add-product-body-steps-card-title">
                        {`${i + 2}:`} {attribute.label}
                      </div>
                      <div className="hk-digital-add-product-body-steps-card-description">
                        <div className="product-tag-container">
                          {!!attribute.allow &&
                            attribute.allow.map((tag: string, i: number) => (
                              <div
                                className={`product-tag ${
                                  checkIfProductTagSelected(
                                    attribute.field,
                                    tag
                                  )
                                    ? 'product-tag-selected'
                                    : ''
                                }`}
                                key={`product-tag-${i}`}
                                onClick={() =>
                                  selectProductTag(attribute.field, tag)
                                }
                              >
                                {tag}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    // Text Input Questions
                    <div
                      className="hk-digital-add-product-body-steps-card"
                      key={`input-question-${i}`}
                    >
                      <div className="hk-digital-add-product-body-steps-card-title">
                        {`${i + 3}:`} {attribute.label}
                      </div>
                      <div className="hk-digital-add-product-body-steps-card-description">
                        <IonInput
                          name={attribute.field}
                          ref={register}
                          className="hk-digital-add-product-input"
                          placeholder="Enter here..."
                        ></IonInput>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          )}
      </>
    );
  }

  function DesktopInventoryAddProductView() {
    return (
      <>
        <IonContent className="hk-desktop-digital-add-product">
          {loading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-desktop-digital-add-product-header"
                style={{
                  backgroundImage: `linear-gradient(
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.4)
          ), url(${ItemImage})`
                }}
              >
                <div className="hk-desktop-digital-add-product-header-title">
                  Add an Item
                </div>
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideAddItemModal())}
                  >
                    <IonIcon color="white" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <form onSubmit={handleSubmit(addProduct)}>
                <div className="hk-desktop-digital-add-product-body">
                  <div>
                    <IonLabel className="hk-desktop-digital-add-product-body-select-product-label">
                      Group
                    </IonLabel>
                    <IonSelect
                      className="hk-desktop-digital-add-product-body-select-product"
                      placeholder="Select item group"
                      value={selectedProductGroup}
                      onIonChange={(e) =>
                        setSelectedProductGroup(e.detail.value)
                      }
                    >
                      {!!productTypeGroupsList.length &&
                        productTypeGroupsList.map(
                          (productType: ProductGroupType, i: number) => {
                            return (
                              <IonSelectOption
                                key={'product' + productType.id}
                                value={productType.id}
                              >
                                {productType.name}
                              </IonSelectOption>
                            );
                          }
                        )}
                    </IonSelect>
                    <IonLabel className="hk-desktop-digital-add-product-body-select-product-label">
                      Type
                    </IonLabel>
                    <IonSelect
                      className="hk-desktop-digital-add-product-body-select-product"
                      placeholder="Select item type"
                      value={selectedProductType}
                      disabled={!selectedProductGroup}
                      onIonChange={(e) =>
                        setSelectedProductType(e.detail.value)
                      }
                    >
                      {!!productTypeGroupsList.length &&
                        productTypeGroupsList
                          .find((group) => group.id === selectedProductGroup)
                          ?.product_template_types?.map(
                            (productType: ProductTemplateType) => {
                              return (
                                <IonSelectOption
                                  key={'product' + productType.id}
                                  value={productType.id}
                                >
                                  {productType.label}
                                </IonSelectOption>
                              );
                            }
                          )}
                    </IonSelect>
                  </div>
                  {!!selectedProductGroup &&
                    !!selectedProductType &&
                    AddProductQuestions()}
                </div>
              </form>
            </>
          )}
        </IonContent>
        <div className="hk-desktop-digital-add-product-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={loading}
            onClick={cancelAddProduct}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            onClick={handleSubmit(addProduct)}
            disabled={!productTemplate || !modelPhoto || loading}
          >
            {loading ? <IonSpinner name="dots" /> : 'Save'}
          </IonButton>
        </div>
      </>
    );
  }

  function MobileInventoryAddProductView() {
    return (
      <>
        <IonContent className="hk-digital-add-product">
          {loading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-digital-add-product-header"
                style={{
                  backgroundImage: `linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)
              ), url(${ItemImage})`
                }}
              >
                <div className="hk-digital-add-product-header-title">
                  Add an Item
                </div>
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideAddItemModal())}
                  >
                    <IonIcon color="white" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <form onSubmit={handleSubmit(addProduct)}>
                <div className="hk-digital-add-product-body">
                  <div>
                    <IonLabel className="hk-digital-add-product-body-select-product-label">
                      Group
                    </IonLabel>
                    <IonSelect
                      className="hk-digital-add-product-body-select-product"
                      placeholder="Select item group"
                      value={selectedProductGroup}
                      onIonChange={(e) =>
                        setSelectedProductGroup(e.detail.value)
                      }
                    >
                      {!!productTypeGroupsList.length &&
                        productTypeGroupsList.map(
                          (productType: ProductGroupType, i: number) => {
                            return (
                              <IonSelectOption
                                key={'product' + productType.id}
                                value={productType.id}
                              >
                                {productType.name}
                              </IonSelectOption>
                            );
                          }
                        )}
                    </IonSelect>
                    <IonLabel className="hk-digital-add-product-body-select-product-label">
                      Type
                    </IonLabel>
                    <IonSelect
                      className="hk-digital-add-product-body-select-product"
                      placeholder="Select item type"
                      value={selectedProductType}
                      disabled={!selectedProductGroup}
                      onIonChange={(e) =>
                        setSelectedProductType(e.detail.value)
                      }
                    >
                      {!!productTypeGroupsList.length &&
                        productTypeGroupsList
                          .find((group) => group.id === selectedProductGroup)
                          ?.product_template_types?.map(
                            (productType: ProductTemplateType) => {
                              return (
                                <IonSelectOption
                                  key={'product' + productType.id}
                                  value={productType.id}
                                >
                                  {productType.label}
                                </IonSelectOption>
                              );
                            }
                          )}
                    </IonSelect>
                  </div>
                  {!!selectedProductGroup &&
                    !!selectedProductType &&
                    AddProductQuestions()}
                </div>
              </form>
            </>
          )}
        </IonContent>
        <IonToolbar className="hk-digital-add-product-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            disabled={loading}
            onClick={cancelAddProduct}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            type="submit"
            strong={true}
            onClick={handleSubmit(addProduct)}
            disabled={!productTemplate || !modelPhoto || loading}
          >
            {loading ? <IonSpinner name="dots" /> : 'Save'}
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopInventoryAddProductView()
        : MobileInventoryAddProductView()}
    </>
  );
};

export default InventoryAddProduct;
