import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonItem,
  IonToolbar,
  IonTextarea
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './OnboardingQuestion4.scss';
import { useHistory } from 'react-router';
import LoadingIonList from 'core/layout/LoadingIonList';
import CompleteIcon from 'assets/icons/complete.svg';
import { useEffect, useState } from 'react';
import {
  saveOnboarding4,
  saveOnboardingOther4
} from 'features/shared/signup/SignupSlice';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import { gaBtnClickEvent } from 'core/util';

interface HomeownerType {
  title: string;
  description: string;
}

const homeownerTypes: HomeownerType[] = [
  {
    title: 'First-time Homeowner',
    description: 'Recently bought my first home and new to homeownership'
  },
  {
    title: 'Move-up Homeowner',
    description:
      'Outgrown current home and looking to upgrade to a larger property'
  },
  {
    title: 'Downsizing Homeowner',
    description: 'Looking to move to a smaller and or more affordable property'
  },
  {
    title: 'Second-home or Investment',
    description: 'Own multiple properties for pleasure or to generate income'
  },
  {
    title: 'Multi-generational Homeowner',
    description: 'Live with multiple generations of family under the same roof'
  },
  {
    title: 'Retiree Homeowner',
    description: 'Retired or planning to retire'
  },
  {
    title: 'Other',
    description: ''
  }
];

const OnboardingQuestion4: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { onboarding_4, onboarding_other_4 } = useSelector(
    (state: RootState) => state.signup
  );
  const [selectedHomeowner, setSelectedHomeowner] = useState<string>('');

  const onNext = () => {
    if (selectedHomeowner === 'Other') {
      dispatch(saveOnboarding4(selectedHomeowner));
    } else {
      dispatch(saveOnboarding4(selectedHomeowner));
    }
    gaBtnClickEvent('next_onboarding_4');
    history.push(`/onboarding/how-long-do-you-expect-to-stay`);
  };

  useEffect(() => {
    if (!!onboarding_4) {
      setSelectedHomeowner(onboarding_4);
    }
  }, [dispatch, onboarding_4]);

  function DesktopOnboardingView() {
    return (
      <IonPage>
        <div className="desktop-onboarding-4-header-container">
          <div className="desktop-onboarding-4-header">
            What type of homeowner are you?
          </div>
          <div className="desktop-onboarding-4-subheader">
            Select the one that best describes your situation
          </div>
        </div>
        <IonContent
          className="desktop-onboarding-4-view ion-padding"
          fullscreen
          scrollY={true}
        >
          <LoadingIonList
            className="desktop-onboarding-4-view-list"
            label="Homeowner Type"
            items={homeownerTypes}
            loading={false}
          >
            {homeownerTypes.map((homeowner: HomeownerType, i: number) => {
              const { title, description } = homeowner;
              return (
                <IonItem
                  key={`desktop-homeowner-${i}`}
                  lines="none"
                  onClick={() => setSelectedHomeowner(title)}
                >
                  {title === 'Other' ? (
                    <div
                      className={`desktop-onboarding-4-view-card ${
                        selectedHomeowner === title &&
                        'desktop-onboarding-4-view-card-selected'
                      }`}
                    >
                      <div className="desktop-onboarding-4-view-card-body">
                        <div className="desktop-onboarding-4-view-card-body-title">
                          {title}
                        </div>
                        <div className="desktop-onboarding-4-view-card-body-input">
                          <IonTextarea
                            name="other"
                            placeholder="Enter Text"
                            autoCorrect="off"
                            autoCapitalize="none"
                            value={
                              !!onboarding_other_4 ? onboarding_other_4 : ''
                            }
                            onIonChange={(e) =>
                              dispatch(saveOnboardingOther4(e.detail.value!))
                            }
                            rows={1}
                            maxlength={255}
                            autoGrow={true}
                          ></IonTextarea>
                        </div>
                      </div>
                      <div className="desktop-onboarding-4-view-card-checkmark">
                        {selectedHomeowner === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="desktop-onboarding-4-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`desktop-onboarding-4-view-card ${
                        selectedHomeowner === title &&
                        'desktop-onboarding-4-view-card-selected'
                      }`}
                    >
                      <div className="desktop-onboarding-4-view-card-body">
                        <div className="desktop-onboarding-4-view-card-body-title">
                          {title}
                        </div>
                        <div className="desktop-onboarding-4-view-card-body-description">
                          {description}
                        </div>
                      </div>
                      <div className="desktop-onboarding-4-view-card-checkmark">
                        {selectedHomeowner === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="desktop-onboarding-4-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  )}
                </IonItem>
              );
            })}
          </LoadingIonList>
          <div className="desktop-onboarding-4-button-container">
            <IonButton
              className="desktop-onboarding-4-button"
              color="primary"
              type="button"
              disabled={!selectedHomeowner}
              onClick={() => onNext()}
            >
              Next
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  function MobileOnboardingView() {
    return (
      <IonPage>
        <BackButtonHeader title={''} />
        <div className="onboarding-4-header-container">
          <h3 className="onboarding-4-header">
            <b>What type of homeowner are you?</b>
          </h3>
          <h6 className="onboarding-4-subheader">
            Select the one that best describes your situation
          </h6>
        </div>
        <IonContent className="onboarding-4-view" fullscreen scrollY={true}>
          <LoadingIonList
            className="onboarding-4-view-list"
            label="Homeowner Type"
            items={homeownerTypes}
            loading={false}
          >
            {homeownerTypes.map((homeowner: HomeownerType, i: number) => {
              const { title, description } = homeowner;
              return (
                <IonItem
                  key={`homeowner-${i}`}
                  lines="none"
                  onClick={() => setSelectedHomeowner(title)}
                >
                  {title === 'Other' ? (
                    <div
                      className={`onboarding-4-view-card ${
                        selectedHomeowner === title &&
                        'onboarding-4-view-card-selected'
                      }`}
                    >
                      <div className="onboarding-4-view-card-body">
                        <div className="onboarding-4-view-card-body-title">
                          {title}
                        </div>
                        <div className="onboarding-4-view-card-body-input">
                          <IonTextarea
                            name="other"
                            placeholder="Enter Text"
                            autoCorrect="off"
                            autoCapitalize="none"
                            value={
                              !!onboarding_other_4 ? onboarding_other_4 : ''
                            }
                            onIonChange={(e) =>
                              dispatch(saveOnboardingOther4(e.detail.value!))
                            }
                            rows={1}
                            maxlength={255}
                            autoGrow={true}
                          ></IonTextarea>
                        </div>
                      </div>
                      <div className="onboarding-4-view-card-checkmark">
                        {selectedHomeowner === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="onboarding-4-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`onboarding-4-view-card ${
                        selectedHomeowner === title &&
                        'onboarding-4-view-card-selected'
                      }`}
                    >
                      <div className="onboarding-4-view-card-body">
                        <div className="onboarding-4-view-card-body-title">
                          {title}
                        </div>
                        <div className="onboarding-4-view-card-body-description">
                          {description}
                        </div>
                      </div>
                      <div className="onboarding-4-view-card-checkmark">
                        {selectedHomeowner === title ? (
                          <IonIcon color="primary" icon={CompleteIcon} />
                        ) : (
                          <div className="onboarding-4-view-card-checkmark-circle"></div>
                        )}
                      </div>
                    </div>
                  )}
                </IonItem>
              );
            })}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="onboarding-4-toolbar">
          <IonButton
            className="onboarding-4-button"
            color="primary"
            type="button"
            onClick={() => onNext()}
          >
            Next
          </IonButton>
        </IonToolbar>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopOnboardingView()
        : MobileOnboardingView()}
    </>
  );
};

export default OnboardingQuestion4;
