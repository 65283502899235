import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  isSideMenuOpen: boolean;
}

export const initialState: InitialState = {
  isSideMenuOpen: false
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    showSlideOutMenu: (state) => {
      state.isSideMenuOpen = true;
    },
    hideSlideOutMenu: (state) => {
      state.isSideMenuOpen = false;
    }
  }
});

export const { showSlideOutMenu, hideSlideOutMenu } = menuSlice.actions;

export default menuSlice.reducer;
