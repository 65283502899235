import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonToggle,
  IonToolbar
} from '@ionic/react';
import './Summary.scss';
import {
  HKBillingPeriod,
  HKPlatform,
  RECURLY_DEVL_KEY,
  RECURLY_PUBLIC_KEY
} from 'core/constants';
import {
  hideSubscriptionSummaryModal,
  setAutoRenew,
  setPromoCode
} from './BillingSlice';
import LeftArrow from 'assets/icons/arrow-left.svg';
import Close from 'assets/icons/close.svg';
import {
  Elements,
  RecurlyProvider,
  useCheckoutPricing,
  UseCheckoutPricingInput
} from '@recurly/react-recurly';
import { gaBtnClickEvent, gaModalView, isDev } from 'core/util';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Checkout from './Checkout';
import AddressSearchBar from 'components/address-searchbar/AddressSearchBar';
import {
  setMalformedAddressError,
  setSignupAddress,
  setZipcodeError
} from '../signup/SignupSlice';
import { useHistory } from 'react-router';
import { ADDRESS_HEADERS } from '../signup/views/digital-signup/Recurly';
import { checkZipcode } from '../signup/SignupActions';

// Mobile Only
const Summary: React.FC = () => {
  const { loggedIn } = useSelector((state: RootState) => state.login);
  const { name, isDigital } = useSelector((state: RootState) => state.user);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { newAddOns, newPlan, newBillingPeriod, promoCode, autoRenew } =
    useSelector((state: RootState) => state.billing);
  const { signupPlans, postal_code } = useSelector(
    (state: RootState) => state.signup
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const [recurlyPromoCode, setRecurlyPromoCode] = useState<any>('');
  const [recurlyErrorPromoCode, setRecurlyErrorPromoCode] = useState<any>('');
  const [recurlyError, setRecurlyError] = useState<any>(null);
  const promoCodeInputRef = useRef<any>(null);
  let [toggleIndex, setToggleIndex] = useState(0);

  const applyPromoCode = () => {
    setRecurlyError(null);
    setRecurlyPromoCode(promoCodeInputRef.current.value);
    setRecurlyErrorPromoCode(promoCodeInputRef.current.value);
    dispatch(setPromoCode(promoCodeInputRef.current.value));
    gaBtnClickEvent('apply_promo_code');
  };

  const submitToCheckout = () => {
    setToggleIndex(1);
    gaBtnClickEvent('proceed_to_checkout');
  };

  const cancelModal = () => {
    if (loggedIn) {
      gaBtnClickEvent('close_renew_summary_modal');
      dispatch(hideSubscriptionSummaryModal());
    } else {
      history.push('/signup/plans');
    }
  };

  const addressSelect = async (address: any) => {
    console.log('caller');
    dispatch(setMalformedAddressError(false));
    try {
      const addressSections = address.split(',');
      const street = addressSections[0];
      const city = addressSections[1];
      const state = addressSections[2];
      const postalCode = addressSections[3].split(' ')[2];
      const addressBody = {
        address1: street,
        city: city,
        state: state,
        postal_code: postalCode
      };
      dispatch(setMalformedAddressError(false));
      dispatch(setSignupAddress(addressBody));
      gaBtnClickEvent('selected_address');
      dispatch(checkZipcode(postalCode));
      setToggleIndex(2);
    } catch {
      const addressSections = address.split(',');
      const properllyFormattedAddress =
        addressSections.length < 4 && addressSections.length > 1;
      dispatch(setMalformedAddressError(properllyFormattedAddress));
      if (!properllyFormattedAddress) {
        await dispatch(setMalformedAddressError(false));
      }
    }
  };

  useEffect(() => {
    if (!!postal_code) {
      const error = signupPlans.length > 2 ? false : true;
      dispatch(setMalformedAddressError(error));
      dispatch(setZipcodeError(error));
    }
  }, [signupPlans]);

  useEffect(() => {
    if (!!postal_code) {
      const error = signupPlans.length > 2 ? false : true;
      console.log(error);
      dispatch(setMalformedAddressError(error));
    }
  }, [signupPlans]);

  useEffect(() => {
    if (!!promoCode) {
      setRecurlyPromoCode(promoCode);
    }
    gaModalView(isDigital, '/renew/summary');
  }, []);

  function PricingPreview() {
    const recurlyAddOns: { code: any; quantity: number }[] = [];

    // Service Plan (as an add-on)
    if (!!newPlan.pricing[newBillingPeriod].addon_code) {
      recurlyAddOns.push({
        code: newPlan.pricing[newBillingPeriod].addon_code,
        quantity: 1
      });
    }

    // Actual add-ons
    if (!!newAddOns.length) {
      newPlan.addons.forEach((item: any) => {
        if (newAddOns.includes(item.id) && !!item.pricing[newBillingPeriod]) {
          recurlyAddOns.push({
            code: item.pricing[newBillingPeriod].addon_code,
            quantity: 1
          });
        }
      });
    }

    const initialPricingInput: UseCheckoutPricingInput = {
      address: {
        first_name: name,
        last_name: name,
        postal_code: loggedIn ? currentHome?.postal_code : postal_code,
        country: 'US'
      },
      currency: 'USD',
      subscriptions: [
        {
          plan: newPlan.pricing[newBillingPeriod].base_code,
          quantity: 1,
          addons: recurlyAddOns
        }
      ],
      coupon: recurlyPromoCode
    };

    const [{ price, loading }] = useCheckoutPricing(
      initialPricingInput,
      setRecurlyError
    );
    if (recurlyError) {
      if (recurlyError.code !== 'limit-exceeded') {
        setRecurlyPromoCode('');
        dispatch(setPromoCode(''));
      }
    }

    return (
      <div className="hk-billing-summary-content">
        <h2>Subscription Summary</h2>
        <p>Confirm your selections and proceed to checkout.</p>
        <section className="hk-billing-summary-subscription">
          <div className="hk-billing-summary-subscription-header">
            <h4>YOUR SUBSCRIPTION</h4>
            <div className="hk-subscription-pill">
              {newBillingPeriod === HKBillingPeriod.MONTHLY
                ? 'Monthly'
                : 'Yearly'}
            </div>
          </div>
          <div className="hk-billing-summary-subscription-item">
            <h6 className="hk-billing-summary-subscription-item-name">
              {newPlan.name}
            </h6>
            <div className="hk-billing-summary-subscription-item-price">
              <>
                ${newPlan.pricing[newBillingPeriod].usd}/
                {newBillingPeriod === HKBillingPeriod.MONTHLY ? 'mo' : 'yr'}
              </>
            </div>
          </div>
          <CalculateAddOns />
          <div className="hk-billing-summary-subscription-promo">
            <div className="hk-billing-summary-subscription-promo-body">
              <IonInput
                className={`hk-billing-summary-subscription-promo-input ${
                  recurlyError &&
                  'hk-billing-summary-subscription-promo-input-error'
                }`}
                name="promo"
                placeholder="Promo Code"
                ref={promoCodeInputRef}
                value={!recurlyError ? recurlyPromoCode : recurlyErrorPromoCode}
              ></IonInput>
              <IonButton
                expand="block"
                color="primary"
                strong={true}
                className="hk-billing-summary-subscription-promo-button"
                onClick={() => applyPromoCode()}
              >
                Apply
              </IonButton>
            </div>

            <div className="hk-billing-summary-subscription-promo-error">
              {recurlyError ? <span>Enter a valid promo code</span> : ''}
            </div>
          </div>

          <div className="hk-billing-summary-subscription-auto-renew">
            <span>Auto Renew</span>
            <IonToggle
              checked={autoRenew}
              onIonChange={(e) => dispatch(setAutoRenew(e.detail.checked))}
            ></IonToggle>
          </div>

          <div className="hk-billing-summary-subscription-total">
            <div className="hk-billing-summary-subscription-total-sub-container">
              <div className="hk-billing-summary-subscription-total-item">
                <h6 className="hk-billing-summary-subscription-total-item-name">
                  Subtotal
                </h6>
                <div className="hk-billing-summary-subscription-total-item-price">
                  {!loading && !recurlyError ? (
                    <div>
                      {price.currency.symbol}
                      {price.next.subscriptions}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="hk-billing-summary-subscription-total-item">
              <h6 className="hk-billing-summary-subscription-total-item-name">
                Discount
              </h6>
              <div className="hk-billing-summary-subscription-total-item-price">
                {!loading && !recurlyError ? (
                  <div>
                    -{price.currency.symbol}
                    {price.next.discount}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>{' '}
            <div className="hk-billing-summary-subscription-total-item">
              <h6 className="hk-billing-summary-subscription-total-item-name">
                Estimated Tax
              </h6>
              <div className="hk-billing-summary-subscription-total-item-price">
                {!loading ? (
                  <div>
                    {price.currency.symbol}
                    {price.next.taxes}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="hk-billing-summary-due">
          <div className="hk-billing-summary-due-item">
            <h6 className="hk-billing-summary-due-item-name">
              TOTAL DUE TODAY
            </h6>
            <div className="hk-billing-summary-due-item-price">
              {!loading && !recurlyError ? (
                <div>
                  {price.currency.symbol}
                  {price.next.total}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
        <section className="hk-billing-summary-button">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={submitToCheckout}
          >
            {loggedIn ? 'Proceed to Checkout' : 'Continue'}
          </IonButton>
        </section>
      </div>
    );
  }

  function CalculateAddOns() {
    const plans: any = [];
    newPlan.addons.forEach((item: any) => {
      if (newAddOns.includes(item.id)) {
        plans.push(item);
      }
    });

    return (
      <>
        {plans.map((item: any, i: number) => {
          return (
            <div
              className="hk-billing-summary-subscription-item"
              key={`addon-${i}`}
            >
              <h6 className="hk-billing-summary-subscription-item-name">
                {item.name}
              </h6>
              <div className="hk-billing-summary-subscription-item-price">
                {!!item.pricing.price_text ? (
                  item.pricing.price_text
                ) : (
                  <>
                    {!!item.pricing[newBillingPeriod] && (
                      <>
                        ${item.pricing[newBillingPeriod].usd}/
                        {newBillingPeriod === HKBillingPeriod.MONTHLY
                          ? 'mo'
                          : 'yr'}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function MobileSummaryView() {
    return (
      <>
        <IonContent fullscreen>
          <RecurlyProvider
            publicKey={isDev() ? RECURLY_DEVL_KEY : RECURLY_PUBLIC_KEY}
          >
            <Elements>
              <PricingPreview />
            </Elements>
          </RecurlyProvider>
        </IonContent>
      </>
    );
  }

  function AddressSearchView() {
    let addressTitle;
    let addressDescription;

    if (newPlan.name.includes('Full Service')) {
      addressTitle = ADDRESS_HEADERS.FULL_SERVICE.title;
      addressDescription = ADDRESS_HEADERS.FULL_SERVICE.description;
    } else if (newPlan.name.includes('Delivery')) {
      addressTitle = ADDRESS_HEADERS.AUTO_DELIVERY.title;
      addressDescription = ADDRESS_HEADERS.AUTO_DELIVERY.description;
    } else {
      addressTitle = ADDRESS_HEADERS.DIGITAL.title;
      addressDescription = ADDRESS_HEADERS.DIGITAL.description;
    }

    return (
      <>
        <IonContent fullscreen>
          <div className="hk-billing-summary-address-view">
            <h2>{addressTitle}</h2>
            <p>{addressDescription}</p>
            <AddressSearchBar
              onSearchSelect={(address) => addressSelect(address)}
            />
          </div>
        </IonContent>
      </>
    );
  }

  return (
    <IonPage color="white">
      <IonHeader className="ion-no-border">
        <IonToolbar color="white" className="hk-billing-summary-toolbar">
          {toggleIndex !== 0 && (
            <IonButtons slot="start">
              <IonFabButton
                color="black"
                className={`hk-fab-button ${
                  platformType === HKPlatform.DESKTOP && isDesktopWidth
                    ? ''
                    : 'hk-fab-button-margin-top'
                }`}
                onClick={() => setToggleIndex(--toggleIndex)}
              >
                <IonIcon color="dark" icon={LeftArrow} />
              </IonFabButton>
            </IonButtons>
          )}

          <IonButtons slot="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={cancelModal}
            >
              <IonIcon color="dark" icon={Close} />
            </IonFabButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <SwitchTransition>
        <CSSTransition
          key={toggleIndex ? 'Summary' : 'Checkout'}
          addEndListener={(node, done) =>
            node.addEventListener('transitionend', done, false)
          }
          classNames="toggle"
        >
          <div
            className={`hk-billing-summary-animation  ${
              toggleIndex === 0 ? 'left' : 'right'
            }`}
          >
            {toggleIndex === 0 && MobileSummaryView()}
            {loggedIn ? (
              <> {toggleIndex === 1 && <Checkout />}</>
            ) : (
              <>
                {toggleIndex === 1 && AddressSearchView()}
                {toggleIndex === 2 && (
                  <Checkout
                    onAddressEdit={() => setToggleIndex(--toggleIndex)}
                  />
                )}
              </>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </IonPage>
  );
};

export default Summary;
