import { DANGER, DARK, PRIMARY } from 'core/constants';
import { AppDispatch } from 'core/store';
import { displayAlert } from './AlertSlice';

export const displayToast =
  (message: string, title = ''): any =>
  async (dispatch: AppDispatch) => {
    dispatch(
      displayAlert({
        title,
        message,
        intent: DARK
      })
    );
  };

export const displaySuccess =
  (message: string, title = ''): any =>
  async (dispatch: AppDispatch) => {
    dispatch(
      displayAlert({
        title,
        message,
        intent: PRIMARY
      })
    );
  };

export const displayError =
  (message: string): any =>
  async (dispatch: AppDispatch) => {
    dispatch(
      displayAlert({
        title: 'Experienced an error',
        message,
        intent: DANGER
      })
    );
  };
