import { CONTACT_LIST_URL, CONTACT_URL } from 'core/constants';
import { createApiRequest } from 'core/util';
import {
  createContactStart,
  createContactSuccess,
  createContactError,
  fetchContactListSuccess,
  fetchContactListError,
  fetchContactStart,
  fetchContactSuccess,
  fetchContactError,
  fetchContactListStart,
  deleteContactError,
  deleteContactStart,
  deleteContactSuccess
} from './ContactsSlice';

export const fetchContactList = (homeId: number) =>
  createApiRequest({
    route: CONTACT_LIST_URL,
    replace: ['home_id', homeId],
    onStart: fetchContactListStart,
    onSuccess: fetchContactListSuccess,
    onError: fetchContactListError,
    defaultErrorMessage: 'There was a problem loading your contacts.',
    useGlobalLoader: true
  })();

export const fetchContact = (homeId: number, contactId: number) =>
  createApiRequest({
    route: CONTACT_URL,
    replace: [
      ['home_id', homeId],
      ['contact_id', contactId]
    ],
    onStart: fetchContactStart,
    onSuccess: fetchContactSuccess,
    onError: fetchContactError,
    defaultErrorMessage: 'There was a problem loading your contacts.',
    useGlobalLoader: true
  })();

export const createContact = (homeId: number, body: object) =>
  createApiRequest({
    route: CONTACT_LIST_URL,
    method: 'post',
    body: body,
    replace: [['home_id', homeId]],
    onStart: createContactStart,
    onSuccess: createContactSuccess,
    onError: createContactError,
    defaultErrorMessage: 'There was an error updating the product templates.'
  })();

export const updateContact = (
  homeId: number,
  contactId: number,
  body: object
) =>
  createApiRequest({
    route: CONTACT_URL,
    method: 'patch',
    body: body,
    replace: [
      ['home_id', homeId],
      ['contact_id', contactId]
    ],
    onStart: createContactStart,
    onSuccess: createContactSuccess,
    onError: createContactError,
    defaultErrorMessage: 'There was an error updating the product templates.'
  })();

export const deleteContact = (homeId: number, contactId: number) =>
  createApiRequest({
    route: CONTACT_URL,
    method: 'delete',
    replace: [
      ['home_id', homeId],
      ['contact_id', contactId]
    ],
    onStart: deleteContactStart,
    onSuccess: deleteContactSuccess,
    onError: deleteContactError,
    defaultErrorMessage: 'There was a problem loading your contacts.',
    useGlobalLoader: true
  })();
