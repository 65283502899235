import {
  PRODUCT_GROUP_COUNT_URL,
  PRODUCT_GROUP_URL,
  PRODUCT_DETAILS_URL,
  PRODUCT_GROUP_LIST_URL,
  PRODUCT_TYPE_DETAIL_URL,
  PRODUCT_URL,
  PRODUCT_SEARCH_URL
} from 'core/constants';
import { createApiRequest } from 'core/util';
import {
  fetchProductGroupCountSuccess,
  fetchProductGroupStart,
  fetchProductGroupSuccess,
  fetchProductDetailsSuccess,
  fetchProductTypeGroupsListSuccess,
  fetchProductTypeGroupsListError,
  fetchProductTemplateSuccess,
  fetchProductTemplateStart,
  fetchProductTemplateError,
  createProductStart,
  createProductSuccess,
  createProductError,
  fetchProductDetailsStart,
  fetchSearchedProductsError,
  fetchSearchedProductsStart,
  fetchSearchedProductsSuccess
} from './InventorySlice';

export const requestProductGroupCount = (homeId: number) =>
  createApiRequest({
    route: PRODUCT_GROUP_COUNT_URL,
    replace: ['home_id', homeId],
    onSuccess: fetchProductGroupCountSuccess,
    defaultErrorMessage: 'There was a problem loading your products.',
    useGlobalLoader: true
  })();

export const requestProductGroup = (homeId: number, groupId: string) =>
  createApiRequest({
    route: PRODUCT_GROUP_URL,
    replace: [
      ['home_id', homeId],
      ['group_id', groupId]
    ],
    onStart: fetchProductGroupStart,
    onSuccess: fetchProductGroupSuccess,
    defaultErrorMessage: 'There was a problem loading your products.',
    useGlobalLoader: true
  })();

export const fetchProductDetails = (homeId: number, itemId: number) =>
  createApiRequest({
    route: PRODUCT_DETAILS_URL,
    replace: [
      ['home_id', homeId],
      ['item_id', itemId]
    ],
    onStart: fetchProductDetailsStart,
    onSuccess: fetchProductDetailsSuccess,
    defaultErrorMessage: 'There was a problem loading item details.',
    useGlobalLoader: true
  })();

export const fetchProductTypeGroups = () =>
  createApiRequest({
    route: PRODUCT_GROUP_LIST_URL,
    method: 'get',
    onSuccess: fetchProductTypeGroupsListSuccess,
    onError: fetchProductTypeGroupsListError
  })();

export const fetchProductTemplate = (productTypeId: number) =>
  createApiRequest({
    route: PRODUCT_TYPE_DETAIL_URL,
    method: 'get',
    replace: [['product_type_id', productTypeId]],
    onStart: fetchProductTemplateStart,
    onSuccess: fetchProductTemplateSuccess,
    onError: fetchProductTemplateError,
    defaultErrorMessage: 'There was a problem fetching your catalog tasks.'
  })();

export const createInventoryProduct = (homeId: number, body: object) =>
  createApiRequest({
    route: PRODUCT_URL,
    method: 'post',
    body: body,
    replace: [['home_id', homeId]],
    onStart: createProductStart,
    onSuccess: createProductSuccess,
    onError: createProductError,
    defaultErrorMessage: 'There was an error updating the product templates.'
  })();

export const fetchSearchedProducts = (homeId: number, term: string) =>
  createApiRequest({
    route: PRODUCT_SEARCH_URL,
    method: 'get',
    replace: [
      ['home_id', homeId],
      ['term', term]
    ],
    onStart: fetchSearchedProductsStart,
    onSuccess: fetchSearchedProductsSuccess,
    onError: fetchSearchedProductsError,
    defaultErrorMessage: 'There was a problem searching products.'
  })();
