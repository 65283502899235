import {
  ZIP_CODE_URL,
  AVAILABLE_PLANS_URL,
  REFRESH_CONTACT_URL,
  SIGNUP_URL,
  UNSUBSCRIBE_URL,
  SIGNUP_EMAIL_URL,
  ZIPCODE_CHECK_URL
} from 'core/constants';
import { createApiRequest } from 'core/util';
import {
  checkPostalCodeSuccess,
  checkPostalCodeFailure,
  createContactSuccess,
  createContactFailure,
  fetchHomePackagesSuccess,
  fetchHomePackagesFailure,
  fetchContactAddressSuccess,
  fetchContactAddressFailure,
  updateUserSuccess,
  updateUserFailure,
  unsubscribeEmailSuccess,
  unsubscribeEmailFailure,
  createDigitalAccountSuccess,
  createDigitalAccountFailure,
  createDigitalAccountStart,
  checkEmailSuccess,
  checkEmailStart,
  checkEmailFailure,
  checkZipcodeSuccess,
  checkZipcodeFailure,
  fetchInitialPlansSuccess,
  fetchInitialPlansFailure
} from './SignupSlice';

export const checkPostalCode = (postal_code: string) =>
  createApiRequest({
    route: ZIP_CODE_URL,
    replace: ['postal_code', postal_code],
    method: 'get',
    onSuccess: checkPostalCodeSuccess,
    onError: checkPostalCodeFailure,
    defaultErrorMessage: 'There was an error verifying your zip code.',
    useGlobalLoader: true
  })();

export const checkEmail = (email: string) =>
  createApiRequest({
    route: SIGNUP_EMAIL_URL,
    replace: ['email', email],
    method: 'get',
    onStart: checkEmailStart,
    onSuccess: checkEmailSuccess,
    onError: checkEmailFailure,
    defaultErrorMessage: 'Email Unavailable',
    useGlobalLoader: true
  })();

export const checkZipcode = (postal_code: string) =>
  createApiRequest({
    route: ZIPCODE_CHECK_URL,
    replace: ['postal_code', postal_code],
    method: 'get',
    onSuccess: checkZipcodeSuccess,
    onError: checkZipcodeFailure,
    defaultErrorMessage: 'There was an error verifying your zip code.',
    useGlobalLoader: true
  })();

export const fetchInitialPlans = () =>
  createApiRequest({
    route: SIGNUP_URL,
    method: 'get',
    onSuccess: fetchInitialPlansSuccess,
    onError: fetchInitialPlansFailure,
    defaultErrorMessage: 'There was an error loading the available plans.',
    useGlobalLoader: true
  })();

export const createDigitalAccount = (payload: object) =>
  createApiRequest({
    route: SIGNUP_URL,
    method: 'post',
    body: payload,
    onStart: createDigitalAccountStart,
    onSuccess: createDigitalAccountSuccess,
    onError: createDigitalAccountFailure,
    defaultErrorMessage: 'There was an error creating your account.',
    useGlobalLoader: true
  })();

export const createContact = (payload: object) =>
  createApiRequest({
    route: SIGNUP_URL,
    method: 'post',
    body: payload,
    onSuccess: createContactSuccess,
    onError: createContactFailure,
    defaultErrorMessage: 'There was an error creating your account.',
    useGlobalLoader: true
  })();

export const fetchHomePlans = (params: object) =>
  createApiRequest({
    route: AVAILABLE_PLANS_URL,
    method: 'get',
    params: params,
    onSuccess: fetchHomePackagesSuccess,
    onError: fetchHomePackagesFailure,
    defaultErrorMessage: 'There was an error fetching the available packages.',
    useGlobalLoader: true
  })();

export const fetchContactAddress = (contactId: string) =>
  createApiRequest({
    route: REFRESH_CONTACT_URL,
    method: 'get',
    replace: ['contact_id', contactId],
    onSuccess: fetchContactAddressSuccess,
    onError: fetchContactAddressFailure,
    defaultErrorMessage: 'There was an error fetching the available packages.',
    useGlobalLoader: true
  })();

export const updateUser = (payload: object) =>
  createApiRequest({
    route: SIGNUP_URL,
    method: 'patch',
    body: payload,
    onSuccess: updateUserSuccess,
    onError: updateUserFailure,
    defaultErrorMessage: 'There was an error creating your account.',
    useGlobalLoader: true
  })();

export const unsubscribeEmail = (payload: object) =>
  createApiRequest({
    route: UNSUBSCRIBE_URL,
    method: 'post',
    body: payload,
    onSuccess: unsubscribeEmailSuccess,
    onError: unsubscribeEmailFailure,
    defaultErrorMessage: 'There was an error unsubscribing this email.',
    useGlobalLoader: true
  })();

export const deleteUnsubscribeEmail = (payload: object) =>
  createApiRequest({
    route: UNSUBSCRIBE_URL,
    method: 'delete',
    body: payload,
    onSuccess: unsubscribeEmailSuccess,
    onError: unsubscribeEmailFailure,
    defaultErrorMessage: 'There was an error unsubscribing this email.',
    useGlobalLoader: true
  })();
