import React from 'react';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage
} from '@ionic/react';
import Illustration from 'components/Illustrations/Illustration';
import UpdateIllustration from 'assets/illustrations/update.svg';
import './Update.scss';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { useHistory } from 'react-router';
import Close from 'assets/icons/close.svg';

const Update: React.FC = () => {
  const history = useHistory();

  const openAppStore = async () => {
    await AppUpdate.openAppStore();
  };

  const goToDashboard = () => {
    history.push(`/dashboard`);
  };

  return (
    <IonPage>
      <IonContent fullscreen className="hk-update ion-padding" scroll-y="false">
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            color="background"
            className="hk-fab-button hk-fab-button-margin-top"
            onClick={() => goToDashboard()}
          >
            <IonIcon color="white" icon={Close} />
          </IonFabButton>
        </IonFab>
        <section className="ion-text-center ion-margin-vertical">
          <Illustration image={UpdateIllustration} description="HomeKeep" />
        </section>
        <h3 className="ion-text-center">
          <b>Update Available</b>
        </h3>
        <p className="ion-padding-horizontal ion-text-center">
          We're getting better!
        </p>
        <p className="ion-padding-horizontal ion-text-center">
          Update the app to unlock new features.
        </p>
        <IonButton
          className="hk-update-button"
          expand="block"
          color="primary"
          type="submit"
          strong={true}
          onClick={() => openAppStore()}
        >
          Update
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default Update;
