import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonSpinner,
  IonText
} from '@ionic/react';
import {
  DATE_FORMAT_FULL_MONTH,
  HKPlatform,
  TASK_IMAGES_LIST
} from 'core/constants';
import { RootState } from 'core/store';
import {
  hideTaskDetailsModal,
  resetDigitalTaskDetails
} from 'features/digital/store/DigitalTasksSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DigitalMonthlyTaskDetails.scss';
import Close from '../../../../assets/icons/close.svg';
import {
  fetchTaskDetails,
  fetchTaskStatusTags,
  patchTaskDetails
} from 'features/digital/store/DigitalTasksActions';
import ReactPlayer from 'react-player';
import { formatTimezoneDate, gaBtnClickEvent, gaModalView } from 'core/util';
import Lock from '../../../../assets/icons/lock.svg';
import { TaskBodyDetails } from 'core/types';
import ExternalLink from 'components/external-link/ExternalLink';

const DigitalMonthlyTaskDetails: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { taskLoading, taskId, taskDetails, taskStatusTags } = useSelector(
    (state: RootState) => state.digitalTasks
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const userId = useSelector((state: RootState) => state.user.id);

  const dispatch = useDispatch();
  const [notes, setNotes] = useState<string | null>('');
  const [selectedStatusTags, setSelectedStatusTags] = useState<number[]>([]);
  const [taskAvailable, setTaskAvailable] = useState<boolean>(true);
  const [videoError, setVideoError] = useState<boolean>(false);

  const getTaskImage = (taskType: string) => {
    return TASK_IMAGES_LIST.find((task) => task.type === taskType)?.image;
  };

  const selectTag = (tagId: number) => {
    if (selectedStatusTags.includes(tagId)) {
      setSelectedStatusTags(selectedStatusTags.filter((id) => id !== tagId));
    } else {
      setSelectedStatusTags([...selectedStatusTags, tagId]);
    }
  };

  const checkIfTaskAvailable = (date: any) => {
    const currentTaskMonth = formatTimezoneDate(date, DATE_FORMAT_FULL_MONTH);
    const today = new Date();
    if (
      today.toLocaleString('default', { month: 'long' }) !== currentTaskMonth
    ) {
      setTaskAvailable(false);
    }
  };

  const convertToReadableLabel = (label: string) => {
    return label.replaceAll('_', ' ');
  };

  const completeTask = () => {
    gaBtnClickEvent('complete_task');
    const body: TaskBodyDetails = {};
    if (!!homeId && !!taskId) {
      body.finished = new Date();
      body.finished_by_id = userId;
      body.tag_ids = selectedStatusTags;
      body.notes = notes;
      dispatch(patchTaskDetails(homeId, taskId, body))
        .then(() => {
          dispatch(hideTaskDetailsModal());
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const updateTask = () => {
    gaBtnClickEvent('update_task');
    const body: TaskBodyDetails = {};
    if (!!homeId && !!taskId) {
      body.tag_ids = selectedStatusTags;
      body.notes = notes;
      dispatch(patchTaskDetails(homeId, taskId, body))
        .then(() => {
          dispatch(hideTaskDetailsModal());
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const handleVideoError = (e: any) => {
    console.log(e);
    setVideoError(true);
  };

  useEffect(() => {
    dispatch(resetDigitalTaskDetails);
    if (!!homeId && !!taskId) {
      dispatch(fetchTaskDetails(homeId, taskId));
      dispatch(fetchTaskStatusTags());
    }
  }, [dispatch, homeId, taskId]);

  useEffect(() => {
    if (!!taskDetails) {
      checkIfTaskAvailable(taskDetails.scheduled);
      setNotes(taskDetails.notes);
      setSelectedStatusTags(taskDetails.status_tags.map((tag) => tag.id));
    }
  }, [taskDetails]);

  useEffect(() => {
    gaModalView(isDigital, '/task/details');
  }, []);

  function DesktopDigitalTaskDetailsView() {
    return (
      <>
        <IonContent className={`hk-desktop-digital-task-details`}>
          {taskLoading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-desktop-digital-task-details-header"
                style={{
                  backgroundImage: `linear-gradient(
                rgba(0, 0, 0, 0.4),
                rgba(0, 0, 0, 0.4)
              ), url(${
                !!taskDetails?.product_type_name
                  ? getTaskImage(taskDetails.product_type_name)
                  : ''
              }`
                }}
              >
                <div className="hk-desktop-digital-task-details-header-pills">
                  <div className="hk-desktop-digital-task-details-header-pills-product">
                    {!!taskDetails?.product_type_name
                      ? convertToReadableLabel(taskDetails.product_type_name)
                      : 'Product Type'}
                  </div>
                  {!taskAvailable && taskDetails?.finished === null && (
                    <div className="hk-desktop-digital-task-details-header-pills-not-available">
                      <IonIcon icon={Lock} />
                      Not Yet Available
                    </div>
                  )}
                </div>
                <div className="hk-desktop-digital-task-details-header-title">
                  {!!taskDetails?.name ? taskDetails.name : 'Digital Name'}
                </div>
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideTaskDetailsModal())}
                  >
                    <IonIcon color="white" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <div className="hk-desktop-digital-task-details-why">
                {!!taskDetails?.why ? taskDetails.why : 'Why Description'}
              </div>
              <div
                className={`hk-desktop-digital-task-details-body hk-desktop-digital-task-details-body-margin-bottom`}
              >
                {!!taskDetails?.consumables.length && (
                  <div
                    className={`hk-desktop-digital-task-details-body-consumables ${
                      !!taskDetails?.tools.length
                        ? 'hk-desktop-digital-task-details-body-consumables-has-tools'
                        : ''
                    }`}
                  >
                    <IonAccordionGroup>
                      <IonAccordion className="hk-desktop-digital-task-details-body-accordion">
                        <IonItem
                          className="hk-desktop-digital-task-details-body-accordion-header"
                          slot="header"
                        >
                          <IonLabel>Required Consumables</IonLabel>
                        </IonItem>
                        <IonList
                          className="hk-desktop-digital-task-details-body-accordion-content"
                          slot="content"
                        >
                          <p className="hk-desktop-digital-task-details-body-accordion-content__affiliate-text">
                            As an Amazon Affiliate, we earn from qualifying
                            purchases.
                          </p>
                          {!!taskDetails?.consumables &&
                            taskDetails.consumables.map((consumable, i) => (
                              <ExternalLink
                                name={consumable.name}
                                url={consumable.link}
                                key={`consumable-${i}`}
                              />
                            ))}
                        </IonList>
                      </IonAccordion>
                    </IonAccordionGroup>
                  </div>
                )}

                {!!taskDetails?.tools.length && (
                  <div className="hk-desktop-digital-task-details-body-tools">
                    <IonAccordionGroup>
                      <IonAccordion className="hk-desktop-digital-task-details-body-accordion">
                        <IonItem
                          className="hk-desktop-digital-task-details-body-accordion-header"
                          slot="header"
                        >
                          <IonLabel>Recommended Tools and Equipment</IonLabel>
                        </IonItem>
                        <IonList
                          className="hk-desktop-digital-task-details-body-accordion-content"
                          slot="content"
                        >
                          <p className="hk-desktop-digital-task-details-body-accordion-content__affiliate-text">
                            As an Amazon Affiliate, we earn from qualifying
                            purchases.
                          </p>
                          {!!taskDetails?.tools &&
                            taskDetails.tools.map((tool, i) => (
                              <ExternalLink
                                name={tool.name}
                                url={tool.link}
                                key={`tool-${i}`}
                              />
                            ))}
                        </IonList>
                      </IonAccordion>
                    </IonAccordionGroup>
                  </div>
                )}

                {!!taskDetails?.steps.length && (
                  <div className="hk-desktop-digital-task-details-body-steps">
                    {!!taskDetails?.steps &&
                      taskDetails.steps.map((step, i) => {
                        return (
                          <div
                            key={`step-${i}`}
                            className="hk-desktop-digital-task-details-body-steps-card"
                          >
                            <div className="hk-desktop-digital-task-details-body-steps-card-title">
                              {`${i + 1}: ${step}`}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}

                {!!taskDetails?.tutorial_url && !videoError && (
                  <div className="hk-desktop-digital-task-details-body-video">
                    <div className="hk-desktop-digital-task-details-body-video-title">
                      Video Guide:
                    </div>
                    <div className="hk-desktop-digital-task-details-body-video-content">
                      <div className="video">
                        <>
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            controls={true}
                            onError={(e) => handleVideoError(e)}
                            url={taskDetails.tutorial_url}
                          />
                        </>
                        {/* )} */}
                      </div>
                      {/* TODO: Add back when video's have descriptions */}
                      {/* <div className="video-description">
              <div className="video-description-title">
                HVAC Air and Fan
              </div>
              <div className="video-description-subtitle">
                We walk you through everything on how to successfully
                catalog your refrigeration device.
              </div>
            </div> */}
                    </div>
                  </div>
                )}

                <IonInput
                  className="hk-desktop-digital-task-details-body-note"
                  placeholder="Leave a note about, location, color, etc..."
                  value={notes}
                  onIonChange={(e) => setNotes(e.detail.value!)}
                ></IonInput>

                {taskStatusTags?.length !== 0 && (
                  <div className="hk-desktop-digital-task-details-body-tags">
                    <div className="hk-desktop-digital-task-details-body-tags-title">
                      Add a Tag:
                    </div>
                    <div className="hk-desktop-digital-task-details-body-tags-content">
                      {!!taskStatusTags &&
                        taskStatusTags.map((tag, i) => {
                          return (
                            <>
                              {tag.digital && (
                                <div
                                  className={`tag ${
                                    selectedStatusTags.includes(tag.id) &&
                                    'tag-selected'
                                  }`}
                                  key={`tag-${i}`}
                                  onClick={() => selectTag(tag.id)}
                                >
                                  {tag.name}
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              {taskDetails?.finished === null && (
                <div className="hk-desktop-digital-task-details-footer">
                  <IonButton
                    color={taskAvailable ? 'primary' : 'medium'}
                    className="hk-desktop-digital-task-details-footer-button"
                    disabled={!taskAvailable}
                    onClick={() => completeTask()}
                  >
                    {taskAvailable ? 'Complete Task' : 'Not Yet Available'}
                  </IonButton>
                </div>
              )}
              {taskDetails?.finished !== null && (
                <div className="hk-desktop-digital-task-details-footer">
                  <IonButton
                    color={'primary'}
                    className="hk-desktop-digital-task-details-footer-button"
                    onClick={() => updateTask()}
                  >
                    Update Task
                  </IonButton>
                </div>
              )}
            </>
          )}
        </IonContent>
      </>
    );
  }

  function MobileDigitalTaskDetailsView() {
    return (
      <>
        <IonContent className={`hk-digital-task-details`}>
          {taskLoading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div
                className="hk-digital-task-details-header"
                style={{
                  backgroundImage: `linear-gradient(
                    rgba(0, 0, 0, 0.4),
                    rgba(0, 0, 0, 0.4)
                  ), url(${
                    !!taskDetails?.product_type_name
                      ? getTaskImage(taskDetails.product_type_name)
                      : ''
                  }`
                }}
              >
                <div className="hk-digital-task-details-header-pills">
                  <div className="hk-digital-task-details-header-pills-product">
                    {!!taskDetails?.product_type_name
                      ? convertToReadableLabel(taskDetails.product_type_name)
                      : 'Product Type'}
                  </div>
                  {!taskAvailable && taskDetails?.finished === null && (
                    <div className="hk-digital-task-details-header-pills-not-available">
                      <IonIcon icon={Lock} />
                      Not Yet Available
                    </div>
                  )}
                  <IonFab
                    className="hk-digital-task-details-fab-button"
                    vertical="top"
                    horizontal="end"
                  >
                    <IonFabButton
                      color="background"
                      className="hk-fab-button"
                      onClick={() => dispatch(hideTaskDetailsModal())}
                    >
                      <IonIcon color="white" icon={Close} />
                    </IonFabButton>
                  </IonFab>
                </div>
                <div className="hk-digital-task-details-header-title">
                  {!!taskDetails?.name ? taskDetails.name : 'Digital Name'}
                </div>
              </div>
              <div className="hk-digital-task-details-why">
                {!!taskDetails?.why ? taskDetails.why : 'Why Description'}
              </div>
              <div
                className={`hk-digital-task-details-body hk-digital-task-details-body-margin-bottom`}
              >
                {!!taskDetails?.consumables.length && (
                  <div
                    className={`hk-digital-task-details-body-consumables ${
                      !!taskDetails?.tools.length
                        ? 'hk-digital-task-details-body-consumables-has-tools'
                        : ''
                    }`}
                  >
                    <IonAccordionGroup>
                      <IonAccordion className="hk-digital-task-details-body-accordion">
                        <IonItem
                          className="hk-digital-task-details-body-accordion-header"
                          slot="header"
                        >
                          <IonLabel>Required Consumables</IonLabel>
                        </IonItem>
                        <IonList
                          className="hk-digital-task-details-body-accordion-content"
                          slot="content"
                        >
                          <p className="hk-digital-task-details-body-accordion-content__affiliate-text">
                            As an Amazon Affiliate, we earn from qualifying
                            purchases.
                          </p>
                          {!!taskDetails?.consumables &&
                            taskDetails.consumables.map((consumable, i) => (
                              <ExternalLink
                                name={consumable.name}
                                url={consumable.link}
                                key={`consumable-${i}`}
                              />
                            ))}
                        </IonList>
                      </IonAccordion>
                    </IonAccordionGroup>
                  </div>
                )}

                {!!taskDetails?.tools.length && (
                  <div className="hk-digital-task-details-body-tools">
                    <IonAccordionGroup>
                      <IonAccordion className="hk-digital-task-details-body-accordion">
                        <IonItem
                          className="hk-digital-task-details-body-accordion-header"
                          slot="header"
                        >
                          <IonLabel>Recommended Tools and Equipment</IonLabel>
                        </IonItem>
                        <IonList
                          className="hk-digital-task-details-body-accordion-content"
                          slot="content"
                        >
                          <p className="hk-digital-task-details-body-accordion-content__affiliate-text">
                            As an Amazon Affiliate, we earn from qualifying
                            purchases.
                          </p>
                          {!!taskDetails?.tools &&
                            taskDetails.tools.map((tool, i) => (
                              <ExternalLink
                                name={tool.name}
                                url={tool.link}
                                key={`tool-${i}`}
                              />
                            ))}
                        </IonList>
                      </IonAccordion>
                    </IonAccordionGroup>
                  </div>
                )}

                {!!taskDetails?.steps.length && (
                  <div className="hk-digital-task-details-body-steps">
                    {!!taskDetails?.steps &&
                      taskDetails.steps.map((step, i) => {
                        return (
                          <div
                            key={`step-${i}`}
                            className="hk-digital-task-details-body-steps-card"
                          >
                            <div className="hk-digital-task-details-body-steps-card-title">
                              {`${i + 1}: ${step}`}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}

                {!!taskDetails?.tutorial_url && !videoError && (
                  <div className="hk-digital-task-details-body-video">
                    <div className="hk-digital-task-details-body-video-title">
                      Video Guide:
                    </div>
                    <div className="hk-digital-task-details-body-video-content">
                      <div className="video">
                        {/* {platformType === HKPlatform.MOBILE_WEB && ( */}
                        <>
                          <ReactPlayer
                            width="100%"
                            height="100%"
                            controls={true}
                            onError={(e) => handleVideoError(e)}
                            url={taskDetails.tutorial_url}
                          />
                        </>
                        {/* )} */}
                      </div>
                      {/* TODO: Add back when video's have descriptions */}
                      {/* <div className="video-description">
                  <div className="video-description-title">
                    HVAC Air and Fan
                  </div>
                  <div className="video-description-subtitle">
                    We walk you through everything on how to successfully
                    catalog your refrigeration device.
                  </div>
                </div> */}
                    </div>
                  </div>
                )}

                <IonInput
                  className="hk-digital-task-details-body-note"
                  placeholder="Leave a note about, location, color, etc..."
                  value={notes}
                  onIonChange={(e) => setNotes(e.detail.value!)}
                ></IonInput>

                {taskStatusTags?.length !== 0 && (
                  <div className="hk-digital-task-details-body-tags">
                    <div className="hk-digital-task-details-body-tags-title">
                      Add a Tag:
                    </div>
                    <div className="hk-digital-task-details-body-tags-content">
                      {!!taskStatusTags &&
                        taskStatusTags.map((tag, i) => {
                          return (
                            <>
                              {tag.digital && (
                                <div
                                  className={`tag ${
                                    selectedStatusTags.includes(tag.id) &&
                                    'tag-selected'
                                  }`}
                                  key={`tag-${i}`}
                                  onClick={() => selectTag(tag.id)}
                                >
                                  {tag.name}
                                </div>
                              )}
                            </>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
              {taskDetails?.finished === null && (
                <div className="hk-digital-task-details-footer">
                  <IonButton
                    color={taskAvailable ? 'primary' : 'medium'}
                    className="hk-digital-task-details-footer-button"
                    disabled={!taskAvailable}
                    onClick={() => completeTask()}
                  >
                    {taskAvailable ? 'Complete Task' : 'Not Yet Available'}
                  </IonButton>
                </div>
              )}
              {taskDetails?.finished !== null && (
                <div className="hk-digital-task-details-footer">
                  <IonButton
                    color={'primary'}
                    className="hk-digital-task-details-footer-button"
                    onClick={() => updateTask()}
                  >
                    Update Task
                  </IonButton>
                </div>
              )}
            </>
          )}
        </IonContent>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDigitalTaskDetailsView()
        : MobileDigitalTaskDetailsView()}
    </>
  );
};

export default DigitalMonthlyTaskDetails;
