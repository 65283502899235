import { createApiRequest } from 'core/util';
import { HOME_URL } from 'core/constants';
import {
  deleteHomeError,
  deleteHomeSuccess,
  setCurrentHome,
  setGlobalAccountSettings,
  setIsEligibleRenewal,
  setIsExpired,
  updateHomeFailure,
  updateHomeSuccess,
  setWarrantyURL
} from './HomeSlice';
import { setUserDigital } from './UserSlice';
import { AppDispatch, AppThunk } from '../store';
import { hideUserEdit } from 'features/shared/account/AccountSlice';

export const requestHomeInfo = (id: number) =>
  createApiRequest({
    route: HOME_URL,
    replace: ['home_id', id],
    onSuccess: fetchHomeSuccess,
    onGlobalSuccess: fetchGlobalHomeSuccess,
    defaultErrorMessage: ''
  })();

export const fetchHomeSuccess =
  (home: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setCurrentHome(home));
    dispatch(setUserDigital(home.is_diy));
  };

export const fetchGlobalHomeSuccess =
  (global: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setGlobalAccountSettings(global));
    dispatch(setIsExpired(global.expired === null ? true : global.expired));
    dispatch(setIsEligibleRenewal(global.expires));
    dispatch(setWarrantyURL(global.avid_warranty_url));
  };

export const updateHome = (home: any, homeId: number) =>
  createApiRequest({
    route: HOME_URL,
    replace: ['home_id', homeId],
    method: 'patch',
    body: home,
    onSuccess: updateHomeSuccess,
    onError: updateHomeFailure,
    onComplete: completeHomeUpdate,
    defaultErrorMessage: 'There was a problem updating your home.',
    useGlobalLoader: true
  })();

export const deleteHome = (homeId: number) =>
  createApiRequest({
    route: HOME_URL,
    replace: ['home_id', homeId],
    method: 'delete',
    onSuccess: deleteHomeSuccess,
    onError: deleteHomeError,
    defaultErrorMessage: 'There was a problem updating your home.',
    useGlobalLoader: true
  })();

export const completeHomeUpdate =
  (): AppThunk => async (dispatch: AppDispatch) => {
    dispatch(hideUserEdit());
  };
