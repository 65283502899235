import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'core/store';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonIcon,
  IonInput,
  IonPage,
  IonToggle,
  IonSpinner
} from '@ionic/react';
import './CheckoutDesktop.scss';
import {
  HKBillingPeriod,
  HKPlatform,
  RECURLY_DEVL_KEY,
  RECURLY_PUBLIC_KEY
} from 'core/constants';
import { removeSavedCard, setAutoRenew, setPromoCode } from './BillingSlice';
import {
  Elements,
  RecurlyProvider,
  useCheckoutPricing,
  UseCheckoutPricingInput
} from '@recurly/react-recurly';
import Summary from './Summary';
import CreditCard from 'assets/icons/credit-card.svg';
import Complete from 'assets/icons/complete.svg';
import VisaCard from 'assets/icons/visa-card.svg';
import Mastercard from 'assets/icons/mastercard.svg';
import DiscoverCard from 'assets/icons/discover-card.svg';
import AmericanExpressCard from 'assets/icons/american-express-card.svg';
import JcbCard from 'assets/icons/jcb-card.svg';
import { renewSubscription, requestCreditCardInfo } from './BillingActions';
import CreditCardForm from './CreditCardForm';
import { gaBtnClickEvent, isDev } from 'core/util';
import { displayError } from 'core/services/AlertActions';

const CheckoutDesktop: React.FC = () => {
  const { name } = useSelector((state: RootState) => state.user);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const {
    loading,
    newAddOns,
    newPlan,
    newBillingPeriod,
    currentBillingInfo,
    promoCode,
    autoRenew,
    error
  } = useSelector((state: RootState) => state.billing);
  const dispatch = useDispatch();
  let history = useHistory();
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);

  const [recurlyPromoCode, setRecurlyPromoCode] = useState<any>('');
  const [recurlyErrorPromoCode, setRecurlyErrorPromoCode] = useState<any>('');
  const [recurlyError, setRecurlyError] = useState<any>(null);
  const promoCodeInputRef = useRef<any>(null);

  const goBack = () => {
    gaBtnClickEvent('back_to_renew');
    history.goBack();
  };

  const applyPromoCode = () => {
    setRecurlyError(null);
    setRecurlyPromoCode(promoCodeInputRef.current.value);
    setRecurlyErrorPromoCode(promoCodeInputRef.current.value);
    dispatch(setPromoCode(promoCodeInputRef.current.value));
    gaBtnClickEvent('apply_promo_code');
  };

  const addNewCard = () => {
    dispatch(removeSavedCard());
    gaBtnClickEvent('add_new_card');
  };

  const submitPayment = async () => {
    const body: any = {
      currency: 'USD',
      base_plan_id: newPlan.id,
      addon_ids: newAddOns,
      billing_period: newBillingPeriod === HKBillingPeriod.MONTHLY ? 1 : 12,
      billing_id: currentBillingInfo.billing_id,
      auto_renew: autoRenew
    };
    if (promoCodeInputRef.current.value) {
      body['coupon_code'] = promoCodeInputRef.current.value;
    }
    if (!!homeId) {
      await dispatch(renewSubscription(homeId, body));
      if (!error) {
        gaBtnClickEvent('submit_payment');
        history.push('/renew/confirmation');
      } else {
        console.log(error);
        dispatch(displayError(error));
      }
    }
  };

  const getCreditCardIcon = () => {
    switch (currentBillingInfo.card_type) {
      case 'MasterCard':
        return Mastercard;
      case 'Visa':
        return VisaCard;
      case 'Discover':
        return DiscoverCard;
      case 'AmericanExpress':
        return AmericanExpressCard;
      case 'JCB':
        return JcbCard;
    }
    return VisaCard;
  };

  useEffect(() => {
    if (!!homeId) {
      dispatch(requestCreditCardInfo(homeId));
    }
  }, []);

  useEffect(() => {
    if (!!promoCode) {
      setRecurlyPromoCode(promoCode);
    }
  }, []);

  function SavedCard() {
    return (
      <>
        <div className="hk-desktop-checkout-card-container">
          <div className="hk-desktop-checkout-card-icon">
            <IonIcon icon={CreditCard} />
            <span>Credit Card</span>
          </div>

          <div className="hk-saved-card">
            <div className="hk-saved-card-info-container">
              <div className="hk-saved-card-icon">
                <IonIcon icon={getCreditCardIcon()} />
              </div>
              <div className="hk-saved-card-info">
                <div className="hk-saved-card-info-card-number">
                  {currentBillingInfo.card_type} ending in{' '}
                  {currentBillingInfo.last_four}
                </div>
                <div className="hk-saved-card-info-expiration">
                  {currentBillingInfo.expire_month}/
                  {currentBillingInfo.expire_year}{' '}
                </div>
              </div>
            </div>
            <div className="hk-saved-card-check">
              <IonIcon color="primary" icon={Complete} />
            </div>
          </div>

          <div className="hk-saved-card-new" onClick={addNewCard}>
            Add New Payment
          </div>
        </div>
      </>
    );
  }

  function PricingPreview() {
    const recurlyAddOns: { code: any; quantity: number }[] = [];

    // Full Service Plan (as an add-on)
    if (!!newPlan.pricing[newBillingPeriod].addon_code) {
      recurlyAddOns.push({
        code: newPlan.pricing[newBillingPeriod].addon_code,
        quantity: 1
      });
    }

    // Actual add-ons
    if (!!newAddOns.length) {
      newPlan.addons.forEach((item: any) => {
        if (newAddOns.includes(item.id) && !!item.pricing[newBillingPeriod]) {
          recurlyAddOns.push({
            code: item.pricing[newBillingPeriod].addon_code,
            quantity: 1
          });
        }
      });
    }

    const initialPricingInput: UseCheckoutPricingInput = {
      address: {
        first_name: name,
        last_name: name,
        postal_code: currentHome?.postal_code,
        country: 'US'
      },
      currency: 'USD',
      subscriptions: [
        {
          plan: newPlan.pricing[newBillingPeriod].base_code,
          quantity: 1,
          addons: recurlyAddOns
        }
      ],
      coupon: recurlyPromoCode
    };

    const [{ price, loading }] = useCheckoutPricing(
      initialPricingInput,
      setRecurlyError
    );
    if (recurlyError) {
      if (recurlyError.code !== 'limit-exceeded') {
        setRecurlyPromoCode('');
        dispatch(setPromoCode(''));
      }
    }

    return (
      <div className="hk-billing-summary-content">
        <section className="hk-billing-summary-subscription">
          <div className="hk-billing-summary-subscription-header">
            <h4>YOUR SUBSCRIPTION</h4>
            <div className="hk-subscription-pill">
              {newBillingPeriod === HKBillingPeriod.MONTHLY
                ? 'Monthly'
                : 'Yearly'}
            </div>
          </div>
          <div className="hk-billing-summary-subscription-item">
            <h6 className="hk-billing-summary-subscription-item-name">
              {newPlan.name}
            </h6>
            <div className="hk-billing-summary-subscription-item-price">
              <>
                ${newPlan.pricing[newBillingPeriod].usd}/
                {newBillingPeriod === HKBillingPeriod.MONTHLY ? 'mo' : 'yr'}
              </>
            </div>
          </div>
          <CalculateAddOns />
          <div className="hk-billing-summary-subscription-promo">
            <div className="hk-billing-summary-subscription-promo-body">
              <IonInput
                className={`hk-billing-summary-subscription-promo-input ${
                  recurlyError &&
                  'hk-billing-summary-subscription-promo-input-error'
                }`}
                name="promo"
                placeholder="Promo Code"
                ref={promoCodeInputRef}
                value={!recurlyError ? recurlyPromoCode : recurlyErrorPromoCode}
              ></IonInput>
              <IonButton
                expand="block"
                color="primary"
                strong={true}
                className="hk-billing-summary-subscription-promo-button"
                onClick={() => applyPromoCode()}
              >
                Apply
              </IonButton>
            </div>
            <div className="hk-billing-summary-subscription-promo-error">
              {recurlyError ? <span>Enter a valid promo code</span> : ''}
            </div>
          </div>

          <div className="hk-billing-summary-subscription-auto-renew">
            <span>Auto Renew</span>
            <IonToggle
              checked={autoRenew}
              onIonChange={(e) => dispatch(setAutoRenew(e.detail.checked))}
            ></IonToggle>
          </div>

          <div className="hk-billing-summary-subscription-total">
            <div className="hk-billing-summary-subscription-total-sub-container">
              <div className="hk-billing-summary-subscription-total-item">
                <h6 className="hk-billing-summary-subscription-total-item-name">
                  Subtotal
                </h6>
                <div className="hk-billing-summary-subscription-total-item-price">
                  {!loading && !recurlyError ? (
                    <div>
                      {price.currency.symbol}
                      {price.next.subscriptions}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="hk-billing-summary-subscription-total-item">
              <h6 className="hk-billing-summary-subscription-total-item-name">
                Discount
              </h6>
              <div className="hk-billing-summary-subscription-total-item-price">
                {!loading && !recurlyError ? (
                  <div>
                    -{price.currency.symbol}
                    {price.next.discount}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>{' '}
            <div className="hk-billing-summary-subscription-total-item">
              <h6 className="hk-billing-summary-subscription-total-item-name">
                Estimated Tax
              </h6>
              <div className="hk-billing-summary-subscription-total-item-price">
                {!loading && !recurlyError ? (
                  <div>
                    {price.currency.symbol}
                    {price.next.taxes}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="hk-billing-summary-due">
          <div className="hk-billing-summary-due-item">
            <h6 className="hk-billing-summary-due-item-name">
              TOTAL DUE TODAY
            </h6>
            <div className="hk-billing-summary-due-item-price">
              {!loading && !recurlyError ? (
                <div>
                  {price.currency.symbol}
                  {price.next.total}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }

  function CalculateAddOns() {
    const plans: any = [];
    newPlan.addons.forEach((item: any) => {
      if (newAddOns.includes(item.id)) {
        plans.push(item);
      }
    });

    return (
      <>
        {plans.map((item: any, i: number) => {
          return (
            <div
              className="hk-billing-summary-subscription-item"
              key={`addon-${i}`}
            >
              <h6 className="hk-billing-summary-subscription-item-name">
                {item.name}
              </h6>
              <div className="hk-billing-summary-subscription-item-price">
                {!!item.pricing.price_text ? (
                  item.pricing.price_text
                ) : (
                  <>
                    {!!item.pricing[newBillingPeriod] && (
                      <>
                        ${item.pricing[newBillingPeriod].usd}/
                        {newBillingPeriod === HKBillingPeriod.MONTHLY
                          ? 'mo'
                          : 'yr'}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function DesktopCheckoutView() {
    return (
      <>
        <RecurlyProvider
          publicKey={isDev() ? RECURLY_DEVL_KEY : RECURLY_PUBLIC_KEY}
        >
          <div className="hk-desktop-checkout-page-header">
            <IonBreadcrumbs>
              <IonBreadcrumb href="/renew">Select Your Plan</IonBreadcrumb>
              <IonBreadcrumb>Checkout Summary</IonBreadcrumb>
            </IonBreadcrumbs>
          </div>
          <div className="hk-desktop-checkout-page-container">
            <section className="hk-desktop-checkout-card-section">
              <div className="hk-desktop-checkout-form">
                <div className="hk-desktop-checkout-card-header">
                  <h2>Subscription Summary & Checkout</h2>
                  <p>Confirm your selections and proceed to checkout.</p>
                </div>
                {!!currentBillingInfo ? (
                  <Elements>
                    <SavedCard />
                    <div className="hk-desktop-recurly-form-actions">
                      <div
                        className="hk-desktop-recurly-form-back-button"
                        onClick={goBack}
                      >
                        Back
                      </div>
                      <IonButton
                        className="hk-desktop-recurly-button"
                        size="default"
                        color="primary"
                        type="submit"
                        strong={true}
                        disabled={loading}
                        onClick={submitPayment}
                      >
                        {loading ? <IonSpinner name="dots" /> : 'Submit'}
                      </IonButton>
                    </div>
                  </Elements>
                ) : (
                  <Elements>
                    <CreditCardForm />
                  </Elements>
                )}
              </div>
            </section>
            <section className="hk-desktop-checkout-summary-section">
              <Elements>
                <PricingPreview />
              </Elements>
            </section>
          </div>
        </RecurlyProvider>
      </>
    );
  }

  function MobileCheckoutView() {
    return (
      <>
        <Summary />
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-checkout-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopCheckoutView()
        : MobileCheckoutView()}
    </IonPage>
  );
};

export default CheckoutDesktop;
