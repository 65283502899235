import React from 'react';
import { IonIcon, IonText, IonThumbnail } from '@ionic/react';
import Clock from 'assets/icons/clock.svg';
import './TaskCard.scss';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { HKPlatform } from 'core/constants';
import Thumbnail from 'components/thumbnail/Thumbnail';
import Complete from 'assets/icons/complete.svg';
import Lock from 'assets/icons/lock.svg';

export enum TaskType {
  DEFAULT = 'default',
  CATALOG = 'catalog'
}

type TaskCardProps = {
  className?: string;
  image?: string;
  title?: string;
  product?: string | null;
  description?: string;
  completed?: boolean;
  available?: boolean;
  time?: number;
  type?: TaskType;
  taskClicked?: (e: any) => void;
};

const TaskCard: React.FC<TaskCardProps> = ({
  className,
  image,
  title,
  product,
  description,
  completed = false,
  available = true,
  time,
  type = TaskType.DEFAULT,
  taskClicked,
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopTaskCardView() {
    return (
      <div
        className={`hk-desktop-task-card ${className}
      ${completed ? 'completed' : ''}
      ${available ? '' : 'locked'} `}
        {...rest}
        onClick={taskClicked}
      >
        {!!image && (
          <IonThumbnail slot="end" className="hk-desktop-task-card-thumbnail">
            <Thumbnail
              src={image}
              alt="Task Image"
              className="hk-desktop-task-card-thumbnail-img"
            />
          </IonThumbnail>
        )}

        <div className="hk-desktop-task-card-body">
          {!!product && (
            <div className="hk-desktop-task-card-body-product">{product}</div>
          )}
          <div>
            <div className="hk-desktop-task-card-body-title">{title}</div>
            <div className="hk-desktop-task-card-body-description">
              {description === 'None' ? '' : description}
            </div>
          </div>
          <div className="hk-desktop-task-card-body-time">
            <IonIcon icon={Clock} />
            <IonText>{time} Min</IonText>
          </div>
          {completed && (
            <div
              className={`${
                type === TaskType.CATALOG
                  ? 'hk-desktop-task-card-body-completed-small'
                  : 'hk-desktop-task-card-body-completed'
              }`}
            >
              <IonIcon color="primary" icon={Complete} />
            </div>
          )}
          {!available && !completed && (
            <div className="hk-desktop-task-card-body-locked">
              <IonIcon icon={Lock} />
            </div>
          )}
        </div>
      </div>
    );
  }

  function MobileTaskCardView() {
    return (
      <div
        className={`hk-task-card ${className}
        ${completed ? 'completed' : ''}
        ${available ? '' : 'locked'} `}
        {...rest}
        onClick={taskClicked}
      >
        {!!image && (
          <IonThumbnail slot="end" className="hk-task-card-thumbnail">
            <Thumbnail
              src={image}
              alt="Task Image"
              className="hk-task-card-thumbnail-img"
            />
          </IonThumbnail>
        )}

        <div className="hk-task-card-body">
          {!!product && (
            <div className="hk-task-card-body-product">{product}</div>
          )}
          <div>
            <div className="hk-task-card-body-title">{title}</div>
            <div className="hk-task-card-body-description">
              {description === 'None' ? '' : description}
            </div>
          </div>
          <div className="hk-task-card-body-time">
            <IonIcon icon={Clock} />
            <IonText>{time} Min</IonText>
          </div>
          {completed && (
            <div
              className={`${
                type === TaskType.CATALOG
                  ? 'hk-task-card-body-completed-small'
                  : 'hk-task-card-body-completed'
              }`}
            >
              <IonIcon color="primary" icon={Complete} />
            </div>
          )}
          {!available && !completed && (
            <div className="hk-task-card-body-locked">
              <IonIcon icon={Lock} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopTaskCardView()
        : MobileTaskCardView()}
    </>
  );
};

export default TaskCard;
