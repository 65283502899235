import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useHistory } from 'react-router';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonModal,
  IonPage,
  IonRow,
  IonText
} from '@ionic/react';
import {
  hideAppointmentModal,
  resetAppointmentWizard,
  showAppointmentWizard
} from '../wizard/AppointmentWizardSlice';
import { fetchUpcomingVisits } from '../../dashboard/DashboardActions';
import { HKPlatform, HKScheduleType } from 'core/constants';
import CalendarIllustration from 'assets/illustrations/calendar.svg';
import AppointmentWizard from '../wizard/AppointmentWizard';
import './InitialAppointment.scss';
import { gaBtnClickEvent } from 'core/util';
import { requestHomeInfo } from 'core/services/HomeActions';

const InitialAppointment: React.FC = () => {
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { appointment, wizardModalVisible } = useSelector(
    (state: RootState) => state.appointmentWizard
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { upcomingVisits } = useSelector((state: RootState) => state.dashboard);
  const { name } = useSelector((state: RootState) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyAppointment = () => {
    dispatch(hideAppointmentModal());
    if (!!appointment && !!currentHome) {
      dispatch(requestHomeInfo(currentHome?.id));
      history.push('/dashboard');
    }
  };

  const scheduleInitialAppointment = () => {
    gaBtnClickEvent('schedule_initial_appointment');
    if (!!upcomingVisits) {
      dispatch(resetAppointmentWizard());
      dispatch(
        showAppointmentWizard({
          type: HKScheduleType.NEW,
          home_id: currentHome?.id,
          visit_hash: upcomingVisits[0].plan_hash,
          name: upcomingVisits[0].name
        })
      );
    }
  };

  useEffect(() => {
    if (!upcomingVisits && !!currentHome) {
      dispatch(fetchUpcomingVisits(currentHome.id));
    }
  }, [dispatch, upcomingVisits, currentHome]);

  function DesktopInitialDashboardView() {
    return (
      <>
        <IonContent>
          <div className="hk-desktop-initial-appointment">
            <section className="hk-desktop-initial-appointment-header">
              <IonText className="hk-desktop-initial-appointment-header-text">
                <h1>
                  Welcome <span className="hk-bold">{name}</span>
                </h1>
              </IonText>
              <IonText>
                <p>
                  Thanks for setting up your HomeKeep account. We're looking
                  forward to caring for your home! First things first, let's
                  schedule your initial home cataloging appointment.
                </p>
              </IonText>
            </section>
            <section className="hk-desktop-initial-appointment-body">
              <div className="hk-desktop-initial-appointment-body-content">
                <img
                  className="hk-card-image"
                  src={CalendarIllustration}
                  alt="Calendar"
                />
                <div className="hk-desktop-initial-appointment-body-content-text">
                  <IonText color="light">
                    <h1 className="hk-card-text">
                      Schedule your cataloging appointment
                    </h1>
                  </IonText>
                  <IonText color="light">
                    <h3 className="hk-card-text">
                      Select a day and time that works best for you.
                    </h3>
                  </IonText>
                </div>
              </div>
              <IonButton
                disabled={upcomingVisits?.length === 0}
                expand="block"
                color="primary"
                onClick={scheduleInitialAppointment}
              >
                Schedule
              </IonButton>
            </section>
          </div>
        </IonContent>
      </>
    );
  }

  function MobileInitialDashboardView() {
    return (
      <IonContent fullscreen scroll-y="false" className="hk-schedule">
        <section className="hk-schedule-header ion-padding">
          <IonText className="hk-schedule-header-text" color="light">
            <h1>Welcome</h1>
          </IonText>
          <IonText className="hk-schedule-header-text name" color="light">
            <h1>{name}</h1>
          </IonText>
          <IonText color="light">
            <p>
              Thanks for setting up your HomeKeep account. We're looking forward
              to caring for your home! First things first, let's schedule your
              initial home cataloging appointment.
            </p>
          </IonText>
        </section>
        <section className="hk-schedule-body ion-padding-top">
          <IonCard className="ion-padding-top ion-padding-bottom hk-grey-background">
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <img
                      className="hk-card-image"
                      src={CalendarIllustration}
                      alt="Calendar"
                    />
                  </IonCol>
                  <IonCol size="8">
                    <IonText color="light">
                      <h1 className="hk-card-text">
                        Schedule your cataloging appointment
                      </h1>
                    </IonText>
                    <IonText color="light">
                      <h3 className="hk-card-text">
                        Select a day and time that works best for you.
                      </h3>
                    </IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol>
                    <IonButton
                      disabled={upcomingVisits?.length === 0}
                      expand="block"
                      color="primary"
                      className="ion-margin-vertical"
                      onClick={scheduleInitialAppointment}
                    >
                      Schedule
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </section>
      </IonContent>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-initial-appointment-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopInitialDashboardView()
        : MobileInitialDashboardView()}
      <IonModal
        className="hk-appointment-wizard-modal"
        isOpen={wizardModalVisible}
        onDidDismiss={() => verifyAppointment()}
      >
        <AppointmentWizard />
      </IonModal>
    </IonPage>
  );
};

export default InitialAppointment;
