import { createApiRequest, saveFirebaseTokenInStorage } from '../util';
import {
  API_HEARTBEAT_URL,
  PUSH_NOTIFICATIONS_LOG_URL,
  USER_NOTIFICATION_TOKEN_URL
} from '../constants';
import {
  fetchApiVersionSuccess,
  logPushNotificationFailure,
  logPushNotificationSuccess,
  updateUserWithPushNotificationTokenFailure,
  updateUserWithPushNotificationTokenSuccess
} from './PlatformSlice';
import store, { AppDispatch, AppThunk } from '../store';

export const updateUserWithPushNotificationToken = (token: string) =>
  createApiRequest({
    route: USER_NOTIFICATION_TOKEN_URL,
    method: 'post',
    body: { token: token },
    onSuccess: updateUserWithPushNotificationTokenSuccess,
    onError: updateUserWithPushNotificationTokenFailure,
    onComplete: updateUserWithPushNotificationTokenComplete,
    defaultErrorMessage:
      'There was a problem registering your device for notifications.'
  })();

export const updateUserWithPushNotificationTokenComplete =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const { platform } = store.getState();
    if (!platform.error && !!platform.pushNotificationToken) {
      saveFirebaseTokenInStorage(platform.pushNotificationToken).then();
    }
  };

export const logPushNotification = (pushId: string) =>
  createApiRequest({
    route: PUSH_NOTIFICATIONS_LOG_URL,
    method: 'post',
    replace: ['uuid', pushId],
    onSuccess: logPushNotificationSuccess,
    onError: logPushNotificationFailure,
    defaultErrorMessage: 'There was a problem logging your notifications.'
  })();

export const fetchApiVersion = () =>
  createApiRequest({
    route: API_HEARTBEAT_URL,
    onSuccess: fetchApiVersionSuccess,
    defaultErrorMessage: 'There was a problem fetching the API heartbeat.'
  })();
