import { IonButton, IonIcon, IonModal } from '@ionic/react';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logo from 'assets/icons/HK-logo.svg';
import './TermsAndConditions.scss';
import { updateUserMe } from 'core/services/UserActions';
import { gaBtnClickEvent } from 'core/util';

const TermsAndConditions: React.FC = () => {
  const { id, termsAndConditions, impersonating } = useSelector(
    (state: RootState) => state.user
  );
  const { loggedIn } = useSelector((state: RootState) => state.login);

  const [isModalOpen, setIsModalOpen] = useState(true);
  const dispatch = useDispatch();

  const CompleteTermsAndConditions = () => {
    gaBtnClickEvent('complete_terms_and_conditions');
    const body = { terms_and_conditions: true };
    dispatch(updateUserMe(body));
    setIsModalOpen(false);
  };

  const openTermsOfUse = () => {
    window.open('https://homekeep.com/terms-of-use/', '_blank');
  };

  const openPrivacyPolicy = () => {
    window.open('https://homekeep.com/privacy-policy/', '_blank');
  };

  const termsSigned = () => {
    return (
      id !== null &&
      loggedIn === true &&
      impersonating === false &&
      termsAndConditions === null
    );
  };

  useEffect(() => {
    if (termsAndConditions !== null) {
      setIsModalOpen(false);
    }
  }, [termsAndConditions]);

  return termsSigned() ? (
    <IonModal
      className="hk-terms-and-conditions-modal"
      backdropDismiss={false}
      isOpen={isModalOpen}
      onDidDismiss={() => setIsModalOpen(false)}
    >
      <div className="hk-terms-and-conditions">
        <div className="hk-terms-and-conditions-header">
          <div className="hk-terms-and-conditions-header-title">
            <IonIcon
              icon={Logo}
              className="hk-terms-and-conditions-header-title-icon"
            />
            <div className="hk-terms-and-conditions-header-title-text">
              Privacy Updates
            </div>
            <div className="hk-terms-and-conditions-header-title-subtext">
              We've updated our Terms of Service and Privacy Policy. Please take
              a look at them using the links below.
            </div>
          </div>
        </div>
        <div className="hk-terms-and-conditions-body">
          <div className="hk-terms-and-conditions-body-text">
            By clicking "Continue" you agree to the HomeKeep{' '}
            <a color="primary" onClick={() => openTermsOfUse()}>
              Terms of Use
            </a>
            {' and '}
            <a color="primary" onClick={() => openPrivacyPolicy()}>
              Privacy Policy
            </a>
            .
          </div>
          <IonButton
            size="small"
            color="primary"
            onClick={() => CompleteTermsAndConditions()}
          >
            Continue
          </IonButton>
        </div>
      </div>
    </IonModal>
  ) : (
    <></>
  );
};

export default TermsAndConditions;
