import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPlatforms } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { RootState } from '../store';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema
} from '@capacitor/push-notifications';
import {
  HKNotificationType,
  Notification,
  setDeviceInfo,
  setIsDesktopWidth,
  setPlatformType,
  setVersion
} from './PlatformSlice';
import {
  appMinorVersionGreaterThanExpected,
  determinePlatformType,
  gaMobileScreenView,
  gaToggle,
  gaWebPageView,
  openInAppBrowserLink,
  validateOpenedURL
} from '../util';
import {
  AppUpdate,
  AppUpdateAvailability
} from '@capawesome/capacitor-app-update';
import { HKPlatform, firebaseConfig } from '../constants';
import { displayToast } from './AlertActions';
import { useLocation } from 'react-router';
import { logout } from 'features/shared/login/LoginSlice';
import { resetAppToInitialState } from 'features/shared/login/LoginActions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { logPushNotification } from './PlatformActions';

const PlatformListener: React.FC = ({ children }) => {
  const { pushNotificationToken, version, isDesktopWidth, platformType } =
    useSelector((state: RootState) => state.platform);
  const { globalAccountSettings } = useSelector(
    (state: RootState) => state.home
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { REACT_APP_VERSION } = process.env;
  let dispatch = useDispatch();
  let location = useLocation();
  let history = useHistory();

  firebase.initializeApp(firebaseConfig);

  const handlePushNotificationAction = (notification: Notification) => {
    const data = notification.data;

    if (data.PUSH_ID) {
      dispatch(logPushNotification(data.PUSH_ID));
    }

    if (data.hk_url) {
      openInAppBrowserLink(data.hk_url, platformType);
    }

    if (data.hk_path) {
      history.push(data.hk_path);
    } else {
      // TODO: Deprecate in favor of hk_path
      switch (data.hk_type) {
        case HKNotificationType.CatalogReminder:
          history.push(`/initial`);
          break;
        case HKNotificationType.AppointmentReminder:
          history.push(`/appointment/${data.hk_id}`);
          break;
        case HKNotificationType.DigitalCatalogReminder:
          history.push(`/dashboard`);
          break;
        case HKNotificationType.DigitalTaskReminder:
          history.push(`/catalog`);
          break;
        case HKNotificationType.Dashboard:
          history.push(`/dashboard`);
          break;
      }
    }
  };

  const getDeviceInfo = async () => {
    const info = await Device.getInfo();
    dispatch(setDeviceInfo(info));
  };

  // checks if app store update is available
  const appstoreUpdateAvailable = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
      history.push('/update');
    }
  };

  // checks if app minor version was updated, if yes then log out and clear cache
  const clearCacheIfAppWasUpdated = () => {
    if (appMinorVersionGreaterThanExpected(version!)) {
      gaToggle(globalAccountSettings?.impersonating === null);
      firebase.auth().signOut().then();
      dispatch(logout());
      dispatch(resetAppToInitialState());
      history.push(`/`);
    }
  };

  // track page views
  useEffect(() => {
    if (
      platformType === HKPlatform.ANDROID ||
      platformType === HKPlatform.IOS ||
      platformType === HKPlatform.CAPACITOR
    ) {
      gaMobileScreenView(isDigital, location.pathname);
    } else {
      gaWebPageView(isDigital, location.pathname);
    }
  }, [location]);

  // set the app display version
  useEffect(() => {
    if (!version) {
      // store app version in cache
      const parts = String(REACT_APP_VERSION).split('.');
      dispatch(setVersion(`v ${parts[0]}.${parts[1]}.${parts[2]}`));
    }
    clearCacheIfAppWasUpdated();
  }, [version]);

  // listen for magic link URLs
  useEffect(() => {
    App.addListener('appUrlOpen', (data: any) => {
      if (!!data.url) {
        const url = validateOpenedURL(data.url);
        if (!!url) {
          history.push(url);
        }
      }
    });
  }, [history]);

  // check appstore for updates
  useEffect(() => {
    if (
      platformType === HKPlatform.ANDROID ||
      platformType === HKPlatform.IOS ||
      platformType === HKPlatform.CAPACITOR
    ) {
      appstoreUpdateAvailable();
    }
  }, []);

  useEffect(() => {
    const platform = determinePlatformType(getPlatforms());
    if (platform !== HKPlatform.DESKTOP) {
      getDeviceInfo().then();
    }
    dispatch(setPlatformType(platform));
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    const handler = () => {
      dispatch(setIsDesktopWidth(mediaQuery.matches));
    };

    if (isDesktopWidth === null) {
      handler();
    }
    if (mediaQuery?.addEventListener) {
      mediaQuery.addEventListener('change', handler);
    } else {
      mediaQuery.addListener(handler);
    }
  }, []);

  useEffect(() => {
    if (
      Capacitor.isPluginAvailable('PushNotifications') &&
      !!pushNotificationToken
    ) {
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          const hkNotification = notification as Notification;
          // console.log(JSON.stringify(hkNotification));
          dispatch(displayToast(hkNotification.body, hkNotification.title));
        }
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          const hkNotification = notification.notification as Notification;
          // console.log(JSON.stringify(hkNotification));
          handlePushNotificationAction(hkNotification);
        }
      );
    }
  }, [pushNotificationToken]);

  return <>{children}</>;
};

export default PlatformListener;
