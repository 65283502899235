import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalMode } from './Account';

interface AccountState {
  modal_mode: number;
  cancelSubscriptionModalVisible: boolean;
  deleteAccountModalVisible: boolean;
  discardModalVisible: boolean;
  changeModalVisible: boolean;
}

export const initialState: AccountState = {
  modal_mode: 0,
  cancelSubscriptionModalVisible: false,
  deleteAccountModalVisible: false,
  discardModalVisible: false,
  changeModalVisible: false
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    showUserEdit: (state, action: PayloadAction<any>) => {
      state.modal_mode = action.payload;
    },
    hideUserEdit: (state) => {
      state.modal_mode = ModalMode.Hidden;
    },
    showCancelSubscriptionModal: (state) => {
      state.cancelSubscriptionModalVisible = true;
    },
    hideCancelSubscriptionModal: (state) => {
      state.cancelSubscriptionModalVisible = false;
    },
    showDeleteModal: (state) => {
      state.deleteAccountModalVisible = true;
    },
    hideDeleteModal: (state) => {
      state.deleteAccountModalVisible = false;
    },
    showDiscardModal: (state) => {
      state.discardModalVisible = true;
    },
    hideDiscardModal: (state) => {
      state.discardModalVisible = false;
    },
    showChangeModal: (state) => {
      state.changeModalVisible = true;
    },
    hideChangeModal: (state) => {
      state.changeModalVisible = false;
    }
  }
});

export const {
  showUserEdit,
  hideUserEdit,
  showCancelSubscriptionModal,
  hideCancelSubscriptionModal,
  showDeleteModal,
  hideDeleteModal,
  showDiscardModal,
  hideDiscardModal,
  showChangeModal,
  hideChangeModal
} = accountSlice.actions;

export default accountSlice.reducer;
