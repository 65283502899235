import React, { useRef, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar,
  IonicSlides
} from '@ionic/react';
import {
  arrowForward,
  chevronBackOutline,
  chevronForwardOutline
} from 'ionicons/icons';
import Illustration from 'components/Illustrations/Illustration';
import ScheduleIllustration from 'assets/illustrations/schedule.svg';
import CatalogIllustration from 'assets/illustrations/catalog.svg';
import RelaxIllustration from 'assets/illustrations/relax.svg';
import { gaBtnClickEvent, saveTutorialViewdInStorage } from 'core/util';
import './Tutorial.scss';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Swiper as SwiperInterface } from 'swiper';

const Tutorial: React.FC = () => {
  const [slide, setSlide] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  let history = useHistory();

  const nextSlide = (swiperRef: any) => {
    gaBtnClickEvent('next_tutorial_slide');
    swiperRef.slideNext();
  };

  const prevSlide = (swiperRef: any) => {
    gaBtnClickEvent('previous_tutorial_slide');
    swiperRef.slidePrev();
  };

  const onSlideChange = (slide: any) => {
    setSlide(slide.activeIndex);
  };

  const skipTutorial = () => {
    gaBtnClickEvent('skip_tutorial');
    history.push(`/initial`);
  };

  const getStarted = () => {
    gaBtnClickEvent('get_started_tutorial');
    history.push(`/initial`);
  };

  useEffect(() => {
    saveTutorialViewdInStorage().then();
  }, []);

  const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return (
      <div className="hk-tutorial-buttons">
        <IonButton
          disabled={slide === 0}
          color="medium"
          className="hk-tutorial-buttons-back"
          onClick={() => prevSlide(swiper)}
        >
          <IonIcon icon={chevronBackOutline} />
          Back
        </IonButton>
        {slide < 2 && (
          <IonButton
            className="hk-tutorial-buttons-next"
            onClick={() => nextSlide(swiper)}
          >
            Next <IonIcon icon={chevronForwardOutline} />
          </IonButton>
        )}
        {slide === 2 && (
          <IonButton
            onClick={() => history.push('/initial')}
            className="hk-tutorial-buttons-next"
          >
            Next
            <IonIcon icon={chevronForwardOutline} />
          </IonButton>
        )}
      </div>
    );
  };

  const MobileSwiperButtonNext = () => {
    const swiper = useSwiper();
    return (
      <IonFab vertical="bottom" horizontal="end">
        {slide < 2 && (
          <IonFabButton onClick={() => nextSlide(swiper)}>
            <IonIcon icon={arrowForward} />
          </IonFabButton>
        )}
        {slide === 2 && (
          <IonButton strong={true} onClick={() => getStarted()}>
            Get Started
          </IonButton>
        )}
      </IonFab>
    );
  };

  function DesktopTutorialView() {
    return (
      <IonPage>
        <IonToolbar className="hk-desktop-tutorial-header">
          <IonButtons slot="end">
            <IonButton color="medium" onClick={skipTutorial}>
              skip
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonContent className="hk-desktop-tutorial ion-padding">
          <Swiper
            className="swiper-container"
            onSwiper={(swiper: any) => setSwiperInstance(swiper)}
            modules={[IonicSlides]}
            onSlideChange={(slide: any) => onSlideChange(slide)}
            pagination={false}
            allowTouchMove={false}
            scrollbar={true}
            initialSlide={0}
            speed={400}
          >
            <SwiperSlide>
              <Illustration image={ScheduleIllustration} />
              <section>
                <h2>Schedule your first appointment</h2>
                <p>
                  Pick the time and day that works best for a HomeKeep rep to
                  meet you at your home.
                </p>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <Illustration image={CatalogIllustration} />
              <section>
                <h2>Get your home inventoried</h2>
                <p>
                  Have your home products cataloged in the app and registered
                  under warranty.
                </p>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <Illustration image={RelaxIllustration} />
              <section>
                <h2>Put your home maintenance on autopilot</h2>
                <p>
                  Make yourself at home while we take care of your maintenance
                  needs year-round.
                </p>
              </section>
            </SwiperSlide>
            <SwiperButtonNext />
          </Swiper>
        </IonContent>
      </IonPage>
    );
  }

  function MobileTutorialView() {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar className="tutorial-toolbar">
            {slide > 0 && (
              <IonButtons slot="start">
                <IonButton
                  color="medium"
                  onClick={() => prevSlide(swiperInstance)}
                >
                  <IonIcon icon={chevronBackOutline} />
                </IonButton>
              </IonButtons>
            )}
            <IonButtons slot="end">
              <IonButton color="medium" onClick={skipTutorial}>
                skip
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          fullscreen
          className="hk-tutorial ion-padding"
          scroll-y="false"
        >
          <Swiper
            className="swiper-container"
            onSwiper={(swiper: any) => setSwiperInstance(swiper)}
            modules={[IonicSlides]}
            onSlideChange={(slide: any) => onSlideChange(slide)}
            pagination={false}
            allowTouchMove={false}
            scrollbar={true}
            initialSlide={0}
            speed={400}
          >
            <SwiperSlide>
              <Illustration image={ScheduleIllustration} />
              <section>
                <h2>Schedule your first appointment</h2>
                <p>
                  Pick the time and day that works best for a HomeKeep rep to
                  meet you at your home.
                </p>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <Illustration image={CatalogIllustration} />
              <section>
                <h2>Get your home inventoried</h2>
                <p>
                  Have your home products cataloged in the app and registered
                  under warranty.
                </p>
              </section>
            </SwiperSlide>
            <SwiperSlide>
              <Illustration image={RelaxIllustration} />
              <section>
                <h2>Put your home maintenance on autopilot</h2>
                <p>
                  Make yourself at home while we take care of your maintenance
                  needs year-round.
                </p>
              </section>
            </SwiperSlide>
            <MobileSwiperButtonNext />
          </Swiper>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopTutorialView()
        : MobileTutorialView()}
    </>
  );
};

export default Tutorial;
