import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  hideAppointmentModal,
  setAppointmentNote,
  setSlideNum
} from './AppointmentWizardSlice';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonSpinner,
  IonText,
  IonTextarea,
  IonToolbar,
  IonicSlides
} from '@ionic/react';
import Illustration from 'components/Illustrations/Illustration';
import CalendarIllustration from 'assets/illustrations/calendar.svg';
import RocketIllustration from 'assets/illustrations/rocket.svg';
import {
  confirmAppointmentProposal,
  confirmRescheduleProposal
} from './AppointmentWizardActions';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import {
  DATE_FORMAT_WEEKDAY_DATE,
  HKPlatform,
  HKScheduleType
} from 'core/constants';
import { formatTimezoneDate, gaBtnClickEvent, gaModalView } from 'core/util';
import AppointmentProposals from './proposals/AppointmentProposals';
import LeftArrow from 'assets/icons/arrow-left.svg';
import Close from 'assets/icons/close.svg';
import './AppointmentWizard.scss';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperInterface } from 'swiper';

const AppointmentWizard: React.FC = () => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperInterface>();
  const { loading } = useSelector((state: RootState) => state.loading);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { detailedAppointment } = useSelector(
    (state: RootState) => state.appointment
  );
  const {
    scheduleHomeId,
    proposalHash,
    appointmentNote,
    appointment,
    scheduleType,
    upcomingVisitHash,
    appointmentId,
    slideNum
  } = useSelector((state: RootState) => state.appointmentWizard);
  const contentRef = useRef<HTMLIonContentElement>(null);

  const { isOpen } = useKeyboardState();
  const dispatch = useDispatch();

  const nextSlide = (swiperRef: any) => {
    gaBtnClickEvent('next_appointment_slide');
    swiperRef.slideNext();
  };

  const prevSlide = (swiperRef: any) => {
    gaBtnClickEvent('previous_appointment_slide');
    swiperRef.slidePrev();
  };

  const onSlideChange = (slideIndex: any) => {
    dispatch(setSlideNum(slideIndex));
    scrollToTop();
  };

  const scrollToTop = () => {
    contentRef.current?.scrollToTop();
  };

  const addNote = (note: string) => {
    dispatch(setAppointmentNote(note));
  };

  const confirm = () => {
    const body = {
      schedule_hash: proposalHash,
      notes: appointmentNote
    };
    if (scheduleType === HKScheduleType.NEW && !!upcomingVisitHash) {
      gaBtnClickEvent('confirm_appointment');
      dispatch(
        confirmAppointmentProposal(scheduleHomeId, upcomingVisitHash, body)
      );
    }
    if (scheduleType === HKScheduleType.RESCHEDULE && !!appointmentId) {
      gaBtnClickEvent('confirm_rescheduled_appointment');
      dispatch(confirmRescheduleProposal(scheduleHomeId, appointmentId, body));
    }
  };

  useEffect(() => {
    if (!!appointment) {
      nextSlide(swiperInstance);
    }
  }, [appointment]);

  useEffect(() => {
    gaModalView(isDigital, '/appointment/wizard');
  }, []);

  function DesktopSlideCalendarView() {
    return (
      <>
        {slideNum === 0 && (
          <AppointmentProposals
            onProposalSelected={() => nextSlide(swiperInstance)}
          />
        )}
      </>
    );
  }

  function MobileSlideCalendarView() {
    return (
      <>
        <Illustration image={CalendarIllustration} />
        <h2>
          {scheduleType === HKScheduleType.NEW
            ? 'What days work best for you?'
            : 'Need to reschedule?'}
        </h2>
        <h6>
          {scheduleType === HKScheduleType.NEW
            ? 'You may select up to 3 dates below to view available appointment times for those days.'
            : 'You can reschedule your appointment to one of the available times below.'}
        </h6>
        {scheduleType === HKScheduleType.RESCHEDULE && (
          <h6 className="hk-appointment-wizard-current">
            Currently scheduled for:
            <IonText color="black" className="ion-text-">
              <b>
                {formatTimezoneDate(
                  detailedAppointment?.scheduled || '',
                  DATE_FORMAT_WEEKDAY_DATE,
                  currentHome?.market_timezone
                )}
              </b>
            </IonText>
          </h6>
        )}
        {slideNum === 0 && (
          <AppointmentProposals
            onProposalSelected={() => nextSlide(swiperInstance)}
          />
        )}
      </>
    );
  }

  return (
    <IonPage>
      <IonContent
        className={`hk-appointment-wizard ion-padding ${
          slideNum === 0 &&
          platformType === HKPlatform.DESKTOP &&
          isDesktopWidth
            ? 'hk-desktop-appointment-wizard'
            : ''
        }`}
        ref={contentRef}
        fullscreen
        scrollY={true}
      >
        <Swiper
          className="swiper-container"
          onSwiper={(swiper: any) => setSwiperInstance(swiper)}
          modules={[IonicSlides]}
          onSlideChange={(slide: any) => onSlideChange(slide.activeIndex)}
          pagination={false}
          allowTouchMove={false}
          scrollbar={true}
          initialSlide={0}
          speed={400}
        >
          <SwiperSlide
            className={`${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? 'hk-desktop-slide-calendar'
                : ''
            }`}
          >
            {platformType === HKPlatform.DESKTOP && isDesktopWidth
              ? DesktopSlideCalendarView()
              : MobileSlideCalendarView()}
          </SwiperSlide>
          <SwiperSlide
            className={`${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? 'hk-desktop-slide-notes'
                : ''
            }`}
          >
            <div className="hk-slide-notes-container">
              <Illustration image={CalendarIllustration} />
              <h2>Any entry-access details we should know?</h2>
              <h6>
                Please provide any important home access details (garage code,
                gate access number, parking information, pets on the property,
                etc.
              </h6>
              <IonTextarea
                className="hk-optional-notes"
                disabled={loading}
                placeholder="Leave an optional note..."
                value={appointmentNote}
                rows={4}
                autoGrow={true}
                onIonChange={(e) => addNote(e.detail.value!)}
              ></IonTextarea>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className={`${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? 'hk-desktop-slide-confirmation'
                : ''
            }`}
          >
            <div className="hk-slide-confirmation-container">
              <Illustration image={CalendarIllustration} />
              <h2>You're all set!</h2>
              <h6>
                We’ll send you a reminder prior to your appointment. If you need
                to make changes to your appointment, you can do so on the
                dashboard.
              </h6>
              <Illustration
                image={RocketIllustration}
                className={`ion-margin-vertical ${
                  platformType === HKPlatform.DESKTOP && isDesktopWidth
                    ? 'hk-desktop-slide-confirmation-image'
                    : 'hk-slide-confirmation-image'
                }`}
              />
            </div>
          </SwiperSlide>
        </Swiper>
        {slideNum > 0 && slideNum < 2 && (
          <IonFab vertical="top" horizontal="start">
            <IonFabButton
              color="white"
              className="hk-fab-button ion-margin-top"
              onClick={() => prevSlide(swiperInstance)}
            >
              <IonIcon color="medium" icon={LeftArrow} />
            </IonFabButton>
          </IonFab>
        )}
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            color="background"
            className={`hk-fab-button ${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? ''
                : 'hk-fab-button-margin-top'
            }`}
            onClick={() => dispatch(hideAppointmentModal())}
          >
            <IonIcon color="medium" icon={Close} />
          </IonFabButton>
        </IonFab>
      </IonContent>
      {slideNum === 1 && isOpen === false && (
        <IonToolbar
          className={`hk-appointment-wizard-toolbar ion-padding ${
            platformType === HKPlatform.DESKTOP && isDesktopWidth
              ? 'hk-desktop-slide-toolbar'
              : ''
          }`}
        >
          <IonButton
            disabled={loading}
            expand="block"
            color="primary"
            strong={true}
            className="ion-margin"
            onClick={confirm}
          >
            {loading ? (
              <IonSpinner name="dots" />
            ) : scheduleType === HKScheduleType.NEW ? (
              'Confirm Appointment'
            ) : (
              'Reschedule Appointment'
            )}
          </IonButton>
        </IonToolbar>
      )}
      {slideNum === 2 && (
        <IonToolbar
          className={`hk-appointment-wizard-toolbar ion-padding ${
            platformType === HKPlatform.DESKTOP && isDesktopWidth
              ? 'hk-desktop-slide-toolbar'
              : ''
          }`}
        >
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            className="ion-margin"
            onClick={() => dispatch(hideAppointmentModal())}
          >
            {scheduleType === HKScheduleType.NEW
              ? 'View Dashboard'
              : 'View Appointment'}
          </IonButton>
        </IonToolbar>
      )}
    </IonPage>
  );
};

export default AppointmentWizard;
