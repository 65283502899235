import {
  IonButton,
  IonContent,
  IonIcon,
  IonRow,
  IonToggle
} from '@ionic/react';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DigitalDashboardSettings.scss';
import Close from 'assets/icons/close.svg';
import {
  fetchProductTypeToggles,
  patchProductTypeToggle
} from 'features/digital/store/DigitalTasksActions';
import { hideProductTypeTogglesModal } from 'features/digital/store/DigitalTasksSlice';
import SearchBar, { filterItems } from 'components/searchbar/SearchBar';
import LoadingIonList from 'core/layout/LoadingIonList';
import DigitalDashboardSettingsSkeletonLoader from './DigitalDashboardSettingsSkeletonLoader';

const DigitalDashboardSettings: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { loading, productTypeToggles } = useSelector(
    (state: RootState) => state.digitalTasks
  );

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const sortedProductTypes = () => {
    const productTypesList = [...productTypeToggles];
    return productTypesList.sort((a, b) => a.label!.localeCompare(b.label!));
  };

  const searchedProductTypes = filterItems(
    sortedProductTypes() || [],
    ['label'],
    search
  );

  const generateListOfProductGroups = () => {
    let listOfGroups: any[] = [];
    let uniqueListOfGroups = new Set();
    if (!!searchedProductTypes) {
      searchedProductTypes.forEach((product: any) => {
        const group = product.group_name;
        uniqueListOfGroups.add(group);
      });
    }
    listOfGroups = Array.from(uniqueListOfGroups).filter((group) => {
      return group !== undefined;
    });
    return listOfGroups.sort((a, b) => a!.localeCompare(b!));
  };

  const filteredProductsTypes = generateListOfProductGroups();

  const toggleProductType = (id: number, checked: any) => {
    const body = {
      current_state: checked
    };
    if (!!homeId) {
      dispatch(patchProductTypeToggle(homeId, id, body));
    }
  };

  useEffect(() => {
    if (!!homeId) {
      dispatch(fetchProductTypeToggles(homeId));
    }
  }, [homeId]);

  function SearchedProductTypeTogglesView() {
    return (
      <>
        {searchedProductTypes.length ? (
          <>
            {filteredProductsTypes.map((groupType: any, i: any) => {
              return (
                <div
                  className="hk-digital-settings-toggles"
                  key={'group-type-' + i}
                >
                  <h4 className="hk-digital-settings-toggles-group-name">
                    {groupType}
                  </h4>
                  {searchedProductTypes!.map(
                    (searchedProduct: any, i: number) => {
                      const { id, current_state, group_name, label } =
                        searchedProduct;
                      return (
                        <div key={'product-type-' + i}>
                          {group_name === groupType && (
                            <div className="hk-digital-settings-toggles-product">
                              <div className="hk-digital-settings-toggles-product-label">
                                {label}
                              </div>
                              <IonToggle
                                checked={current_state}
                                onIonChange={(e) =>
                                  toggleProductType(id, e.detail.checked)
                                }
                                className="hk-digital-settings-toggles-product-toggle"
                              ></IonToggle>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <div className="hk-digital-settings-search-empty">
            <h1>No Matches Found</h1>
            <p>
              The thing you are searching for isn't here. Please try another
              search.
            </p>
          </div>
        )}
      </>
    );
  }

  function MobileDigitalSettingsView() {
    return (
      <>
        <IonContent className="hk-digital-settings">
          <IonRow className="hk-digital-settings-header">
            <div className="hk-digital-settings-header-title">
              <span>Task Settings</span>
              <IonButton
                color="#1D202B"
                onClick={() => dispatch(hideProductTypeTogglesModal())}
              >
                <IonIcon color="white" icon={Close} />
              </IonButton>
            </div>
            {!loading && (
              <div className="hk-digital-settings-header-search">
                <SearchBar
                  value={search}
                  onSearchChange={(e) => setSearch(e)}
                ></SearchBar>
                <p>
                  Toggles turn on or off all type of tasks that are available to
                  you for that section. These tasks display in the monthly
                  sections.
                </p>
              </div>
            )}
          </IonRow>
          {loading ? (
            <DigitalDashboardSettingsSkeletonLoader />
          ) : (
            <>
              <LoadingIonList
                label="Product Types"
                className="hk-digital-settings-list"
                loading={loading}
                items={productTypeToggles}
              >
                <SearchedProductTypeTogglesView />
              </LoadingIonList>
            </>
          )}
        </IonContent>
      </>
    );
  }

  return <>{MobileDigitalSettingsView()}</>;
};

export default DigitalDashboardSettings;
