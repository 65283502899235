import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { gaBtnClickEvent, gaModalView } from 'core/util';
import Close from 'assets/icons/close.svg';
import './ChangeAccount.scss';
import { hideChangeModal } from './AccountSlice';

const ChangeAccount: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const onCall = () => {
    gaBtnClickEvent('call_concierge_change_account_modal');
    dispatch(hideChangeModal());
    window.open(`tel:608-719-4899`);
  };

  const cancelChange = () => {
    gaBtnClickEvent('cancel_change_account_modal');
    dispatch(hideChangeModal());
  };

  useEffect(() => {
    gaModalView(isDigital, '/account/change');
  }, []);

  function DesktopChangeAccountView() {
    return (
      <>
        <IonContent color="white" className="ion-padding">
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={cancelChange}
            >
              <IonIcon icon={Close} />
            </IonFabButton>
          </IonFab>
          <div className="hk-desktop-change-account-body">
            <h3>Confirm Plan Change</h3>
            <p>Are you sure you want to change your plan?</p>
            <p>
              To help you decide, check out our plan comparison at{' '}
              <a href="https://homekeep.com/plans">homekeep.com/plans</a>. Know
              that switching between Full Service and Digital will impact any
              appointments and home products such as filters, softener salt,
              batteries, etc.
            </p>
            <p>
              When you are ready to change your plan, contact our Concierge Team
              and they will help you.
            </p>
            <span className="call-concierge">
              Call our Concierge: 608-719-4899
            </span>
          </div>
        </IonContent>
        <IonToolbar className="hk-desktop-change-account-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            onClick={cancelChange}
          >
            Cancel
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  function MobileChangeAccountView() {
    return (
      <>
        <IonContent fullscreen color="white" className="ion-padding">
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={cancelChange}
            >
              <IonIcon icon={Close} />
            </IonFabButton>
          </IonFab>
          <div className="hk-change-account-body">
            <h3>Confirm Plan Change</h3>
            <p>Are you sure you want to change your plan?</p>
            <p>
              To help you decide, check out our plan comparison at{' '}
              <a href="https://homekeep.com/plans">homekeep.com/plans</a>. Know
              that switching between Full Service and Digital will impact any
              appointments and home products such as filters, softener salt,
              batteries, etc.
            </p>
            <p>
              When you are ready to change your plan, contact our Concierge Team
              and they will help you.
            </p>
          </div>
        </IonContent>
        <IonToolbar className="hk-change-account-toolbar ion-padding">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            onClick={cancelChange}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={onCall}
          >
            Call Concierge
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopChangeAccountView()
        : MobileChangeAccountView()}
    </IonPage>
  );
};

export default ChangeAccount;
