import React, { useEffect } from 'react';
import { IonFab, IonFabButton, IonIcon, IonRouterOutlet } from '@ionic/react';
import SideMenu from './side-menu/SideMenu';
import Chat from '../../assets/icons/chat.svg';
import { openConcierge } from 'core/util';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { fetchZendeskToken } from 'core/services/UserActions';
import { ZENDESK_CHAT_URL } from 'core/constants';

declare global {
  interface Window {
    zE: any;
  }
}

type MenuLayoutProps = {
  showHelp?: boolean;
};

const MenuLayout: React.FC<MenuLayoutProps> = ({
  showHelp = true,
  children
}) => {
  const { isDesktopWidth } = useSelector((state: RootState) => state.platform);

  useEffect(() => {
    if (showHelp && window.zE) {
      window.zE('messenger', 'show');
    } else if (!showHelp && window.zE) {
      window.zE('messenger', 'hide');
    }
  }, []);

  return !isDesktopWidth ? (
    <>
      <SideMenu />
      <IonRouterOutlet id="main-content">{children}</IonRouterOutlet>
    </>
  ) : (
    <IonRouterOutlet id="main-content">{children}</IonRouterOutlet>
  );
};

export default MenuLayout;
