import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RootState } from 'core/store';
import { IonButton, IonContent, IonPage } from '@ionic/react';
import Illustration from 'components/Illustrations/Illustration';
import ConfirmationIllustration from 'assets/illustrations/billing-confirmation.svg';
import './Confirmation.scss';
import { HKPlatform } from 'core/constants';
import { gaBtnClickEvent } from 'core/util';

const Confirmation: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const history = useHistory();

  const redirectToDashboardPage = () => {
    gaBtnClickEvent('get_started');
    history.push(`/dashboard`);
  };

  function MobileConfirmationView() {
    return (
      <IonContent color="white" fullscreen>
        <div className="hk-billing-confirmation-content">
          <Illustration image={ConfirmationIllustration} />
          <h2>Thank You</h2>
          <p>
            A receipt has been sent to your email. Your subscription is now
            active. We hope you enjoy your premium experience.
          </p>
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            className=""
            onClick={redirectToDashboardPage}
          >
            Get Started
          </IonButton>
        </div>
      </IonContent>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-confirmation-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? MobileConfirmationView()
        : MobileConfirmationView()}
    </IonPage>
  );
};

export default Confirmation;
