import React from 'react';
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { useHistory } from 'react-router';
import { chevronBackOutline } from 'ionicons/icons';

type BackButtonHeaderProps = {
  title?: string;
  color?: string;
  url?: string;
};

const BackButtonHeader: React.FC<BackButtonHeaderProps> = ({
  title = '',
  color = 'grey',
  url = '',
  children,
  ...rest
}) => {
  const history = useHistory();
  const goBack = () => {
    if (url) {
      history.push(url);
    } else {
      history.goBack();
    }
  };
  const css = `
        .hk-toolbar-title {
          font-size: 14px!important;
          font-weight: bold!important;
        }`;
  return (
    <IonHeader {...rest}>
      <style>{css}</style>
      <IonToolbar color={color}>
        <IonButtons slot="start">
          <IonButton onClick={() => goBack()}>
            <IonIcon color="light" icon={chevronBackOutline} />
          </IonButton>
        </IonButtons>
        <IonTitle className="hk-toolbar-title">{title}</IonTitle>
        {children}
      </IonToolbar>
    </IonHeader>
  );
};

export default BackButtonHeader;
