import React from 'react';
import './Illustration.scss';

type IllustrationProps = {
  image: string;
  description?: string;
  className?: string;
  size?: string;
};

const Illustration: React.FC<IllustrationProps> = ({
  image,
  className = '',
  description,
  size,
  ...rest
}) => {
  return (
    <div className={`hk-illustration ${className}`} {...rest}>
      <img src={image} alt={description || 'Illustration'} width={size} />
    </div>
  );
};

export default Illustration;
