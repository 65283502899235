import {
  IonButton,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonSpinner,
  IonText,
  IonToolbar,
  useIonAlert,
  useIonPicker
} from '@ionic/react';
import {
  CONTACT_TYPE_LIST,
  EMAIL_REGEX,
  HKPlatform,
  HOMEKEEP_CONTACT,
  HOMEKEEP_CONTACT_ID,
  PHONE_REGEX
} from 'core/constants';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import { RootState } from 'core/store';
import {
  deleteContact,
  fetchContact,
  updateContact
} from 'features/shared/contacts/ContactsActions';
import {
  contactOwnedByUser,
  fetchContactSuccess,
  resetCurrentContact
} from 'features/shared/contacts/ContactsSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import './ContactDetails.scss';
import { ellipsisHorizontal } from 'ionicons/icons';
import { Controller, useForm } from 'react-hook-form';
import { displaySuccess } from 'core/services/AlertActions';
import { NewContactForm } from './NewContact';
import {
  formatMobilePhoneNumber,
  gaBtnClickEvent,
  gaModalView,
  validateURL
} from 'core/util';
import ErrorIcon from 'assets/icons/overdue.svg';
import DownArrow from 'assets/icons/arrow-down.svg';

interface ContactParams {
  id?: string | undefined;
}

interface Section {
  label: string;
  id: string;
  values: any[];
}

// This view is Mobile only
const ContactDetails: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { loading, currentContact } = useSelector(
    (state: RootState) => state.contacts
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const [sections, setSections] = useState<Section[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedContactType, setSelectedContactType] = useState<string>('');
  const [selectedContactTypeLabel, setSelectedContactTypeLabel] =
    useState<any>();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    reset,
    formState,
    errors
  } = useForm<NewContactForm>({
    defaultValues: {
      name: '',
      email: '',
      mobile_phone: '',
      type: '',
      url: ''
    },
    mode: 'onChange'
  });
  let { id } = useParams<ContactParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();
  const [present] = useIonPicker();

  const getContactTypeLabel = (contactType: string) => {
    const label = CONTACT_TYPE_LIST.find(
      (contact) => contact.type === contactType
    )?.name;
    return !!label ? label : contactType;
  };

  const openPicker = async () => {
    present({
      cssClass: 'hk-picker',
      mode: 'ios',
      columns: [
        {
          name: 'contactTypes',
          options: CONTACT_TYPE_LIST.map((contact: any, i: number) => {
            return {
              text: `${contact.name}`,
              value: contact.type
            };
          })
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: (value) => {
            setSelectedContactTypeLabel(value.contactTypes.text);
            setSelectedContactType(value.contactTypes.value);
          }
        }
      ]
    });
  };

  const callContact = () => {
    gaBtnClickEvent('call_contact');
    window.open(`tel:${currentContact?.mobile_phone}`);
  };

  const saveContact = (formData: any) => {
    gaBtnClickEvent('update_contact');
    formData['type'] = selectedContactType;
    if (!!homeId && !!formData) {
      if (!!formData['url']) {
        formData['url'] = validateURL(formData['url']);
      }
      dispatch(updateContact(homeId, Number(id), formData))
        .then(() => {
          dispatch(displaySuccess('Contact Updated', 'Success'));
          dispatch(fetchContact(homeId, Number(id)));
          setIsEditMode(false);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const removeContact = () => {
    gaBtnClickEvent('delete_contact');
    if (!!homeId) {
      dispatch(deleteContact(homeId, Number(id)))
        .then(() => {
          dispatch(displaySuccess('Contact Deleted', 'Success'));
          setIsEditMode(false);
          history.goBack();
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const formRule = (id: string) => {
    if (id === 'mobile_phone') {
      return {
        pattern: {
          value: PHONE_REGEX,
          message: 'Invalid phone number'
        }
      };
    }
    if (id === 'email') {
      return {
        pattern: {
          value: EMAIL_REGEX,
          message: 'Invalid email address'
        }
      };
    }
    if (id === 'type' || id === 'name') {
      return {
        required: true
      };
    }
    return { required: false };
  };

  const formatLink = (id: string, value: string) => {
    switch (id) {
      case 'url':
        return validateURL(value);
      case 'email':
        return `mailto:${value}`;
      case 'mobile_phone':
        return `tel:${currentContact?.mobile_phone}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    if (!!currentContact) {
      setSections([
        {
          label: 'Phone Number',
          id: 'mobile_phone',
          values: [
            !!currentContact.mobile_phone
              ? formatMobilePhoneNumber(currentContact.mobile_phone!)
              : 'None'
          ]
        },
        {
          label: 'Email',
          id: 'email',
          values: [!!currentContact.email ? currentContact.email : 'None']
        },
        {
          label: 'Type',
          id: 'type',
          values: [getContactTypeLabel(currentContact.type!)]
        },
        {
          label: 'Website',
          id: 'url',
          values: [!!currentContact.url ? currentContact.url : 'None']
        }
      ]);
      setSelectedContactType(currentContact.type!);
      setSelectedContactTypeLabel(getContactTypeLabel(currentContact.type!));
      reset({
        name: currentContact.name,
        email: currentContact.email!,
        mobile_phone: formatMobilePhoneNumber(currentContact.mobile_phone!)!,
        type: currentContact.type,
        url: currentContact.url!
      });
    }
  }, [currentContact]);

  useEffect(() => {
    gaModalView(isDigital, '/contact/details');
    const contactId = Number(id);
    if (!!homeId && !!contactId) {
      if (contactId === HOMEKEEP_CONTACT_ID) {
        dispatch(fetchContactSuccess(HOMEKEEP_CONTACT));
      } else {
        dispatch(fetchContact(homeId, contactId));
      }
    }
  }, [dispatch, homeId]);

  // component Unmount (cleanup)
  useEffect(() => {
    return () => {
      dispatch(resetCurrentContact());
    };
  }, []);

  function MobileContactsView() {
    return (
      <>
        <BackButtonHeader color={'dark'}>
          {currentContact?.id !== HOMEKEEP_CONTACT_ID &&
            contactOwnedByUser(homeId!, currentContact?.home_ids!) && (
              <div className="hk-digital-contact-details-actions">
                <IonIcon
                  icon={ellipsisHorizontal}
                  id="contact-actions"
                ></IonIcon>
                <IonPopover
                  trigger="contact-actions"
                  triggerAction="click"
                  dismissOnSelect={true}
                >
                  <IonList lines="none">
                    <IonItem
                      className="hk-digital-contact-details-actions-button"
                      button={true}
                      detail={false}
                      onClick={() => setIsEditMode(true)}
                    >
                      Edit
                    </IonItem>
                    <IonItem
                      className="hk-digital-contact-details-actions-button"
                      button={true}
                      detail={false}
                      onClick={() =>
                        presentAlert({
                          header: 'Confirm Delete Contact',
                          buttons: [
                            {
                              text: 'Cancel',
                              role: 'cancel',
                              handler: () => {}
                            },
                            {
                              text: 'Delete',
                              cssClass:
                                'hk-digital-contact-details-actions-button-delete',
                              role: 'destructive',
                              handler: () => {
                                removeContact();
                              }
                            }
                          ]
                        })
                      }
                    >
                      Delete
                    </IonItem>
                  </IonList>
                </IonPopover>
              </div>
            )}
        </BackButtonHeader>
        <IonContent className="hk-digital-contact-details">
          {loading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <form
                className="hk-digital-contact-details-edit-form"
                onSubmit={handleSubmit(saveContact)}
              >
                <div className="hk-digital-contact-details-header">
                  <div className="hk-digital-contact-details-header-avatar">
                    {currentContact?.name!.charAt(0)}
                  </div>
                  {!isEditMode && <IonText>{currentContact?.name}</IonText>}
                  {isEditMode && (
                    <input
                      className="hk-digital-contact-details-edit"
                      name={'name'}
                      ref={register(formRule('name'))}
                      defaultValue={getValues('name')}
                      style={{
                        color: errors['name'] ? '#ff6b69' : '#fff',
                        borderBottom: errors['name'] ? '1px solid #ff6b69' : ''
                      }}
                      type="text"
                    />
                  )}
                </div>
                <IonList className="ion-margin-vertical">
                  {sections.map((field, i) => {
                    const { label, id, values } = field;
                    return (
                      <div
                        key={`item-${i}`}
                        className="hk-digital-contact-details-item ion-margin-horizontal"
                      >
                        <IonLabel className="hk-digital-contact-details-label ion-text-nowrap">
                          <IonText>{label}</IonText>
                        </IonLabel>
                        {!isEditMode &&
                          values.map((val, j) => {
                            return !!val ? (
                              <IonText
                                key={`val-${i}-${j}`}
                                className="hk-digital-contact-details-value ion-text-nowrap"
                              >
                                {id === 'url' ||
                                id === 'email' ||
                                id === 'mobile_phone' ? (
                                  <>
                                    {val === 'None' ? (
                                      val
                                    ) : (
                                      <a
                                        href={formatLink(id, val)}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        {val}
                                      </a>
                                    )}
                                  </>
                                ) : (
                                  val
                                )}
                              </IonText>
                            ) : null;
                          })}
                        {isEditMode &&
                          values.map((val, j) => {
                            return (
                              <div key={`item-edit-${i}`}>
                                {label !== 'Type' ? (
                                  <>
                                    {label === 'Phone Number' ? (
                                      <Controller
                                        key={`controller-phone-number`}
                                        control={control}
                                        name={id}
                                        rules={formRule(id)}
                                        render={({
                                          onChange,
                                          onBlur,
                                          value
                                        }) => (
                                          <>
                                            <input
                                              style={{
                                                color: errors[id]
                                                  ? '#ff6b69'
                                                  : 'white'
                                              }}
                                              className="hk-digital-contact-details-edit"
                                              type="text"
                                              onChange={(e) => {
                                                onChange(
                                                  formatMobilePhoneNumber(
                                                    e.target.value
                                                  )
                                                );
                                              }}
                                              value={value || ''}
                                            />
                                            {!!errors[id] && (
                                              <span className="invalid-input-error">
                                                Invalid phone number
                                                <IonIcon src={ErrorIcon} />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      ></Controller>
                                    ) : (
                                      <>
                                        <input
                                          name={id}
                                          ref={register(formRule(id))}
                                          defaultValue={getValues(id)}
                                          style={{
                                            color: errors[id]
                                              ? '#ff6b69'
                                              : 'white'
                                          }}
                                          className="hk-digital-contact-details-edit"
                                          type="text"
                                        />
                                        {!!errors['email'] &&
                                          id === 'email' && (
                                            <span className="invalid-input-error">
                                              Invalid email
                                              <IonIcon src={ErrorIcon} />
                                            </span>
                                          )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="hk-digital-contact-details-edit-form-picker">
                                      <IonInput
                                        readonly
                                        className="hk-digital-contact-details-edit-form-picker-input ion-margin-bottom"
                                        onClick={openPicker}
                                        value={selectedContactTypeLabel}
                                        type="text"
                                      ></IonInput>
                                      <IonIcon
                                        className="hk-digital-contact-details-edit-form-picker-input-icon"
                                        icon={DownArrow}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </IonList>
              </form>
            </>
          )}
        </IonContent>
        <IonToolbar color="dark" className="hk-digital-contact-details-toolbar">
          {!isEditMode && (
            <IonButton
              disabled={!currentContact?.mobile_phone}
              expand="block"
              color="primary"
              strong={true}
              className="ion-margin"
              onClick={() => callContact()}
            >
              {loading ? <IonSpinner name="dots" /> : 'Call'}
            </IonButton>
          )}
          {isEditMode && (
            <IonButton
              expand="block"
              color="primary"
              strong={true}
              className="ion-margin"
              disabled={!formState.isValid || loading}
              onClick={handleSubmit(saveContact)}
            >
              {loading ? <IonSpinner name="dots" /> : 'Save'}
            </IonButton>
          )}
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? MobileContactsView()
        : MobileContactsView()}
    </IonPage>
  );
};

export default ContactDetails;
