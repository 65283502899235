import React from 'react';
import { IonContent } from '@ionic/react';
import './DigitalDashboardSettingsSkeletonLoader.scss';

const DigitalDashboardSettingsSkeletonLoader: React.FC = () => {
  function MobileSettingsSkeletonLoaderView() {
    return (
      <IonContent className="digital-dashboard-settings-skeleton" fullscreen>
        <div className="digital-dashboard-settings-skeleton-header">
          <div
            className="hk-skeleton-rectangle"
            style={{ height: '46px' }}
          ></div>
          <div className="hk-skeleton-oval" style={{ marginTop: '24px' }}></div>
          <div
            className="hk-skeleton-oval"
            style={{ marginTop: '8px', width: '90%' }}
          ></div>
          <div
            className="hk-skeleton-oval"
            style={{ marginTop: '8px', width: '40%' }}
          ></div>
        </div>
        <div className="digital-dashboard-settings-skeleton-body">
          <div
            className="hk-skeleton-oval"
            style={{ height: '24px', width: '50%' }}
          ></div>
          <div
            className="hk-skeleton-rectangle"
            style={{ marginTop: '8px' }}
          ></div>
          <div
            className="hk-skeleton-rectangle"
            style={{ marginTop: '4px' }}
          ></div>
          <div
            className="hk-skeleton-rectangle"
            style={{ marginTop: '4px' }}
          ></div>
          <div
            className="hk-skeleton-rectangle"
            style={{ marginTop: '4px' }}
          ></div>
          <div
            className="hk-skeleton-rectangle"
            style={{ marginTop: '4px' }}
          ></div>
          <div
            className="hk-skeleton-rectangle"
            style={{ marginTop: '4px' }}
          ></div>
        </div>
      </IonContent>
    );
  }

  return MobileSettingsSkeletonLoaderView();
};

export default DigitalDashboardSettingsSkeletonLoader;
