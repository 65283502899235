import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DigitalCatalogComplete.scss';
import Close from 'assets/icons/close.svg';
import CatalogComplete from 'assets/illustrations/catalog-complete.svg';
import { hideCompleteModal } from 'features/digital/store/DigitalCatalogSlice';
import Illustration from 'components/Illustrations/Illustration';
import { useHistory } from 'react-router';
import { gaBtnClickEvent, gaModalView } from 'core/util';

const DigitalCatalogComplete: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const redirectToTasks = () => {
    dispatch(hideCompleteModal());
    history.goBack();
    gaBtnClickEvent('catalog_complete_start_tasks');
  };

  useEffect(() => {
    gaModalView(isDigital, '/catalog/complete');
  }, []);

  function MobileDigitalCatalogCompleteView() {
    return (
      <>
        <IonContent>
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="background"
              className={`hk-fab-button ${
                platformType === HKPlatform.DESKTOP && isDesktopWidth
                  ? ''
                  : 'hk-fab-button-margin-top'
              }`}
              onClick={() => dispatch(hideCompleteModal())}
            >
              <IonIcon color="dark" icon={Close} />
            </IonFabButton>
          </IonFab>
          <div className="hk-digital-catalog-complete">
            <section className="ion-text-center ion-margin-vertical">
              <Illustration
                image={CatalogComplete}
                description="CatalogComplete"
              />
            </section>
            <h3 className="ion-text-center">Cataloging Complete</h3>
            <p className="ion-padding-horizontal ion-text-center">
              Congratulations you have completed the cataloging of your home. We
              will now begin to process your products. In the meantime you can
              still complete basic tasks not tailored to your home.
            </p>
          </div>
        </IonContent>
        <IonToolbar className="ion-padding-bottom ion-padding-start ion-padding-end">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={redirectToTasks}
          >
            Start Tasks
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? MobileDigitalCatalogCompleteView()
        : MobileDigitalCatalogCompleteView()}
    </IonPage>
  );
};

export default DigitalCatalogComplete;
