import {
  addMinutes,
  compareAsc,
  differenceInCalendarDays,
  format,
  intervalToDuration,
  isValid,
  parseISO
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { InAppBrowserObject } from '@ionic-native/in-app-browser';
import {
  BASE_REM_VAL,
  DATE_FORMAT_HOUR_MINUTES,
  DEVELOPMENT,
  HKPlatform,
  HKVisit,
  HOMEKEEP_URL_MAGIC_CODE,
  LOCAL,
  GA_PAGE_TITLES,
  PRODUCTION,
  STAGING,
  TWO_SECONDS,
  ZENDESK_CHAT_URL
} from 'core/constants';
import { Appointment, StatusTag } from '../types';
import Catalog from 'assets/icons/home-catalog.svg';
import SpringSummer from 'assets/icons/spring-summer.svg';
import SpringSummerDisabled from 'assets/icons/spring-summer-disabled.svg';
import FallWinter from 'assets/icons/fall-winter.svg';
import FallWinterDisabled from 'assets/icons/fall-winter-disabled.svg';
import ImageIcon from 'assets/icons/image.svg';
import PdfIcon from 'assets/icons/pdf-icon.svg';
import DocIcon from 'assets/icons/doc-icon.svg';
import FileIcon from 'assets/icons/file-icon.svg';
import VideoIcon from 'assets/icons/video-icon.svg';
import {
  FirebaseAuthentication,
  GetCurrentUserResult
} from '@tambroseavid/capacitor-firebase-authentication';
import { Capacitor } from '@capacitor/core';
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth
} from 'firebase/auth';
import { getApp } from 'firebase/app';
import { AppDispatch } from 'core/store';
import store from 'core/store';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfig } from 'core/constants';
import { setAuthIdToken } from 'features/shared/login/LoginSlice';
import { requestTokenForFirebaseUser } from 'features/shared/login/LoginActions';
import { getPlatforms } from '@ionic/react';

export const gaSetBuilder = (builderId: number) => {
  FirebaseAnalytics.setUserProperty({
    name: 'builder',
    value: builderId.toString()
  });
};

export const gaToggle = (enabled: boolean) => {
  FirebaseAnalytics.getAppInstanceId().then(() => {
    FirebaseAnalytics.setCollectionEnabled({
      enabled: enabled
    });
  });
};

export const gaLinkClickEvent = (name: string) => {
  FirebaseAnalytics.logEvent({
    name: 'link_click',
    params: { name: name }
  }).then();
};

export const gaBtnClickEvent = (name: string) => {
  FirebaseAnalytics.logEvent({
    name: 'button_click',
    params: { name: name }
  }).then();
};

export const gaModalView = (isDigital: boolean | null, path: string) => {
  const platform = determinePlatformType(getPlatforms());
  if (
    platform === HKPlatform.ANDROID ||
    platform === HKPlatform.IOS ||
    platform === HKPlatform.CAPACITOR
  ) {
    gaMobileScreenView(isDigital, path);
  } else {
    gaWebPageView(isDigital, path);
  }
};

export const gaWebPageView = (isDigital: boolean | null, path: string) => {
  // filter out root path (/)
  if (path.length > 1) {
    // remove any ids from the end of paths eg: /appointment/:id or /inventory/group/:id
    const stripped = path.replace(/\/\d+/g, '');
    let title = GA_PAGE_TITLES.find((pt) => pt.path === stripped)?.title;
    if (isDigital !== null) {
      title = isDigital ? 'digital-' + title : 'full-service-' + title;
    }
    try {
      FirebaseAnalytics.logEvent({
        name: 'screen_view',
        params: { page_title: !!title ? title : stripped }
      }).catch((e) => {});
    } catch (e) {
      console.log('FirebaseAnalytics not initialized');
    }
  }
};

export const gaMobileScreenView = (isDigital: boolean | null, path: string) => {
  // filter out root path (/)
  if (path.length > 1) {
    // remove any ids from the end of paths eg: /appointment/:id or /inventory/group/:id
    const stripped = path.replace(/\/\d+/g, '');
    const title = GA_PAGE_TITLES.find((pt) => pt.path === stripped)?.title;
    try {
      // setScreenName is Mobile only
      FirebaseAnalytics.setScreenName({
        screenName: !!title ? title : stripped,
        nameOverride: !!title ? title : stripped
      }).then();
    } catch (e) {
      console.log('FirebaseAnalytics not initialized');
    }
  }
};

export const copyJSON = (element: object): object => {
  return JSON.parse(JSON.stringify(element));
};

export const remToPx = (rem: string): number => {
  let n = 0;
  if (rem && typeof rem === 'string') {
    n = Number(rem.replace('rem', '')) * BASE_REM_VAL;
  }
  return n;
};

export const replaceParams = (
  route: string,
  params: Array<Array<any>> | Array<any>
): string => {
  let newRoute = route;
  const replace = (p: Array<any>) => {
    newRoute = newRoute.replace(`{${p[0]}}`, String(p[1]));
  };
  if (Array.isArray(params[0])) {
    params.forEach((p) => replace(p));
  } else {
    replace(params);
  }
  return newRoute;
};

export const getEnvName = () => process.env.REACT_APP_ENV_NAME;

export const getBaseApiUrl = () => {
  const { REACT_APP_API_PROTOCOL, REACT_APP_API_URL, REACT_APP_API_PORT } =
    process.env;
  return (
    (REACT_APP_API_PROTOCOL || 'https') +
    '://' +
    REACT_APP_API_URL +
    (REACT_APP_API_PORT ? `:${REACT_APP_API_PORT}` : '')
  );
};

export const appMinorVersionGreaterThanExpected = (
  appVersion: string
): boolean => {
  if (!!process.env.REACT_APP_VERSION) {
    const expectedMinor = Number(process.env.REACT_APP_VERSION?.split('.')[1]);
    const storedMinor = Number(appVersion?.split('.')[1]);
    return storedMinor < expectedMinor;
  }
  return false;
};

export const openConcierge = (
  zendeskTokenURL: string,
  platformType: HKPlatform
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const browser = new InAppBrowserObject(
    ZENDESK_CHAT_URL,
    '_blank',
    getBrowserOptions(platformType)
  );
  gaBtnClickEvent('open_concierge');
};

export const openHelp = (zendeskTokenURL: string, platformType: HKPlatform) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const browser = new InAppBrowserObject(
    zendeskTokenURL,
    '_blank',
    getBrowserOptions(platformType)
  );
  gaBtnClickEvent('open_help');
};

export const viewDocument = (url: string, platformType: HKPlatform) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const browser = new InAppBrowserObject(
    url,
    platformType === HKPlatform.ANDROID ? '_system' : '_blank',
    getBrowserOptions(platformType)
  );
  gaBtnClickEvent('view_document');
};

export const openInAppBrowserLink = (url: string, platformType: HKPlatform) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const browser = new InAppBrowserObject(
    url,
    platformType === HKPlatform.ANDROID ? '_system' : '_blank',
    getBrowserOptions(platformType)
  );
  gaBtnClickEvent('open_in_app_browser_link');
};

export const getBrowserOptions = (platformType: HKPlatform) => {
  let options =
    'location=no,toolbarcolor=#353A4D,closebuttoncolor=#ffffff,hidenavigationbuttons=yes';
  switch (platformType) {
    case HKPlatform.ANDROID:
      options += ',fullscreen=yes';
      break;
    case HKPlatform.IOS:
      options +=
        ',toolbarposition=top,closebuttoncaption=Close,lefttoright=yes';
      break;
  }
  return options;
};

export const validateOpenedURL = (url: string) => {
  if (url.indexOf(HOMEKEEP_URL_MAGIC_CODE) === 0) {
    return `/auth/${url.replace(HOMEKEEP_URL_MAGIC_CODE, '')}`;
  }
  return null;
};

export const validateURL = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  }
  return 'https://' + url;
};

export const highestStatusTagLevel = (tags?: StatusTag[]): number => {
  if (!!tags && tags.length > 0) {
    const sorted = copyJSON(tags) as StatusTag[];
    sorted.sort((a, b) => {
      return b.level - a.level;
    });
    return sorted[0].level;
  }
  return 0;
};

export const isIOS = (platform: HKPlatform): boolean => {
  return platform === HKPlatform.IOS;
};

export const isAndroid = (platform: HKPlatform): boolean => {
  return platform === HKPlatform.ANDROID;
};

export const isDesktop = (platform: HKPlatform): boolean => {
  return platform === HKPlatform.DESKTOP;
};

export const isMobileWeb = (platform: HKPlatform): boolean => {
  return platform === HKPlatform.MOBILE_WEB;
};

export const determinePlatformType = (platforms: string[]): HKPlatform => {
  if (
    platforms.includes(HKPlatform.IOS) &&
    platforms.includes(HKPlatform.CAPACITOR)
  ) {
    return HKPlatform.IOS;
  } else if (
    platforms.includes(HKPlatform.ANDROID) &&
    platforms.includes(HKPlatform.CAPACITOR)
  ) {
    return HKPlatform.ANDROID;
  } else if (
    platforms.includes(HKPlatform.IOS) &&
    platforms.includes(HKPlatform.MOBILE_WEB)
  ) {
    return HKPlatform.IOS_BROWSER;
  } else if (
    platforms.includes(HKPlatform.ANDROID) &&
    platforms.includes(HKPlatform.MOBILE_WEB)
  ) {
    return HKPlatform.ANDROID_BROWSER;
  }
  return HKPlatform.DESKTOP;
};

export const isDev = () => {
  const env = getEnvName();
  return env === LOCAL || env === DEVELOPMENT;
};

export const isProd = () => {
  const env = getEnvName();
  return env === STAGING || env === PRODUCTION;
};

export const isFunction = (f: any) => f && typeof f === 'function';

export const sleep = (t = TWO_SECONDS) =>
  new Promise((resolve) => setTimeout(resolve, t));

export const constructAddress = (city: string, state: string, zip: string) => {
  let address = '';

  if (!!city) {
    address += city + ', ';
  }
  if (!!state) {
    address += state + ' ';
  }
  if (!!zip) {
    address += zip;
  }

  return address;
};

export const capitalize = (string: string) => {
  return !!string ? string[0].toUpperCase() + string.slice(1) : '';
};

export const PDF_REGEX = /\.(pdf|pdfx)$/i;
export const DOCUMENTS_REGEX = /\.(docx?|xlsx?|csv?|pptx?|txt?)$/i;
export const IMAGE_REGEX = /\.(jpe?g|png|gif|bmp|svg)$/i;
export const VIDEO_REGEX = /\.(mp4|avi|mov|wmv|flv|mkv)$/i;

export const isFileTypeImage = (fileUrl: string) => {
  if (IMAGE_REGEX.test(fileUrl)) {
    return true;
  }
  return false;
};

export const isFileTypeVideo = (fileUrl: string) => {
  if (VIDEO_REGEX.test(fileUrl)) {
    return true;
  }
  return false;
};

export const getDocumentFileTypeIcon = (fileUrl: string) => {
  let icon;
  if (PDF_REGEX.test(fileUrl)) {
    icon = PdfIcon;
  } else if (DOCUMENTS_REGEX.test(fileUrl)) {
    icon = DocIcon;
  } else if (IMAGE_REGEX.test(fileUrl)) {
    icon = ImageIcon;
  } else if (VIDEO_REGEX.test(fileUrl)) {
    icon = VideoIcon;
  } else {
    icon = FileIcon;
  }
  return icon;
};

export const getSeasonIcon = (type: string, disabled?: boolean): string => {
  const title = type?.toLowerCase();
  if (title.includes(HKVisit.Catalog)) {
    return Catalog;
  } else if (title.includes(HKVisit.Spring) || title.includes(HKVisit.Summer)) {
    return disabled ? SpringSummerDisabled : SpringSummer;
  } else if (title.includes(HKVisit.Autumn) || title.includes(HKVisit.Winter)) {
    return disabled ? FallWinterDisabled : FallWinter;
  } else {
    return SpringSummer;
  }
};

enum AppointmentType {
  Upcoming,
  Past
}

export const filterAppointments = (
  type: AppointmentType,
  appointments: Appointment[]
): Appointment[] => {
  const now = new Date();
  const filteredAppointments = appointments.filter((appt) => {
    const apptDate = new Date(appt.scheduled || 0);
    return (
      (type === AppointmentType.Upcoming && compareAsc(apptDate, now) === 1) ||
      (type === AppointmentType.Past && compareAsc(now, apptDate) === 1)
    );
  });

  // Sort the filtered appointments in ascending order
  return filteredAppointments.sort((a, b) => {
    const dateA = new Date(a.scheduled || 0);
    const dateB = new Date(b.scheduled || 0);
    return dateA.getTime() - dateB.getTime();
  });
};

export const formatAppointmentDisplayTimeSlot = (
  appointment: Appointment,
  timezone: string
): string => {
  const date = utcToZonedTime(appointment.scheduled, timezone);
  const endtime = addMinutes(date, appointment.duration);
  return `${format(date, DATE_FORMAT_HOUR_MINUTES)} - ${format(
    endtime,
    DATE_FORMAT_HOUR_MINUTES
  )}`;
};

export const formatTaskFinishedDate = (
  date_finished: string,
  timezone: string
): string => {
  const duration = intervalToDuration({
    start: utcToZonedTime(date_finished, timezone || ''),
    end: utcToZonedTime(new Date(), timezone || '')
  });
  if (!!duration) {
    if (!!duration.years && duration.years > 0) {
      return `${duration.years} years ago`;
    }
    if (!!duration.months && duration.months > 0) {
      return `${duration.months} months ago`;
    }
    if (!!duration.weeks && duration.weeks > 0) {
      return `${duration.weeks} weeks ago`;
    }
    if (!!duration.days && duration.days > 0) {
      return `${duration.days} days ago`;
    }
    if (!!duration.hours && duration.hours > 0) {
      return `${duration.hours} hours ago`;
    }
    if (!!duration.minutes && duration.minutes > 0) {
      return `${duration.minutes} minutes ago`;
    }
  }
  return '';
};

export const formatISODate = (date: string, pattern: string): string => {
  return isValid(parseISO(date)) ? format(parseISO(date), pattern) : '';
};

export const formatTimezoneDate = (
  date: string,
  pattern: string,
  timezone?: string
): string => {
  const tzDate = utcToZonedTime(date, timezone || '');
  return `${format(tzDate, pattern)}`;
};

export const formatSimpleDate = (date: string, pattern: string): string => {
  const tzDate = new Date(date);
  return `${format(tzDate, pattern)}`;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  var cleaned = ('' + phoneNumber).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

export const formatMobilePhoneNumber = (phoneNumber: string) => {
  if (!!phoneNumber) {
    var x = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (phoneNumber = !x![2]
      ? x![1]
      : '(' + x![1] + ') ' + x![2] + (x![3] ? '-' + x![3] : ''));
  } else {
    return null;
  }
};

export const translateCreateContactError = (error: string): string => {
  switch (error) {
    case 'Prospect already exists':
      return 'This email is already associated with another account.';
    default:
      return error;
  }
};

export const daysUntilRenewal = (date: string) => {
  const expirationDate = new Date(date);
  const nowDate = new Date();
  return Math.abs(differenceInCalendarDays(expirationDate, nowDate));
};

export const getCurrentUser = async (): Promise<GetCurrentUserResult> => {
  const result = await FirebaseAuthentication.getCurrentUser();
  return result;
};

export const getIdToken = async () => {
  const result = await FirebaseAuthentication.getIdToken({
    forceRefresh: true
  });
  return result.token;
};

export const refreshToken = (): any => async (dispatch: AppDispatch) => {
  let auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    auth = getAuth();
  }
  getCurrentUser().then((result) => {
    if (!!result.user) {
      firebase.initializeApp(firebaseConfig);
      getIdToken().then((token) => {
        dispatch(setAuthIdToken(token));
      });
      const { login } = store.getState();
      dispatch(requestTokenForFirebaseUser(login.authIdToken!));
    }
  });
};

export const fetchBase64Document = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject('method did not return a string');
      }
    };
    reader.readAsDataURL(blob);
  });
};
