import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InventoryItem,
  InventoryGroup,
  ProductTemplateType,
  ProductGroupType,
  SearchedProduct
} from 'core/types';
export interface ProductGroup {
  id: number;
  group: string;
  count: number;
  names?: string[];
}

interface InventoryState {
  loading: boolean;
  error: string | null;
  productGroups: ProductGroup[];
  products: InventoryItem[];
  product_count: number | null;
  inventory: InventoryGroup[];
  product_id: number | null;
  product: InventoryItem | null;
  productTypeGroupsList: ProductGroupType[];
  productTemplate: ProductTemplateType | null;
  searchedProducts: SearchedProduct[];
  addItemModalVisible: boolean;
  itemDetailsModalVisible: boolean;
}

export const initialState: InventoryState = {
  productGroups: [],
  products: [],
  product_count: null,
  inventory: [],
  product_id: null,
  product: null,
  productTypeGroupsList: [],
  productTemplate: null,
  searchedProducts: [],
  loading: false,
  error: null,
  addItemModalVisible: false,
  itemDetailsModalVisible: false
};

export const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setInventoryLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    fetchProductGroupCountSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      state.productGroups = items;
      state.loading = false;
    },
    fetchProductGroupStart: (state, action: PayloadAction<InventoryItem[]>) => {
      state.loading = true;
    },
    fetchProductGroupSuccess: (
      state,
      action: PayloadAction<InventoryItem[]>
    ) => {
      state.products = action.payload;
      state.loading = false;
    },
    resetProductGroup: (state) => {
      state.products = [];
      state.loading = true;
    },
    fetchProductDetailsStart: (state) => {
      state.loading = true;
    },
    fetchProductDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.product = action.payload;
      state.loading = false;
    },
    fetchProductTypeGroupsListSuccess: (state, action: PayloadAction<any>) => {
      state.productTypeGroupsList = action.payload;
    },
    fetchProductTypeGroupsListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchProductTemplateStart: (state) => {
      state.loading = true;
    },
    fetchProductTemplateSuccess: (state, action: PayloadAction<any>) => {
      state.productTemplate = action.payload;
      state.loading = false;
    },
    fetchProductTemplateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchSearchedProductsStart: (state) => {
      state.loading = true;
    },
    fetchSearchedProductsSuccess: (state, action: PayloadAction<any>) => {
      state.searchedProducts = action.payload;
      state.loading = false;
    },
    fetchSearchedProductsError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetSearchedProducts: (state) => {
      state.searchedProducts = [];
    },
    createProductStart: (state) => {
      state.loading = true;
    },
    createProductSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
    },
    createProductError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    showAddItemModal: (state) => {
      state.addItemModalVisible = true;
    },
    hideAddItemModal: (state) => {
      state.addItemModalVisible = false;
    },
    showItemDetailsModal: (state, action: PayloadAction<any>) => {
      state.product_id = Number(action.payload);
      state.itemDetailsModalVisible = true;
    },
    hideItemDetailsModal: (state) => {
      state.product_id = null;
      state.product = null;
      state.itemDetailsModalVisible = false;
    },
    showDesktopItemDetails: (state, action: PayloadAction<any>) => {
      state.product_id = Number(action.payload);
      state.loading = true;
    },
    resetInventoryDetails: (state) => {
      state.product_id = null;
      state.product = null;
    },
    resetProductTemplate: (state) => {
      state.loading = false;
      state.productTemplate = null;
    },
    resetInventoryList: () => initialState
  }
});

export const {
  setInventoryLoading,
  fetchProductGroupCountSuccess,
  fetchProductGroupStart,
  fetchProductGroupSuccess,
  resetProductGroup,
  fetchProductTypeGroupsListSuccess,
  fetchProductTypeGroupsListError,
  fetchProductTemplateStart,
  fetchProductTemplateSuccess,
  fetchProductTemplateError,
  createProductStart,
  createProductSuccess,
  createProductError,
  fetchProductDetailsStart,
  fetchProductDetailsSuccess,
  fetchSearchedProductsStart,
  fetchSearchedProductsSuccess,
  fetchSearchedProductsError,
  resetSearchedProducts,
  resetProductTemplate,
  showAddItemModal,
  hideAddItemModal,
  showItemDetailsModal,
  hideItemDetailsModal,
  showDesktopItemDetails,
  resetInventoryDetails,
  resetInventoryList
} = inventorySlice.actions;

export default inventorySlice.reducer;
