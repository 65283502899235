import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonText
} from '@ionic/react';
import { RootState } from 'core/store';
import { setModalTask } from '../AppointmentDetailsSlice';
import CompletedCheckmark from 'assets/icons/complete.svg';
import Pending from 'assets/icons/pending.svg';
import Close from 'assets/icons/close.svg';
import Error from 'assets/icons/error.svg';
import { gaModalView, highestStatusTagLevel } from 'core/util';
import 'components/items/task-item/TaskItem.scss';
import './TaskDetails.scss';
import { Asset } from 'core/types';
import { HKPlatform } from 'core/constants';

const TaskDetails: React.FC = () => {
  const { modalTask } = useSelector((state: RootState) => state.appointment);
  const { isDigital } = useSelector((state: RootState) => state.user);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const dispatch = useDispatch();

  const iconForElement = () => {
    if (!!modalTask?.finished) {
      return CompletedCheckmark;
    }
    const highLevel = highestStatusTagLevel(modalTask?.statuses);
    return highLevel > 0 ? Error : Pending;
  };

  const colorForIcon = () => {
    const highLevel = highestStatusTagLevel(modalTask?.statuses);
    if (highLevel === 2) {
      return 'danger';
    }
    if (highLevel === 1) {
      return 'warning';
    }
    return 'primary';
  };

  const displayAssetThumbnail = (asset: Asset) => {
    return asset.thumb_cloud_store ? asset.thumb_cloud_store : asset.file_url;
  };

  useEffect(() => {
    gaModalView(isDigital, '/appointment/task');
  }, []);

  function DesktopTaskDetailsView() {
    return (
      <IonPage>
        <IonContent className="hk-desktop-task-details-container">
          <div className="hk-desktop-task-details">
            <div className="hk-desktop-task-details-header">
              <div className="hk-status-tag-list">
                {!!modalTask?.finished ? (
                  <>
                    <div className={`hk-status-tag-list-item level-0`}>
                      complete
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`hk-status-tag-list-item  hk-status-pill-incomplete`}
                    >
                      incomplete
                    </div>
                  </>
                )}
                {modalTask?.statuses?.map((tag, index) => {
                  return (
                    <>
                      {!!tag.name && (
                        <div
                          className={`hk-status-tag-list-item level-${tag.level}`}
                          key={`status-tag-${modalTask.id}-${index}`}
                        >
                          {tag.name}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className="hk-desktop-task-details-header-title">
                {modalTask?.name}
              </div>
            </div>
            {!!modalTask?.worker_notes && (
              <div className="hk-desktop-task-details-notes">
                <h6>Notes:</h6>
                <p>{modalTask?.worker_notes}</p>
              </div>
            )}
            <div className="hk-desktop-task-details-images">
              {modalTask?.assets.map((asset, i) => {
                return (
                  <img
                    key={`image-${i}`}
                    className="hk-desktop-task-details-images-image"
                    src={displayAssetThumbnail(asset)}
                    alt="Issue"
                  />
                );
              })}
            </div>
          </div>
        </IonContent>
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            color="white"
            className="hk-fab-button"
            onClick={() => dispatch(setModalTask(null))}
          >
            <IonIcon color="medium" icon={Close} />
          </IonFabButton>
        </IonFab>
      </IonPage>
    );
  }

  function MobileTaskDetailsView() {
    return (
      <IonPage>
        <IonContent className="hk-task-details ion-margin-vertical ion-padding">
          <div className="hk-task-details-header">
            <div className="hk-task-details-header-status">
              <IonIcon color={colorForIcon()} src={iconForElement()} />
              <IonText color="grey">
                {!!modalTask?.finished ? 'Complete' : 'Incomplete'}
              </IonText>
            </div>
            <h5>{modalTask?.name}</h5>
          </div>
          {!!modalTask?.worker_notes && (
            <div className="hk-task-details-notes">
              <h6>Notes:</h6>
              <p>{modalTask?.worker_notes}</p>
            </div>
          )}
          <div className="hk-status-tag-list">
            {modalTask?.statuses?.map((tag, index) => {
              return (
                <>
                  {!!tag.name && (
                    <div
                      className={`hk-status-tag-list-item level-${tag.level}`}
                      key={`status-tag-${modalTask.id}-${index}`}
                    >
                      {tag.name}
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div className="hk-task-details-images">
            {modalTask?.assets.map((asset, i) => {
              return (
                <img
                  key={`image-${i}`}
                  className="hk-task-details-images-image"
                  src={displayAssetThumbnail(asset)}
                  alt="Issue"
                />
              );
            })}
          </div>
        </IonContent>
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            color="white"
            className="hk-fab-button ion-margin-top"
            onClick={() => dispatch(setModalTask(null))}
          >
            <IonIcon color="medium" icon={Close} />
          </IonFabButton>
        </IonFab>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopTaskDetailsView()
        : MobileTaskDetailsView()}
    </>
  );
};

export default TaskDetails;
