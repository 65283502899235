import { DATE_FORMAT_MONTH_DATE, HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Renew.scss';
import {
  IonButton,
  IonChip,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonPage
} from '@ionic/react';
import { useHistory } from 'react-router';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import AddOns from 'features/shared/billing/AddOns';
import {
  hideAddOnsModal,
  hideSubscriptionSummaryModal,
  setNewBillingPeriod,
  setNewPlan,
  setPromoCode,
  showAddOnsModal,
  showSubscriptionSummaryModal
} from 'features/shared/billing/BillingSlice';
import { requestSubscriptionPlans } from 'features/shared/billing/BillingActions';
import { formatSimpleDate, gaBtnClickEvent } from 'core/util';
import Summary from 'features/shared/billing/Summary';
import Close from 'assets/icons/close.svg';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const Renew: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { globalAccountSettings, isExpired } = useSelector(
    (state: RootState) => state.home
  );
  const {
    currentPlans,
    availablePlans,
    addOnModalVisible,
    subscriptionSummaryModalVisible
  } = useSelector((state: RootState) => state.billing);

  const history = useHistory();
  const [toggleIndex, setToggleIndex] = useState(0);
  const [requireRenew, setRequireRenew] = useState(false);
  const toggleOptions = ['Monthly', 'Yearly'];
  const dispatch = useDispatch();

  const planSlideOpts = {
    initialSlide: 0,
    height: 400,
    speed: 400
  };

  const submitToCheckoutMobile = (plan: any) => {
    const period = toggleIndex === 0 ? 'monthly' : 'yearly';
    dispatch(setNewBillingPeriod(period));
    dispatch(setNewPlan(plan));
    dispatch(showSubscriptionSummaryModal());
    if (currentPlans.length > 0 && plan.id === currentPlans[0].id) {
      gaBtnClickEvent('renew_subscription');
    } else {
      gaBtnClickEvent('update_and_renew_subscription');
    }
  };

  const submitToCheckoutDesktop = (plan: any) => {
    const period = toggleIndex === 0 ? 'monthly' : 'yearly';
    dispatch(setNewBillingPeriod(period));
    dispatch(setNewPlan(plan));
    if (plan.id === currentPlans[0].id) {
      gaBtnClickEvent('renew_subscription');
    } else {
      gaBtnClickEvent('update_and_renew_subscription');
    }
    history.push(`/renew/checkout`);
  };

  const openAddOnsModal = (plan: any) => {
    const period = toggleIndex === 0 ? 'monthly' : 'yearly';
    dispatch(setNewBillingPeriod(period));
    dispatch(setNewPlan(plan));
    dispatch(showAddOnsModal(plan.addons));
    gaBtnClickEvent('open_addons_modal');
  };

  const dismissAddOnsModal = () => {
    dispatch(hideAddOnsModal());
  };

  const dismissSubscriptionSummaryModal = () => {
    dispatch(hideSubscriptionSummaryModal());
  };

  const closeRenewPage = () => {
    if (isDigital) {
      history.push(`/account`);
    } else {
      history.push(`/dashboard`);
    }
  };

  const displayPlanName = (plan: any) => {
    let planName;
    if (
      plan.square_feet_min &&
      plan.square_feet_max &&
      plan.square_feet_max !== 1000000
    ) {
      planName = `${plan.name} ${plan.square_feet_min.toLocaleString(
        'en-US'
      )}-${plan.square_feet_max.toLocaleString('en-US')} sq ft`;
    } else if (plan.square_feet_min && !plan.square_feet_max) {
      planName = `${plan.name} > ${plan.square_feet_min.toLocaleString(
        'en-US'
      )} sq ft`;
    } else if (!plan.square_feet_min && plan.square_feet_max) {
      planName = `${plan.name} < ${plan.square_feet_max.toLocaleString(
        'en-US'
      )} sq ft`;
    } else if (plan.square_feet_max >= 1000000) {
      planName = `${plan.name} ${plan.square_feet_min.toLocaleString(
        'en-US'
      )}+ sq ft`;
    } else {
      planName = plan.name;
    }
    return planName;
  };

  useEffect(() => {
    if (!!currentPlans[0]) {
      if (!!currentPlans[0].promo_coupon) {
        dispatch(setPromoCode(currentPlans[0].promo_coupon.recurly_code));
      }
    }
  }, [currentPlans]);

  useEffect(() => {
    if (!!homeId) {
      dispatch(requestSubscriptionPlans(homeId));
    }
  }, [toggleIndex, homeId]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!!params.has('required')) {
      setRequireRenew(params.has('required'));
    }
  }, []);

  function DesktopRenewView() {
    return (
      <>
        <IonContent className="hk-desktop-renew" fullscreen>
          <div className="hk-desktop-renew-header-container">
            <div className="hk-desktop-renew-header">
              <section>
                <h2>
                  {isExpired
                    ? 'YOUR SUBSCRIPTION HAS EXPIRED'
                    : 'YOUR SUBSCRIPTION IS EXPIRING SOON'}
                </h2>
                <h4>
                  {isExpired ? 'Expired' : 'Expires'} on:{' '}
                  {formatSimpleDate(
                    globalAccountSettings?.expires!,
                    DATE_FORMAT_MONTH_DATE
                  )}
                </h4>
                <p>
                  {requireRenew ? (
                    `Just a heads up! Your subscription includes two service appointments.
                    To book your next one, you'll need to renew your subscription.
                    Thanks for staying with us!`
                  ) : (
                    <>
                      {isExpired
                        ? 'You can unlock access to your account by renewing below'
                        : 'You can renew your subscription below'}
                    </>
                  )}
                </p>
              </section>
              <section>
                <div className="hk-desktop-renew-toggle-container">
                  <div className="hk-desktop-renew-toggle">
                    {toggleOptions.map((opt, i) => {
                      return (
                        <IonChip
                          key={'chip-' + i}
                          className={toggleIndex === i ? 'selected' : ''}
                          onClick={() => {
                            setToggleIndex(i);
                          }}
                        >
                          {opt}
                        </IonChip>
                      );
                    })}
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="hk-desktop-renew-body">
            <SwitchTransition>
              <CSSTransition
                key={toggleIndex ? 'Monthly' : 'Yearly'}
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                classNames="toggle"
              >
                <div
                  className={`hk-desktop-renew-plans ${
                    toggleIndex === 0 ? 'left' : 'right'
                  }`}
                >
                  {availablePlans.map((plan: any, i: number) => {
                    return (
                      <>
                        <div className="hk-plan-card" key={'plan-' + i}>
                          {plan.id === currentPlans[0].id && (
                            <div className="hk-plan-card-last-plan-header">
                              Your Last Plan
                            </div>
                          )}
                          <div
                            className={`hk-promo-banner ${
                              !currentPlans[0].promo_coupon && 'no-promo'
                            }`}
                          >
                            {!!currentPlans[0].length
                              ? currentPlans[0].promo_coupon.description
                              : ''}
                          </div>
                          <div
                            className={`hk-subscription-plan ${
                              toggleIndex === 0 ? 'left' : 'right'
                            }`}
                          >
                            <div>
                              <div className="hk-subscription-plan-last-plan">
                                <h6>{displayPlanName(plan)}</h6>
                              </div>
                              <div className="hk-subscription-plan-cost">
                                <span className="hk-subscription-plan-cost-amount">
                                  {plan.hasOwnProperty('pricing') && (
                                    <>
                                      {toggleIndex === 0 && (
                                        <>${plan.pricing.monthly.usd}/Month</>
                                      )}
                                      {toggleIndex === 1 && (
                                        <>${plan.pricing.yearly.usd}/Year</>
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                              <div className="hk-subscription-plan-description">
                                {plan.description}
                              </div>
                              <div className="hk-subscription-plan-list">
                                <ul style={{ listStyleType: 'square' }}>
                                  {plan.hasOwnProperty('bullets') &&
                                    plan.bullets.map(
                                      (bullet: any, i: number) => (
                                        <li key={`bullet-${i}`}> {bullet}</li>
                                      )
                                    )}
                                </ul>
                              </div>
                            </div>

                            <div>
                              <IonButton
                                expand="block"
                                strong={true}
                                className="hk-subscription-plan-renew-button"
                                onClick={() => submitToCheckoutDesktop(plan)}
                              >
                                {plan.id === currentPlans[0].id ? (
                                  <>Renew Subscription</>
                                ) : (
                                  <>Update & Renew Subscription</>
                                )}
                              </IonButton>
                              <div
                                className="hk-subscription-plan-add-ons"
                                onClick={() => openAddOnsModal(plan)}
                              >
                                See add-ons
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </IonContent>
      </>
    );
  }

  function MobileRenewView() {
    return (
      <>
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            color="background"
            className={`hk-fab-button ${
              platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? ''
                : 'hk-fab-button-margin-top'
            }`}
            onClick={closeRenewPage}
          >
            <IonIcon color="dark" icon={Close} />
          </IonFabButton>
        </IonFab>
        <IonContent fullscreen className="hk-renew">
          <div className="hk-renew-header">
            <h2>
              {isExpired
                ? 'YOUR SUBSCRIPTION HAS EXPIRED'
                : 'YOUR SUBSCRIPTION IS EXPIRING SOON'}
            </h2>
            {globalAccountSettings?.expires !== null && (
              <h4>
                {isExpired ? 'Expired' : 'Expires'} on:{' '}
                {formatSimpleDate(
                  globalAccountSettings?.expires!,
                  DATE_FORMAT_MONTH_DATE
                )}
              </h4>
            )}
            <p>
              {requireRenew ? (
                `Just a heads up! Your subscription includes two service appointments.
                    To book your next one, you'll need to renew your subscription.
                    Thanks for staying with us!`
              ) : (
                <>
                  {isExpired
                    ? 'You can unlock access to your account by renewing below'
                    : 'You can renew your subscription below'}
                </>
              )}
            </p>
          </div>
          <div className="hk-renew-body">
            <div className="hk-renew-toggle-container">
              <div className="hk-renew-toggle">
                {toggleOptions.map((opt, i) => {
                  return (
                    <IonChip
                      key={'chip-' + i}
                      className={toggleIndex === i ? 'selected' : ''}
                      onClick={() => {
                        setToggleIndex(i);
                      }}
                    >
                      {opt}
                    </IonChip>
                  );
                })}
              </div>
            </div>

            <SwitchTransition>
              <CSSTransition
                key={toggleIndex ? 'Monthly' : 'Yearly'}
                addEndListener={(node, done) =>
                  node.addEventListener('transitionend', done, false)
                }
                classNames="toggle"
              >
                <div className={toggleIndex === 0 ? 'left' : 'right'}>
                  <Swiper
                    className="hk-item-detail-image-slider"
                    pager={true}
                    options={planSlideOpts}
                  >
                    {availablePlans.map((plan: any, i: number) => {
                      return (
                        <>
                          <SwiperSlide key={`slide-${i}`}>
                            <div className="hk-plan-card">
                              <div
                                className={`hk-promo-banner ${
                                  currentPlans.length > 0 &&
                                  !currentPlans[0].promo_coupon &&
                                  'no-promo'
                                }`}
                              >
                                {currentPlans.length > 0 &&
                                !!currentPlans[0].length
                                  ? currentPlans[0].promo_coupon.description
                                  : ''}
                              </div>
                              <div
                                className={`hk-subscription-plan ${
                                  toggleIndex === 0 ? 'left' : 'right'
                                }`}
                              >
                                <div>
                                  <div className="hk-subscription-plan-last-plan">
                                    <h6>
                                      {currentPlans.length > 0 &&
                                      plan.id === currentPlans[0].id ? (
                                        <>Your Last Plan:</>
                                      ) : (
                                        <>New Plan:</>
                                      )}
                                    </h6>
                                    <h6>{displayPlanName(plan)}</h6>
                                  </div>
                                  <div className="hk-subscription-plan-cost">
                                    <span className="hk-subscription-plan-cost-amount">
                                      {plan.hasOwnProperty('pricing') && (
                                        <>
                                          {toggleIndex === 0 && (
                                            <>
                                              ${plan.pricing.monthly.usd}
                                              /Month
                                            </>
                                          )}
                                          {toggleIndex === 1 && (
                                            <>${plan.pricing.yearly.usd}/Year</>
                                          )}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                  <div className="hk-subscription-plan-description">
                                    {plan.description}
                                  </div>
                                  <div className="hk-subscription-plan-list">
                                    <ul style={{ listStyleType: 'square' }}>
                                      {plan.hasOwnProperty('bullets') &&
                                        plan.bullets.map(
                                          (bullet: any, i: number) => (
                                            <li key={`bullet-${i}`}>
                                              {' '}
                                              {bullet}
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>

                                <div>
                                  <IonButton
                                    expand="block"
                                    strong={true}
                                    className="hk-subscription-plan-renew-button"
                                    onClick={() => submitToCheckoutMobile(plan)}
                                  >
                                    {currentPlans.length > 0 &&
                                    plan.id === currentPlans[0].id ? (
                                      <>Renew Subscription</>
                                    ) : (
                                      <>Update & Renew Subscription</>
                                    )}
                                  </IonButton>
                                  <div
                                    className="hk-subscription-plan-add-ons"
                                    onClick={() => openAddOnsModal(plan)}
                                  >
                                    See add-ons
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </IonContent>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-renew-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopRenewView()
        : MobileRenewView()}
      <IonModal
        className={
          platformType === HKPlatform.DESKTOP && isDesktopWidth
            ? 'hk-desktop-addon-modal'
            : 'hk-plan-addon-modal'
        }
        isOpen={addOnModalVisible}
        onDidDismiss={dismissAddOnsModal}
      >
        <AddOns />
      </IonModal>
      <IonModal
        className="hk-subscription-summary-modal"
        isOpen={subscriptionSummaryModalVisible}
        onDidDismiss={dismissSubscriptionSummaryModal}
      >
        <Summary />
      </IonModal>
    </IonPage>
  );
};

export default Renew;
