import { Appointment, Worker } from '../../../core/types';
import React from 'react';
import { IonButton, IonIcon, IonText, IonThumbnail } from '@ionic/react';
import Clock from '../../../assets/icons/clock.svg';
import './ServicePersonCard.scss';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { HKPlatform } from 'core/constants';
import AvatarMissing from '../../../assets/icons/avatar-missing.svg';
import Thumbnail from 'components/thumbnail/Thumbnail';

type ServicePersonCardProps = {
  appointment?: Appointment;
  person?: Worker;
  time: string;
  date: string;
  icon?: string;
  completed: boolean;
  className?: string;
  cancelClicked?: (e: any) => void;
  rescheduleClicked?: (e: any) => void;
};

const ServicePersonCard: React.FC<ServicePersonCardProps> = ({
  appointment,
  person,
  time,
  date,
  icon,
  completed = false,
  className = '',
  cancelClicked,
  rescheduleClicked,
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopServicePersonCardView() {
    return (
      <div className={`hk-desktop-person-card`} {...rest}>
        <div className="hk-desktop-person-card-title">
          {icon && (
            <IonIcon
              className="hk-desktop-season-icon"
              color="white"
              icon={icon}
            />
          )}
          {completed ? (
            <div className="hk-desktop-person-card-title__name--completed">
              <IonText>{appointment?.name}</IonText>
              <div className="hk-complete-pill">complete</div>
            </div>
          ) : (
            <IonText className="hk-desktop-person-card-title__name">
              {appointment?.name}
            </IonText>
          )}
        </div>
        <div className="hk-desktop-person-card-body">
          <IonThumbnail slot="end" className="hk-desktop-list-card-thumbnail">
            <Thumbnail
              src={
                !!appointment?.worker?.image
                  ? appointment?.worker?.image
                  : AvatarMissing
              }
              alt="Service person"
            />
          </IonThumbnail>
          <div className="hk-desktop-person-card-body-details">
            <div>
              <IonText className="hk-bold">{person?.name}</IonText>
            </div>
            <div>
              <IonText>
                {person?.title ? person?.title : 'Maintenance Specialist'}
              </IonText>
            </div>
          </div>
        </div>
        <div className="hk-desktop-person-card-footer">
          <div className="hk-desktop-person-card-footer__details">
            <IonText className="hk-desktop-person-card-footer__details-title hk-bold">
              Appointment Time
            </IonText>
            <div>
              <span>
                <IonText>{date}</IonText>
              </span>
              <span>&nbsp;</span>
              <IonText color="dark" className="hk-bold">
                <IonText
                  color={completed ? 'grey' : 'dark'}
                  className="hk-bold"
                >
                  {!!time ? `(${time})` : '-'}
                </IonText>
              </IonText>
            </div>
          </div>
        </div>
        {!completed && (
          <>
            {appointment?.allow_cancel && (
              <IonButton
                className={`hk-desktop-person-cancel-button`}
                expand="block"
                color="white"
                strong={true}
                onClick={cancelClicked}
              >
                Cancel
              </IonButton>
            )}
            <IonButton
              className={`hk-desktop-person-button`}
              disabled={!appointment?.allow_reschedule}
              onClick={rescheduleClicked}
            >
              Reschedule
            </IonButton>
          </>
        )}
      </div>
    );
  }

  function MobileServicePersonCardView() {
    return (
      <div className={`hk-person-card`} {...rest}>
        <div className="hk-person-card-header-wrapper">
          <div className="hk-person-card-header">
            {icon && (
              <IonIcon className="hk-season-icon" color="white" icon={icon} />
            )}
            {completed ? (
              <div className="hk-person-card-header-name-completed">
                <IonText className="hk-person-card-header-name" color="white">
                  {appointment?.name}
                </IonText>
                <div className="hk-complete-pill">complete</div>
              </div>
            ) : (
              <IonText className="hk-person-card-header-name" color="white">
                {appointment?.name}
              </IonText>
            )}
          </div>
        </div>
        <div className="hk-person-card-body-wrapper">
          <div className="hk-person-card-body">
            <IonThumbnail slot="end" className="hk-list-card-thumbnail">
              <Thumbnail
                src={
                  !!appointment?.worker?.image
                    ? appointment?.worker?.image
                    : AvatarMissing
                }
                alt="Service person"
              />
            </IonThumbnail>
            <div className="hk-person-card-body-details">
              <div className="hk-person-card-body-details-name">
                <IonText color="dark" className="hk-bold">
                  {person?.name}
                </IonText>
              </div>
              <div>
                <IonText color="dark">
                  {person?.title ? person?.title : 'Maintenance Specialist'}
                </IonText>
              </div>
            </div>
          </div>
          <div className="hk-person-card-footer">
            <span>
              <IonIcon color={completed ? 'grey' : 'dark'} icon={Clock} />
              <IonText color={completed ? 'grey' : 'dark'}>{date}</IonText>
            </span>
            <span>&nbsp;</span>
            <IonText color={completed ? 'grey' : 'dark'} className="hk-bold">
              {!!time ? `(${time})` : '-'}
            </IonText>
          </div>
          {!completed && (
            <div className="hk-person-button-wrapper">
              {appointment?.allow_cancel && (
                <IonButton
                  className={`hk-person-cancel-button`}
                  expand="block"
                  color="white"
                  strong={true}
                  onClick={cancelClicked}
                >
                  Cancel
                </IonButton>
              )}

              <IonButton
                className={`hk-person-button}`}
                disabled={!appointment?.allow_reschedule}
                onClick={rescheduleClicked}
              >
                Reschedule
              </IonButton>
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopServicePersonCardView()
        : MobileServicePersonCardView()}
    </>
  );
};

export default ServicePersonCard;
