import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusTag } from 'core/types';

interface DigitalCatalogState {
  loading: boolean;
  taskLoading: boolean;
  error: string | null;
  catalogTasksList: any[];
  modalDetailsVisible: boolean;
  modalCompleteVisible: boolean;
  catalogTaskId: number | null;
  catalogTaskProductTypeId: number | null;
  catalogTaskDetails: any;
  currentCatalogProduct: any;
  catalogTaskStatusTags: StatusTag[] | null;
  productTemplatesList: any[];
  productTemplate: any;
  productsList: any;
}

export const initialState: DigitalCatalogState = {
  loading: false,
  taskLoading: false,
  error: null,
  catalogTasksList: [],
  modalDetailsVisible: false,
  modalCompleteVisible: false,
  catalogTaskId: null,
  catalogTaskProductTypeId: null,
  catalogTaskDetails: null,
  currentCatalogProduct: null,
  catalogTaskStatusTags: null,
  productTemplatesList: [],
  productTemplate: null,
  productsList: null
};

export const digitalCatalogSlice = createSlice({
  name: 'digitalCatalog',
  initialState,
  reducers: {
    setDigitalCatalogTaskLoading: (state, action: PayloadAction<any>) => {
      state.taskLoading = action.payload;
    },
    fetchDigitalCatalogListStart: (state) => {
      state.loading = true;
    },
    fetchDigitalCatalogListSuccess: (state, action: PayloadAction<any>) => {
      state.catalogTasksList = action.payload;
      state.loading = false;
    },
    fetchDigitalCatalogListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchDigitalCatalogTaskStart: (state, action: PayloadAction<any>) => {
      state.taskLoading = true;
    },
    fetchDigitalCatalogTaskSuccess: (state, action: PayloadAction<any>) => {
      state.catalogTaskDetails = action.payload;
      state.taskLoading = false;
    },
    fetchDigitalCatalogTaskError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.catalogTaskDetails = null;
      state.taskLoading = false;
    },
    fetchDigitalCatalogTaskStatusTags: (state, action: PayloadAction<any>) => {
      state.catalogTaskStatusTags = action.payload;
    },
    fetchDigitalCatalogTaskStatusTagsError: (
      state,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
      state.catalogTaskStatusTags = null;
    },
    fetchDigitalProductTemplateStart: (state) => {
      state.taskLoading = true;
    },
    fetchDigitalProductTemplateSuccess: (state, action: PayloadAction<any>) => {
      state.productTemplate = action.payload;
      state.taskLoading = false;
    },
    fetchDigitalProductTemplateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.taskLoading = false;
    },
    fetchDigitalProductTemplatesList: (state, action: PayloadAction<any>) => {
      state.productTemplatesList = action.payload.items;
    },
    fetchDigitalProductTemplatesListError: (
      state,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
    },
    fetchDigitalProducts: (state, action: PayloadAction<any>) => {
      const sortedProductsList = action.payload.sort(
        (a: any, b: any) => a.id - b.id
      );
      state.productsList = sortedProductsList;
    },
    fetchDigitalCatalogProductDetailsSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.currentCatalogProduct = action.payload;
      state.loading = false;
    },
    patchDigitalCatalogTaskDetailsStart: (state) => {
      state.taskLoading = true;
    },
    patchDigitalCatalogTaskDetailsSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.catalogTaskDetails = action.payload;
      state.taskLoading = false;
    },
    patchDigitalCatalogTaskDetailsError: (
      state,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
      state.taskLoading = false;
    },
    createDigitalCatalogProductStart: (state) => {
      state.taskLoading = true;
    },
    createDigitalCatalogProductSuccess: (state, action: PayloadAction<any>) => {
      state.taskLoading = false;
    },
    createDigitalCatalogProductError: (state, action: PayloadAction<any>) => {
      state.taskLoading = false;
      state.error = action.payload;
    },
    showCatalogModal: (state, action: PayloadAction<any>) => {
      const { taskId, productTypeId } = action.payload;
      if (!!taskId) {
        state.catalogTaskId = taskId;
      }
      if (!!productTypeId) {
        state.catalogTaskProductTypeId = productTypeId;
      }
      state.modalDetailsVisible = true;
    },
    hideCatalogModal: (state) => {
      state.loading = true;
      state.taskLoading = true;
      state.catalogTaskDetails = null;
      state.catalogTaskId = null;
      state.catalogTaskProductTypeId = null;
      state.catalogTaskStatusTags = null;
      state.productTemplate = null;
      state.currentCatalogProduct = null;
      state.modalDetailsVisible = false;
    },
    showCompleteModal: (state) => {
      state.modalCompleteVisible = true;
    },
    hideCompleteModal: (state) => {
      state.modalCompleteVisible = false;
    },
    clearCurrentCatalogProduct: (state) => {
      state.currentCatalogProduct = null;
    },
    resetDigitalCatalogTaskDetails: (state) => {
      state.catalogTaskDetails = null;
      state.catalogTaskStatusTags = null;
      state.productTemplate = null;
      state.productTemplatesList = [];
      state.productsList = null;
    },
    resetDigitalCatalogList: () => initialState
  }
});

export const {
  setDigitalCatalogTaskLoading,
  fetchDigitalCatalogListStart,
  fetchDigitalCatalogListSuccess,
  fetchDigitalCatalogListError,
  fetchDigitalCatalogTaskStart,
  fetchDigitalCatalogTaskSuccess,
  fetchDigitalCatalogTaskError,
  fetchDigitalCatalogTaskStatusTags,
  fetchDigitalCatalogTaskStatusTagsError,
  fetchDigitalProductTemplateStart,
  fetchDigitalProductTemplateSuccess,
  fetchDigitalProductTemplateError,
  fetchDigitalProductTemplatesList,
  fetchDigitalProductTemplatesListError,
  fetchDigitalProducts,
  fetchDigitalCatalogProductDetailsSuccess,
  patchDigitalCatalogTaskDetailsStart,
  patchDigitalCatalogTaskDetailsSuccess,
  patchDigitalCatalogTaskDetailsError,
  createDigitalCatalogProductStart,
  createDigitalCatalogProductSuccess,
  createDigitalCatalogProductError,
  showCatalogModal,
  showCompleteModal,
  hideCatalogModal,
  hideCompleteModal,
  clearCurrentCatalogProduct,
  resetDigitalCatalogTaskDetails,
  resetDigitalCatalogList
} = digitalCatalogSlice.actions;

export default digitalCatalogSlice.reducer;
