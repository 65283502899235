import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { IonIcon, IonList, IonRow, IonSpinner, IonText } from '@ionic/react';
import {
  setDateConfig,
  DateConfig,
  filterDaysForSelectedMonth,
  setProposalGroups,
  setProposalHash
} from '../AppointmentWizardSlice';
import { AppointmentProposal, AppointmentProposalGroup } from 'core/types';
import {
  DATE_FORMAT_DATE_DAY,
  DATE_FORMAT_DATE_NUM,
  DATE_FORMAT_FULL_MONTH_YEAR,
  DATE_FORMAT_HOUR_MINUTES,
  DATE_FORMAT_SHORT_MONTH,
  DATE_FORMAT_WEEKDAY_DATE,
  HKPlatform,
  HKScheduleType
} from 'core/constants';
import { copyJSON, formatISODate, formatTimezoneDate } from 'core/util';
import ListItem from 'components/items/list-item/ListItem';
import {
  requestAppointmentProposals,
  requestRescheduleProposals
} from '../AppointmentWizardActions';
import CalendarImg from 'assets/illustrations/calendar.svg';
import Hourglass from 'assets/illustrations/hourglass.svg';
import CalendarIllustration from 'assets/illustrations/calendar.svg';
import './AppointmentProposals.scss';
import Illustration from 'components/Illustrations/Illustration';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import Calendar from 'components/calendar/Calendar';

type AppointmentProposalsProps = {
  onProposalSelected: () => void;
};

const maxDatesSelected = 3;

const AppointmentProposals: React.FC<AppointmentProposalsProps> = ({
  onProposalSelected
}) => {
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { loading } = useSelector((state: RootState) => state.loading);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { detailedAppointment } = useSelector(
    (state: RootState) => state.appointment
  );
  const {
    wizardModalVisible,
    dateConfig,
    proposalGroups,
    scheduleHomeId,
    scheduleType,
    upcomingVisitHash,
    appointmentId,
    appointmentName
  } = useSelector((state: RootState) => state.appointmentWizard);
  const dispatch = useDispatch();

  const getAvailableDates = (availableDates: any) => {
    if (availableDates.length !== 0) {
      return availableDates;
    }
  };

  const firstAvailableDate = () => {
    if (!!dateConfig?.dateListForMonth) {
      return getAvailableDates(dateConfig?.dateListForMonth)[0];
    }
  };

  const prevMonthLabel = () => {
    if (
      !!dateConfig &&
      !!dateConfig.monthList &&
      dateConfig.monthIndex !== undefined &&
      dateConfig.monthIndex > 0
    ) {
      const date = dateConfig.monthList[dateConfig.monthIndex - 1];
      return formatISODate(date, DATE_FORMAT_SHORT_MONTH);
    }
    return '';
  };

  const nextMonthLabel = () => {
    if (
      !!dateConfig &&
      !!dateConfig.monthList &&
      dateConfig.monthIndex !== undefined &&
      dateConfig.monthIndex + 1 < dateConfig.monthList.length
    ) {
      const date = dateConfig.monthList[dateConfig.monthIndex + 1];
      return formatISODate(date, DATE_FORMAT_SHORT_MONTH);
    }
    return '';
  };

  const selectedMonthLabel = () => {
    if (
      !!dateConfig &&
      dateConfig.monthIndex !== undefined &&
      !!dateConfig.monthList
    ) {
      return formatISODate(
        dateConfig.monthList[dateConfig.monthIndex],
        DATE_FORMAT_FULL_MONTH_YEAR
      );
    }
  };

  const prevMonth = () => {
    if (dateConfig?.monthIndex !== undefined && dateConfig?.monthIndex > 0) {
      setMonthIndex(dateConfig.monthIndex - 1);
    }
  };

  const nextMonth = () => {
    if (
      dateConfig?.monthIndex !== undefined &&
      dateConfig?.monthList &&
      dateConfig?.monthIndex + 1 < dateConfig?.monthList.length
    ) {
      setMonthIndex(dateConfig.monthIndex + 1);
    }
  };

  const setMonthIndex = (index: number) => {
    if (!!dateConfig) {
      const configCopy = copyJSON(dateConfig) as DateConfig;
      configCopy.monthIndex = index;
      filterDaysForSelectedMonth(configCopy);
      dispatch(setDateConfig(configCopy));
      document.getElementById('date-list')?.scrollTo(0, 0);
    }
  };

  const toggleDate = (date: string) => {
    if (!!dateConfig) {
      // clear date
      dispatch(setProposalGroups([]));
      // add date
      if (!!date && !!proposalGroups) {
        if (scheduleType === HKScheduleType.NEW && !!upcomingVisitHash) {
          dispatch(
            requestAppointmentProposals(scheduleHomeId, upcomingVisitHash, date)
          );
        }
        if (scheduleType === HKScheduleType.RESCHEDULE && !!appointmentId) {
          dispatch(
            requestRescheduleProposals(scheduleHomeId, appointmentId, date)
          );
        }
      }
    }
  };

  const selectProposal = (hash: string) => {
    dispatch(setProposalHash(hash));
    onProposalSelected();
  };

  useEffect(() => {
    if (wizardModalVisible && !proposalGroups) {
      if (scheduleType === HKScheduleType.NEW && !!upcomingVisitHash) {
        dispatch(
          requestAppointmentProposals(scheduleHomeId, upcomingVisitHash)
        );
      }
      if (scheduleType === HKScheduleType.RESCHEDULE && !!appointmentId) {
        dispatch(requestRescheduleProposals(scheduleHomeId, appointmentId));
      }
    }
  }, [
    dispatch,
    wizardModalVisible,
    proposalGroups,
    upcomingVisitHash,
    scheduleHomeId,
    scheduleType
  ]);

  function DesktopAppointmentProposalsView() {
    return (
      <div className="hk-desktop-appointment-proposals">
        <div className="hk-desktop-appointment-proposals-calendar">
          <div className="hk-desktop-appointment-proposals-calendar__title">
            <Illustration
              className="calendar-image"
              image={CalendarIllustration}
            />
            <div>
              <h2>
                {scheduleType === HKScheduleType.NEW
                  ? 'What days work best for you?'
                  : 'Need to reschedule?'}
              </h2>
              <h6>
                {scheduleType === HKScheduleType.NEW
                  ? 'Select a day to view available appointment times.'
                  : 'You can reschedule your appointment to one of the available times below.'}
              </h6>
              {scheduleType === HKScheduleType.RESCHEDULE && (
                <h6 className="hk-appointment-wizard-current">
                  Currently scheduled for:
                  <IonText color="black" className="ion-text-">
                    <b>
                      {formatTimezoneDate(
                        detailedAppointment?.scheduled || '',
                        DATE_FORMAT_WEEKDAY_DATE,
                        currentHome?.market_timezone
                      )}
                    </b>
                  </IonText>
                </h6>
              )}
            </div>
          </div>
          <div className="hk-desktop-appointment-proposals-calendar__date-picker">
            {!!dateConfig && (
              <div
                className="hk-desktop-appointment-proposals-dates"
                id="date-menu"
              >
                <Calendar
                  onDateSelected={(date) => toggleDate(date)}
                  initialDateSelected={firstAvailableDate()}
                  availableDates={getAvailableDates(
                    dateConfig?.dateListForMonth
                  )}
                />
              </div>
            )}
          </div>
        </div>
        <div className="hk-desktop-appointment-proposals-dates-list">
          {!loading &&
            !!dateConfig &&
            !!proposalGroups &&
            proposalGroups.length === 0 && (
              <div className="hk-desktop-appointment-proposals-no-dates">
                <img src={CalendarImg} alt="Hourglass" />
                <h4>No Dates Selected</h4>
              </div>
            )}
          {!loading && !!dateConfig && !!proposalGroups && (
            <IonList className="hk-desktop-appointment-proposals-list">
              {proposalGroups?.map((group: AppointmentProposalGroup, i) => {
                return (
                  <div key={`section-${i}`}>
                    <h4
                      className="hk-desktop-appointment-proposals-list-header"
                      key={`appointment-header-${i}`}
                    >
                      {formatISODate(group.date, DATE_FORMAT_WEEKDAY_DATE)}
                    </h4>
                    {group.proposals.length === 0 && (
                      <div className="hk-desktop-appointment-proposals-list-none">
                        <img src={Hourglass} alt="Hourglass" />
                        <h5>No Time Slots Available</h5>
                      </div>
                    )}
                    {group.proposals?.map((appt: AppointmentProposal, j) => {
                      const { schedule_hash, schedule_start, schedule_end } =
                        appt;
                      return (
                        <ListItem
                          key={`item-${j}`}
                          title={`${formatTimezoneDate(
                            schedule_start,
                            DATE_FORMAT_HOUR_MINUTES,
                            currentHome?.market_timezone
                          )} - ${formatTimezoneDate(
                            schedule_end,
                            DATE_FORMAT_HOUR_MINUTES,
                            currentHome?.market_timezone
                          )}`}
                          description={appointmentName || ''}
                          onClick={() => selectProposal(schedule_hash)}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </IonList>
          )}
          {loading && (
            <div className="hk-body-spinner">
              <IonRow className="vertical-align-center">
                <IonSpinner name="dots" />
              </IonRow>
            </div>
          )}
        </div>
      </div>
    );
  }

  function MobileAppointmentProposalsView() {
    return (
      <div className="hk-appointment-proposals">
        {!!dateConfig && (
          <div className="hk-appointment-proposals-dates" id="date-menu">
            {dateConfig.monthIndex !== undefined && (
              <div className="hk-appointment-proposals-dates-months">
                <div
                  onClick={prevMonth}
                  className="hk-appointment-proposals-dates-months-prev"
                >
                  {prevMonthLabel() !== '' && (
                    <IonIcon icon={chevronBackOutline} />
                  )}
                  {prevMonthLabel()}
                </div>
                <div className="hk-appointment-proposals-dates-months-current">
                  {selectedMonthLabel()}
                </div>
                <div
                  onClick={nextMonth}
                  className="hk-appointment-proposals-dates-months-next"
                >
                  {nextMonthLabel()}
                  {nextMonthLabel() !== '' && (
                    <IonIcon icon={chevronForwardOutline} />
                  )}
                </div>
              </div>
            )}
            <div className="hk-appointment-proposals-dates-list" id="date-list">
              {dateConfig.dateListForMonth?.map((date, i) => {
                return (
                  <div
                    key={'date-' + i}
                    onClick={() => toggleDate(date)}
                    className={`hk-appointment-proposals-dates-list-date ${
                      proposalGroups?.map((group) => group.date).includes(date)
                        ? 'selected'
                        : proposalGroups?.length === maxDatesSelected
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <div className="hk-appointment-proposals-dates-list-date-num">
                      {formatISODate(date, DATE_FORMAT_DATE_NUM)}
                    </div>
                    <div className="hk-appointment-proposals-dates-list-date-day">
                      {formatISODate(date, DATE_FORMAT_DATE_DAY)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {!loading &&
          !!dateConfig &&
          !!proposalGroups &&
          proposalGroups.length === 0 && (
            <div className="hk-appointment-proposals-no-dates">
              <img src={CalendarImg} alt="Hourglass" />
              <h4>No Dates Selected</h4>
            </div>
          )}
        {!loading && !!dateConfig && !!proposalGroups && (
          <IonList className="hk-appointment-proposals-list">
            {proposalGroups?.map((group: AppointmentProposalGroup, i) => {
              return (
                <div key={`section-${i}`}>
                  <h4
                    className="hk-appointment-proposals-list-header"
                    key={`appointment-header-${i}`}
                  >
                    {formatISODate(group.date, DATE_FORMAT_WEEKDAY_DATE)}
                  </h4>
                  {group.proposals.length === 0 && (
                    <div className="hk-appointment-proposals-list-none">
                      <img src={Hourglass} alt="Hourglass" />
                      <h5>No Time Slots Available</h5>
                    </div>
                  )}
                  {group.proposals?.map((appt: AppointmentProposal, j) => {
                    const { schedule_hash, schedule_start, schedule_end } =
                      appt;
                    return (
                      <ListItem
                        key={`item-${j}`}
                        title={`${formatTimezoneDate(
                          schedule_start,
                          DATE_FORMAT_HOUR_MINUTES,
                          currentHome?.market_timezone
                        )} - ${formatTimezoneDate(
                          schedule_end,
                          DATE_FORMAT_HOUR_MINUTES,
                          currentHome?.market_timezone
                        )}`}
                        description={appointmentName || ''}
                        onClick={() => selectProposal(schedule_hash)}
                      />
                    );
                  })}
                </div>
              );
            })}
          </IonList>
        )}
        {loading && (
          <div className="hk-body-spinner">
            <IonRow className="vertical-align-center">
              <IonSpinner name="dots" />
            </IonRow>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopAppointmentProposalsView()
        : MobileAppointmentProposalsView()}
    </>
  );
};

export default AppointmentProposals;
