import { createApiRequest } from 'core/util';
import { USER_CONTACT_URL, USER_URL, ZENDESK_AUTH_URL } from 'core/constants';
import { AppThunk, AppDispatch } from '../store';
import {
  fetchUserFailure,
  fetchZendeskTokenFailure,
  fetchZendeskTokenSuccess,
  setImpersonating,
  setUserId,
  setUserInfo,
  updateUserFailure,
  updateUserMeFailure,
  updateUserMeSuccess,
  updateUserSuccess
} from './UserSlice';
import { setHomes } from './HomeSlice';
import { requestHomeInfo } from './HomeActions';

export const requestUserInfo = () =>
  createApiRequest({
    route: USER_URL,
    onGlobalSuccess: fetchGlobalSuccess,
    onSuccess: fetchUserSuccess,
    onError: fetchUserFailure,
    defaultErrorMessage: 'There was a problem retrieving your account.'
  })();

export const fetchUserSuccess =
  (me: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setUserId(me));
    dispatch(setUserInfo(me.contact));
    dispatch(setHomes(me.homes));
    dispatch(requestHomeInfo(me.homes[0].id));
  };

export const fetchGlobalSuccess =
  (global: any): AppThunk =>
  async (dispatch: AppDispatch) => {
    dispatch(setImpersonating(global.impersonating));
  };

export const updateUserMe = (user: object) =>
  createApiRequest({
    route: USER_URL,
    method: 'patch',
    body: user,
    onSuccess: updateUserMeSuccess,
    onError: updateUserMeFailure,
    defaultErrorMessage: 'There was a problem updating your account.',
    useGlobalLoader: true
  })();

export const updateUser = (user: object) =>
  createApiRequest({
    route: USER_CONTACT_URL,
    method: 'patch',
    body: user,
    onSuccess: updateUserSuccess,
    onError: updateUserFailure,
    defaultErrorMessage: 'There was a problem updating your account.',
    useGlobalLoader: true
  })();

export const fetchZendeskToken = (returnTo: string) =>
  createApiRequest({
    route: ZENDESK_AUTH_URL,
    replace: [['return_to', returnTo]],
    onSuccess: fetchZendeskTokenSuccess,
    onError: fetchZendeskTokenFailure,
    defaultErrorMessage: 'There was a problem fetching your help token.'
  })();
