import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HOMEKEEP_CONTACT } from 'core/constants';
import { ContactInfo } from 'core/types';

interface ContactsState {
  loading: boolean;
  error: string | null;
  currentContact: ContactInfo | null;
  currentContactId: number | null;
  contacts: ContactInfo[];
  newContactModalVisible: boolean;
  editContactModalVisible: boolean;
}

export const initialState: ContactsState = {
  loading: false,
  error: null,
  currentContact: null,
  currentContactId: null,
  contacts: [],
  newContactModalVisible: false,
  editContactModalVisible: false
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    fetchContactListStart: (state) => {
      state.loading = true;
    },
    fetchContactListSuccess: (state, action: PayloadAction<any>) => {
      const { items } = action.payload;
      state.contacts = items;
      state.contacts.push(HOMEKEEP_CONTACT);
      state.loading = false;
    },
    fetchContactListError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchContactStart: (state) => {
      state.loading = true;
    },
    fetchContactSuccess: (state, action: PayloadAction<any>) => {
      state.currentContact = action.payload;
      state.loading = false;
    },
    fetchContactError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    createContactStart: (state) => {
      state.loading = true;
    },
    createContactSuccess: (state) => {
      state.newContactModalVisible = false;
      state.loading = false;
    },
    createContactError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    updateContactStart: (state) => {
      state.loading = true;
    },
    updateContactSuccess: (state) => {
      state.loading = false;
    },
    updateContactError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    deleteContactStart: (state) => {
      state.loading = true;
    },
    deleteContactSuccess: (state) => {
      state.loading = false;
    },
    deleteContactError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
      state.loading = false;
    },
    showNewContactsModal: (state) => {
      state.newContactModalVisible = true;
    },
    hideNewContactsModal: (state) => {
      state.newContactModalVisible = false;
    },
    showEditContactsModal: (state, action: PayloadAction<any>) => {
      state.currentContactId = action.payload;
      state.editContactModalVisible = true;
    },
    hideEditContactsModal: (state) => {
      state.currentContactId = null;
      state.editContactModalVisible = false;
    },
    resetContactsList: () => initialState,
    resetCurrentContact: (state) => {
      state.currentContact = null;
    }
  }
});

export const {
  fetchContactListStart,
  fetchContactListSuccess,
  fetchContactListError,
  fetchContactStart,
  fetchContactSuccess,
  fetchContactError,
  createContactStart,
  createContactSuccess,
  createContactError,
  updateContactStart,
  updateContactSuccess,
  updateContactError,
  deleteContactStart,
  deleteContactSuccess,
  deleteContactError,
  showNewContactsModal,
  hideNewContactsModal,
  showEditContactsModal,
  hideEditContactsModal,
  resetContactsList,
  resetCurrentContact
} = contactsSlice.actions;

export default contactsSlice.reducer;

export function contactOwnedByUser(id: number, idList: number[]): boolean {
  if (idList) {
    return idList.includes(id);
  }
  return false;
}
