import { createApiRequest } from 'core/util';
import { TaskBodyDetails } from 'core/types';
import {
  fetchDigitalTasksError,
  fetchDigitalFinishedTasks,
  fetchDigitalTaskDetailsStart,
  fetchDigitalTaskDetailsSuccess,
  fetchDigitalTaskStatusTags,
  fetchDigitalTaskStatusTagsError,
  fetchDigitalUpcomingTasksStart,
  fetchDigitalUpcomingTasksSuccess,
  fetchDigitalOverdueTasks,
  patchDigitalTaskDetailsError,
  patchDigitalTaskDetailsStart,
  patchDigitalTaskDetailsSuccess,
  fetchProductTypeTogglesSuccess,
  fetchProductTypeTogglesStart,
  patchProductTypeTogglesSuccess,
  patchProductTypeTogglesError
} from './DigitalTasksSlice';
import {
  DIGITAL_TASK_URL,
  FINISHED_TASKS_URL,
  OVERDUE_TASKS_URL,
  PRODUCT_TYPE_TOGGLE,
  PRODUCT_TYPE_TOGGLES,
  STATUS_TAG_LIST_URL,
  UPCOMING_TASKS_URL
} from 'core/constants';

export const fetchUpcomingTasks = (homeId: number) =>
  createApiRequest({
    route: UPCOMING_TASKS_URL,
    method: 'get',
    replace: [['home_id', homeId]],
    onStart: fetchDigitalUpcomingTasksStart,
    onSuccess: fetchDigitalUpcomingTasksSuccess,
    onError: fetchDigitalTasksError,
    defaultErrorMessage: 'There was a problem fetching your upcoming visits.'
  })();

export const fetchOverdueTasks = (homeId: number) =>
  createApiRequest({
    route: OVERDUE_TASKS_URL,
    method: 'get',
    replace: [['home_id', homeId]],
    onSuccess: fetchDigitalOverdueTasks,
    onError: fetchDigitalTasksError,
    defaultErrorMessage: 'There was a problem fetching your upcoming visits.'
  })();

export const fetchCompletedTasks = (homeId: number) =>
  createApiRequest({
    route: FINISHED_TASKS_URL,
    replace: ['home_id', homeId],
    onSuccess: fetchDigitalFinishedTasks,
    onError: fetchDigitalTasksError,
    defaultErrorMessage: 'There was a problem loading your completed tasks.',
    useGlobalLoader: true
  })();

export const fetchTaskDetails = (homeId: number, taskId: number) =>
  createApiRequest({
    route: DIGITAL_TASK_URL,
    method: 'get',
    replace: [
      ['home_id', homeId],
      ['task_id', taskId]
    ],
    onStart: fetchDigitalTaskDetailsStart,
    onSuccess: fetchDigitalTaskDetailsSuccess,
    onError: fetchDigitalTasksError,
    defaultErrorMessage: 'There was a problem fetching your digital task.'
  })();

export const fetchTaskStatusTags = () =>
  createApiRequest({
    route: STATUS_TAG_LIST_URL,
    method: 'get',
    onSuccess: fetchDigitalTaskStatusTags,
    onError: fetchDigitalTaskStatusTagsError
  })();

export const fetchProductTypeToggles = (homeId: number) =>
  createApiRequest({
    route: PRODUCT_TYPE_TOGGLES,
    method: 'get',
    replace: [['home_id', homeId]],
    onStart: fetchProductTypeTogglesStart,
    onSuccess: fetchProductTypeTogglesSuccess,
    onError: fetchDigitalTasksError,
    defaultErrorMessage: 'There was a problem fetching your upcoming visits.'
  })();

export const patchProductTypeToggle = (
  homeId: number,
  productTypeId: number,
  body: object
) =>
  createApiRequest({
    route: PRODUCT_TYPE_TOGGLE,
    replace: [
      ['home_id', homeId],
      ['type_id', productTypeId]
    ],
    method: 'patch',
    body: body,
    onSuccess: patchProductTypeTogglesSuccess,
    onError: patchProductTypeTogglesError,
    defaultErrorMessage: 'There was an error updating the product toggle.'
  })();

export const patchTaskDetails = (
  homeId: number,
  taskId: number,
  body: TaskBodyDetails
) =>
  createApiRequest({
    route: DIGITAL_TASK_URL,
    replace: [
      ['home_id', homeId],
      ['task_id', taskId]
    ],
    method: 'patch',
    body: body,
    onStart: patchDigitalTaskDetailsStart,
    onSuccess: patchDigitalTaskDetailsSuccess,
    onError: patchDigitalTaskDetailsError,
    defaultErrorMessage: 'There was an error updating the product templates.'
  })();
