import { Capacitor } from '@capacitor/core';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import store from 'core/store';
import { displayError } from './AlertActions';
import { getEnvName, saveFirebaseTokenInStorage } from '../util';
import {
  HKEnvironment,
  PUSH_NOTIFICATIONS_DENIED,
  PUSH_NOTIFICATIONS_NOTAVAILABLE
} from '../constants';
import { updateUserWithPushNotificationToken } from './PlatformActions';
import { setPushNotificationToken } from './PlatformSlice';

// From capacitorjs.com -- https://capacitorjs.com/docs/apis/push-notifications#removedeliverednotifications

export const registerForPushNotifications = () => {
  const dispatch = store.dispatch;
  if (Capacitor.isPluginAvailable('PushNotifications')) {
    PushNotifications.addListener('registration', (token: Token) => {
      const tokenValue = token.value;
      dispatch(setPushNotificationToken(tokenValue));
      dispatch(updateUserWithPushNotificationToken(tokenValue));
      // output token for testing
      if (getEnvName() === HKEnvironment.LOCL) {
        console.log(`firebase token: ${tokenValue}`);
      }
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      dispatch(displayError('Error on registration: ' + JSON.stringify(error)));
    });

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register().then();
      } else {
        setPushNotificationToken(PUSH_NOTIFICATIONS_DENIED);
        saveFirebaseTokenInStorage(PUSH_NOTIFICATIONS_DENIED).then();
      }
    });
  } else {
    setPushNotificationToken(PUSH_NOTIFICATIONS_NOTAVAILABLE);
    saveFirebaseTokenInStorage(PUSH_NOTIFICATIONS_NOTAVAILABLE).then();
  }
};
