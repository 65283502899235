import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSpinner
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import MenuHeader from 'core/layout/menu-header/MenuHeader';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './DigitalCatalog.scss';
import ReactPlayer from 'react-player';
import LoadingIonList from 'core/layout/LoadingIonList';
import CatalogAccordion from 'components/accordion/catalog/CatalogAccordion';
import TaskCard, { TaskType } from 'components/cards/task/TaskCard';
import {
  hideCatalogModal,
  hideCompleteModal,
  resetDigitalCatalogList,
  showCatalogModal,
  showCompleteModal
} from 'features/digital/store/DigitalCatalogSlice';
import DigitalCatalogDetails from './DigitalCatalogDetails';
import { fetchCatalogTasksList } from 'features/digital/store/DigitalCatalogActions';
import { DigitalCatalogTask } from 'core/types';
import DigitalCatalogComplete from './DigitalCatalogComplete';
import { gaBtnClickEvent } from 'core/util';

const DigitalCatalog: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { catalogTasksList, modalDetailsVisible, modalCompleteVisible } =
    useSelector((state: RootState) => state.digitalCatalog);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const [pageLoad, setPageLoad] = useState(true);

  const dispatch = useDispatch();

  const fetchAllCatalogData = async () => {
    setPageLoad(true);
    dispatch(resetDigitalCatalogList());
    if (!!homeId) {
      await dispatch(fetchCatalogTasksList(homeId));
    }
    setPageLoad(false);
  };

  const openTask = (taskId: number, productTypeId: number) => {
    gaBtnClickEvent('open_catalog_task');
    dispatch(
      showCatalogModal({
        taskId,
        productTypeId
      })
    );
  };

  const closeTask = () => {
    if (!!homeId) {
      dispatch(fetchCatalogTasksList(homeId));
    }
    dispatch(hideCatalogModal());
  };

  const openCompleteModal = () => {
    closeCompleteModal();
    dispatch(showCompleteModal());
  };

  const closeCompleteModal = () => {
    dispatch(hideCompleteModal());
  };

  const allCatalogTasksCompleted = () => {
    const totalFinished = catalogTasksList.filter(
      (task) => !!task.finished
    ).length;
    return (
      !!catalogTasksList.length && totalFinished === catalogTasksList.length
    );
  };

  const sortedTasksList = (tasks: DigitalCatalogTask[]) => {
    let listOfGroups: any[] = [];
    let uniqueListOfGroups = new Set();
    tasks.forEach((task) => {
      const group = task.product_type_group;
      uniqueListOfGroups.add(group);
    });
    listOfGroups = Array.from(uniqueListOfGroups).filter((group) => {
      return group !== undefined;
    });
    return listOfGroups;
  };

  const getFilteredTasks = (tasks: DigitalCatalogTask[], group: string) => {
    let groupedTasksList: any[] = [];
    tasks.forEach((task) => {
      if (task.product_type_group === group) {
        groupedTasksList.push(task);
      }
    });
    return groupedTasksList;
  };

  const isGroupFinished = (group: string) => {
    return getFilteredTasks(catalogTasksList, group).every(
      (task) => !!task.finished
    );
  };

  const progressBarAmount = (list: any[]) => {
    const sum = list.length;
    const totalFinished = list.filter((task) => !!task.finished).length;
    return totalFinished / sum;
  };

  const openPlansLink = () => {
    gaBtnClickEvent('see_whats_available');
    window.open('https://homekeep.com/plans', '_blank');
  };

  useEffect(() => {
    if (!pageLoad) {
      if (allCatalogTasksCompleted()) {
        setTimeout(() => {
          openCompleteModal();
        }, 0);
      }
    }
  }, [catalogTasksList, pageLoad]);

  useEffect(() => {
    fetchAllCatalogData();
  }, [homeId]);

  function DesktopDigitalCatalogView() {
    return (
      <>
        <IonRow className="hk-desktop-digital-catalog-header">
          <div className="hk-desktop-digital-catalog-header-container">
            <IonBreadcrumbs>
              <IonBreadcrumb href="/dashboard">Dashboard</IonBreadcrumb>
              <IonBreadcrumb href="/tasks">Home Profile</IonBreadcrumb>
            </IonBreadcrumbs>
            <div className="hk-desktop-digital-catalog-header-progress">
              <IonProgressBar
                className="hk-desktop-digital-catalog-header-progress-bar"
                value={
                  !!catalogTasksList.length
                    ? progressBarAmount(catalogTasksList)
                    : 0
                }
              ></IonProgressBar>
              <div className="hk-desktop-digital-catalog-header-progress-percentage">
                {!!catalogTasksList.length
                  ? Math.round(progressBarAmount(catalogTasksList) * 100)
                  : 0}
                %
              </div>
            </div>
          </div>
        </IonRow>
        <IonContent>
          <div className="hk-desktop-digital-catalog-body">
            {pageLoad ? (
              <div className="hk-loading-spinner">
                <IonSpinner name="crescent" color="primary" />
              </div>
            ) : (
              <LoadingIonList
                className="hk-desktop-digital-catalog-task-list"
                label={'Catalog Tasks'}
                items={catalogTasksList}
                loading={pageLoad}
              >
                {sortedTasksList(catalogTasksList).map(
                  (group: string, i: number) => {
                    return (
                      <CatalogAccordion
                        key={`catalog-group-${i}}`}
                        label={group}
                        complete={isGroupFinished(group)}
                      >
                        {getFilteredTasks(catalogTasksList, group).map(
                          (task: DigitalCatalogTask, j: number) => {
                            return (
                              <IonItem key={`catalog-item-${j}`}>
                                <TaskCard
                                  className="hk-desktop-digital-catalog-task"
                                  title={task.name}
                                  description={task.description}
                                  image={task.image!}
                                  time={!!task.duration ? task.duration : 5}
                                  completed={!!task?.finished}
                                  taskClicked={() =>
                                    openTask(task.id, task.product_type_id)
                                  }
                                ></TaskCard>
                              </IonItem>
                            );
                          }
                        )}
                      </CatalogAccordion>
                    );
                  }
                )}
              </LoadingIonList>
            )}

            <div className="hk-desktop-digital-catalog-video">
              <IonLabel className="hk-desktop-digital-catalog-video-label">
                Task Overview Video
              </IonLabel>
              <ReactPlayer
                className="hk-desktop-digital-catalog-video-player"
                width="260px"
                height="100%"
                controls={true}
                url={
                  'https://media.homekeep.com/maintenancevideos/cataloging.mp4'
                }
              />
              <div className="hk-desktop-digital-catalog-video-text">
                <span>Want HomeKeep to do this for you?</span>
                <IonButton
                  expand="block"
                  color="secondary"
                  strong={true}
                  className="hk-desktop-digital-catalog-video-text-button"
                  onClick={openPlansLink}
                >
                  See What's Available
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      </>
    );
  }

  function MobileDigitalCatalogView() {
    return (
      <>
        <MenuHeader title="Home Profile"></MenuHeader>
        <IonContent>
          <div className="hk-digital-catalog-progress">
            <IonProgressBar
              className="hk-digital-catalog-progress-bar"
              value={
                !!catalogTasksList.length
                  ? progressBarAmount(catalogTasksList)
                  : 0
              }
            ></IonProgressBar>
            <div className="hk-digital-catalog-progress-percentage">
              {!!catalogTasksList.length
                ? Math.round(progressBarAmount(catalogTasksList) * 100)
                : 0}
              %
            </div>
          </div>
          <div className="hk-digital-catalog-video">
            <ReactPlayer
              width="100%"
              height="100%"
              controls={true}
              url={
                'https://media.homekeep.com/maintenancevideos/cataloging.mp4'
              }
            />
          </div>
          <div className="hk-digital-catalog-video-text">
            <span>Want Homekeep to do this for you?</span>
            <IonButton
              expand="block"
              color="secondary"
              strong={true}
              className="hk-digital-catalog-video-text-button"
              onClick={openPlansLink}
            >
              See What's Available
            </IonButton>
          </div>
          <LoadingIonList
            className="hk-digital-catalog-task-list"
            label={'Catalog Tasks'}
            items={catalogTasksList}
            loading={pageLoad}
          >
            {sortedTasksList(catalogTasksList).map(
              (group: string, i: number) => {
                return (
                  <CatalogAccordion
                    key={`catalog-group-${i}}`}
                    label={group}
                    complete={isGroupFinished(group)}
                  >
                    {getFilteredTasks(catalogTasksList, group).map(
                      (task: DigitalCatalogTask, j: number) => {
                        return (
                          <IonItem key={`catalog-item-${j}`}>
                            <TaskCard
                              className="hk-digital-catalog-task"
                              title={task.name}
                              description={task.description}
                              image={task.image!}
                              time={!!task.duration ? task.duration : 5}
                              completed={!!task?.finished}
                              type={TaskType.CATALOG}
                              taskClicked={() =>
                                openTask(task.id, task.product_type_id)
                              }
                            ></TaskCard>
                          </IonItem>
                        );
                      }
                    )}
                  </CatalogAccordion>
                );
              }
            )}
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-digital-catalog-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDigitalCatalogView()
        : MobileDigitalCatalogView()}
      <IonModal
        className="hk-catalog-task-modal"
        isOpen={modalDetailsVisible}
        onDidDismiss={() => {
          closeTask();
        }}
      >
        <DigitalCatalogDetails />
      </IonModal>
      <IonModal
        className="hk-catalog-complete-modal"
        isOpen={modalCompleteVisible}
        onDidDismiss={() => {
          closeCompleteModal();
        }}
      >
        <DigitalCatalogComplete />
      </IonModal>
    </IonPage>
  );
};

export default DigitalCatalog;
