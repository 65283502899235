import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  clearStorage,
  removeAuthTokenFromStorage,
  saveAuthTokenInStorage
} from '../../../core/util';

interface LoginState {
  authEmail: string | null;
  authIdToken: string | null;
  emailSubmitted: boolean;
  loading: boolean;
  loggedIn: boolean;
  accessToken: string | null;
  loginMethod: string;
  error: string | null;
  errorPasswordReset: string | null;
  loginDest: string | null;
  initialSignInEmails: any[];
}

export const initialState: LoginState = {
  loading: false,
  authEmail: null,
  authIdToken: null,
  loggedIn: false,
  emailSubmitted: false,
  accessToken: null,
  loginMethod: '',
  error: null,
  errorPasswordReset: null,
  loginDest: null,
  initialSignInEmails: []
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setAuthEmail: (state, action: PayloadAction<string>) => {
      state.authEmail = action.payload;
    },
    setAuthIdToken: (state, action: PayloadAction<string>) => {
      state.authIdToken = action.payload;
    },
    setLoginMethod: (state, action: PayloadAction<string>) => {
      state.loginMethod = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setLoginDest: (state, action: PayloadAction<string | null>) => {
      state.loginDest = action.payload;
    },
    requestTokenForFirebaseUserStart: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = true;
    },
    requestTokenForFirebaseUserSuccess: (state, action: PayloadAction<any>) => {
      state.accessToken = action.payload.access_token;
      state.loading = false;
      state.loggedIn = true;
      saveAuthTokenInStorage(action.payload.access_token).then();
    },
    requestTokenForFirebaseUserError: (
      state,
      action: PayloadAction<string>
    ) => {
      state.authEmail = null;
      state.authIdToken = null;
      state.accessToken = null;
      state.error = action.payload;
      state.loading = false;
    },
    requestFirebasePasswordResetStart: (
      state,
      action: PayloadAction<string>
    ) => {
      state.errorPasswordReset = null;
      state.loading = true;
    },
    requestFirebasePasswordResetSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.authEmail = action.payload.login_email;
      state.loading = false;
    },
    requestFirebasePasswordResetError: (state, action: PayloadAction<any>) => {
      state.errorPasswordReset = action.payload;
      state.loading = false;
    },
    fetchInitialSignInEmailsStart: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    fetchInitialSignInEmailsSuccess: (state, action: PayloadAction<any>) => {
      const { users } = action.payload;
      state.initialSignInEmails = users;
      state.error = null;
      state.loading = false;
    },
    fetchInitialSignInEmailsError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.initialSignInEmails = [];
      state.error = action.payload;
    },
    requestInitialSignInPasswordResetStart: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = true;
    },
    requestInitialSignInPasswordResetSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
      state.error = null;
    },
    requestInitialSignInPasswordResetError: (
      state,
      action: PayloadAction<any>
    ) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearInitialSignInEmails: (state) => {
      state.loading = false;
      state.initialSignInEmails = [];
    },
    logout: (state) => {
      removeAuthTokenFromStorage().then();
      clearStorage().then();
      state.authEmail = null;
      state.authIdToken = null;
      state.emailSubmitted = false;
      state.accessToken = null;
      state.loggedIn = false;
      state.loading = false;
      state.loginMethod = '';
      state.error = null;
      state.errorPasswordReset = null;
      state.loginDest = null;
      state.initialSignInEmails = [];
    }
  }
});

export const {
  setAuthEmail,
  setAuthIdToken,
  setLoginMethod,
  setError,
  setLoginDest,
  requestTokenForFirebaseUserStart,
  requestTokenForFirebaseUserSuccess,
  requestTokenForFirebaseUserError,
  requestFirebasePasswordResetStart,
  requestFirebasePasswordResetSuccess,
  requestFirebasePasswordResetError,
  fetchInitialSignInEmailsStart,
  fetchInitialSignInEmailsSuccess,
  fetchInitialSignInEmailsError,
  requestInitialSignInPasswordResetStart,
  requestInitialSignInPasswordResetSuccess,
  requestInitialSignInPasswordResetError,
  clearInitialSignInEmails,
  logout
} = loginSlice.actions;

export default loginSlice.reducer;
