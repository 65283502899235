import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  useIonAlert
} from '@ionic/react';
import {
  CONTACT_TYPE_LIST,
  EMAIL_REGEX,
  HKPlatform,
  HOMEKEEP_CONTACT,
  HOMEKEEP_CONTACT_ID,
  PHONE_REGEX
} from 'core/constants';
import { displaySuccess } from 'core/services/AlertActions';
import { RootState } from 'core/store';
import {
  formatMobilePhoneNumber,
  gaBtnClickEvent,
  gaModalView,
  validateURL
} from 'core/util';
import {
  deleteContact,
  fetchContact,
  updateContact
} from 'features/shared/contacts/ContactsActions';
import {
  contactOwnedByUser,
  fetchContactSuccess,
  hideEditContactsModal,
  resetCurrentContact
} from 'features/shared/contacts/ContactsSlice';
import { ellipsisHorizontal } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import ErrorIcon from 'assets/icons/error.svg';
import './EditContact.scss';
import { ContactInputField, NewContactForm } from './NewContact';

interface Section {
  label: string;
  id: string;
  values: any[];
}
export const contactFields: ContactInputField[] = [
  { label: 'Contact Name', id: 'name' },
  { label: 'Phone Number', id: 'mobile_phone' },
  { label: 'Email', id: 'email' },
  { label: 'Type', id: 'type' },
  { label: 'Website', id: 'url' }
];

// This view is Desktop only
const EditContact: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { loading, currentContact, currentContactId } = useSelector(
    (state: RootState) => state.contacts
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const [sections, setSections] = useState<Section[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [selectedContactType, setSelectedContactType] = useState<string>('');

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState,
    errors
  } = useForm<NewContactForm>({
    defaultValues: {
      name: '',
      email: '',
      mobile_phone: '',
      type: '',
      url: ''
    },
    mode: 'onChange'
  });
  const dispatch = useDispatch();
  const [presentAlert] = useIonAlert();

  const contactTypeAlertOptions = {
    header: 'Contact Type',
    cssClass: 'hk-contact-type-alert'
  };

  const getContactTypeLabel = (contactType: string) => {
    const label = CONTACT_TYPE_LIST.find(
      (contact) => contact.type === contactType
    )?.name;
    return !!label ? label : contactType;
  };

  const callContact = () => {
    gaBtnClickEvent('call_contact');
    window.open(`tel:${currentContact?.mobile_phone}`);
  };

  const saveContact = (formData: any) => {
    gaBtnClickEvent('update_contact');
    // Remove empty keys in formData object before submitting
    Object.keys(formData).forEach((k) => !formData[k] && delete formData[k]);
    formData['type'] = selectedContactType;
    if (!!homeId && !!currentContactId && !!formData) {
      if (!!formData['url']) {
        formData['url'] = validateURL(formData['url']);
      }
      dispatch(updateContact(homeId, currentContactId, formData))
        .then(() => {
          dispatch(displaySuccess('Contact Updated', 'Success'));
          dispatch(fetchContact(homeId, currentContactId));
          setIsEditMode(false);
          dispatch(hideEditContactsModal());
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const removeContact = () => {
    gaBtnClickEvent('delete_contact');
    if (!!homeId && !!currentContactId) {
      dispatch(deleteContact(homeId, currentContactId))
        .then(() => {
          dispatch(displaySuccess('Contact Deleted', 'Success'));
          setIsEditMode(false);
          dispatch(hideEditContactsModal());
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  };

  const formRule = (id: string) => {
    if (id === 'mobile_phone') {
      return {
        pattern: {
          value: PHONE_REGEX,
          message: 'Invalid phone number'
        }
      };
    }
    if (id === 'email') {
      return {
        pattern: {
          value: EMAIL_REGEX,
          message: 'Invalid email address'
        }
      };
    }
    if (id === 'type' || id === 'name') {
      return {
        required: true
      };
    }
    return { required: false };
  };

  useEffect(() => {
    if (!!currentContact) {
      setSections([
        {
          label: 'Phone Number',
          id: 'mobile_phone',
          values: [
            !!currentContact.mobile_phone
              ? formatMobilePhoneNumber(currentContact.mobile_phone!)
              : 'None'
          ]
        },
        {
          label: 'Email',
          id: 'email',
          values: [!!currentContact.email ? currentContact.email : 'None']
        },
        {
          label: 'Type',
          id: 'type',
          values: [getContactTypeLabel(currentContact.type!)]
        },
        {
          label: 'Website',
          id: 'url',
          values: [!!currentContact.url ? currentContact.url : 'None']
        }
      ]);
      setSelectedContactType(currentContact.type!);
      reset({
        name: currentContact.name,
        email: currentContact.email!,
        mobile_phone: formatMobilePhoneNumber(currentContact.mobile_phone!)!,
        type: currentContact.type,
        url: currentContact.url!
      });
    }
  }, [currentContact]);

  useEffect(() => {
    gaModalView(isDigital, '/contact/details');
    if (!!homeId && !!currentContactId) {
      if (currentContactId === HOMEKEEP_CONTACT_ID) {
        dispatch(fetchContactSuccess(HOMEKEEP_CONTACT));
      } else {
        dispatch(fetchContact(homeId, currentContactId));
      }
    }
  }, [dispatch, homeId]);

  // component Unmount (cleanup)
  useEffect(() => {
    return () => {
      dispatch(resetCurrentContact());
    };
  }, []);

  function DesktopDigitalEditContactView() {
    return (
      <>
        <IonContent className="hk-digital-edit-contacts-modal-details">
          {loading ? (
            <div className="hk-loading-spinner">
              <IonSpinner name="crescent" color="primary" />
            </div>
          ) : (
            <>
              <div className="hk-digital-edit-contacts-modal-header">
                <IonFab vertical="top" horizontal="end">
                  <IonFabButton
                    color="background"
                    className={`hk-fab-button ${
                      platformType === HKPlatform.DESKTOP && isDesktopWidth
                        ? ''
                        : 'hk-fab-button-margin-top'
                    }`}
                    onClick={() => dispatch(hideEditContactsModal())}
                  >
                    <IonIcon color="dark" icon={Close} />
                  </IonFabButton>
                </IonFab>
              </div>
              <form
                className="hk-digital-edit-contacts-modal-details-edit-form"
                onSubmit={handleSubmit(saveContact)}
              >
                <div className="hk-digital-edit-contacts-modal-details-header">
                  <div className="hk-digital-edit-contacts-modal-details-header-avatar-container">
                    <div className="hk-digital-edit-contacts-modal-details-header-avatar">
                      {currentContact?.name!.charAt(0)}
                    </div>
                    {!isEditMode && <IonText>{currentContact?.name}</IonText>}
                    {isEditMode && (
                      <input
                        className="hk-digital-edit-contacts-modal-details-edit"
                        name={'name'}
                        ref={register(formRule('name'))}
                        defaultValue={getValues('name')}
                        style={{
                          color: errors['name'] ? '#ff6b69' : '#1b1c1f',
                          borderBottom: errors['name']
                            ? '1px solid #ff6b69'
                            : ''
                        }}
                        type="text"
                      />
                    )}
                  </div>
                  {currentContactId !== HOMEKEEP_CONTACT_ID &&
                    contactOwnedByUser(homeId!, currentContact?.home_ids!) && (
                      <div className="hk-digital-edit-contacts-modal-actions">
                        <IonIcon
                          icon={ellipsisHorizontal}
                          id="contact-actions"
                        ></IonIcon>
                        <IonPopover
                          trigger="contact-actions"
                          triggerAction="click"
                          dismissOnSelect={true}
                        >
                          <IonList lines="none">
                            <IonItem
                              className="hk-digital-edit-contacts-modal-actions-button"
                              button={true}
                              detail={false}
                              onClick={() => setIsEditMode(true)}
                            >
                              Edit
                            </IonItem>
                            <IonItem
                              className="hk-digital-edit-contacts-modal-actions-button"
                              button={true}
                              detail={false}
                              onClick={() =>
                                presentAlert({
                                  header: 'Confirm Delete Contact',
                                  buttons: [
                                    {
                                      text: 'Cancel',
                                      role: 'cancel',
                                      handler: () => {}
                                    },
                                    {
                                      text: 'Delete',
                                      cssClass:
                                        'hk-digital-edit-contacts-modal-actions-button-delete',
                                      role: 'destructive',
                                      handler: () => {
                                        removeContact();
                                      }
                                    }
                                  ]
                                })
                              }
                            >
                              Delete
                            </IonItem>
                          </IonList>
                        </IonPopover>
                      </div>
                    )}
                </div>
                <IonList className="ion-margin-vertical">
                  {sections.map((field, i) => {
                    const { label, id, values } = field;
                    return (
                      <div
                        key={`item-${i}`}
                        className="hk-digital-edit-contacts-modal-details-item ion-margin-horizontal"
                      >
                        <IonLabel className="hk-digital-edit-contacts-modal-details-label ion-text-nowrap">
                          <IonText>{label}</IonText>
                        </IonLabel>
                        {!isEditMode &&
                          values.map((val, j) => {
                            return !!val ? (
                              <IonText
                                key={`val-${i}-${j}`}
                                className="hk-digital-edit-contacts-modal-details-value ion-text-nowrap"
                              >
                                {id === 'url' || id === 'email' ? (
                                  <>
                                    {val === 'None' ? (
                                      val
                                    ) : (
                                      <a
                                        href={
                                          id === 'url'
                                            ? validateURL(val)
                                            : `mailto:${val}`
                                        }
                                        rel="noopener noreferrer"
                                        target="_blank"
                                      >
                                        {val}
                                      </a>
                                    )}
                                  </>
                                ) : (
                                  val
                                )}
                              </IonText>
                            ) : null;
                          })}
                        {isEditMode &&
                          values.map((val, j) => {
                            return (
                              <div key={`item-edit-${i}`}>
                                {label !== 'Type' ? (
                                  <>
                                    {label === 'Phone Number' ? (
                                      <Controller
                                        key={`controller-phone-number`}
                                        control={control}
                                        name={id}
                                        rules={formRule(id)}
                                        render={({
                                          onChange,
                                          onBlur,
                                          value
                                        }) => (
                                          <>
                                            <input
                                              style={{
                                                color: errors[id]
                                                  ? '#ff6b69'
                                                  : '#1b1c1f'
                                              }}
                                              className="hk-digital-edit-contacts-modal-details-edit"
                                              type="text"
                                              onChange={(e) => {
                                                onChange(
                                                  formatMobilePhoneNumber(
                                                    e.target.value
                                                  )
                                                );
                                              }}
                                              value={value || ''}
                                            />
                                            {!!errors[id] && (
                                              <span className="invalid-input-error">
                                                Invalid phone number
                                                <IonIcon src={ErrorIcon} />
                                              </span>
                                            )}
                                          </>
                                        )}
                                      ></Controller>
                                    ) : (
                                      <>
                                        <input
                                          name={id}
                                          ref={register(formRule(id))}
                                          defaultValue={getValues(id)}
                                          style={{
                                            color: errors[id]
                                              ? '#ff6b69'
                                              : '#1b1c1f'
                                          }}
                                          className="hk-digital-edit-contacts-modal-details-edit"
                                          type="text"
                                        />
                                        {!!errors['email'] &&
                                          id === 'email' && (
                                            <span className="invalid-input-error">
                                              Invalid email
                                              <IonIcon src={ErrorIcon} />
                                            </span>
                                          )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <IonSelect
                                    className="hk-digital-edit-contacts-modal-details-select"
                                    interfaceOptions={contactTypeAlertOptions}
                                    value={selectedContactType}
                                    onIonChange={(e) =>
                                      setSelectedContactType(e.detail.value)
                                    }
                                  >
                                    {CONTACT_TYPE_LIST.map((contact) => {
                                      return (
                                        <IonSelectOption
                                          key={contact.type}
                                          value={contact.type}
                                        >
                                          {contact.name}
                                        </IonSelectOption>
                                      );
                                    })}
                                  </IonSelect>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
                </IonList>
              </form>
            </>
          )}
        </IonContent>
        <div className="hk-digital-edit-contacts-modal-details-toolbar">
          {!isEditMode && (
            <IonButton
              disabled={!currentContact?.mobile_phone}
              expand="block"
              color="primary"
              strong={true}
              className="ion-margin"
              onClick={() => callContact()}
            >
              {loading ? <IonSpinner name="dots" /> : 'Call'}
            </IonButton>
          )}
          {isEditMode && (
            <IonButton
              expand="block"
              color="primary"
              strong={true}
              className="ion-margin"
              disabled={!formState.isValid || loading}
              onClick={handleSubmit(saveContact)}
            >
              {loading ? <IonSpinner name="dots" /> : 'Save'}
            </IonButton>
          )}
        </div>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDigitalEditContactView()
        : DesktopDigitalEditContactView()}
    </>
  );
};

export default EditContact;
