import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonContent,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonToolbar
} from '@ionic/react';
import ListItem from 'components/items/list-item/ListItem';
import SearchBar from 'components/searchbar/SearchBar';
import { HKPlatform } from 'core/constants';
import LoadingIonList from 'core/layout/LoadingIonList';
import MenuHeader from 'core/layout/menu-header/MenuHeader';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Appliances from 'assets/icons/appliances.svg';
import Exterior from 'assets/icons/exterior.svg';
import HotCold from 'assets/icons/hotcold.svg';
import Plumbing from 'assets/icons/plumbing.svg';
import Utility from 'assets/icons/utility.svg';
import Window from 'assets/icons/window.svg';
import Cosmetics from 'assets/icons/paint-brush.svg';
import HealthSafety from 'assets/icons/health-safety.svg';
import SmartHome from 'assets/icons/smart-home.svg';
import Furniture from 'assets/icons/furniture.svg';
import ArrowRight from 'assets/icons/arrow-right.svg';
import './InventoryGroups.scss';
import {
  hideAddItemModal,
  resetInventoryDetails,
  resetProductGroup,
  resetSearchedProducts,
  showAddItemModal,
  showDesktopItemDetails,
  showItemDetailsModal
} from 'features/shared/inventory/InventorySlice';
import {
  fetchSearchedProducts,
  requestProductGroupCount
} from 'features/shared/inventory/InventoryActions';
import InventoryAddProduct from './InventoryAddProduct';
import { gaBtnClickEvent } from 'core/util';
import { SearchedProduct } from 'core/types';
import ListCard from 'components/cards/list/ListCard';
import InventoryDetails from './InventoryDetails';

export enum ProductGroups {
  Appliances = 1,
  Exterior = 2,
  HeatingCooling = 3,
  Plumbing = 4,
  Utility = 5,
  HealthSafety = 6,
  Window = 7,
  Cosmetics = 8,
  Furniture = 9,
  SmartHome = 10
}

const InventoryGroups: React.FC = () => {
  const {
    loading,
    productGroups,
    searchedProducts,
    addItemModalVisible,
    itemDetailsModalVisible
  } = useSelector((state: RootState) => state.inventory);
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const generateListOfSearchedGroups = () => {
    let listOfGroups: any[] = [];
    let uniqueListOfGroups = new Set();
    if (!!searchedProducts) {
      searchedProducts.forEach((product) => {
        const group = product.group_name;
        uniqueListOfGroups.add(group);
      });
    }
    listOfGroups = Array.from(uniqueListOfGroups).filter((group) => {
      return group !== undefined;
    });
    return listOfGroups;
  };

  const filteredInventory = generateListOfSearchedGroups();

  const showGroup = (id: number) => {
    if (id !== undefined) {
      dispatch(resetProductGroup());
      history.push(`/inventory/group/${id}`);
    }
  };

  const goToInventoryDetailsPage = (groupId: number, productId: number) => {
    if (groupId !== undefined) {
      dispatch(resetInventoryDetails());
      dispatch(showDesktopItemDetails(productId));
      history.push(`/inventory/group/${groupId}/product/${productId}`);
    }
  };

  const openAddItemModal = () => {
    gaBtnClickEvent('add_item');
    dispatch(showAddItemModal());
  };

  const closeTask = () => {
    if (!!homeId) {
      dispatch(requestProductGroupCount(homeId));
    }
    dispatch(hideAddItemModal());
  };

  const imageForItem = (item: SearchedProduct): string => {
    if (!!item.image) {
      return item.image;
    }
    if (!!item.product_template.manufacturer_image) {
      return item.product_template.manufacturer_image;
    }
    return '';
  };

  const iconForGroup = (id: number): string => {
    switch (id) {
      case ProductGroups.Appliances:
        return Appliances;
      case ProductGroups.Cosmetics:
        return Cosmetics;
      case ProductGroups.Exterior:
        return Exterior;
      case ProductGroups.HealthSafety:
        return HealthSafety;
      case ProductGroups.HeatingCooling:
        return HotCold;
      case ProductGroups.Plumbing:
        return Plumbing;
      case ProductGroups.Utility:
        return Utility;
      case ProductGroups.Window:
        return Window;
      case ProductGroups.Furniture:
        return Furniture;
      case ProductGroups.SmartHome:
        return SmartHome;
    }
    return '';
  };

  const showDetailsModal = (id: number) => {
    dispatch(showItemDetailsModal(id));
  };

  const closeDetailsModal = () => {
    if (!!homeId) {
      dispatch(requestProductGroupCount(homeId));
    }
    dispatch(hideAddItemModal());
  };

  useEffect(() => {
    if (!!homeId) {
      dispatch(requestProductGroupCount(homeId));
    }
  }, [dispatch, homeId]);

  useEffect(() => {
    if (!!homeId && !!search) {
      dispatch(fetchSearchedProducts(homeId, search));
    } else {
      dispatch(resetSearchedProducts());
    }
  }, [search]);

  function SearchedProductsGroupListView() {
    return (
      <>
        {searchedProducts.length ? (
          <>
            {filteredInventory.map((group: string, i: number) => {
              return (
                <div
                  className={
                    platformType === HKPlatform.DESKTOP && isDesktopWidth
                      ? 'hk-desktop-searched-products'
                      : 'hk-searched-products'
                  }
                  key={`searched-group-${i}`}
                >
                  <h4>{group}</h4>
                  {searchedProducts!.map(
                    (searchedProduct: SearchedProduct, i: number) => {
                      const {
                        id,
                        name,
                        group_id,
                        group_name,
                        product_template
                      } = searchedProduct;
                      const image = imageForItem(searchedProduct);
                      const css =
                        image?.indexOf('/static/media') === -1
                          ? ''
                          : 'no-image';
                      return (
                        <>
                          {group_name === group && (
                            <ListCard
                              className={css}
                              key={`searched-product-${i}`}
                              title={!!name ? name : product_template.name}
                              descriptionOne={
                                'Model #' + product_template.model
                              }
                              image={image}
                              onClick={() => {
                                platformType === HKPlatform.DESKTOP &&
                                isDesktopWidth
                                  ? goToInventoryDetailsPage(group_id, id)
                                  : showDetailsModal(id);
                              }}
                            />
                          )}
                        </>
                      );
                    }
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <div className="hk-search-empty">
            <h1>No Matches Found</h1>
            <p>
              The thing you are searching for isn't here. Please try another
              search.
            </p>
            <p>
              You can also add an item
              {platformType === HKPlatform.DESKTOP && isDesktopWidth
                ? ' above'
                : ' below'}
              .
            </p>
          </div>
        )}
      </>
    );
  }

  function DesktopInventoryGroupListView() {
    return (
      <>
        <IonRow className="hk-desktop-digital-inventory-group-header">
          <div className="hk-desktop-digital-inventory-group-header-container">
            <IonBreadcrumbs className="hk-desktop-digital-inventory-group-header-breadcrumbs">
              <IonBreadcrumb>Inventory</IonBreadcrumb>
            </IonBreadcrumbs>
            <div className="hk-desktop-digital-inventory-group-header-right">
              <div className="hk-desktop-digital-inventory-group-search">
                <SearchBar
                  value={search}
                  disabled={productGroups?.length === 0}
                  debounce={1000}
                  onSearchChange={(e) => setSearch(e)}
                ></SearchBar>
              </div>
              <div className="hk-desktop-digital-inventory-group-header-button">
                <IonButton
                  expand="block"
                  color="primary"
                  strong={true}
                  onClick={() => openAddItemModal()}
                >
                  Add Item
                </IonButton>
              </div>
            </div>
          </div>
        </IonRow>
        <IonContent className="hk-desktop-digital-inventory-group">
          <LoadingIonList
            className="hk-desktop-digital-inventory-group-list"
            label={'Items In Inventory'}
            description={'There are no items of this kind added yet.'}
            items={productGroups}
            loading={loading}
          >
            {!!search ? (
              <SearchedProductsGroupListView />
            ) : (
              <>
                {productGroups!.map((g: any, i: number) => {
                  const { id, group, count } = g;
                  return (
                    <ListItem
                      key={`item-${i}`}
                      customView={true}
                      onClick={() => showGroup(id)}
                    >
                      <IonIcon
                        slot="start"
                        icon={iconForGroup(id)}
                        className="hk-desktop-digital-inventory-group-list-item-start-icon"
                        color="dark"
                      />
                      <div className="hk-desktop-digital-inventory-group-list-item-body">
                        <IonText className="hk-desktop-digital-inventory-group-list-item-body-title">
                          <h2>{group}</h2>
                        </IonText>
                        <IonText className="hk-desktop-digital-inventory-group-list-item-body-description">
                          <p>{count + ' item(s)'}</p>
                        </IonText>
                        <IonIcon
                          slot="end"
                          icon={ArrowRight}
                          className="hk-desktop-digital-inventory-group-list-item-body-end-icon"
                        ></IonIcon>
                      </div>
                    </ListItem>
                  );
                })}
              </>
            )}
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  function MobileInventoryGroupListView() {
    return (
      <>
        <MenuHeader title={'Inventory'} />
        <IonContent fullscreen>
          <SearchBar
            value={search}
            disabled={productGroups?.length === 0}
            debounce={1000}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList
            label={'Items In Inventory'}
            description={'There are no items of this kind added yet.'}
            items={productGroups}
            loading={loading}
          >
            {!!search ? (
              <SearchedProductsGroupListView />
            ) : (
              <>
                {productGroups!.map((g: any, i: number) => {
                  const { id, group, count } = g;
                  return (
                    <ListItem
                      key={`item-${i}`}
                      startIcon={iconForGroup(id)}
                      endIcon={ArrowRight}
                      title={group}
                      description={count + ' item(s)'}
                      onClick={() => showGroup(id)}
                    />
                  );
                })}
              </>
            )}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="hk-mobile-digital-inventory-groups-toolbar">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={() => openAddItemModal()}
          >
            Add Item
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-digital-inventory-group-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopInventoryGroupListView()
        : MobileInventoryGroupListView()}
      <IonModal
        className="hk-digital-inventory-add-product-modal"
        isOpen={addItemModalVisible}
        onDidDismiss={() => {
          closeTask();
        }}
      >
        <InventoryAddProduct />
      </IonModal>
      <IonModal
        className="hk-digital-inventory-details-modal"
        isOpen={itemDetailsModalVisible}
        onDidDismiss={() => {
          closeDetailsModal();
        }}
      >
        <InventoryDetails />
      </IonModal>
    </IonPage>
  );
};

export default InventoryGroups;
