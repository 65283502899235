import { createApiRequest } from 'core/util';
import {
  DOCUMENTS_LIST_URL,
  DOCUMENTS_SEARCH_URL,
  DOCUMENTS_URL,
  DOCUMENT_COUNT_URL,
  DOCUMENT_TYPES_URL,
  DOCUMENT_URL
} from 'core/constants';
import {
  fetchDocumentCountSuccess,
  fetchDocumentCountError,
  fetchDocumentsSuccess,
  fetchDocumentsError,
  createDocumentStart,
  createDocumentSuccess,
  createDocumentError,
  fetchDocumentCountStart,
  fetchDocumentsStart,
  fetchDocumentTypesSuccess,
  fetchDocumentTypesError,
  fetchSearchedDocumentsStart,
  fetchSearchedDocumentsSuccess,
  fetchSearchedDocumentsError,
  deleteDocumentError,
  deleteDocumentStart,
  deleteDocumentSuccess,
  updateDocumentError,
  updateDocumentStart,
  updateDocumentSuccess
} from 'features/shared/documents/DocumentsSlice';

export const fetchDocumentTypes = () =>
  createApiRequest({
    route: DOCUMENT_TYPES_URL,
    onSuccess: fetchDocumentTypesSuccess,
    onError: fetchDocumentTypesError,
    defaultErrorMessage: 'There was a problem loading your documents.',
    useGlobalLoader: true
  })();

export const fetchDocumentCount = (homeId: number) =>
  createApiRequest({
    route: DOCUMENT_COUNT_URL,
    replace: ['home_id', homeId],
    onStart: fetchDocumentCountStart,
    onSuccess: fetchDocumentCountSuccess,
    onError: fetchDocumentCountError,
    defaultErrorMessage: 'There was a problem loading your documents.',
    useGlobalLoader: true
  })();

export const fetchDocuments = (homeId: number, type: string) =>
  createApiRequest({
    route: DOCUMENTS_LIST_URL,
    replace: [
      ['home_id', homeId],
      ['type', type]
    ],
    onStart: fetchDocumentsStart,
    onSuccess: fetchDocumentsSuccess,
    onError: fetchDocumentsError,
    defaultErrorMessage: 'There was a problem loading your documents.',
    useGlobalLoader: true
  })();

export const fetchSearchedDocuments = (homeId: number, term: string) =>
  createApiRequest({
    route: DOCUMENTS_SEARCH_URL,
    method: 'get',
    replace: [
      ['home_id', homeId],
      ['term', term]
    ],
    onStart: fetchSearchedDocumentsStart,
    onSuccess: fetchSearchedDocumentsSuccess,
    onError: fetchSearchedDocumentsError,
    defaultErrorMessage: 'There was a problem searching documents.'
  })();

export const createDocument = (homeId: number, body: object) =>
  createApiRequest({
    route: DOCUMENTS_URL,
    method: 'post',
    body: body,
    replace: [['home_id', homeId]],
    onStart: createDocumentStart,
    onSuccess: createDocumentSuccess,
    onError: createDocumentError,
    defaultErrorMessage: 'There was an error updating the product templates.'
  })();

export const updateDocument = (homeId: number, assetId: number, body: object) =>
  createApiRequest({
    route: DOCUMENT_URL,
    method: 'patch',
    body: body,
    replace: [
      ['home_id', homeId],
      ['asset_id', assetId]
    ],
    onStart: updateDocumentStart,
    onSuccess: updateDocumentSuccess,
    onError: updateDocumentError,
    defaultErrorMessage: 'There was an error updating the document.'
  })();

export const deleteDocument = (homeId: number, assetId: number) =>
  createApiRequest({
    route: DOCUMENT_URL,
    method: 'delete',
    replace: [
      ['home_id', homeId],
      ['asset_id', assetId]
    ],
    onStart: deleteDocumentStart,
    onSuccess: deleteDocumentSuccess,
    onError: deleteDocumentError,
    defaultErrorMessage: 'There was a problem deleting the document.',
    useGlobalLoader: true
  })();
