import { createApiRequest } from '../../../core/util';
import {
  APPOINTMENT_LIST_URL,
  UPCOMING_VISITS_URL
} from '../../../core/constants';
import {
  fetchVisitsListError,
  fetchVisitsListStart,
  fetchVisitsListSuccess
} from './DashboardSlice';
import {
  fetchAppointmentListError,
  fetchAppointmentListStart,
  fetchAppointmentListSuccess
} from './DashboardSlice';

export const fetchAppointmentList = (homeId: number) =>
  createApiRequest({
    route: APPOINTMENT_LIST_URL,
    replace: ['home_id', homeId],
    onStart: fetchAppointmentListStart,
    onSuccess: fetchAppointmentListSuccess,
    onError: fetchAppointmentListError,
    defaultErrorMessage: 'There was a problem loading your appointments.'
  })();

export const fetchUpcomingVisits = (homeId: number) =>
  createApiRequest({
    route: UPCOMING_VISITS_URL,
    method: 'get',
    replace: ['home_id', homeId],
    onStart: fetchVisitsListStart,
    onSuccess: fetchVisitsListSuccess,
    onError: fetchVisitsListError,
    defaultErrorMessage: 'There was aa problem fetching your upcoming visits.'
  })();
