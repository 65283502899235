import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react';
import React from 'react';
import './DashboardAccordion.scss';
import ArrowIcon from '../../../assets/icons/arrow-right.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { HKPlatform } from 'core/constants';

type AccordionProps = {
  label?: string;
  value?: string;
  description?: string;
  collapsed?: boolean;
  className?: string;
};

const DashboardAccordion: React.FC<AccordionProps> = ({
  label = 'Items',
  value = '',
  description = '',
  collapsed = false,
  className,
  children,
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopDashboardAccordion() {
    return (
      <IonAccordionGroup
        multiple={true}
        value={collapsed ? null : value}
        className={className}
      >
        <IonAccordion
          className="hk-desktop-dashboard-accordion"
          toggleIcon={ArrowIcon}
          toggleIconSlot={'end'}
          value={value}
        >
          <IonItem
            className="hk-desktop-dashboard-accordion-header"
            color="white"
            slot="header"
          >
            <IonLabel>{label}</IonLabel>
          </IonItem>

          <IonList
            className="hk-desktop-dashboard-accordion-content"
            slot="content"
          >
            {children}
          </IonList>
        </IonAccordion>
      </IonAccordionGroup>
    );
  }

  function MobileDashboardAccordion() {
    return (
      <IonAccordionGroup
        multiple={true}
        className={className}
        value={collapsed ? null : value}
      >
        <IonAccordion
          className="hk-dashboard-accordion"
          toggleIcon={ArrowIcon}
          toggleIconSlot={'end'}
          value={value}
        >
          <IonItem
            className="hk-dashboard-accordion-header"
            color="white"
            slot="header"
          >
            <IonLabel>{label}</IonLabel>
          </IonItem>

          <IonList className="hk-dashboard-accordion-content" slot="content">
            {children}
          </IonList>
        </IonAccordion>
      </IonAccordionGroup>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDashboardAccordion()
        : MobileDashboardAccordion()}
    </>
  );
};

export default DashboardAccordion;
