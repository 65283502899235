import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { IonButton, IonContent, IonPage } from '@ionic/react';
import { HKPlatform } from 'core/constants';
import AddressSearchBar from 'components/address-searchbar/AddressSearchBar';
import { gaBtnClickEvent } from 'core/util';
import Footer from '../signup/views/Footer';
import Header from '../signup/views/Header';
import './InitialSignIn.scss';
import {
  requestInitialSignInEmails,
  sendInitialSignInPasswordReset
} from './LoginActions';
import { clearInitialSignInEmails } from './LoginSlice';
import Illustration from 'components/Illustrations/Illustration';
import InitialSignInSkeleton from 'assets/illustrations/initial-sign-in-skeleton.png';
import InitialSignInSkeletonMobile from 'assets/illustrations/initial-sign-in-skeleton-mobile.png';

const InitialSignIn: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { loading, initialSignInEmails, error } = useSelector(
    (state: RootState) => state.login
  );

  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const dispatch = useDispatch();

  const addressSelect = (address: string) => {
    if (!!address) {
      setAddress(address);
      const addressSections = address.split(',');
      const street = addressSections[0];
      const city = addressSections[1];
      const state = addressSections[2];
      const postalCode = addressSections[3].split(' ')[2];
      const addressBody = street + city + ',' + state + ' ' + postalCode;
      dispatch(requestInitialSignInEmails(addressBody));
      gaBtnClickEvent('initial_signin_selected_address');
    } else {
      dispatch(clearInitialSignInEmails());
      setEmail('');
      setAddress('');
    }
  };

  const sendPasswordReset = async () => {
    const user = initialSignInEmails.find((user) => user.email === email);
    if (user) {
      await dispatch(sendInitialSignInPasswordReset(user.id, user.reset_hash));
      if (!error) {
        setEmailSent(true);
        gaBtnClickEvent('initial_signin_set_password');
      }
    }
  };

  useEffect(() => {
    if (initialSignInEmails.length === 1) {
      setEmail(initialSignInEmails[0].email);
    }
  }, [initialSignInEmails]);

  function DesktopInitialSignInView() {
    return (
      <IonPage>
        <Header />
        <IonContent>
          {!emailSent ? (
            <div className="hk-desktop-initial-signin-content">
              <h2 className="hk-desktop-initial-signin-title">
                Welcome to HomeKeep
              </h2>
              <p className="hk-desktop-initial-signin-description">
                Search your home address to show what email we have associated
                with your account
              </p>
              <section className="hk-desktop-initial-signin-search-container">
                <AddressSearchBar
                  onSearchSelect={(address) => addressSelect(address)}
                />
                {!loading && !!address && !!initialSignInEmails.length && (
                  <>
                    <h4 className="hk-desktop-initial-signin-password-title">
                      Set Your Password
                    </h4>
                    <p className="hk-desktop-initial-signin-password-description">
                      {initialSignInEmails.length > 1 ? (
                        <>
                          Your address has multiple emails registered with
                          <strong> HomeKeep</strong>.
                        </>
                      ) : (
                        <>
                          Your address is registered with HomeKeep and linked to
                          the email below.
                        </>
                      )}
                    </p>
                    <p className="hk-desktop-initial-signin-password-description">
                      {initialSignInEmails.length > 1 ? (
                        <>
                          Please choose the email you want to use and set your
                          password to sign in.
                        </>
                      ) : (
                        <>Please set your password to sign in.</>
                      )}
                    </p>

                    <div className="hk-desktop-initial-signin-password-email-container">
                      {initialSignInEmails.map((user, i) => {
                        return (
                          <div
                            key={`email-${i}`}
                            className={`hk-desktop-initial-signin-password-email ${
                              email === user.email &&
                              initialSignInEmails.length > 1
                                ? 'hk-desktop-initial-signin-password-email-selected'
                                : ''
                            }`}
                            onClick={() => setEmail(user.email)}
                          >
                            {user.email}
                          </div>
                        );
                      })}
                    </div>

                    <p className="hk-initial-signin-password-description">
                      Don't see your correct email address? You can{' '}
                      <a
                        className="hk-initial-signin-password-help"
                        href="https://homekeep.zendesk.com/hc/en-us/requests/new"
                      >
                        contact support
                      </a>{' '}
                      to fix this.
                    </p>
                    <IonButton
                      expand="block"
                      strong={true}
                      className="hk-desktop-initial-signin-password-button"
                      disabled={!email}
                      onClick={sendPasswordReset}
                    >
                      Set Password
                    </IonButton>
                  </>
                )}
                {loading && <Illustration image={InitialSignInSkeleton} />}
              </section>
            </div>
          ) : (
            <>
              <div className="hk-desktop-initial-signin-sent-content">
                <section className="hk-desktop-initial-signin-sent-content-container">
                  <h2 className="hk-desktop-initial-signin-sent-title">
                    Check Your Inbox
                  </h2>
                  <p className="hk-desktop-initial-signin-sent-description">
                    An email with instructions has been sent. If you did not
                    receive an email or the email address is incorrect you can{' '}
                    <a
                      className="hk-initial-signin-password-help"
                      href="https://homekeep.zendesk.com/hc/en-us/requests/new"
                    >
                      contact support
                    </a>
                  </p>
                  <div className="hk-desktop-initial-signin-password-email">
                    {email}
                  </div>
                </section>
              </div>
            </>
          )}

          <Footer darkMode={true} />
        </IonContent>
      </IonPage>
    );
  }

  function MobileInitialSignInView() {
    return (
      <IonPage>
        <Header />
        <IonContent>
          {!emailSent ? (
            <div className="hk-initial-signin-content">
              <section className="hk-initial-signin-text-container">
                <h2 className="hk-initial-signin-title">Welcome to HomeKeep</h2>
                <p className="hk-initial-signin-description">
                  Search your home address to show what email we have associated
                  with your account
                </p>
              </section>

              <section className="hk-initial-signin-search-container">
                <AddressSearchBar
                  onSearchSelect={(address) => addressSelect(address)}
                />
                {!loading && !!address && !!initialSignInEmails.length && (
                  <>
                    <h4 className="hk-initial-signin-password-title">
                      Set Your Password
                    </h4>
                    <p className="hk-initial-signin-password-description">
                      {initialSignInEmails.length > 1 ? (
                        <>
                          Your address has multiple emails registered with
                          <strong> HomeKeep</strong>. Please choose the email
                          you want to use and set your password to sign in.
                        </>
                      ) : (
                        <>
                          Your address is registered with HomeKeep and linked to
                          the email below.
                        </>
                      )}
                    </p>
                    <p className="hk-initial-signin-password-description">
                      {initialSignInEmails.length > 1 ? (
                        <></>
                      ) : (
                        <>Please set your password to sign in.</>
                      )}
                    </p>

                    <div className="hk-initial-signin-password-email-container">
                      {initialSignInEmails.map((user, i) => {
                        return (
                          <div
                            key={`email-${i}`}
                            className={`hk-initial-signin-password-email ${
                              email === user.email &&
                              initialSignInEmails.length > 1
                                ? 'hk-initial-signin-password-email-selected'
                                : ''
                            }`}
                            onClick={() => setEmail(user.email)}
                          >
                            {user.email}
                          </div>
                        );
                      })}
                    </div>

                    <p className="hk-initial-signin-password-description">
                      Don't see your correct email address? You can{' '}
                      <a
                        className="hk-initial-signin-password-help"
                        href="https://homekeep.zendesk.com/hc/en-us/requests/new"
                      >
                        contact support
                      </a>{' '}
                      to fix this.
                    </p>
                    <IonButton
                      expand="block"
                      strong={true}
                      className="hk-initial-signin-password-button"
                      disabled={!email}
                      onClick={sendPasswordReset}
                    >
                      Set Password
                    </IonButton>
                  </>
                )}
                {loading && (
                  <Illustration image={InitialSignInSkeletonMobile} />
                )}
              </section>
            </div>
          ) : (
            <>
              <div className="hk-initial-signin-sent-content">
                <h2 className="hk-initial-signin-sent-title">
                  Check Your Inbox
                </h2>
                <strong className="hk-initial-signin-sent-subtitle">
                  Setup a secure password and you are ready to start maintaining
                  your home investment
                </strong>
                <section className="hk-initial-signin-sent-content-container">
                  <p className="hk-initial-signin-sent-description">
                    An email with instructions has been sent. If you did not
                    receive an email or the email address is incorrect you can{' '}
                    <a
                      className="hk-initial-signin-password-help"
                      href="https://homekeep.zendesk.com/hc/en-us/requests/new"
                    >
                      contact support
                    </a>
                  </p>
                  <div className="hk-initial-signin-password-email">
                    {email}
                  </div>
                </section>
              </div>
            </>
          )}

          <Footer darkMode={true} />
        </IonContent>
      </IonPage>
    );
  }
  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopInitialSignInView()
        : MobileInitialSignInView()}
    </>
  );
};

export default InitialSignIn;
