import React from 'react';
import './NotFound.scss';
import NotFoundImage from 'assets/illustrations/404-image.svg';
import NotFoundBorderLeftImage from 'assets/illustrations/404-left.svg';
import NotFoundBorderRightImage from 'assets/illustrations/404-right.svg';
import Illustration from 'components/Illustrations/Illustration';
import { IonButton } from '@ionic/react';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import { HKPlatform } from 'core/constants';
import { useHistory } from 'react-router';

const NotFound: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { loggedIn } = useSelector((state: RootState) => state.login);
  const history = useHistory();

  const buttonClick = () => {
    if (loggedIn) {
      history.push('/dashboard');
    } else {
      history.push('/login');
    }
  };

  return (
    <div className="hk-not-found">
      <img
        alt="404 border"
        src={NotFoundBorderLeftImage}
        className={`hk-not-found-border-left ${
          platformType === HKPlatform.DESKTOP && !isDesktopWidth
            ? 'hk-not-found-border-mobile'
            : ''
        } ${loggedIn ? 'hk-not-found-border-desktop' : ''}`}
      />
      <img
        alt="404 border"
        src={NotFoundBorderRightImage}
        className={`hk-not-found-border-right ${
          platformType === HKPlatform.DESKTOP && !isDesktopWidth
            ? 'hk-not-found-border-mobile'
            : ''
        }`}
      />
      <Illustration image={NotFoundImage} className="hk-not-found-404" />
      <p className="hk-not-found-text">Sorry, we can't find that page!</p>
      <p className="hk-not-found-text">Try going back to the dashboard.</p>
      <IonButton
        expand="block"
        strong={true}
        className="hk-not-found-button"
        onClick={buttonClick}
      >
        Dashboard
      </IonButton>
    </div>
  );
};

export default NotFound;
