import React from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { Visit } from 'features/full-service/dashboard/DashboardSlice';
import './VisitCard.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { HKPlatform } from 'core/constants';

type VisitCardProps = {
  icon: string;
  visit: Visit;
  expired: any;
  onClick?: (e: any) => void;
};

const VisitCard: React.FC<VisitCardProps> = ({
  visit,
  icon,
  expired,
  onClick,
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopVisitCardView() {
    return (
      <div
        className={`hk-desktop-visit-card ${
          visit.can_schedule ? '' : 'no-schedule'
        }`}
        {...rest}
      >
        <div className="hk-desktop-visit-card-body">
          <div className="hk-desktop-visit-card-body__header">
            {icon && <IonIcon className="hk-desktop-season-icon" icon={icon} />}
            <div className="hk-desktop-visit-card-body__header-title">
              <IonText className="hk-desktop-visit-card-body__header-title-name hk-bold">
                {visit?.name}
              </IonText>
              <IonText className="hk-desktop-visit-card-body__header-title-description">
                {visit?.description}
              </IonText>
            </div>
          </div>
          <div className="hk-desktop-visit-card-body__content">
            {/* Placeholder for future
            <a>Task List</a>
            <a>Test List</a>
            <a>Inspections</a> */}
          </div>
          <div className="hk-desktop-visit-card-body__button">
            <IonButton
              className={`hk-desktop-schedule-button ${
                !visit.can_schedule ? 'disabled' : ''
              }`}
              disabled={!visit.can_schedule || expired}
              onClick={onClick}
            >
              Schedule
            </IonButton>
          </div>
        </div>
        {!visit.can_schedule && (
          <div className="hk-desktop-visit-card-footer">
            <IonText color="dark">This task cannot be scheduled yet.</IonText>
          </div>
        )}
        {expired && (
          <div className="hk-desktop-visit-card-footer">
            <IonText color="dark">Resubscribe to schedule</IonText>
          </div>
        )}
      </div>
    );
  }

  function MobileVisitCardView() {
    return (
      <div
        className={`hk-visit-card ion-margin-horizontal ${
          visit.can_schedule ? '' : 'no-schedule'
        }`}
        {...rest}
      >
        <div
          className={`hk-visit-card-header-wrapper ${
            expired ? 'hk-visit-card-header-wrapper-disabled' : ''
          }`}
        >
          <div className="hk-visit-card-header">
            {icon && <IonIcon className="hk-season-icon" icon={icon} />}
            <IonText className="hk-visit-card-header-name" color="white">
              {visit?.name}
            </IonText>
          </div>
        </div>
        <div className="hk-visit-card-body-wrapper">
          <div className="hk-visit-card-body">
            <div>
              <IonText color="dark">
                <p className="hk-visit-card-description">
                  {visit?.description}
                </p>
              </IonText>
            </div>
            <IonButton
              className={`hk-schedule-button ${
                !visit.can_schedule ? 'disabled' : ''
              }`}
              disabled={!visit.can_schedule || expired}
              onClick={onClick}
            >
              Schedule
            </IonButton>
          </div>
        </div>
        {!visit.can_schedule && (
          <div className="hk-visit-card-footer">
            <IonText color="dark">This task cannot be scheduled yet.</IonText>
          </div>
        )}
        {expired && (
          <div className="hk-visit-card-footer">
            <IonText color="dark">Resubscribe to schedule</IonText>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth ? (
        <DesktopVisitCardView />
      ) : (
        <MobileVisitCardView />
      )}
    </>
  );
};

export default VisitCard;
