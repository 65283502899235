import { createApiRequest } from 'core/util';
import { SUBSCRIPTION_PLANS_URL, SUBSCRIPTION_URL } from 'core/constants';
import {
  deleteSubscriptionFailure,
  deleteSubscriptionStart,
  deleteSubscriptionSuccess,
  fetchCreditCardInfoFailure,
  fetchCreditCardInfoStart,
  fetchCreditCardInfoSuccess,
  fetchSubscriptionPlansFailure,
  fetchSubscriptionPlansStart,
  fetchSubscriptionPlansSuccess,
  renewSubscriptionFailure,
  renewSubscriptionStart,
  renewSubscriptionSuccess,
  updateSubscriptionFailure,
  updateSubscriptionStart,
  updateSubscriptionSuccess
} from './BillingSlice';
import { fetchGlobalHomeSuccess } from 'core/services/HomeActions';

export const requestSubscriptionPlans = (homeId: number) =>
  createApiRequest({
    route: SUBSCRIPTION_PLANS_URL,
    method: 'get',
    replace: [['home_id', homeId]],
    onStart: fetchSubscriptionPlansStart,
    onSuccess: fetchSubscriptionPlansSuccess,
    onError: fetchSubscriptionPlansFailure,
    defaultErrorMessage: 'There was a problem fetching your subscription plans.'
  })();

export const requestCreditCardInfo = (homeId: number) =>
  createApiRequest({
    route: SUBSCRIPTION_URL,
    method: 'get',
    replace: [['home_id', homeId]],
    onStart: fetchCreditCardInfoStart,
    onSuccess: fetchCreditCardInfoSuccess,
    onError: fetchCreditCardInfoFailure,
    defaultErrorMessage:
      'There was a problem fetching your credit card information.'
  })();

export const renewSubscription = (homeId: number, body: object) =>
  createApiRequest({
    route: SUBSCRIPTION_URL,
    method: 'post',
    body: body,
    replace: [['home_id', homeId]],
    onStart: renewSubscriptionStart,
    onSuccess: renewSubscriptionSuccess,
    onError: renewSubscriptionFailure,
    onGlobalSuccess: fetchGlobalHomeSuccess,
    defaultErrorMessage: 'There was an error renewing your subscription.'
  })();

export const updateSubscription = (homeId: number, body: object) =>
  createApiRequest({
    route: SUBSCRIPTION_URL,
    replace: ['home_id', homeId],
    method: 'patch',
    body: body,
    onStart: updateSubscriptionStart,
    onSuccess: updateSubscriptionSuccess,
    onError: updateSubscriptionFailure,
    defaultErrorMessage: 'There was a problem updating your credit card.',
    useGlobalLoader: true
  })();

export const deleteSubscription = (homeId: number, body: object) =>
  createApiRequest({
    route: SUBSCRIPTION_URL,
    replace: ['home_id', homeId],
    method: 'delete',
    body: body,
    onStart: deleteSubscriptionStart,
    onSuccess: deleteSubscriptionSuccess,
    onError: deleteSubscriptionFailure,
    defaultErrorMessage: 'There was a problem cancelling your subscription.',
    useGlobalLoader: true
  })();
