import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DigitalSignup,
  HKPackage,
  HKSignupStep,
  HKSubscription
} from 'core/types';
import { translateCreateContactError } from 'core/util/helpers';

interface SignupState {
  loading: boolean;
  signupPlans: [];
  step: number;
  valid_market: boolean | null;
  uid: string;
  email: string;
  mobile_phone: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  postal_code: string;
  firstName: string;
  lastName: string;
  home_size: number | null;
  home_floors: number | null;
  contact_id: number | null;
  maintenance_packages: HKPackage[] | null;
  subscriptions: HKSubscription[] | null;
  user_id: number | null;
  access_token: string | null;
  home_id: number | null;
  home_type: string | null;
  password: string | null;
  emailValid: boolean | null;
  error: string | null;
  onboarding_3: string[];
  onboarding_other_3: string | null;
  onboarding_4: string | null;
  onboarding_other_4: string | null;
  onboarding_5: string | null;
  onboarding_other_5: string | null;
  zipcodeModalVisible: boolean;
  zipcodeError: boolean;
  malformedAddressError: boolean;
}

export const initialState: SignupState = {
  loading: false,
  signupPlans: [],
  step: 1,
  valid_market: null,
  uid: '',
  email: '',
  mobile_phone: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  postal_code: '',
  firstName: '',
  lastName: '',
  home_size: null,
  home_floors: 1,
  contact_id: null,
  maintenance_packages: null,
  subscriptions: null,
  user_id: null,
  access_token: null,
  home_id: null,
  home_type: null,
  password: null,
  emailValid: null,
  error: null,
  onboarding_3: [],
  onboarding_other_3: null,
  onboarding_4: null,
  onboarding_other_4: null,
  onboarding_5: null,
  onboarding_other_5: null,
  zipcodeModalVisible: false,
  zipcodeError: false,
  malformedAddressError: false
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setSignupLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
      state.error = state.loading ? '' : state.error;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setZipcodeError: (state, action: PayloadAction<boolean>) => {
      state.zipcodeError = action.payload;
    },
    setMalformedAddressError: (state, action: PayloadAction<boolean>) => {
      state.malformedAddressError = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.error = '';
      state.step = action.payload;
    },
    saveEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    saveUid: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    saveZipCode: (state, action: PayloadAction<string>) => {
      state.postal_code = action.payload;
    },
    setSignupAddress: (state, action: PayloadAction<any>) => {
      state.address1 = action.payload.address1;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.postal_code = action.payload.postal_code;
    },
    saveHomeSize: (state, action: PayloadAction<any>) => {
      state.step = HKSignupStep.Packages;
      state.home_size = action.payload;
      state.maintenance_packages = null;
    },
    saveHomeType: (state, action: PayloadAction<string>) => {
      state.home_type = action.payload;
    },
    saveOnboarding3: (state, action: PayloadAction<string[]>) => {
      state.onboarding_3 = action.payload;
    },
    saveOnboardingOther3: (state, action: PayloadAction<string>) => {
      state.onboarding_other_3 = action.payload;
    },
    saveOnboarding4: (state, action: PayloadAction<string>) => {
      state.onboarding_4 = action.payload;
    },
    saveOnboardingOther4: (state, action: PayloadAction<string>) => {
      state.onboarding_other_4 = action.payload;
    },
    saveOnboarding5: (state, action: PayloadAction<string>) => {
      state.onboarding_5 = action.payload;
    },
    saveOnboardingOther5: (state, action: PayloadAction<string>) => {
      state.onboarding_other_5 = action.payload;
    },
    showZipcodeModal: (state) => {
      state.zipcodeModalVisible = true;
    },
    hideZipcodeModal: (state) => {
      state.zipcodeModalVisible = false;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      state.error = '';
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.error = '';
      state.lastName = action.payload;
    },
    checkPostalCodeSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.step = HKSignupStep.Email;
      state.valid_market = action.payload.valid_market;
    },
    checkPostalCodeFailure: (state, action: PayloadAction<string>) => {
      state.step = HKSignupStep.Email;
      state.error = action.payload;
    },
    checkZipcodeSuccess: (state, action: PayloadAction<any>) => {
      const sortedPlans = action.payload.available_plans;
      sortedPlans.sort(function (a: any, b: any) {
        return a.pricing.monthly.usd - b.pricing.monthly.usd;
      });
      state.signupPlans = sortedPlans;
    },
    checkZipcodeFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    checkEmailStart: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    checkEmailSuccess: (state, action: PayloadAction<string>) => {
      state.emailValid = true;
      state.loading = false;
    },
    checkEmailFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.emailValid = false;
      state.loading = false;
    },
    createDigitalAccountStart: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    createDigitalAccountSuccess: (
      state,
      action: PayloadAction<DigitalSignup>
    ) => {
      state.error = null;
      state.home_id = action.payload.home_id;
      state.user_id = action.payload.user_id;
      state.access_token = action.payload.access_token;
      state.loading = false;
    },
    createDigitalAccountFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    createContactSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.step = state.valid_market ? HKSignupStep.Size : HKSignupStep.Done;
      state.contact_id = action.payload.contact_id;
    },
    createContactFailure: (state, action: PayloadAction<string>) => {
      state.error = translateCreateContactError(action.payload);
    },
    fetchInitialPlansSuccess: (state, action: PayloadAction<any>) => {
      const sortedPlans = action.payload.available_plans;
      sortedPlans.sort(function (a: any, b: any) {
        return a.pricing.monthly.usd - b.pricing.monthly.usd;
      });
      state.signupPlans = sortedPlans;
    },
    fetchInitialPlansFailure: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    fetchHomePackagesSuccess: (
      state,
      action: PayloadAction<{ items: HKPackage[] }>
    ) => {
      state.maintenance_packages = action.payload.items;
    },
    fetchHomePackagesFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    fetchContactAddressSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.address1 = action.payload.address1;
      state.address2 = action.payload.address2;
      state.city = action.payload.city;
      state.state = action.payload.state;
      state.postal_code = action.payload.postal_code;
      state.subscriptions = action.payload.subscriptions;
    },
    fetchContactAddressFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    updateUserSuccess: (state, action: PayloadAction<any>) => {
      state.error = null;
      state.user_id = action.payload.user_id;
    },
    updateUserFailure: (state, action: PayloadAction<string>) => {
      state.error = translateCreateContactError(action.payload);
    },
    unsubscribeEmailSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
    },
    unsubscribeEmailFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetSignup: () => initialState
  }
});

export const {
  setSignupLoading,
  setError,
  setZipcodeError,
  setMalformedAddressError,
  setStep,
  setFirstName,
  setLastName,
  setPassword,
  setSignupAddress,
  saveEmail,
  saveUid,
  saveZipCode,
  saveHomeSize,
  saveHomeType,
  saveOnboarding3,
  saveOnboarding4,
  saveOnboarding5,
  saveOnboardingOther3,
  saveOnboardingOther4,
  saveOnboardingOther5,
  showZipcodeModal,
  hideZipcodeModal,
  checkEmailStart,
  checkEmailSuccess,
  checkEmailFailure,
  checkPostalCodeSuccess,
  checkPostalCodeFailure,
  checkZipcodeSuccess,
  checkZipcodeFailure,
  createDigitalAccountStart,
  createDigitalAccountSuccess,
  createDigitalAccountFailure,
  createContactSuccess,
  createContactFailure,
  fetchInitialPlansSuccess,
  fetchInitialPlansFailure,
  fetchContactAddressSuccess,
  fetchContactAddressFailure,
  fetchHomePackagesSuccess,
  fetchHomePackagesFailure,
  updateUserSuccess,
  updateUserFailure,
  unsubscribeEmailSuccess,
  unsubscribeEmailFailure,
  resetSignup
} = signupSlice.actions;

export default signupSlice.reducer;
