import * as React from 'react';
import {
  HKBillingPeriod,
  HKPlatform,
  RECURLY_DEVL_KEY,
  RECURLY_PUBLIC_KEY
} from 'core/constants';
import {
  RecurlyProvider,
  Elements,
  UseCheckoutPricingInput,
  useCheckoutPricing
} from '@recurly/react-recurly';
import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonButton,
  IonContent,
  IonInput,
  IonPage,
  IonToggle
} from '@ionic/react';
import './Recurly.scss';
import { RootState } from 'core/store';
import { useDispatch, useSelector } from 'react-redux';
import { gaBtnClickEvent, isDev } from 'core/util';
import { useEffect, useRef, useState } from 'react';
import AddressSearchBar from 'components/address-searchbar/AddressSearchBar';
import { requestCreditCardInfo } from 'features/shared/billing/BillingActions';
import {
  setAutoRenew,
  setPromoCode
} from 'features/shared/billing/BillingSlice';
import CreditCardForm from 'features/shared/billing/CreditCardForm';
import Summary from 'features/shared/billing/Summary';
import Header from '../Header';
import Footer from '../Footer';
import {
  setSignupAddress,
  setZipcodeError,
  setMalformedAddressError
} from '../../SignupSlice';
import { checkZipcode } from '../../SignupActions';

export const ADDRESS_HEADERS: any = {
  FULL_SERVICE: {
    title: 'Appointment Address',
    description:
      'We use your physical address to schedule appointments and ensure our service providers know where to meet you.'
  },
  AUTO_DELIVERY: {
    title: 'Delivery Address',
    description:
      'We use this address to ship your maintenance products and tailor task recommendations to your area and timezone.'
  },
  DIGITAL: {
    title: 'Home Address',
    description:
      'We use this address to tailor task recommendations to your geographic area and timezone.'
  }
};

const Recurly: React.FC = () => {
  const { name } = useSelector((state: RootState) => state.user);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { newAddOns, newPlan, newBillingPeriod, promoCode, autoRenew } =
    useSelector((state: RootState) => state.billing);
  const { signupPlans, postal_code, zipcodeError, malformedAddressError } =
    useSelector((state: RootState) => state.signup);
  const dispatch = useDispatch();
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);

  const [recurlyPromoCode, setRecurlyPromoCode] = useState<any>('');
  const [recurlyErrorPromoCode, setRecurlyErrorPromoCode] =
    React.useState<any>('');
  const [recurlyError, setRecurlyError] = useState<any>(null);
  const promoCodeInputRef = useRef<any>(null);

  const applyPromoCode = () => {
    setRecurlyError(null);
    setRecurlyPromoCode(promoCodeInputRef.current.value);
    setRecurlyErrorPromoCode(promoCodeInputRef.current.value);
    dispatch(setPromoCode(promoCodeInputRef.current.value));
    gaBtnClickEvent('apply_promo_code');
  };

  const addressSelect = async (address: any) => {
    dispatch(setMalformedAddressError(false));
    try {
      const addressSections = address.split(',');
      const street = addressSections[0];
      const city = addressSections[1];
      const state = addressSections[2];
      const postalCode = addressSections[3].split(' ')[2];
      const addressBody = {
        address1: street,
        city: city,
        state: state,
        postal_code: postalCode
      };
      dispatch(setMalformedAddressError(false));
      dispatch(setSignupAddress(addressBody));
      dispatch(checkZipcode(postalCode));
    } catch {
      const addressSections = address.split(',');
      const properllyFormattedAddress =
        addressSections.length < 4 && addressSections.length > 1;
      dispatch(setMalformedAddressError(properllyFormattedAddress));
      if (!properllyFormattedAddress) {
        await dispatch(setMalformedAddressError(false));
      }
    }
  };

  useEffect(() => {
    if (!!postal_code) {
      const error = signupPlans.length > 2 ? false : true;
      dispatch(setZipcodeError(error));
    }
  }, [signupPlans]);

  useEffect(() => {
    if (!!homeId) {
      dispatch(requestCreditCardInfo(homeId));
    }
    if (!!promoCode) {
      setRecurlyPromoCode(promoCode);
    }
  }, []);

  function PricingPreview() {
    const recurlyAddOns: { code: any; quantity: number }[] = [];

    // Full Service Plan (as an add-on)
    if (!!newPlan.pricing[newBillingPeriod].addon_code) {
      recurlyAddOns.push({
        code: newPlan.pricing[newBillingPeriod].addon_code,
        quantity: 1
      });
    }

    // Actual add-ons
    if (!!newAddOns.length) {
      newPlan.addons.forEach((item: any) => {
        if (newAddOns.includes(item.id) && !!item.pricing[newBillingPeriod]) {
          recurlyAddOns.push({
            code: item.pricing[newBillingPeriod].addon_code,
            quantity: 1
          });
        }
      });
    }

    const initialPricingInput: UseCheckoutPricingInput = {
      address: {
        first_name: name,
        last_name: name,
        postal_code: postal_code,
        country: 'US'
      },
      currency: 'USD',
      subscriptions: [
        {
          plan: newPlan.pricing[newBillingPeriod].base_code,
          quantity: 1,
          addons: recurlyAddOns
        }
      ],
      coupon: recurlyPromoCode
    };

    const [{ price, loading }] = useCheckoutPricing(
      initialPricingInput,
      setRecurlyError
    );
    if (recurlyError) {
      if (recurlyError.code !== 'limit-exceeded') {
        setRecurlyPromoCode('');
        dispatch(setPromoCode(''));
      }
    }

    return (
      <div className="hk-billing-summary-content">
        <section className="hk-billing-summary-subscription">
          <div className="hk-billing-summary-subscription-header">
            <h4>YOUR SUBSCRIPTION</h4>
            <div className="hk-subscription-pill">
              {newBillingPeriod === HKBillingPeriod.MONTHLY
                ? 'Monthly'
                : 'Yearly'}
            </div>
          </div>
          <div className="hk-billing-summary-subscription-item">
            <h6 className="hk-billing-summary-subscription-item-name">
              {newPlan.name}
            </h6>
            <div className="hk-billing-summary-subscription-item-price">
              <>
                ${newPlan.pricing[newBillingPeriod].usd}/
                {newBillingPeriod === HKBillingPeriod.MONTHLY ? 'mo' : 'yr'}
              </>
            </div>
          </div>
          <CalculateAddOns />
          <div className="hk-billing-summary-subscription-promo">
            <div className="hk-billing-summary-subscription-promo-body">
              <IonInput
                className={`hk-billing-summary-subscription-promo-input ${
                  recurlyError &&
                  'hk-billing-summary-subscription-promo-input-error'
                }`}
                name="promo"
                placeholder="Promo Code"
                ref={promoCodeInputRef}
                value={!recurlyError ? recurlyPromoCode : recurlyErrorPromoCode}
              ></IonInput>
              <IonButton
                expand="block"
                color="primary"
                strong={true}
                className="hk-billing-summary-subscription-promo-button"
                onClick={() => applyPromoCode()}
              >
                Apply
              </IonButton>
            </div>
            <div className="hk-billing-summary-subscription-promo-error">
              {recurlyError ? <span>Enter a valid promo code</span> : ''}
            </div>
          </div>

          <div className="hk-billing-summary-subscription-auto-renew">
            <span>Auto Renew</span>
            <IonToggle
              checked={autoRenew}
              onIonChange={(e) => dispatch(setAutoRenew(e.detail.checked))}
            ></IonToggle>
          </div>

          <div className="hk-billing-summary-subscription-total">
            <div className="hk-billing-summary-subscription-total-sub-container">
              <div className="hk-billing-summary-subscription-total-item">
                <h6 className="hk-billing-summary-subscription-total-item-name">
                  Subtotal
                </h6>
                <div className="hk-billing-summary-subscription-total-item-price">
                  {!loading && !recurlyError ? (
                    <div>
                      {price.currency.symbol}
                      {price.next.subscriptions}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="hk-billing-summary-subscription-total-item">
              <h6 className="hk-billing-summary-subscription-total-item-name">
                Discount
              </h6>
              <div className="hk-billing-summary-subscription-total-item-price">
                {!loading && !recurlyError ? (
                  <div>
                    -{price.currency.symbol}
                    {price.next.discount}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>{' '}
            <div className="hk-billing-summary-subscription-total-item">
              <h6 className="hk-billing-summary-subscription-total-item-name">
                Estimated Tax
              </h6>
              <div className="hk-billing-summary-subscription-total-item-price">
                {!loading && !recurlyError ? (
                  <div>
                    {price.currency.symbol}
                    {price.next.taxes}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="hk-billing-summary-due">
          <div className="hk-billing-summary-due-item">
            <h6 className="hk-billing-summary-due-item-name">
              TOTAL DUE TODAY
            </h6>
            <div className="hk-billing-summary-due-item-price">
              {!loading && !recurlyError ? (
                <div>
                  {price.currency.symbol}
                  {price.next.total}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }

  function CalculateAddOns() {
    const plans: any = [];
    newPlan.addons.forEach((item: any) => {
      if (newAddOns.includes(item.id)) {
        plans.push(item);
      }
    });

    return (
      <>
        {plans.map((item: any, i: number) => {
          return (
            <div
              className="hk-billing-summary-subscription-item"
              key={`addon-${i}`}
            >
              <h6 className="hk-billing-summary-subscription-item-name">
                {item.name}
              </h6>
              <div className="hk-billing-summary-subscription-item-price">
                {!!item.pricing.price_text ? (
                  item.pricing.price_text
                ) : (
                  <>
                    {!!item.pricing[newBillingPeriod] && (
                      <>
                        ${item.pricing[newBillingPeriod].usd}/
                        {newBillingPeriod === HKBillingPeriod.MONTHLY
                          ? 'mo'
                          : 'yr'}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  function AddressHeaders() {
    let addressTitle;
    let addressDescription;

    if (newPlan.name.includes('Full Service')) {
      addressTitle = ADDRESS_HEADERS.FULL_SERVICE.title;
      addressDescription = ADDRESS_HEADERS.FULL_SERVICE.description;
    } else if (newPlan.name.includes('Delivery')) {
      addressTitle = ADDRESS_HEADERS.AUTO_DELIVERY.title;
      addressDescription = ADDRESS_HEADERS.AUTO_DELIVERY.description;
    } else {
      addressTitle = ADDRESS_HEADERS.DIGITAL.title;
      addressDescription = ADDRESS_HEADERS.DIGITAL.description;
    }

    return (
      <>
        <h2>{addressTitle}</h2>
        <p>{addressDescription}</p>
      </>
    );
  }

  function DesktopCheckoutView() {
    return (
      <>
        <Header />
        <IonContent fullscreen>
          <RecurlyProvider
            publicKey={isDev() ? RECURLY_DEVL_KEY : RECURLY_PUBLIC_KEY}
          >
            <div className="hk-desktop-signup-checkout-page-header">
              <IonBreadcrumbs>
                <IonBreadcrumb href="/signup/plans">
                  Select Your Plan
                </IonBreadcrumb>
                <IonBreadcrumb>Checkout Summary</IonBreadcrumb>
              </IonBreadcrumbs>
            </div>
            <div className="hk-desktop-signup-checkout-page-container">
              <section className="hk-desktop-signup-checkout-card-section">
                <div className="hk-desktop-signup-checkout-card-header">
                  <AddressHeaders />
                </div>
                <div className="hk-desktop-signup-checkout-card-header">
                  <AddressSearchBar
                    className={
                      zipcodeError && newPlan.name.includes('Full Service')
                        ? 'address-bar-zipcode-error'
                        : malformedAddressError
                        ? 'address-bar-zipcode-error'
                        : ''
                    }
                    onSearchSelect={(address) => addressSelect(address)}
                  />
                  {zipcodeError && newPlan.name.includes('Full Service') && (
                    <div className="hk-desktop-signup-checkout-zipcode-error">
                      <p>
                        The address you entered is not available in our full
                        service area, please enter one that matches the zipcode
                        you entered.
                      </p>
                    </div>
                  )}
                </div>

                <div className="hk-desktop-signup-checkout-form">
                  <div className="hk-desktop-signup-checkout-card-header">
                    <h2>Payment</h2>
                    <p>Add a payment method to your account to checkout.</p>
                  </div>
                  <Elements>
                    <CreditCardForm />
                  </Elements>
                </div>
              </section>
              <section className="hk-desktop-signup-checkout-summary-section">
                <Elements>
                  <PricingPreview />
                </Elements>
              </section>
            </div>
          </RecurlyProvider>
          <Footer />
        </IonContent>
      </>
    );
  }

  function MobileCheckoutView() {
    return (
      <>
        <Summary />
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-signup-checkout-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopCheckoutView()
        : MobileCheckoutView()}
    </IonPage>
  );
};

export default Recurly;
