import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonContent,
  IonList,
  IonModal,
  IonPage,
  IonRefresher,
  IonRefresherContent
} from '@ionic/react';
import { RootState } from 'core/store';
import { useHistory, useParams } from 'react-router';
import {
  DATE_FORMAT_MONTH_DATE,
  HKPlatform,
  HKScheduleType
} from 'core/constants';
import {
  deleteScheduledAppointment,
  fetchAppointmentDetails,
  fetchAppointmentTaskList
} from './AppointmentDetailActions';
import {
  formatAppointmentDisplayTimeSlot,
  formatTimezoneDate,
  gaBtnClickEvent,
  getSeasonIcon
} from 'core/util/helpers';
import {
  hideAppointmentModal,
  resetAppointmentWizard,
  showAppointmentWizard
} from './wizard/AppointmentWizardSlice';
import {
  hideCancelAppointmentModal,
  removeDetailedAppointment,
  setModalTask,
  showCancelAppointmentModal
} from './AppointmentDetailsSlice';
import ServicePersonCard from 'components/cards/service-person/ServicePersonCard';
import TaskItem from 'components/items/task-item/TaskItem';
import LoadingSpinner, { LoadingMode } from 'components/loading/LoadingSpinner';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import AppointmentWizard from './wizard/AppointmentWizard';
import TaskDetails from './task-details/TaskDetails';
import DownArrow from 'assets/icons/arrow-down.svg';
import './AppointmentDetails.scss';
import CancelAppointment from './cancel-appointment/CancelAppointment';

interface AppointmentParams {
  id?: string | undefined;
}

const AppointmentDetails: React.FC = () => {
  const { id } = useParams<AppointmentParams>();
  const {
    loading,
    tasksLoading,
    detailedAppointment,
    detailedAppointmentTasks,
    modalTask,
    modalCancelAppointmentVisible
  } = useSelector((state: RootState) => state.appointment);
  const { currentHome } = useSelector((state: RootState) => state.home);
  const { wizardModalVisible } = useSelector(
    (state: RootState) => state.appointmentWizard
  );
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const refresherRef = useRef<HTMLIonRefresherElement>(null);
  const dispatch = useDispatch();

  const refreshAppointment = () => {
    dispatch(removeDetailedAppointment());
  };

  const rescheduleAppointment = () => {
    gaBtnClickEvent('reschedule_appointment');
    dispatch(resetAppointmentWizard());
    dispatch(
      showAppointmentWizard({
        type: HKScheduleType.RESCHEDULE,
        id: detailedAppointment?.id,
        name: detailedAppointment?.name,
        home_id: detailedAppointment?.home_id,
        notes: detailedAppointment?.homeowner_notes
      })
    );
  };

  const cancelAppointment = async (homeId: number, appointment: any) => {
    dispatch(showCancelAppointmentModal());
  };

  useEffect(() => {
    if (!loading && !tasksLoading && !!refresherRef) {
      refresherRef.current?.complete();
    }
  }, [dispatch, loading, tasksLoading, refresherRef]);

  useEffect(() => {
    if (!detailedAppointment && !!currentHome) {
      dispatch(fetchAppointmentDetails(currentHome.id, Number(id)));
    }
  }, [dispatch, detailedAppointment, currentHome, id]);

  useEffect(() => {
    if (!detailedAppointmentTasks && !!currentHome) {
      dispatch(fetchAppointmentTaskList(currentHome.id, Number(id)));
    }
  }, [dispatch, detailedAppointmentTasks, currentHome, id]);

  function DesktopAppointmentDetailsView() {
    return (
      <IonPage className="hk-desktop-appointment-details-page">
        {loading ? (
          <LoadingSpinner mode={LoadingMode.Logo} />
        ) : (
          <IonContent>
            <div className="hk-desktop-appointment-details">
              {!loading && !!detailedAppointment && (
                <div className="hk-desktop-appointment-details-header">
                  <ServicePersonCard
                    icon={getSeasonIcon(detailedAppointment.name)}
                    appointment={detailedAppointment}
                    person={detailedAppointment?.worker}
                    time={
                      !!detailedAppointment?.scheduled
                        ? formatAppointmentDisplayTimeSlot(
                            detailedAppointment,
                            currentHome?.market_timezone || ''
                          )
                        : ''
                    }
                    date={
                      !!detailedAppointment?.scheduled
                        ? formatTimezoneDate(
                            detailedAppointment?.scheduled || '',
                            DATE_FORMAT_MONTH_DATE,
                            currentHome?.market_timezone || ''
                          )
                        : ''
                    }
                    completed={!!detailedAppointment?.finished}
                    cancelClicked={() =>
                      cancelAppointment(currentHome?.id!, detailedAppointment)
                    }
                    rescheduleClicked={rescheduleAppointment}
                  />
                  <h6 className="hk-desktop-appointment-details-header-description ion-padding">
                    {detailedAppointment?.description}
                  </h6>
                </div>
              )}
              {!loading && !!detailedAppointmentTasks && (
                <IonList className="hk-desktop-appointment-details-tasks">
                  {detailedAppointmentTasks?.map((task) => {
                    return (
                      <TaskItem
                        key={`task-${task.id}`}
                        task={task}
                        timezone={currentHome?.market_timezone || ''}
                      />
                    );
                  })}
                </IonList>
              )}
              {(loading || tasksLoading) && <LoadingSpinner />}
            </div>
          </IonContent>
        )}

        <IonModal
          className="hk-appointment-details-modal"
          onDidDismiss={() => dispatch(setModalTask(null))}
          isOpen={!!modalTask}
        >
          <TaskDetails />
        </IonModal>
        <IonModal
          className="hk-appointment-wizard-modal"
          isOpen={wizardModalVisible}
          onDidDismiss={() => {
            dispatch(hideAppointmentModal());
          }}
        >
          <AppointmentWizard />
        </IonModal>
      </IonPage>
    );
  }

  function MobileAppointmentDetailsView() {
    return (
      <IonPage>
        <BackButtonHeader title={detailedAppointment?.name} />
        {loading ? (
          <LoadingSpinner mode={LoadingMode.Logo} />
        ) : (
          <IonContent className="hk-appointment-details ion-padding-vertical">
            <IonRefresher
              className="hk-refresher ion-padding"
              ref={refresherRef}
              slot="fixed"
              pullFactor={0.6}
              pullMin={100}
              onIonRefresh={refreshAppointment}
            >
              <IonRefresherContent
                pullingIcon={DownArrow}
                pullingText="Pull to refresh"
                refreshingText="Refreshing..."
              />
            </IonRefresher>
            {!loading && !!detailedAppointment && (
              <div className="hk-appointment-details-header ion-margin-horizontal">
                <ServicePersonCard
                  icon={getSeasonIcon(detailedAppointment.name)}
                  appointment={detailedAppointment}
                  person={detailedAppointment?.worker}
                  time={
                    !!detailedAppointment?.scheduled
                      ? formatAppointmentDisplayTimeSlot(
                          detailedAppointment,
                          currentHome?.market_timezone || ''
                        )
                      : ''
                  }
                  date={
                    !!detailedAppointment?.scheduled
                      ? formatTimezoneDate(
                          detailedAppointment?.scheduled || '',
                          DATE_FORMAT_MONTH_DATE,
                          currentHome?.market_timezone || ''
                        )
                      : ''
                  }
                  completed={!!detailedAppointment?.finished}
                  cancelClicked={() =>
                    cancelAppointment(currentHome?.id!, detailedAppointment)
                  }
                  rescheduleClicked={rescheduleAppointment}
                />
                <h6 className="hk-appointment-details-header-description ion-padding">
                  {detailedAppointment?.description}
                </h6>
              </div>
            )}
            {!loading && !!detailedAppointmentTasks && (
              <IonList className="hk-appointment-details-tasks">
                {detailedAppointmentTasks?.map((task) => {
                  return (
                    <TaskItem
                      key={`task-${task.id}`}
                      task={task}
                      timezone={currentHome?.market_timezone || ''}
                    />
                  );
                })}
              </IonList>
            )}
            {(loading || tasksLoading) && <LoadingSpinner />}
          </IonContent>
        )}

        <IonModal
          isOpen={!!modalTask}
          onDidDismiss={() => dispatch(setModalTask(null))}
        >
          <TaskDetails />
        </IonModal>
        <IonModal
          isOpen={wizardModalVisible}
          onDidDismiss={() => {
            dispatch(hideAppointmentModal());
          }}
        >
          <AppointmentWizard />
        </IonModal>
      </IonPage>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopAppointmentDetailsView()
        : MobileAppointmentDetailsView()}
      <IonModal
        className="hk-cancel-appointment-modal"
        isOpen={modalCancelAppointmentVisible}
        onDidDismiss={() => {
          dispatch(hideCancelAppointmentModal());
        }}
      >
        <CancelAppointment />
      </IonModal>
    </>
  );
};

export default AppointmentDetails;
