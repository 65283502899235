import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonToolbar
} from '@ionic/react';
import { HKBillingPeriod, HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Close from 'assets/icons/close.svg';
import './AddOns.scss';
import { hideAddOnsModal, setNewAddOns } from './BillingSlice';
import Complete from 'assets/icons/complete.svg';
import { gaBtnClickEvent, gaModalView } from 'core/util';

const AddOns: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const { availableAddOns, newAddOns, newBillingPeriod } = useSelector(
    (state: RootState) => state.billing
  );
  const { isDigital } = useSelector((state: RootState) => state.user);
  const [selectedAddOns, setSelectedAddOns] = useState<any[]>([]);

  const dispatch = useDispatch();

  const selectAddOn = (addOn: any) => {
    if (selectedAddOns.includes(addOn.id)) {
      gaBtnClickEvent('deselect_add_on_' + addOn.id);
      const existingAddOnIndex = selectedAddOns.findIndex(
        (item) => item === addOn.id
      );
      const arrayForSort = [...selectedAddOns];
      arrayForSort.splice(existingAddOnIndex, 1);
      setSelectedAddOns([...arrayForSort]);
    } else {
      gaBtnClickEvent('select_add_on_' + addOn.id);
      setSelectedAddOns([...selectedAddOns, addOn.id]);
    }
  };

  const submitAddOns = () => {
    gaBtnClickEvent('add_selected_add_ons_to_subscription');
    dispatch(setNewAddOns(selectedAddOns));
    dispatch(hideAddOnsModal());
  };

  const cancelModal = () => {
    gaBtnClickEvent('close_add_ons_modal');
    dispatch(hideAddOnsModal());
  };

  useEffect(() => {
    if (!!newAddOns.length) {
      setSelectedAddOns(newAddOns);
    }
    gaModalView(isDigital, '/renew/addons');
  }, []);

  function DesktopAddOnsView() {
    return (
      <>
        <IonFab horizontal="end">
          <IonFabButton
            color="background"
            className={`hk-fab-button`}
            onClick={cancelModal}
          >
            <IonIcon color="dark" icon={Close} />
          </IonFabButton>
        </IonFab>
        <IonToolbar className="hk-desktop-plan-addon-content-header">
          <h4>CHOOSE AN ADD-ON</h4>
        </IonToolbar>
        <IonContent className="hk-desktop-plan-addon">
          <div className="hk-desktop-plan-addon-content">
            <div className="hk-desktop-plan-addon-content-body">
              {/* TODO: Eventually will add different addon types here*/}
              {/* <div className="hk-plan-addon-content-body-types">
                <div className="plan-type selected">Expert Help</div>
                <div className="plan-type">Digital + Delivery</div>
                <div className="plan-type">Home Checkup</div>
                <div className="plan-type">Test</div>
              </div> */}
              <div className="hk-desktop-plan-addon-content-body-plans">
                {availableAddOns.map((addOn: any, i: number) => {
                  return (
                    <div
                      className={`plan-card ${
                        selectedAddOns.includes(addOn.id) && 'card-selected'
                      }`}
                      key={`plan-card-${i}`}
                      onClick={() => selectAddOn(addOn)}
                    >
                      <section className="plan-card-header">
                        <span className="plan-card-header-title">
                          {!!addOn.pricing.price_text ? (
                            addOn.pricing.price_text
                          ) : (
                            <>
                              {!!addOn.pricing[newBillingPeriod] && (
                                <>
                                  ${addOn.pricing[newBillingPeriod].usd}/
                                  <span>
                                    {newBillingPeriod ===
                                    HKBillingPeriod.MONTHLY
                                      ? 'Month'
                                      : 'Year'}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                        <div className="plan-card-header-checked">
                          {selectedAddOns.includes(addOn.id) ? (
                            <IonIcon color="primary" icon={Complete} />
                          ) : (
                            <div className="plan-card-header-circle"></div>
                          )}
                        </div>
                      </section>
                      <section className="plan-card-body">
                        <h4 className="plan-card-body-title">{addOn.name}</h4>
                        <ul
                          className="plan-card-body-list"
                          style={{ listStyleType: 'square' }}
                        >
                          <li>Timely access to expert help for home issues</li>
                          <li>Trusted partner in all things home</li>
                          <li>Save time and money calling around</li>
                        </ul>
                      </section>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </IonContent>
        <IonToolbar className="hk-desktop-plan-addon-toolbar ion-padding">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={submitAddOns}
          >
            Add Selected to Subscription
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  function MobileAddOnsView() {
    return (
      <>
        <IonFab horizontal="end">
          <IonFabButton
            color="background"
            className={`hk-fab-button`}
            onClick={cancelModal}
          >
            <IonIcon color="medium" icon={Close} />
          </IonFabButton>
        </IonFab>
        <IonToolbar className="hk-plan-addon-content-header">
          <h4>CHOOSE AN ADD-ON</h4>
        </IonToolbar>
        <IonContent className="hk-plan-addon">
          <div className="hk-plan-addon-content">
            <div className="hk-plan-addon-content-body">
              {/* TODO: Eventually will add different addon types here*/}
              {/* <div className="hk-plan-addon-content-body-types">
                <div className="plan-type selected">Expert Help</div>
                <div className="plan-type">Digital + Delivery</div>
                <div className="plan-type">Home Checkup</div>
                <div className="plan-type">Test</div>
              </div> */}
              <div className="hk-plan-addon-content-body-plans">
                {availableAddOns.map((addOn: any, i: number) => {
                  return (
                    <div
                      className={`plan-card ${
                        selectedAddOns.includes(addOn.id) && 'card-selected'
                      }`}
                      key={`plan-card-${i}`}
                      onClick={() => selectAddOn(addOn)}
                    >
                      <section className="plan-card-header">
                        <span className="plan-card-header-title">
                          {!!addOn.pricing.price_text ? (
                            addOn.pricing.price_text
                          ) : (
                            <>
                              {!!addOn.pricing[newBillingPeriod] && (
                                <>
                                  ${addOn.pricing[newBillingPeriod].usd}/
                                  <span>
                                    {newBillingPeriod ===
                                    HKBillingPeriod.MONTHLY
                                      ? 'Month'
                                      : 'Year'}
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </span>
                        <div className="plan-card-header-checked">
                          {selectedAddOns.includes(addOn.id) ? (
                            <IonIcon color="primary" icon={Complete} />
                          ) : (
                            <div className="plan-card-header-circle"></div>
                          )}
                        </div>
                      </section>
                      <section className="plan-card-body">
                        <h4 className="plan-card-body-title">{addOn.name}</h4>
                        <ul
                          className="plan-card-body-list"
                          style={{ listStyleType: 'square' }}
                        >
                          <li>Timely access to expert help for home issues</li>
                          <li>Trusted partner in all things home</li>
                          <li>Save time and money calling around</li>
                        </ul>
                      </section>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </IonContent>
        <IonToolbar className="hk-plan-addon-toolbar ion-padding">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={submitAddOns}
          >
            Add Selected to Subscription
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopAddOnsView()
        : MobileAddOnsView()}
    </>
  );
};

export default AddOns;
