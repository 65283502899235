import React, { useState } from 'react';
import './HomeSwitcher.scss';
import { IonIcon, IonItem } from '@ionic/react';
import AddressIcon from 'assets/icons/address.svg';
import ArrowIcon from 'assets/icons/arrow-down.svg';
import SearchBar, { filterItems } from 'components/searchbar/SearchBar';
import LoadingIonList from 'core/layout/LoadingIonList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Home, setCurrentHome } from 'core/services/HomeSlice';
import { requestHomeInfo } from 'core/services/HomeActions';

const HomeSwitcher: React.FC = () => {
  const { homes, currentHome } = useSelector((state: RootState) => state.home);
  const [isSwitcherOpen, setIsSwitcherOpen] = useState(false);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const formatAddress = (home: Home) => {
    const homeAddress = {
      id: home.id,
      street: home.address1,
      city: home.city + ', ' + home.state + ' ' + home.postal_code
    };
    return homeAddress;
  };
  const filteredAddresses = () => {
    const homeAddressesList: { id: number; street: string; city: string }[] =
      [];
    if (!homes) {
      return homeAddressesList;
    }
    homes.forEach((home: Home) => {
      homeAddressesList.push(formatAddress(home));
    });
    return homeAddressesList;
  };

  const filteredHomes = filterItems(
    filteredAddresses() || [],
    ['street'],
    search
  );

  const handleSelect = (id: number) => () => {
    if (!homes) {
      return false;
    }
    const home = homes.find((h) => h.id === id);
    dispatch(setCurrentHome(home));
    dispatch(requestHomeInfo(id));
    isSwitcherOpen && setIsSwitcherOpen(false);
  };

  const AddressList = () => {
    return (
      <LoadingIonList
        className="hk-home-switcher-address-list"
        items={filteredHomes}
        label={'addressList'}
        loading={false}
      >
        {filteredHomes.map((home: any, i: any) => {
          return (
            <IonItem
              lines="none"
              className="hk-home-switcher-address-item"
              key={i}
              onClick={handleSelect(home.id)}
            >
              <IonIcon
                className="hk-home-switcher-icon"
                color="white"
                icon={AddressIcon}
              />
              <div className="hk-home-switcher-address">
                <span>{home.street}</span>
                <span>{home.city}</span>
              </div>
            </IonItem>
          );
        })}
      </LoadingIonList>
    );
  };

  return (
    <>
      <div
        className="hk-home-switcher"
        onClick={() => setIsSwitcherOpen(!isSwitcherOpen)}
      >
        <div className="hk-home-switcher-address-container">
          <IonIcon
            className="hk-home-switcher-icon"
            color="white"
            icon={AddressIcon}
          />
          <div className="hk-home-switcher-address">
            <span>{formatAddress(currentHome!).street}</span>
            <span>{formatAddress(currentHome!).city}</span>
          </div>
        </div>
        <IonIcon
          slot="end"
          className="hk-home-switcher-icon"
          style={{
            transform: isSwitcherOpen ? 'rotate(180deg)' : 'rotate(0deg)'
          }}
          color="white"
          icon={ArrowIcon}
        />
      </div>

      <div
        className={`hk-home-switcher-result ${isSwitcherOpen ? 'open' : ''}`}
      >
        <SearchBar
          placeholder={'Search for an address'}
          value={search}
          onSearchChange={(e) => setSearch(e)}
        ></SearchBar>
        {AddressList()}
      </div>
    </>
  );
};

export default HomeSwitcher;
