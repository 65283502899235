import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { Redirect } from 'react-router';
import { loadTutorialViewedFromStorage } from 'core/util';
import { requestUserInfo } from 'core/services/UserActions';
import { CatalogStatus, DEFAULT_HK_BUILDER_ID } from 'core/constants';
import { setScheduleHomeId } from '../../full-service/appointments/wizard/AppointmentWizardSlice';
import LoadingSpinner, { LoadingMode } from 'components/loading/LoadingSpinner';
import { getFirebaseBuilderLogo } from 'core/hooks/useFirebase';
import { setBuilderLogo } from 'core/services/HomeSlice';

const Checkpoint: React.FC = () => {
  const { accessToken, loginDest } = useSelector(
    (state: RootState) => state.login
  );
  const { currentHome, isExpired, globalAccountSettings } = useSelector(
    (state: RootState) => state.home
  );
  const { name, isDigital } = useSelector((state: RootState) => state.user);
  const [route, setRoute] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let initial_route = '/dashboard';
    if (loginDest) {
      initial_route = loginDest;
    }
    if (isDigital === false) {
      let tutorialViewed = false;
      loadTutorialViewedFromStorage().then((viewed) => {
        tutorialViewed = viewed;
      });
      if (
        (currentHome?.catalog_status === undefined ||
          currentHome?.catalog_status === CatalogStatus.NOT_SCHEDULED) &&
        !isExpired
      ) {
        dispatch(setScheduleHomeId(currentHome?.id));
        initial_route = tutorialViewed ? '/initial' : '/tutorial';
      }
      setRoute(initial_route);
    } else if (isDigital === true) {
      if (
        currentHome?.type === undefined ||
        currentHome?.type === null ||
        currentHome?.type === 'default'
      ) {
        initial_route = '/onboarding/what-type-of-home-do-you-own';
      }
      if (isExpired) {
        initial_route = '/renew';
      }
      setRoute(initial_route);
    }
  }, [dispatch, currentHome, isDigital]);

  useEffect(() => {
    getFirebaseBuilderLogo(
      globalAccountSettings?.builder_id
        ? globalAccountSettings?.builder_id
        : DEFAULT_HK_BUILDER_ID
    ).then((builder) => {
      if (!!builder) {
        dispatch(setBuilderLogo(builder.logo_url));
      }
    });
    if (!name) {
      dispatch(requestUserInfo());
    }
  }, [name]);

  useEffect(() => {
    if (!accessToken) {
      setRoute('/login');
    }
  }, [accessToken]);

  return !!route ? (
    <Redirect to={route} />
  ) : (
    <LoadingSpinner mode={LoadingMode.Logo} />
  );
};

export default Checkpoint;
