import React from 'react';
import { IonItem, IonLabel, IonText, IonThumbnail } from '@ionic/react';
import './ListCard.scss';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';
import Thumbnail from 'components/thumbnail/Thumbnail';

type ListItemProps = {
  title: string;
  descriptionOne?: string;
  descriptionTwo?: string;
  descriptionTitleOne?: string;
  descriptionTitleTwo?: string;
  image?: string;
  onClick?: (e: any) => void;
  className?: string;
};

const ListCard: React.FC<ListItemProps> = ({
  title,
  descriptionOne,
  descriptionTwo,
  descriptionTitleOne,
  descriptionTitleTwo,
  image,
  onClick,
  className = '',
  ...rest
}) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  function DesktopDocumentsView() {
    return (
      <IonItem
        lines="none"
        className={`hk-desktop-list-card ${className}`}
        onClick={onClick}
        {...rest}
      >
        <IonThumbnail
          slot="start"
          color="primary"
          className="hk-desktop-list-card-thumbnail"
        >
          <Thumbnail src={image} alt="Item" />
        </IonThumbnail>
        <IonLabel className="hk-desktop-list-card-label">
          <IonText className="hk-desktop-list-card-title" color="white">
            <h2>{title}</h2>
          </IonText>
          {!!descriptionOne && (
            <IonText
              className="hk-desktop-list-card-description"
              color="medium"
            >
              <div>{!!descriptionTitleOne ? descriptionTitleOne : 'Model'}</div>
              <p>{descriptionOne}</p>
            </IonText>
          )}
          {!!descriptionTwo && (
            <IonText
              className="hk-desktop-list-card-description"
              color="medium"
            >
              <div>
                {!!descriptionTitleTwo ? descriptionTitleTwo : 'Product Type'}
              </div>
              <p>{descriptionTwo}</p>
            </IonText>
          )}
        </IonLabel>
      </IonItem>
    );
  }

  function MobileDocumentsView() {
    return (
      <IonItem
        lines="none"
        className={`hk-list-card ion-margin-vertical ${className}`}
        onClick={onClick}
        {...rest}
      >
        <IonLabel>
          <IonText color="white">
            <h2 className="hk-list-card-title">{title}</h2>
          </IonText>
          <IonText color="medium">
            <p className="hk-list-card-description">{descriptionOne}</p>
          </IonText>
          <IonText color="medium">
            <p className="hk-list-card-description">{descriptionTwo}</p>
          </IonText>
        </IonLabel>
        <IonThumbnail
          slot="end"
          color="primary"
          className="hk-list-card-thumbnail"
        >
          <Thumbnail src={image} alt="Item" />
        </IonThumbnail>
      </IonItem>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDocumentsView()
        : MobileDocumentsView()}
    </>
  );
};

export default ListCard;
