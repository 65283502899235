import React from 'react';
import './Footer.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { HKPlatform } from 'core/constants';

type FooterProps = {
  darkMode?: boolean;
};
const Footer: React.FC<FooterProps> = ({ darkMode, children, ...rest }) => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );

  return (
    <div
      className={`${darkMode ? 'hk-footer-dark' : ''} hk-footer`}
      style={{
        padding:
          platformType === HKPlatform.DESKTOP && isDesktopWidth ? '0px' : '32px'
      }}
    >
      © 2022-2024 | HomeKeep™ is a trademarked term. All rights reserved.
    </div>
  );
};

export default Footer;
