import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonToolbar
} from '@ionic/react';
import { HKPlatform } from 'core/constants';
import Close from 'assets/icons/close.svg';
import './DiscardChanges.scss';
import { hideDiscardModal, hideUserEdit } from './AccountSlice';
import { gaBtnClickEvent } from 'core/util';

const DiscardChanges: React.FC = () => {
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const dispatch = useDispatch();

  const onDiscard = () => {
    gaBtnClickEvent('discard_edit_account_changes');
    dispatch(hideUserEdit());
    dispatch(hideDiscardModal());
  };

  function DesktopDiscardChangesView() {
    return (
      <IonPage>
        <IonContent>
          <IonFab vertical="top" horizontal="end">
            <IonFabButton
              color="white"
              className="hk-fab-button"
              onClick={() => dispatch(hideDiscardModal())}
            >
              <IonIcon color="medium" icon={Close} />
            </IonFabButton>
          </IonFab>
          <div className="hk-desktop-discard-account-changes-modal-body">
            <div className="hk-desktop-discard-account-changes-modal-body-title">
              Are you sure you want to discard changes made?
            </div>
            <span className="hk-desktop-discard-account-changes-modal-body-description">
              Unsaved changes will not be saved.
            </span>
          </div>
        </IonContent>
        <div className="hk-desktop-discard-account-changes-modal-toolbar">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            onClick={() => dispatch(hideDiscardModal())}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="danger"
            strong={true}
            onClick={onDiscard}
          >
            Discard Changes
          </IonButton>
        </div>
      </IonPage>
    );
  }

  function MobileDiscardChangesView() {
    return (
      <>
        <IonContent fullscreen className="ion-padding">
          <div className="hk-discard-account-changes-body ">
            <div>
              <h3 className="hk-discard-account-changes-body-title ion-text-center">
                Are you sure you want to discard changes made?
              </h3>
              <h6 className="hk-discard-account-changes-body-description ion-padding-horizontal ion-text-center">
                Unsaved changes will not be saved.
              </h6>
            </div>
          </div>
        </IonContent>
        <IonToolbar className="hk-discard-account-changes-toolbar ion-padding">
          <IonButton
            expand="block"
            color="white"
            strong={true}
            className="ion-margin-vertical"
            onClick={() => dispatch(hideDiscardModal())}
          >
            Cancel
          </IonButton>
          <IonButton
            expand="block"
            color="danger"
            strong={true}
            onClick={onDiscard}
          >
            Discard Changes
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <>
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopDiscardChangesView()
        : MobileDiscardChangesView()}
    </>
  );
};

export default DiscardChanges;
