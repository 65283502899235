import { createApiRequest } from 'core/util';
import {
  AUTH_FIREBASE_URL,
  INITIAL_SIGN_IN_PASSWORD_RESET_URL,
  INITIAL_SIGN_IN_URL,
  USER_RESET_PASSWORD_URL
} from 'core/constants';

import {
  requestTokenForFirebaseUserStart,
  requestTokenForFirebaseUserSuccess,
  requestTokenForFirebaseUserError,
  requestFirebasePasswordResetStart,
  requestFirebasePasswordResetError,
  requestFirebasePasswordResetSuccess,
  fetchInitialSignInEmailsStart,
  fetchInitialSignInEmailsError,
  fetchInitialSignInEmailsSuccess,
  requestInitialSignInPasswordResetError,
  requestInitialSignInPasswordResetStart,
  requestInitialSignInPasswordResetSuccess
} from './LoginSlice';
import { AppDispatch, AppThunk } from 'core/store';
import { resetUserInfo } from 'core/services/UserSlice';
import { resetHomes } from 'core/services/HomeSlice';
import {
  resetAppointmentList,
  resetDashboard,
  resetVisitsList
} from 'features/full-service/dashboard/DashboardSlice';
import {
  resetAppointmentWizard,
  resetProposals
} from 'features/full-service/appointments/wizard/AppointmentWizardSlice';
import { resetAppointmentDetails } from 'features/full-service/appointments/AppointmentDetailsSlice';
import {
  resetAllDocuments,
  resetDocuments
} from 'features/shared/documents/DocumentsSlice';
import { resetSignup } from 'features/shared/signup/SignupSlice';
import {
  resetInventoryList,
  resetProductGroup,
  resetProductTemplate
} from 'features/shared/inventory/InventorySlice';
import {
  resetDigitalTaskDetails,
  resetDigitalTasksList
} from 'features/digital/store/DigitalTasksSlice';
import {
  resetContactsList,
  resetCurrentContact
} from 'features/shared/contacts/ContactsSlice';
import {
  resetDigitalCatalogList,
  resetDigitalCatalogTaskDetails
} from 'features/digital/store/DigitalCatalogSlice';
import { hideUserEdit } from 'features/shared/account/AccountSlice';
import { resetPlatformInfo } from 'core/services/PlatformSlice';

export const requestTokenForFirebaseUser = (userToken: string) =>
  createApiRequest({
    route: AUTH_FIREBASE_URL,
    method: 'post',
    body: { id_token: userToken },
    onStart: requestTokenForFirebaseUserStart,
    onSuccess: requestTokenForFirebaseUserSuccess,
    onError: requestTokenForFirebaseUserError,
    defaultErrorMessage: 'Unable to Log In. Please try again.'
    // useGlobalLoader: true
  })();

export const updateFirebasePassword = (
  userPassword: string,
  userToken: string
) =>
  createApiRequest({
    route: USER_RESET_PASSWORD_URL,
    method: 'post',
    body: { password: userPassword },
    replace: [['token', userToken]],
    onStart: requestFirebasePasswordResetStart,
    onSuccess: requestFirebasePasswordResetSuccess,
    onError: requestFirebasePasswordResetError,
    defaultErrorMessage: 'Unable to reset password. Please try again.'
    // useGlobalLoader: true
  })();

export const requestInitialSignInEmails = (fullAddress: string) =>
  createApiRequest({
    route: INITIAL_SIGN_IN_URL,
    method: 'get',
    replace: [['full_address', fullAddress]],
    onStart: fetchInitialSignInEmailsStart,
    onSuccess: fetchInitialSignInEmailsSuccess,
    onError: fetchInitialSignInEmailsError,
    defaultErrorMessage: 'There was a problem fetching your sign in emails.'
  })();

export const sendInitialSignInPasswordReset = (id: string, resetHash: string) =>
  createApiRequest({
    route: INITIAL_SIGN_IN_PASSWORD_RESET_URL,
    method: 'post',
    body: { id: id, reset_hash: resetHash },
    onStart: requestInitialSignInPasswordResetStart,
    onSuccess: requestInitialSignInPasswordResetSuccess,
    onError: requestInitialSignInPasswordResetError,
    defaultErrorMessage: 'Unable to reset password. Please try again.'
    // useGlobalLoader: true
  })();

export const resetAppToInitialState =
  (): AppThunk => async (dispatch: AppDispatch) => {
    // TODO: Come up with cleaner way to reset entire app state
    dispatch(resetUserInfo());
    dispatch(resetHomes());
    dispatch(resetAppointmentWizard());
    dispatch(resetAppointmentDetails());
    dispatch(resetDashboard());
    dispatch(resetAppointmentList());
    dispatch(resetAllDocuments());
    dispatch(resetVisitsList());
    dispatch(hideUserEdit());
    dispatch(resetSignup());
    dispatch(resetProposals());
    dispatch(resetProductTemplate());
    dispatch(resetProductGroup());
    dispatch(resetDigitalTasksList());
    dispatch(resetDigitalTaskDetails());
    dispatch(resetDocuments());
    dispatch(resetCurrentContact());
    dispatch(resetContactsList());
    dispatch(resetDigitalCatalogTaskDetails());
    dispatch(resetDigitalCatalogList());
    dispatch(resetInventoryList());
    dispatch(resetPlatformInfo());
  };
