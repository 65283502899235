import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { HKPlatform } from 'core/constants';
import { RootState } from 'core/store';
import { useSelector } from 'react-redux';

export interface UserPhoto {
  filePath: string;
  webviewPath?: string;
  imageData?: any;
}

export function usePhotoGallery() {
  const { platformType } = useSelector((state: RootState) => state.platform);

  const takePhoto = async () => {
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source:
        platformType === HKPlatform.IOS || platformType === HKPlatform.ANDROID
          ? CameraSource.Prompt
          : CameraSource.Camera,
      quality: 100,
      saveToGallery: true
    });
    const fileName = `photo.jpg`;
    const blob = await fetch(photo.webPath!).then((r) => r.blob());
    const newPhoto = {
      filePath: fileName,
      webviewPath: photo.webPath,
      imageData: blob
    };
    return newPhoto;
  };

  return {
    takePhoto
  };
}
