import {
  IonButton,
  IonContent,
  IonModal,
  IonPage,
  IonRow,
  IonToolbar
} from '@ionic/react';
import ListItem from 'components/items/list-item/ListItem';
import SearchBar, { filterItems } from 'components/searchbar/SearchBar';
import { HKPlatform } from 'core/constants';
import LoadingIonList from 'core/layout/LoadingIonList';
import MenuHeader from 'core/layout/menu-header/MenuHeader';
import { RootState } from 'core/store';
import { fetchContactList } from 'features/shared/contacts/ContactsActions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './Contacts.scss';
import NewContact from './NewContact';
import ArrowRight from 'assets/icons/arrow-right.svg';
import { callOutline } from 'ionicons/icons';
import {
  showNewContactsModal,
  hideNewContactsModal,
  showEditContactsModal,
  hideEditContactsModal
} from 'features/shared/contacts/ContactsSlice';
import EditContact from './EditContact';
import { gaBtnClickEvent } from 'core/util';

const Contacts: React.FC = () => {
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const { loading, newContactModalVisible, editContactModalVisible, contacts } =
    useSelector((state: RootState) => state.contacts);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const sortedContactsList = () => {
    const contactsList = [...contacts];
    return contactsList.sort((a, b) => a.name!.localeCompare(b.name!));
  };

  const filteredContacts = filterItems(
    sortedContactsList() || [],
    ['name', 'category'],
    search
  );

  const openContactDetails = (contactId: number) => {
    gaBtnClickEvent('open_contact_details');
    platformType === HKPlatform.DESKTOP && isDesktopWidth
      ? dispatch(showEditContactsModal(contactId))
      : history.push(`/contacts/${contactId}`);
  };

  const openNewContactModal = () => {
    gaBtnClickEvent('open_new_contact_modal');
    dispatch(showNewContactsModal());
  };

  const closeTask = () => {
    if (!!homeId) {
      dispatch(fetchContactList(homeId));
    }
    dispatch(hideNewContactsModal());
    dispatch(hideEditContactsModal());
  };

  useEffect(() => {
    if (!!homeId) {
      dispatch(fetchContactList(homeId));
    }
  }, [dispatch, homeId]);

  function DesktopContactsView() {
    return (
      <>
        <IonRow className="hk-desktop-digital-contacts-header">
          <div className="hk-desktop-digital-contacts-header-container">
            <div className="hk-desktop-digital-contacts-search">
              <SearchBar
                value={search}
                disabled={sortedContactsList().length === 0}
                onSearchChange={(e) => setSearch(e)}
              ></SearchBar>
            </div>
            <div className="hk-mobile-digital-contacts-header-button">
              <IonButton
                expand="block"
                color="primary"
                strong={true}
                onClick={() => openNewContactModal()}
              >
                Add New Contact
              </IonButton>
            </div>
          </div>
        </IonRow>
        <IonContent className="hk-desktop-digital-contacts">
          <LoadingIonList
            label="Contacts"
            className="hk-desktop-digital-contacts-list"
            loading={loading}
            items={sortedContactsList()}
          >
            <>
              {filteredContacts.length ? (
                <>
                  {filteredContacts.map((contact: any, i: any) => {
                    const { name, label, email, mobile_phone, url, id } =
                      contact;
                    return (
                      <ListItem
                        key={`item-${i}`}
                        startLabel={name.charAt(0)}
                        contactsView={true}
                        title={name}
                        url={url}
                        description={label || 'Sample category'}
                        email={email}
                        phone={mobile_phone}
                        endIcon={callOutline}
                        onClick={() => openContactDetails(id)}
                      />
                    );
                  })}
                </>
              ) : (
                <div className="hk-contacts-empty">
                  <h1>No Matches Found</h1>
                  <p>
                    The thing you are searching for isn't here. Please try
                    another search.
                  </p>
                  <p>You can also add a contact above.</p>
                </div>
              )}
            </>
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  function MobileContactsView() {
    return (
      <>
        <MenuHeader title={'Contacts'} />
        <IonContent fullscreen className="hk-digital-contacts">
          <SearchBar
            value={search}
            disabled={sortedContactsList().length === 0}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList
            label="Contacts"
            className="hk-digital-contacts-list"
            loading={loading}
            items={sortedContactsList()}
          >
            <>
              {filteredContacts.length ? (
                <>
                  {filteredContacts.map((contact: any, i: any) => {
                    const { name, label, id } = contact;
                    return (
                      <ListItem
                        key={`item-${i}`}
                        startLabel={name.charAt(0)}
                        endIcon={ArrowRight}
                        title={name}
                        description={label || 'Sample category'}
                        onClick={() => openContactDetails(id)}
                      />
                    );
                  })}
                </>
              ) : (
                <div className="hk-contacts-empty">
                  <h1>No Matches Found</h1>
                  <p>
                    The thing you are searching for isn't here. Please try
                    another search.
                  </p>
                  <p>You can also add a contact below.</p>
                </div>
              )}
            </>
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="hk-mobile-digital-contacts-toolbar">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={() => openNewContactModal()}
          >
            Add New Contact
          </IonButton>
        </IonToolbar>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-digital-contacts-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopContactsView()
        : MobileContactsView()}
      <IonModal
        className="hk-digital-new-contacts-modal"
        isOpen={newContactModalVisible}
        onDidDismiss={() => {
          closeTask();
        }}
      >
        <NewContact />
      </IonModal>
      <IonModal
        className="hk-digital-edit-contacts-modal"
        isOpen={editContactModalVisible}
        onDidDismiss={() => {
          closeTask();
        }}
      >
        <EditContact />
      </IonModal>
    </IonPage>
  );
};

export default Contacts;
