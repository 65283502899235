import {
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
  IonList,
  IonIcon
} from '@ionic/react';
import React from 'react';
import './CatalogAccordion.scss';
import CompleteIcon from '../../../assets/icons/complete.svg';
import ArrowIcon from '../../../assets/icons/arrow-right.svg';

type AccordionProps = {
  label?: string;
  value?: string;
  description?: string;
  complete?: boolean;
  className?: string;
};

const CatalogAccordion: React.FC<AccordionProps> = ({
  label = 'Items',
  value = '',
  description = '',
  complete = null,
  className,
  children,
  ...rest
}) => {
  return (
    <IonAccordionGroup
      multiple={true}
      value={complete ? undefined : value}
      className={className}
    >
      <IonAccordion
        className="hk-accordion"
        toggleIcon={ArrowIcon}
        toggleIconSlot={'start'}
        value={value}
      >
        <IonItem className="hk-accordion-header" slot="header">
          <IonLabel>{label}</IonLabel>
          {complete !== null && (
            <div className="hk-accordion-header-task">
              {complete === true ? (
                <IonIcon color="primary" icon={CompleteIcon} />
              ) : (
                <div className="hk-accordion-header-task-circle"></div>
              )}
            </div>
          )}
        </IonItem>

        <IonList className="hk-accordion-content" slot="content">
          {children}
        </IonList>
      </IonAccordion>
    </IonAccordionGroup>
  );
};

export default CatalogAccordion;
