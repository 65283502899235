import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  IonPage,
  IonContent,
  IonModal,
  IonButton,
  IonToolbar,
  IonRow,
  IonBreadcrumbs,
  IonBreadcrumb
} from '@ionic/react';
import ListCard from 'components/cards/list/ListCard';
import SearchBar, { filterItems } from 'components/searchbar/SearchBar';
import { HKPlatform } from 'core/constants';
import BackButtonHeader from 'core/layout/BackButtonHeader';
import LoadingIonList from 'core/layout/LoadingIonList';
import { InventoryItem } from 'core/types';
import { useHistory, useParams } from 'react-router';
import InventoryDetails from './InventoryDetails';
import './InventoryProducts.scss';
import InventoryAddProduct from './InventoryAddProduct';
import {
  showAddItemModal,
  hideAddItemModal,
  showItemDetailsModal,
  resetInventoryDetails,
  showDesktopItemDetails,
  ProductGroup
} from 'features/shared/inventory/InventorySlice';
import {
  requestProductGroup,
  requestProductGroupCount
} from 'features/shared/inventory/InventoryActions';
import { gaBtnClickEvent } from 'core/util';

interface ItemParams {
  id?: string | undefined;
}

const InventoryProducts: React.FC = () => {
  const { id } = useParams<ItemParams>();
  const homeId = useSelector((state: RootState) => state.home.currentHome?.id);
  const {
    products,
    productGroups,
    loading,
    addItemModalVisible,
    itemDetailsModalVisible
  } = useSelector((state: RootState) => state.inventory);
  const { platformType, isDesktopWidth } = useSelector(
    (state: RootState) => state.platform
  );
  const [search, setSearch] = useState('');
  const [group, setGroup] = useState<ProductGroup>({
    id: 0,
    count: 0,
    group: ''
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const filteredInventory = filterItems(
    products || [],
    [
      'product_template.manufacturer_name',
      'product_template.type',
      'product_template.model',
      'name',
      'serial'
    ],
    search
  );

  const showDetailsModal = (id: number) => {
    dispatch(showItemDetailsModal(id));
  };

  const closeDetailsModal = () => {
    if (!!homeId) {
      dispatch(requestProductGroupCount(homeId));
    }
    dispatch(hideAddItemModal());
  };

  const openAddItemModal = () => {
    gaBtnClickEvent('add_item');
    dispatch(showAddItemModal());
  };

  const closeAddItemModal = () => {
    if (!!homeId) {
      dispatch(requestProductGroupCount(homeId));
    }
    dispatch(hideAddItemModal());
  };

  const goToInventoryDetailsPage = (productId: number) => {
    if (id !== undefined) {
      dispatch(resetInventoryDetails());
      dispatch(showDesktopItemDetails(productId));
      history.push(`/inventory/group/${id}/product/${productId}`);
    }
  };

  const imageForItem = (item: InventoryItem): string => {
    if (!!item.image) {
      return item.image;
    }
    if (!!item.product_template.manufacturer_image) {
      return item.product_template.manufacturer_image;
    }
    return '';
  };

  useEffect(() => {
    if (!!homeId && !!id) {
      dispatch(requestProductGroup(homeId, id));
    }
  }, [dispatch, homeId]);

  useEffect(() => {
    if (!!productGroups && !group.count) {
      const g = productGroups?.find((group) => group.id === Number(id));
      if (!!g) {
        setGroup(g);
      }
    }
  }, [productGroups, id]);

  function DesktopInventoryProductsView() {
    return (
      <>
        <IonRow className="hk-desktop-digital-inventory-products-header">
          <div className="hk-desktop-digital-inventory-products-header-container">
            <IonBreadcrumbs className="hk-desktop-digital-inventory-products-header-breadcrumbs">
              <IonBreadcrumb href="/inventory">Inventory</IonBreadcrumb>
              <IonBreadcrumb>{group.group}</IonBreadcrumb>
            </IonBreadcrumbs>
            <div className="hk-desktop-digital-inventory-products-header-right">
              <div className="hk-desktop-digital-inventory-products-search">
                <SearchBar
                  value={search}
                  disabled={products?.length === 0}
                  onSearchChange={(e) => setSearch(e)}
                ></SearchBar>
              </div>
              <div className="hk-desktop-digital-inventory-products-header-button">
                <IonButton
                  expand="block"
                  color="primary"
                  strong={true}
                  onClick={() => openAddItemModal()}
                >
                  Add Item
                </IonButton>
              </div>
            </div>
          </div>
        </IonRow>
        <IonContent className="hk-desktop-digital-inventory-products">
          <LoadingIonList
            className="hk-desktop-digital-inventory-products-list"
            label={'Items In Inventory'}
            description={'There are no items of this kind added yet.'}
            loading={loading}
            items={filteredInventory}
          >
            {filteredInventory.map((product: any, i: any) => {
              const { id, name, serial, product_template } = product;
              const image = imageForItem(product);
              const css =
                image?.indexOf('/static/media') === -1 ? '' : 'no-image';
              return (
                <ListCard
                  className={css}
                  key={`item-${i}`}
                  title={!!name ? name : product_template.name}
                  descriptionTitleOne="Model"
                  descriptionOne={'#' + product_template.model}
                  descriptionTitleTwo="SKU"
                  descriptionTwo={!!serial ? '#' + serial : 'N/A'}
                  image={image}
                  onClick={() => {
                    goToInventoryDetailsPage(id);
                  }}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
      </>
    );
  }

  function MobileInventoryProductsView() {
    return (
      <>
        <BackButtonHeader title={group.group} />
        <IonContent fullscreen>
          <SearchBar
            value={search}
            disabled={products?.length === 0}
            onSearchChange={(e) => setSearch(e)}
          ></SearchBar>
          <LoadingIonList
            label={'Items In Inventory'}
            description={'There are no items of this kind added yet.'}
            loading={loading}
            items={filteredInventory}
            className="hk-digital-inventory-products-list ion-margin-horizontal"
          >
            {filteredInventory.map((product: any, i: any) => {
              const { id, name, product_template } = product;
              const image = imageForItem(product);
              const css =
                image?.indexOf('/static/media') === -1 ? '' : 'no-image';
              return (
                <ListCard
                  className={css}
                  key={`item-${i}`}
                  title={!!name ? name : product_template.name}
                  descriptionOne={'Model #' + product_template.model}
                  image={image}
                  onClick={() => {
                    showDetailsModal(id);
                  }}
                />
              );
            })}
          </LoadingIonList>
        </IonContent>
        <IonToolbar className="hk-mobile-digital-inventory-products-toolbar">
          <IonButton
            expand="block"
            color="primary"
            strong={true}
            onClick={() => openAddItemModal()}
          >
            Add Item
          </IonButton>
        </IonToolbar>
        <IonModal
          className="hk-digital-inventory-details-modal"
          isOpen={itemDetailsModalVisible}
          onDidDismiss={() => {
            closeDetailsModal();
          }}
        >
          <InventoryDetails />
        </IonModal>
      </>
    );
  }

  return (
    <IonPage
      className={
        platformType === HKPlatform.DESKTOP && isDesktopWidth
          ? 'hk-desktop-digital-inventory-products-page'
          : ''
      }
    >
      {platformType === HKPlatform.DESKTOP && isDesktopWidth
        ? DesktopInventoryProductsView()
        : MobileInventoryProductsView()}
      <IonModal
        className="hk-digital-inventory-add-product-modal"
        isOpen={addItemModalVisible}
        onDidDismiss={() => {
          closeAddItemModal();
        }}
      >
        <InventoryAddProduct />
      </IonModal>
    </IonPage>
  );
};

export default InventoryProducts;
